import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const Managefollowup = () => {
  const [viewModal, setViewModal] = useState(false);
  const [fdata, setfdata] = useState([]);

  const [date, setDate] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [leadType, setLeadType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [dealType, setDealType] = useState("");
  const [nextMeetingDate, setNextMeetingDate] = useState("");
  const [message, setMessage] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const openModal = () => {
    setViewModal(true);
  };

  const closeModal = () => {
    setViewModal(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const getAllFollowUp = async () => {
    try {
      const res = await axios.get("/api/v1/followup/getAllFollowUp");
      if (res.data.success) {
        setfdata(res.data.data);
        toast.success(res.data.message);
      }
    } catch (error) {
      toast.error("error data not found...");
    }
  };

  const singleItemFollowUp = async (_id) => {
    try {
      const user = await axios.get("/api/v1/followup/readFollowUp/" + _id);
      setDate(user.data.date);
      setBusinessName(user.data.businessName);
      setBusinessAddress(user.data.businessAddress);
      setBusinessWebsite(user.data.businessWebsite);
      setBusinessEmail(user.data.businessEmail);
      setContactPersonName(user.data.contactPersonName);
      setContactPersonNumber(user.data.contactPersonNumber);
      setLeadType(user.data.leadType);
      setServiceName(user.data.serviceName);
      setDealType(user.data.dealType);
      setNextMeetingDate(user.data.nextMeetingDate);
      setMessage(user.data.message);
      setOfferPrice(user.data.offerPrice);
      openModal();
    } catch (error) {
      console.error("Error fetching follow-up item:", error);
      toast.error("Error fetching follow-up item");
    }
  };

  useEffect(() => {
    getAllFollowUp();
  }, []);

  const handleViewClick = (_id) => {
    singleItemFollowUp(_id);
    setViewModal(true);
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Follow Up
      </h2>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.no
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Business name
              </th>
              <th scope="col" class="px-6 py-3">
                Business address
              </th>
              <th scope="col" class="px-6 py-3">
                Contact person name
              </th>
              <th scope="col" class="px-6 py-3">
                Contact person number
              </th>
              <th scope="col" class="px-6 py-3">
                Deal type
              </th>
              <th scope="col" class="px-6 py-3">
                Next meeting date
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {fdata
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((item, index) => (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" class="px-10 py-4">
                    {index + 1}
                  </th>
                  <td class="px-6 py-4 text-nowrap">{item.date}</td>
                  <td class="px-6 py-4">{item.businessName}</td>
                  <td class="px-6 py-4">{item.businessAddress}</td>
                  <td class="px-6 py-4">{item.contactPersonName}</td>
                  <td class="px-6 py-4">{item.contactPersonNumber}</td>
                  <td class="px-6 py-4">{item.dealType}</td>
                  <td class="px-6 py-4">{item.nextMeetingDate}</td>
                  <td class="px-6 py-4">
                    <Link
                      className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0	hover:bg-white hover:border-0"
                      onClick={() => handleViewClick(item._id)}
                    >
                      view
                    </Link>{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <Modal
          isOpen={viewModal}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "60%",
              height: "77%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          <div className="addstaff-container text-black p-10 px-4 py-8">
            <form className="max-w-5xl mx-auto">
              <h1 className="text-3xl font-bold underline mb-4 text-center">
                Follow Up
              </h1>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 ">
                <div className="mb-3 mx-4">
                  <label htmlFor="date" className="block font-bold">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    readOnly
                    value={date}
                    className="w-full px-4 py-2 text-black border rounded-md text-nowrap"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="businessName" className="block font-bold">
                    Business name
                  </label>
                  <input
                    type="text"
                    id="businessName"
                    name="businessName"
                    readOnly
                    value={businessName}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
                <div className="mb-3 mx-4">
                  <label htmlFor="businessAddress" className="block font-bold">
                    Business address
                  </label>
                  <input
                    type="text"
                    id="businessAddress"
                    name="businessAddress"
                    readOnly
                    value={businessAddress}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="businessWebsite" className="block font-bold">
                    Business website
                  </label>
                  <input
                    type="text"
                    id="businessWebsite"
                    name="businessWebsite"
                    readOnly
                    value={businessWebsite}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="businessEmail" className="block font-bold">
                    Business Email
                  </label>
                  <input
                    type="email"
                    id="businessEmail"
                    name="businessEmail"
                    readOnly
                    value={businessEmail}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label
                    htmlFor="contactPersonName"
                    className="block font-bold"
                  >
                    Contact person name
                  </label>
                  <input
                    type="text"
                    id="contactPersonName"
                    name="contactPersonName"
                    readOnly
                    value={contactPersonName}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label
                    htmlFor="contactPersonNumber"
                    className="block font-bold"
                  >
                    Contact person number
                  </label>
                  <input
                    type="tel"
                    id="contactPersonNumber"
                    name="contactPersonNumber"
                    readOnly
                    value={contactPersonNumber}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="leadType" className="block font-bold">
                    Lead Type
                  </label>
                  <select
                    id="leadType"
                    name="leadType"
                    disabled
                    value={leadType}
                    className="w-full px-4 py-2 border text-black rounded-md"
                  >
                    <option value="">Select Lead Type</option>
                    <option value="cold">cold</option>
                    <option value="warm">warm</option>
                    <option value="hot">hot</option>
                  </select>
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="serviceName" className="block font-bold">
                    Service name
                  </label>
                  <select
                    id="serviceName"
                    name="serviceName"
                    disabled
                    value={serviceName}
                    className="w-full px-4 py-2 border text-black rounded-md"
                  >
                    <option value="">Select Service name</option>
                    <option value="createService">create service</option>
                  </select>
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="dealType" className="block font-bold">
                    Deal type
                  </label>
                  <select
                    id="dealType"
                    name="dealType"
                    value={dealType}
                    disabled
                    className="w-full px-4 py-2 border text-black rounded-md"
                  >
                    <option value="">Select Deal Type</option>
                    <option value="followUp">follow up</option>
                    <option value="dealFinal">deal final</option>
                    <option value="dealCancel">deal cancel</option>
                    <option value="postponed">postponed</option>
                  </select>
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="nextMeetingDate" className="block font-bold">
                    Next meeting date
                  </label>
                  <input
                    type="date"
                    id="nextMeetingDate"
                    name="nextMeetingDate"
                    value={nextMeetingDate}
                    readOnly
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="message" className="block font-bold">
                    Message
                  </label>
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    readOnly
                    value={message}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-3 mx-4">
                  <label htmlFor="offerPrice" className="block font-bold">
                    Offer price
                  </label>
                  <input
                    type="number"
                    id="offerPrice"
                    name="offerPrice"
                    value={offerPrice}
                    readOnly
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
              </div>

              <div className="flex justify-end ">
                <button
                  type="button"
                  className="bg-green-500 hover:bg-green-600 text-black py-1 px-7 rounded mb-4 mx-4 mt-4"
                  onClick={handleCancel}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <Toaster />
    </div>
  );
};

export default Managefollowup;
