import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const CreateCalenderHolidays = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [holidayDescription, setHolidayDescription] = useState("");
  const [addedHolidays, setAddedHolidays] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/calenderholiday/findAllCalenderHoliday"
        );
        if (response.data.success) {
          const rowDataToSet = [];
          response.data.CalenderHolidaydata.forEach((calendarItem, index) => {
            
              if (calendarItem && Array.isArray(calendarItem.row)) {
                  
                  calendarItem.row.forEach(rowData => {
                      rowDataToSet.push(rowData);
                  });
              }
          });         
          setCalendarData(rowDataToSet);
      }
      
        else {
          toast.error("Failed to fetch holidays");
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
        toast.error("An error occurred while fetching holidays.");
      }
    };
    fetchData();
  }, []);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];
  
    for (let i = 1; i <= daysInMonth; i++) {
      const date = `${year}-${String(month + 1).padStart(2, "0")}-${String(
        i
      ).padStart(2, "0")}`;
      const holiday = calendarData.find(
        (item) => item.date.substring(0, 10) === date
      );
      const holidayDescription = holiday ? holiday.description : "";
      const fontSize = window.innerWidth <= 425 ? "8px" : "16px";
      
      // Calculate width based on window width and days in month
      const width = `${95}%`;
      
      calendar.push(
        <div
          key={date}
          className={`calendar-day ${holiday ? "holiday" : ""} ${
            selectedDate === date ? "selected" : ""
          }`}
          onClick={() => handleDateClick(date)}
          style={{
            border: "2px solid #ccc",
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: holiday ? "rgb(244 63 94)" : "",
            width: width, // Set width dynamically
          }}
        >
          <div className='box' style={{ textAlign: "center" }}> {/* Center align content */}
            <span className="day" style={{ color: holiday ? "white" : "" }}>
              {i}
            </span>
            <br />
            {showDescription && holiday && (
              <span
                className="column-1"
                style={{
                  color: "white",
                  // fontFamily: "sans-serif",
                  fontSize: fontSize,
                }}
              >
                {holidayDescription}
              </span>
            )}
          </div>
        </div>
      );
    }
    return calendar;
  };
  
  
  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };


  return (
    <div className="addstaff-container calendar p-4">
      <h1 className="text-center text-2xl underline">Calendar</h1>
      <div className="mt-4 mb-4">
        <button
          onClick={toggleDescription}
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {showDescription ? "Hide festivals" : "Show festivals"}
        </button>
      </div>
      <div className="calendar-header mb-4 flex justify-between items-center">
        <button className="text-xl font-bold" onClick={handlePreviousMonth}>
          &lt;
        </button>
        <h2 className="text-xl font-bold">
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button className="text-xl font-bold" onClick={handleNextMonth}>
          &gt;
        </button>
      </div>
      <div className="calendar-grid grid grid-cols-7  md:gap-2 lg:gap-2 xxl:gap:2  gap-[-3rem] ">
        <div className="calendar-day-header">Sun</div>
        <div className="calendar-day-header">Mon</div>
        <div className="calendar-day-header">Tue</div>
        <div className="calendar-day-header">Wed</div>
        <div className="calendar-day-header">Thu</div>
        <div className="calendar-day-header">Fri</div>
        <div className="calendar-day-header">Sat</div>
        {renderCalendar()}
      </div>
      <div
        className="item-end"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
      
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateCalenderHolidays;
