import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../components/context/auth";
const ManageQuotationStaff = () => {
  const [quotationData, setQuotationData] = useState([]);
  const [selectedQuotation, setSelectedQuotation] = useState(null);

  const [mcompanyData, setCompanyData] = useState([]);

  const textAreaRefs = useRef([]);
  useEffect(() => {
    if (selectedQuotation) {
      textAreaRefs.current.forEach((textarea) => {
        if (textarea) {
          textarea.style.height = "auto";
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      });
    }
  }, [selectedQuotation]);

  const [auth] = useAuth();
  // change by dheerendra
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("/api/v1/company/getCompanyDetails");
        setCompanyData(response.data[0]);
        // console.log(response,"company data ")
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`
          /api/v1/quation/managesingleQuation/${auth.duser.id}`);
        // console.log(response,"single Quotation data ")
        setQuotationData(response.data.data);
      } catch (error) {
        console.error("Error fetching manageQuationData data:", error);
      }
    };

    fetchData();
  }, []);

  const openModal = (quotation) => {
    setSelectedQuotation(quotation);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedQuotation(null);
  };

  const printModel = () => {
    window.print();
  };

  if (!Array.isArray(quotationData)) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-container">
      <style>
        {`
           @media print {
            .hide-on-print {
              display: block;
            }
            /* Hide unnecessary elements when printing */
            body * {
              visibility: hidden;
   
            }
            .print,
            .print * {
              visibility: visible;
            }
            .print {
              position: absolute;
              left: 0;
              top: 0;
            }
          
            /* Adjust page size and margins for A4 paper */
            @page {
              size: A4;
              margin: 0;
            }
          
            /* Ensure content fits within the A4 page size */
            html, body {
              width: 500mm;
              // height: 1000mm;
            }
            .hide-on-print {
              display: none;
            }
          }
        `}
      </style>
      <h2 className="text-3xl text-black font-bold mb-8 text-center underline ">
        Manage Quotation
      </h2>

      {/* // change by dheerendra */}
      <div className="flex flex-col text-1xl p-5 font-bold  sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3  text-gray-600 ">
        <div className="sm:flex sm:flex-col-2 items-stretch justify-end flex-shrink-0 w-full md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
            <input
              className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.startDate}
              onChange={(e) =>
                setSearch({ ...search, startDate: e.target.value })
              }
              placeholder="enter start date"
            />

            <input
              className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.endDate}
              onChange={(e) =>
                setSearch({ ...search, endDate: e.target.value })
              }
              placeholder="enter end date"
            />
          </div>
        </div>{" "}
      </div>

      {/* /////////////////////////////// */}

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className=" text-center text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr className="">
              <th scope="col" className="px-6 py-2">
                S.no
              </th>
              <th scope="col" className="px-6 ">
                Date
              </th>
              <th scope="col" className="px-6 ">
                Sales Person
              </th>
              <th scope="col" className="px-6 ">
                Quotation to
              </th>
              <th scope="col" className="px-6 ">
                Address
              </th>
              <th scope="col" className="px-6 ">
                Contact
              </th>
              {/* Add more fields as needed */}
              <th scope="col" className="px-6  text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {quotationData &&
              // change by dheerendra
              quotationData
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((manageQuationData, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <td className="px-6 py-4  text-nowrap">
                      {new Date(manageQuationData.date).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }
                      )}
                    </td>
                    <td className="px-6 py-4">
                      {manageQuationData.salespersonBy}
                    </td>
                    <td className="px-6 py-4">{manageQuationData.name}</td>

                    <td className="px-6 py-4">{manageQuationData.addressBy}</td>
                    <td className="px-6 py-4">{manageQuationData.contactTo}</td>

                    {/* Add more fields as needed */}
                    <td className=" flex ga">
                      <button
                        onClick={() => openModal(manageQuationData)}
                        className=" font-medium text-blue-600 mr-2 dark:text-blue-500 hover:underline"
                      >
                        View
                      </button>
                      <Link to="/admin/dashboard/invoice">
                        <button className="whitespace-nowrap font-medium text-blue-600 dark:text-blue-500 hover:underline">
                          Create Invoice
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
      {selectedQuotation && (
        <div className="fixed inset-0 z-10 print overflow-x-auto overflow-y-auto">
          <div className=" md:p-6 lg:p-6 bg-white  shadow-sm my-6">
            <form>
              <h2 className="text-3xl text-black  mb-8 text-center  ">
                {mcompanyData.businessName}
              </h2>
              <div className=" px-4 lg:px-0">
                <div className="grid grid-cols-2   sm:p-[0.5rem] bg-white shadow-sm ">
                  <div className="border border-gray-200  p-5 rounded-lg">
                    <h2 className="text-xl font-semibold ">Quotation By -</h2>
                    <p className="text-fuchsia-400 ">
                      {mcompanyData.businessName}
                    </p>

                    <div className="space-y-1 ">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Address
                        </span>
                        <input
                          className="ml-2  flex-1"
                          id="addressBy"
                          name="addressBy"
                          value={selectedQuotation.addressBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Contact
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="contactBy"
                          id="contactBy"
                          value={selectedQuotation.contactBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          State
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="state"
                          value={selectedQuotation.stateBy}
                          id="state"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Website
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="gstin"
                          value={mcompanyData.website}
                          id="gstin"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          GSTIN
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="gstin"
                          value={selectedQuotation.gstInBy}
                          id="gstin"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Sales Person
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="salespersonBy"
                          value={selectedQuotation.salespersonBy}
                          id="salespersonBy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <h2 className="text-xl font-semibold ">Quotation To -</h2>
                    <div className="space-y-1">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Date
                        </span>
                        <input
                          type="text"
                          className="ml-2  flex-1"
                          value={new Date(
                            selectedQuotation.date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Quotation No
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="qutationNo"
                          value={selectedQuotation.qutationNo}
                          id="qutationNo"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Name
                        </span>
                        <input
                          className=" ml-2 flex-1"
                          name="name"
                          value={selectedQuotation.name}
                          id="name"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Address
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="address"
                          value={selectedQuotation.addressTo}
                          id="address"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          State
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="state"
                          value={selectedQuotation.stateTo}
                          id="state"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          Contact
                        </span>
                        <input
                          className="ml-2  flex-1"
                          name="contact"
                          value={selectedQuotation.contactTo}
                          id="contact"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold   md:mb-[-24px]">
                          GSTIN
                        </span>
                        <input
                          className=" ml-2 flex-1"
                          name="gstin"
                          value={selectedQuotation.gstinTo}
                          id="gstin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  table data rendring  */}
              <div className="-mx-4  flow-root sm:mx-0 overflow-x-auto p-5">
                <table className="min-w-full border ">
                  <thead className="border-b border-gray-300 text-gray-900 border">
                    <tr className="text-left border shadow-md bg-gr00">
                      <th scope="col" className="ml-1  border">
                        S.No.
                      </th>
                      <th scope="col" className="ml-1 w-1/3 border">
                        Description
                      </th>
                      <th scope="col" className="ml-1  border">
                        HSN
                      </th>
                      <th scope="col" className="ml-1  border">
                        Qty
                      </th>
                      <th scope="col" className="ml-1  border">
                        UOM
                      </th>
                      <th scope="col" className="ml-1  border">
                        Rate
                      </th>
                      <th scope="col" className="ml-1  border">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log(selectedQuotation, "row")} */}
                    {selectedQuotation.rows.map((rowData, index) => (
                      <tr key={index} className="border   ">
                        <td className="border text-center">{index + 1}</td>
                        <td className="border w-1/3">
                          <td className="border w-1/3">
                            <textarea
                              ref={(el) =>
                                (textAreaRefs.current[index + 5] = el)
                              }
                              className="w-full resize-none overflow-hidden border-none  "
                              value={rowData.description}
                              rows="1"
                              readOnly
                            />
                          </td>
                        </td>
                        <td className="border ">
                          <input
                            className="w-[100%]"
                            value={rowData.hsn}
                            readOnly
                          />
                        </td>
                        <td className="border ">
                          <input
                            className="w-[100%]"
                            value={rowData.qty}
                            readOnly
                          />
                        </td>
                        <td className="border ">
                          <input
                            className="w-[100%]"
                            value={rowData.uom}
                            readOnly
                          />
                        </td>
                        <td className="border ">
                          <input
                            className="w-[100%]"
                            value={rowData.rate}
                            readOnly
                          />
                        </td>
                        <td className="border ">
                          <input
                            className="w-[100%]"
                            value={rowData.amount}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Subtotal & Net Amount */}
              <div className="flex   justify-between ">
                <div className="ml-5 mt-1  w-1/2 h-full ">
                  <div className="shadow-md rounded-lg p-6 overflow-hidden w-3xl border">
                    <div className="text-center  text-2xl text-bold text-black  rounded">
                      Special Notes and Terms:
                    </div>

                    <div className="text-center  text-black ">
                      50% advance payment is applicable.Payment
                    </div>
                    <div className="text-center  text-black ">
                      to be made in Business Account only.
                    </div>
                  </div>
                </div>
                <div className="mt-1 mr-5 w-1/3 h-full">
                  <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                    <table className="w-full border">
                      <tfoot>
                        <tr className="bg-gray-100">
                          <th
                            scope="row"
                            className="px-4 py-1 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                          >
                            Subtotal
                          </th>
                          <td>
                            <input
                              type="text"
                              name="subtotal"
                              className="px-4 py-1 font-bold text-sm text-gray-500 w-full  rounded-md"
                              value={selectedQuotation.subtotal}
                              readOnly
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-200">
                          <th
                            scope="row"
                            className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                          >
                            Discount ( if any)
                          </th>
                          <td>
                            <input
                              type="number"
                              className="px-4 py-1 font-bold text-sm text-gray-500 w-full  rounded-md"
                              value={selectedQuotation.discount}
                              name="discount"
                              // onChange={(e) => setDiscount(parseFloat(e.target.value))}
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <th
                            scope="row"
                            className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                          >
                            Net Amount
                          </th>
                          <td>
                            <input
                              type="text"
                              name="netAmount"
                              className="px-4 py-1 font-bold text-sm text-gray-500 w-full  rounded-md"
                              value={selectedQuotation.netAmount}
                              readOnly
                            />
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <th
                            scope="row"
                            className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                          >
                            Net Amount Words
                          </th>
                          <td>
                            <textarea
                              type="text"
                              name="netAmount"
                              className="px-4 py-1 font-bold text-sm text-gray-500  w-full rounded-md"
                              value={selectedQuotation.netAmountWords}
                              readOnly
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
              <button
                className=" hide-on-print text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                className=" hide-on-print text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={printModel}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageQuotationStaff;
