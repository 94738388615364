import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import EditStaffModal from "./modals/EditStaffModal";
import ViewStaffModal from "./modals/ViewStaffModal";

const Managestaff = () => {
  const [staffData, setStaffData] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [teamNames, setTeamNames] = useState({});
  const [deptNames, setDeptNames] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        setStaffData(response.data.data);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    fetchData();
  }, [editModalVisible]);

  useEffect(() => {
    const fetchTeamNames = async () => {
      const names = {};
      for (const staff of staffData) {
        try {
          const response = await axios.get(
            `/api/v1/team/getById/${staff.team}`
          );
          names[staff.team] = response.data.name;
        } catch (error) {
          console.error(
            `Error fetching team name for team ${staff.team}:`,
            error
          );
          names[staff.team] = "Unknown";
        }
      }
      setTeamNames(names);
    };

    fetchTeamNames();
  }, [staffData]);

  useEffect(() => {
    const fetchDeptNames = async () => {
      const names = {};
      for (const staff of staffData) {
        try {
          const response = await axios.get(
            `/api/v1/depart/getSingledepart/${staff.department}`
          );
          names[staff.department] = response.data.data.name;
        } catch (error) {
          console.error(
            `Error fetching department name for department ${staff.department}:`,
            error
          );
          names[staff.department] = "Unknown";
        }
      }
      setDeptNames(names);
    };

    fetchDeptNames();
  }, [staffData]);

  useEffect(() => {
    // Filter staff based on search query
    const filtered = staffData.filter(
      (staff) =>
        staff.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        staff.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        staff.contact.includes(searchQuery) ||
        staff.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        deptNames[staff.department]
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        teamNames[staff.team].toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredStaff(filtered);
  }, [searchQuery, staffData, deptNames, teamNames]);

  const handleDelete = async (id) => {
    try {
      if (prompt("Are u sure to delete...! press yes") === "yes") {
        const staffToDelete = staffData.find((staff) => staff._id === id);
        setSelectedStaff(staffToDelete);

        await axios.delete(`/api/v1/staff/deleteStaff/${id}`);
        setStaffData(staffData.filter((staff) => staff._id !== id));
        toast.success("Staff Delete successfully");
      } else {
        toast.info("You Not pressing  yes...!");
      }
    } catch (error) {
      console.error("Error deleting staff:", error);
    }
  };

  
  const handleView = (staff) => {
    setSelectedStaff(staff);
    setViewModalVisible(true);
    setEditModalVisible(false); // Ensure edit modal is closed
  };

  const handleEdit = (staff) => {
    setSelectedStaff(staff);
    setEditModalVisible(true);
    setViewModalVisible(false); // Ensure view modal is closed
  };
  const handleCloseModal = () => {
    setSelectedStaff(null);
    setViewModalVisible(false);
    setEditModalVisible(false); // Ensure both modals are closed
  };

  return (
    <div className="main-container">
      <h2 className="text-3xl font-bold mb-8 text-center underline text-red-500">
        Manage Staff
      </h2>
      {/* Render the modal component conditionally */}
      {viewModalVisible && selectedStaff && (
        <ViewStaffModal staff={selectedStaff} onClose={handleCloseModal} />
      )}

      {editModalVisible && selectedStaff && (
        <EditStaffModal staff={selectedStaff} onClose={handleCloseModal} />
      )}
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {/* Search input */}
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search here"
          className="input-field mb-4"
        />
        <table className="w-full text-sm rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-2 py-3 text-center">
                S.no
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                id
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Name
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Designation
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Department
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Team
              </th>
              <th scope="col" className="px-6 py-3 text-center">
                Contact
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                photo
              </th>
              <th scope="col" className="px-2 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredStaff.map((staff, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th scope="row" className="px-2 py-4 text-center">
                  {index + 1}
                </th>
                <td className="px-2 py-4 text-center">{staff.id}</td>
                <td className="px-2 py-4 text-center">{staff.name}</td>
                <td className="px-2 py-4 text-center">{staff.designation}</td>
                <td className="px-2 py-4 text-center">
                  {deptNames[staff.department] ? deptNames[staff.department]:"loading..."}
                </td>
                <td className="px-2 py-4 text-center">
                  {teamNames[staff.team]}
                </td>
                <td className="px-2 py-4 text-center">{staff.contact}</td>
                <td className="px-2 py-4 text-center">
                  <img
                    src={`/api/v1/staff/getPhoto/${staff._id}`}
                    alt="Staff Photo"
                    className="h-12"
                  />
                </td>
                <td className="px-2 py-4 flex gap-2">
                  {/* Trigger view modal */}
                  <Link
                    onClick={() => handleView(staff)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    View
                  </Link>
                  <Link
                    onClick={() => handleEdit(staff)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </Link>
                  <Link
                    onClick={() => handleDelete(staff._id)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Delete
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Managestaff;
