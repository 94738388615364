import React from "react";

const ViewReceiptModal = ({ closeModal, receiptData }) => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="addstaff-container text-black p-8 px-4 py-8 print ">
      <style>
        {`

@media print {

  table {
    display: none;
  }

  @page {
    size: A4;
    margin: 0;
    width:50%;
  }

  .border-b {
    border-bottom: none;
  }
  body * {
    visibility: hidden;

  }
  .addstaff-container, .addstaff-container * {
    visibility: visible;
  }

  .addstaff-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  .flex {
    display: block;
  }

  .hide-on-print {
    display: none !important;
  }
  .hide-on-print button{
    display: none !important;
  }
}

.print-container {
  display: block;
  page-break-before: always;
}
        `}
      </style>

      <form className="max-w-3xl mx-auto rounded-lg shadow-md md:pl-4 md:pr-4 p-2 ">
        <div className="flex flex-col md:flex-row mt-2 text-center  text-bold">
          <span
            type="text"
            id="companyName"
            name="companyName"
            className="flex-1 text-2xl"
            readOnly
          >
            {receiptData.businessName}
          </span>
        </div>
        <div className="flex flex-col md:flex-row mt-2 text-center text-xl">
          <span
            type="text"
            id="address"
            name="address"
            className="flex-1"
            readOnly
          >
            {receiptData.address}
          </span>
        </div>
        <div className="flex justify-end">
          <button
            className="text-gray-500 hover:text-gray-700"
            style={{
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          <div className="mb-5">
            <span type="file" className="hidden text-black" />
            <div className="text-right">
              <div className="mt-2">
                <span>
                  {" "}
                  <img
                    src={`/api/v1/receipt/getPhoto/${receiptData._id}`}
                    alt="Staff Photo"
                    className="h-12"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="contact"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                Contact:
              </label>
              <span
                type="text"
                id="contact"
                name="contact"
                className="flex-1"
                readOnly
              >
                {receiptData.contactNo}
              </span>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="gmail"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                E-mail:
              </label>
              <span
                type="text"
                id="gmail"
                name="gmail"
                className="flex-1"
                readOnly
              >
                {receiptData.email}
              </span>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="website"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                Website:
              </label>
              <span
                type="text"
                id="website"
                name="website"
                className="flex-1"
                readOnly
              >
                {receiptData.website}
              </span>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="gstin"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                GSTIN:
              </label>
              <span
                type="text"
                id="gstin"
                name="gstin"
                className="flex-1"
                readOnly
              >
                {receiptData.gstIn}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h1 className="bold text- mb-4 text-3xl mb-5 mt-2 text-center underline">
            Receipt
          </h1>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="receiptNo"
                className="font-semibold mb-2 md:mb-0 md:mr-2"
              >
                Receipt No
              </label>
              <span
                type="text"
                id="receiptNo"
                name="receiptNo"
                className="w-2/3 px-2 py-1 text-black border-b border-black mb-2 md:mb-0 "
                readOnly
              >
                {receiptData.receiptNo}
              </span>
            </div>
            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label
                htmlFor="date"
                className="font-semibold mb-2 md:mb-0 md:mr-2"
              >
                Date
              </label>
              <span
                type="date"
                id="date"
                name="date"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                readOnly
              >
                {receiptData.date}
              </span>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="received" className="block font-bold mb-1">
                Received with Thanks from
              </label>
              <span
                type="text"
                id="received"
                name="received"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                readOnly
              >
                {receiptData.received}
              </span>
            </div>

            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="againstInvoice" className="block font-bold mb-1">
                Against invoice
              </label>
              <span
                type="text"
                id="againstInvoice"
                name="againstInvoice"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                readOnly
              >
                {receiptData.againstInvoice}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="for" className="block font-bold mb-1">
                For
              </label>
              <span
                type="text"
                id="fordata"
                name="fordata"
                className="w-2/3 px-4 py-2 text-black border-b border-black"
                readOnly
              >
                {receiptData.fordata}
              </span>
            </div>
            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="sumOfRupees" className="block font-bold mb-1">
                the Sum of Rupees
              </label>
              <span
                type="text"
                id="sumOfRupees"
                name="sumOfRupees"
                className="w-2/3 px-4 py-2 text-black border-b border-black"
                readOnly
              >
                {receiptData.sumOfRupees}
              </span>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="by" className="block font-bold mb-1">
                By
              </label>
              <span
                type="text"
                id="by"
                name="by"
                className="w-2/3 px-4 py-2 text-black border-b border-black"
                readOnly
              >
                {receiptData.by}
              </span>
            </div>

            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
              <label htmlFor="transactionId" className="block font-bold mb-1">
                Transaction Id
              </label>
              <span
                type="text"
                id="transactionId"
                name="transactionId"
                className="w-2/3 px-4 py-2 text-black border-b border-black"
                readOnly
              >
                {receiptData.transactionId}
              </span>
            </div>
          </div>

          <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4 mt-4">
            <label htmlFor="amount" className="block font-bold mb-1">
              Amount
            </label>
            <span
              type="number"
              id="amount"
              name="amount"
              className="w-2/3 px-4 py-2 text-black border-b border-black"
              readOnly
            >
              {receiptData.amount}
            </span>
          </div>
        </div>
        <div className="flex justify-center mt-5 ">
          <button
            style={{
              padding: "8px 24px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            className="hide-on-print "
            type="button"
            onClick={handlePrint}
          >
            print
          </button>
        </div>
      </form>
    </div>
  );
};

export default ViewReceiptModal;
