import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function CompanySetUp() {
  const [formData, setFormData] = useState({
    businessName: "",
    logo: null,
    address: "",
    pinCode: "",
    state: "",
    country: "",
    contactNo: "",
    email: "",
    website: "",
    gstApplicable: "",
    registrationType: "",
    gstIn: "",
    tanRegNo: "",
    tanNo: "",
    deductorType: "",
    otherTax: "",
    regNo: "",
  });

  const [showGSTFields, setShowGSTFields] = useState(false);
  const [showTDSFields, setShowTDSFields] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFormData({ ...formData, logo: file }); // Set logo to the selected file
  };
  const handleGSTApplicableChange = (e) => {
    const { value } = e.target;
    setShowGSTFields(value === "yes");
  };

  const handleTDSApplicableChange = (e) => {
    const { value } = e.target;
    setShowTDSFields(value === "yes");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setFormData({ ...formData, logo: file });
    };
    const formDataToSend = new FormData();

    // Append each field to the FormData object
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      for (let pair of formDataToSend.entries()) {
        console.log(pair[0], pair[1]);
      }

      const response = await axios.post(
        "/api/v1/company/create",
        formDataToSend,
        {}
      );

      if (response.data.success) {
        toast.success(response.data.message);
      }
      if (!response.data.success) {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error creating company:", error);
    }
  };

  return (
    <div className="addstaff-container max-w-screen-xl px-4 mx-auto lg:px-12 w-full my-4">
      <div className="relative bg-gray-700 dark:bg-gray-800">
        <div className="flex flex-col items-center justify-center p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 text-center text-gray-100 font-bold text-lg ">
            Company Set Up
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-gray-200">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8  ">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 justify-between p-8 space-y-3 ">
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center">
                    <div className="col-md-4 ">
                      <div className="form-group">
                        <label
                          htmlFor="BusinessName"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Business Name
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="BusinessName"
                          placeholder="business name *"
                          name="businessName"
                          value={formData.businessName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="logo"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Logo
                        </label>
                        <input
                          type="file"
                          className="form-input mt-1 block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500"
                          id="logo"
                          placeholder="Logo*"
                          name="logo"
                          onChange={handleFileChange} // Handle file input change
                        />
                        {formData.logo && (
                          <img
                            src={URL.createObjectURL(formData.logo)} // Render the uploaded logo
                            alt="Logo Preview"
                            className="mt-2"
                            style={{ maxWidth: "100px" }} // Adjust the size as needed
                          />
                        )}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Address
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="address"
                          placeholder="Address *"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="pinCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pincode
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="pinCode"
                          placeholder="Pincode *"
                          name="pinCode"
                          value={formData.pinCode}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="state"
                          className="block text-sm font-medium text-gray-700"
                        >
                          State
                        </label>
                        <select
                          className="form-input mt-1 block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500"
                          id="stateDropdown"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                        >
                          <option value="" disabled defaultValue>
                            Select state
                          </option>
                          <option value="andhra_pradesh">Andhra Pradesh</option>
                          <option value="arunachal_pradesh">
                            Arunachal Pradesh
                          </option>
                          <option value="assam">Assam</option>
                          <option value="bihar">Bihar</option>
                          <option value="chhattisgarh">Chhattisgarh</option>
                          <option value="goa">Goa</option>
                          <option value="gujarat">Gujarat</option>
                          <option value="haryana">Haryana</option>
                          <option value="himachal_pradesh">
                            Himachal Pradesh
                          </option>
                          <option value="jharkhand">Jharkhand</option>
                          <option value="karnataka">Karnataka</option>
                          <option value="kerala">Kerala</option>
                          <option value="madhya_pradesh">Madhya Pradesh</option>
                          <option value="maharashtra">Maharashtra</option>
                          <option value="manipur">Manipur</option>
                          <option value="meghalaya">Meghalaya</option>
                          <option value="mizoram">Mizoram</option>
                          <option value="nagaland">Nagaland</option>
                          <option value="odisha">Odisha</option>
                          <option value="punjab">Punjab</option>
                          <option value="rajasthan">Rajasthan</option>
                          <option value="sikkim">Sikkim</option>
                          <option value="tamil_nadu">Tamil Nadu</option>
                          <option value="telangana">Telangana</option>
                          <option value="tripura">Tripura</option>
                          <option value="uttar_pradesh">Uttar Pradesh</option>
                          <option value="uttarakhand">Uttarakhand</option>
                          <option value="west_bengal">West Bengal</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="country"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="country"
                          placeholder="Country *"
                          name="country"
                          value={formData.country}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="contactno"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Contact No
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="contactno"
                          placeholder="contactno *"
                          name="contactNo"
                          value={formData.contactNo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-mail
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="email"
                          placeholder="E-mail *"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="website"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Website
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="website"
                          placeholder="Website *"
                          name="website"
                          value={formData.website}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="gstApplicable"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Enable GST
                      </label>
                      <select
                        className="form-input mt-1 block w-56 border border-gray-300  bg-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                        id="gstApplicable"
                        name="gst_applicable"
                        onChange={handleGSTApplicableChange}
                      >
                        <option value="" disabled selected>
                          Select{" "}
                        </option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center">
                    {showGSTFields && (
                      <>
                        <div className="col-md-4">
                          <label
                            htmlFor="registrationType"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Registration Type
                          </label>
                          <select
                            className="form-input mt-1 block w-full border border-gray-300  bg-white focus:ring-indigo-500 focus:border-indigo-500"
                            id="registrationType"
                            name="registrationType"
                            value={formData.registrationType}
                            onChange={handleInputChange}
                          >
                            <option value="" disabled selected>
                              Select Registration Type
                            </option>
                            <option value="regular">Regular</option>
                            <option value="composition">Composition</option>
                            <option value="consumer">Consumer</option>
                          </select>
                        </div>
                        <div className="col-md-4" id="gstin">
                          <label
                            htmlFor="gstin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            GSTIN
                          </label>
                          <input
                            type="text"
                            className="form-input mt-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                            id="gstin"
                            placeholder="Enter GSTIN*"
                            name="gstIn"
                            value={formData.gstIn}
                            onChange={handleInputChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="form-group mt-3 w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1  justify-center">
                    <label
                      htmlFor="tdsApplicable"
                      className="block text-sm font-medium text-gray-700  "
                    >
                      Enable TDS
                    </label>
                    <select
                      className="form-input mt-1 block w-56 border border-gray-300  bg-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                      id="tdsApplicable"
                      name="tds_applicable"
                      onChange={handleTDSApplicableChange}
                    >
                      <option value="" disabled selected>
                        Select{" "}
                      </option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </div>
                  <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center">
                    {showTDSFields && (
                      <>
                        <div className="col-md-4" id="tanregno">
                          <label
                            htmlFor="tanregno"
                            className="block text-sm font-medium text-gray-700"
                          >
                            TAN Registration Number
                          </label>
                          <input
                            type="text"
                            className="form-input mt-1 block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500"
                            id="tanregno"
                            placeholder="TAN Registration Number *"
                            name="tanRegNo"
                            value={formData.tanRegNo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-4" id="tanno">
                          <label
                            htmlFor="tanno"
                            className="block text-sm font-medium text-gray-700"
                          >
                            TAN No
                          </label>
                          <input
                            type="text"
                            className="form-input mt-1 block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500"
                            id="tanno"
                            placeholder="TAN No *"
                            name="tanNo"
                            value={formData.tanNo}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <label
                            htmlFor="deductortype"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Deductor TYPE
                          </label>
                          <select
                            className="form-input mt-1 block w-full border border-gray-300  bg-white focus:ring-indigo-500 focus:border-indigo-500"
                            id="deductortype"
                            name="deductorType"
                            value={formData.deductorType}
                            onChange={handleInputChange}
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            <option value="company">Company</option>
                            <option value="individual">Individual</option>
                            <option value="llp">LLP</option>
                          </select>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 justify-center  mt-3">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="othertax"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Other Tax
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="othertax"
                          placeholder="Other Tax *"
                          name="otherTax"
                          value={formData.otherTax}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="regno"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Registration Number
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="regno"
                          placeholder="Registration Number *"
                          name="regNo"
                          value={formData.regNo}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer text-center mt-20">
                    <button
                      className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default CompanySetUp;
