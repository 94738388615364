import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from "../loader/Loader";

const LogOut = () => {
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            sessionStorage.removeItem("dauth");
            setShowSpinner(false);
             navigate("/");
           
        }, 3000); 
    }, [navigate]);

    return (
        <div className="flex addstaff-container w-full h-full justify-center items-center h-screen">
            {showSpinner && <Loader />}
            {!showSpinner && <div>LogOut</div>}
        </div>
    );
};

export default LogOut;
