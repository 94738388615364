import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Approvals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const [staffNames, setStaffNames] = useState({});
  const [selectedName, setSelectedName] = useState("");
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const [selectedStatus, setSelectedStatus] = useState("Pending"); // -9New state for dropdown value
  const [stafid, setStafid] = useState("");
  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        const response = await axios.get("/api/v1/leave/getallleaveAprove");
        setLeaveData(response.data.staff);
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };

    fetchLeaveData();
  }, []);

  useEffect(() => {
    const fetchStaffNames = async () => {
      const names = {};
      const staffIds = [];
      for (const leave of leaveData) {
        try {
          const res = await axios.get(
            `/api/v1/staff/getSingleUserById/${leave.staffId}`
          );

          staffIds.push(res.data.data.id);
          names[leave.staffId] = res.data.data.name;
        } catch (error) {
          console.error("Error fetching staff name:", error);
        }
      }
      setStafid(staffIds);
      setStaffNames(names);
    };

    fetchStaffNames();
  }, [leaveData]);

  const openModal = (leave, name) => {
    setSelectedLeave(leave);
    setSelectedName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateLeave = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `/api/v1/leave/aproveleave/${selectedLeave._id}`,
        {
          status: selectedStatus,
        }
      );

      setIsModalOpen(false);
      if (response.data.success) {
        toast.success(response.data.message);
        // Close modal on successful update
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error updating leave:", error);
    }
  };

  return (
    <div className="addstaff-container w-full">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Approvals
      </h2>
      <div className="relative overflow-x-auto max-auto overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Staff Name
              </th>
              <th scope="col" className="px-6 py-3">
                Staff ID
              </th>
              <th scope="col" className="px-6 py-3">
                Leave from
              </th>
              <th scope="col" className="px-6 py-3">
                Leave To
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(leaveData) &&
              leaveData.filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              }).map((leave, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    {new Date(leave.date).toLocaleDateString("en-GB")}
                  </td>
                  <td className="px-6 py-4">{staffNames[leave.staffId]}</td>
                  <td className="px-6 py-4">{stafid[index]}</td>
                  <td className="px-6 py-4">
                    {new Date(leave.leaveFrom).toLocaleDateString("en-GB")}
                  </td>
                  <td className="px-6 py-4">
                    {new Date(leave.leaveTo).toLocaleDateString("en-GB")}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() =>
                        openModal(leave, staffNames[leave.staffId])
                      }
                      className="text-sm text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Update Leave
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-10 bg-black text-black bg-opacity-50 overflow-auto">
          <div className="bg-white p-6 rounded-lg shadow-md h-full overflow-y-auto flex flex-col">
            <div className="flex justify-between items-center ">
              <button
                onClick={closeModal}
                className="flex-shrink-0 text-white py-2 px-4 rounded"
              >
                Close
              </button>
            </div>
            <div>
              <form className="max-w-xl mx-auto bg-white rounded-lg shadow-md p-3">
                <div>
                  <h2 className="text-2xl font-bold underline text-center mb-8">
                    Update Leave Approval
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="date" className="block font-bold mb-1">
                        Date
                      </label>
                      <input
                        value={new Date(selectedLeave.date).toLocaleDateString(
                          "en-GB"
                        )}
                        readOnly
                        className="w-full py-2 border rounded-md mb-2"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="staffName"
                        className="block font-bold mb-1"
                      >
                        Staff Name
                      </label>
                      <input
                        value={selectedName}
                        className="w-full py-2 border rounded-md mb-2"
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div>
                      <label htmlFor="date" className="block font-bold mb-1">
                        Leave From
                      </label>
                      <input
                        type="text"
                        id="date"
                        name="date"
                        value={new Date(
                          selectedLeave.leaveFrom
                        ).toLocaleDateString("en-GB")}
                        readOnly
                        className="w-full py-2 border rounded-md mb-2"
                      />
                    </div>
                    <div>
                      <label htmlFor="date" className="block font-bold mb-1">
                        Leave To
                      </label>
                      <input
                        type="text"
                        id="date"
                        name="date"
                        value={new Date(
                          selectedLeave.leaveTo
                        ).toLocaleDateString("en-GB")}
                        readOnly
                        className="w-full py-2 border rounded-md mb-2"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="numOfDays"
                      className="block font-bold mb-1 mt-2"
                    >
                      Number of days
                    </label>
                    <input
                      value={selectedLeave.numOfDays}
                      name="numOfDays"
                      className="w-full py-2 border rounded-md mb-2 p-2"
                      readOnly
                    />
                  </div>
                  <div>
                    <label className="block font-bold mb-1">Message</label>
                    <textarea
                      value={selectedLeave.message}
                      name="message"
                      className="w-full py-2 border rounded-md mb-2"
                      readOnly
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="leaveStatus"
                      className="block font-bold mb-1 mt-2"
                    >
                      Select Status
                    </label>
                    <select
                      id="leaveStatus"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)} // Update selectedStatus when dropdown changes
                      className="w-full py-2 border rounded-md mb-2 p-2"
                    >
                      <option value="Pending">Pending</option>
                      <option value="Approved">Approved</option>
                      <option value="Cancel">Cancel</option>
                    </select>
                  </div>
                  <div className="flex justify-between mt-2">
                    <button
                      onClick={updateLeave}
                      className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Approvals;
