import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  BsCart3,
  BsGrid1X2Fill,
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsListCheck,
  BsMenuButtonWideFill,
  BsFillGearFill,
} from "react-icons/bs";
import { FaCaretDown } from "react-icons/fa";
import { IoMdClose, IoMdPersonAdd } from "react-icons/io";
import {
  MdOutlineDashboard,
  MdCreateNewFolder,
  MdMovieCreation,
  MdFollowTheSigns,
  MdOutlineFollowTheSigns,
  MdLeaderboard,
} from "react-icons/md";
import { CiSquareMore } from "react-icons/ci";
import { IoMdSettings } from "react-icons/io";
import { MdUpcoming } from "react-icons/md";
import { GiArcheryTarget } from "react-icons/gi";
import { FaRegistered } from "react-icons/fa6";

import { FiTarget } from "react-icons/fi";
import { SlCalender } from "react-icons/sl";
import { MdOutlineImportantDevices } from "react-icons/md";
import { FaPlusSquare } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { FaPersonCircleCheck, FaPerson } from "react-icons/fa6";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { FcRules } from "react-icons/fc";

function Sidebar({ openSidebarToggle, OpenSidebar }) {
  const [showHRMDropdown, setShowHRMDropdown] = useState(false);
  const [showCRMDropdown, setShowCRMDropdown] = useState(false);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        openSidebarToggle
      ) {
        OpenSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSidebarToggle, OpenSidebar]);

  const closeSidebar = () => {
    if (openSidebarToggle) {
      OpenSidebar();
    }
  };

  return (
    <aside
      ref={sidebarRef}
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
    >
      <div className="sidebar-title">
        <div className="sidebar-brand">
          <img src="https://manasvitech.in/images/white-logo.png" />
        </div>
        <span
          style={{ borderColor: "white" }}
          className="icon close_icon border"
          onClick={OpenSidebar}
        >
          <IoMdClose />
        </span>
      </div>

      <ul className="sidebar-list">
        <li className="sidebar-list-item" >
          <a href="../src/pages/Dharma.js" className="w-full">
            <Link to="/admin/dashboard" class="inline-container">
              <MdOutlineDashboard className="icon" />
              <span>Admin Dashboard</span>
            </Link>
          </a>
        </li>
        <li className="sidebar-list-item" >
          <div
            className="w-full"
            onClick={() => setShowHRMDropdown(!showHRMDropdown)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ cursor: "pointer", textAlign: "left" }}
                className="ml-3"
              >
                HRM
              </span>
              <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                <FaCaretDown />
              </div>
            </div>
          </div>
          {showHRMDropdown && (
            <ul className="sub-menu">
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/companySetUp"
                  class="inline-container"
                >
                  <IoMdPersonAdd className="icon" />
                  Company Set UP
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/department" class="inline-container">
                  <IoMdPersonAdd className="icon" />
                  Department
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/createTeam" class="inline-container">
                  <IoMdPersonAdd className="icon" />
                  Create Team
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/addstaff" class="inline-container">
                  <IoMdPersonAdd className="icon" />
                  Add Staff
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/managestaff"
                  className="inline-container  "
                >
                  <GrUserManager className="icon" />
                  Manage Staff
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/manageleads"
                  className="inline-container  "
                >
                  <GrUserManager className="icon" />
                  Manage Leads
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/allattendence"
                  class="inline-container"
                >
                  <MdCreateNewFolder className="icon" />
                  Manage Attendance
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/aproveles" class="inline-container">
                  <FaPersonCircleCheck className="icon" />
                  Approvals Leaves
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/advancesalary"
                  class="inline-container"
                >
                  <FaPerson className="icon" />
                  Advance salary
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/manageadvancesalary"
                  class="inline-container"
                >
                  <FaPerson className="icon" />
                  Manage Advance salary
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link to="/admin/dashboard/salary" class="inline-container">
                  <FaPerson className="icon" />
                  Create salary
                </Link>
              </li>

              {/* <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/salrayrecord"
                  class="inline-container"
                >
                  <GrUserManager className="icon" />
                  Salary record
                </Link>

              </li> */}

              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/allatttendencerecord"
                  class="inline-container"
                >
                  <FaPlusSquare className="icon" />
                  All Attendence Record
                </Link>
              </li>

              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/alldvancesalary"
                  class="inline-container"
                >
                  <FaPerson className="icon" />
                  All Advance salary
                </Link>
              </li>


              
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/DeductionRecord"
                  class="inline-container"
                >
                  <GrUserManager className="icon" />
                  All Deduction Record
                </Link>

              </li>

              

              {/* <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/allrecords"
                  class="inline-container"
                >
                  <GrUserManager className="icon" />
                 *All Records 
                </Link>

              </li> */}
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/notice" class="inline-container">
                  <GrUserManager className="icon" />
                  Create Notice and Important
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/manageNotice"
                  class="inline-container"
                >
                  <BsFillPersonVcardFill className="icon" />
                  Manage Notice and important
                </Link>
              </li>
              {/* <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="#" class="inline-container">
                  <MdCreateNewFolder className="icon" />
                  Celebration
                </Link>
              </li> */}
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/calender" class="inline-container">
                  <MdCreateNewFolder className="icon" />
                  Calender
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/createcalenderholidays"
                  class="inline-container"
                >
                  <MdCreateNewFolder className="icon" />
                  Add Holidays
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/rulesregulation" class="inline-container">
                  <MdMovieCreation className="icon" />
                  Rules & regulations
                </Link>
              </li>

              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link to="/admin/dashboard/Receipt" class="inline-container">
                  <FaPlusSquare className="icon" />
                  Receipt
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link to="/admin/dashboard/salarycalculation" class="inline-container">
                  <FaPlusSquare className="icon" />
                Salary Calculation
                </Link>
              </li>


              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/manageReceipt"
                  class="inline-container"
                >
                  <FaPlusSquare className="icon" />
                  Manage Receipt
                </Link>
              </li>
              
              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link to="/admin/dashboard/SalarySlip" class="inline-container">
                  <FaPlusSquare className="icon" />
                  Salary Slip
                </Link>
              </li>

              <li className="sidebar-list-item" onClick={closeSidebar}>
                <Link
                  to="/admin/dashboard/manageDeduction"
                  class="inline-container"
                >
                  <FaPlusSquare className="icon" />
                  Add Deduction
                </Link>
              </li>

             
            </ul>
          )}
        </li>

        {/* CRM Dropdown */}
        <li className="sidebar-list-item" >
          <div
            className="w-full"
            onClick={() => setShowCRMDropdown(!showCRMDropdown)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ cursor: "pointer", textAlign: "left" }}
                className="ml-3"
              >
                CRM
              </span>
              <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                <FaCaretDown />
              </div>
            </div>
          </div>
          {showCRMDropdown && (
            <ul className="sub-menu">
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/createperformance"
                  class="inline-container"
                >
                  <MdFollowTheSigns className="icon" />
                  <span> Create Performance</span>
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/manageperformance"
                  class="inline-container"
                >
                  <MdOutlineFollowTheSigns className="icon" />
                  Manage Performance
                </Link>
              </li>
       
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/celebration"
                  class="inline-container"
                >
                  <MdUpcoming className="icon" />
                  Celebration
                </Link>
              </li>
         
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/createtargets"
                  class="inline-container"
                >
                  <FaRegistered className="icon" />
                  Create Targets
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/managetargets" class="inline-container">
                  <MdOutlineImportantDevices className="icon" />
                  Manage targets
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/Managedailytask"
                  class="inline-container"
                >
                  <FaRegistered className="icon" />
                  Manage Daily Tasks
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/Managefollowup"
                  class="inline-container"
                >
                  <FaRegistered className="icon" />
                  Manage Follow up
                </Link>
              </li>
              {/* <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to=" #" class="inline-container">
                  <MdOutlineImportantDevices className="icon" />
                  Manage Canceled Leads
                </Link>
              </li> */}
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/quotation" class="inline-container">
                  <FaRegistered className="icon" />
                  Manage Quotation
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/invoice" class="inline-container">
                  <FaRegistered className="icon" />
                  Create Invoice
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/manageinvoice"
                  class="inline-container"
                >
                  <MdOutlineImportantDevices className="icon" />
                  Manage Invoice
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/incentives" class="inline-container">
                  <FaRegistered className="icon" />
                  Incentives
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/manageworking" class="inline-container">
                  <FaRegistered className="icon" />
                  Manage Working 
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/Remainder" class="inline-container">
                  <CiSquareMore className="icon" />
                  Reminder
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/messagebox" class="inline-container">
                  <FaPlusSquare className="icon" />
                  Create Proposal
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/manageproposal" class="inline-container">
                  <FaPlusSquare className="icon" />
                  Manage Proposal
                </Link>
              </li>
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/allsalaryslip" class="inline-container">
                  <FaPlusSquare className="icon" />
                  All Salary Slip Record
                </Link>
              </li>

              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link to="/admin/dashboard/allincentive" class="inline-container">
                  <FaPlusSquare className="icon" />
                  All Incentive Record
                </Link>
              </li>


            </ul>
            
          )}
        </li>
        <ul className="sub-menu">
              <li className="sidebar-list-item" onClick={closeSidebar} >
                <Link
                  to="/admin/dashboard/log-out"
                  class="inline-container"
                >
                  <MdFollowTheSigns className="icon" />
                  <span>Log Out</span>
                </Link>
              </li>
              </ul>
      </ul>
    </aside>
  );
}

export default Sidebar;
