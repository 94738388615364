import React, { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import html2canvas from "html2canvas";

const LeadModal = ({ lead, onClose }) => {
  useEffect(() => {
    if (lead) {

    }
  }, [lead]);

  const handleDownload = () => {
    const imageElement = document.getElementById("lead-image");

    html2canvas(imageElement).then((canvas) => {
      const imageData = canvas.toDataURL("image/png");

      // Create a temporary link element
      const downloadLink = document.createElement("a");
      downloadLink.href = imageData;
      downloadLink.download = "lead_image.png"; 

      downloadLink.click();
    });
  };

  return (
    <div className="lead-modal-container text-black p-10 px-4 py-8 ">
      <h1 className="text-3xl justify-between font-bold underline  inline-block mb-10">
        Lead Images
      </h1>
      <div className="flex float-end">
        <button
          className="text-gray-500 hover:text-gray-700 flex"
          onClick={onClose}
        >
          <FaTimes className="w-50 h-50" aria-hidden="true" />
        </button>
      </div>

      {lead && (
        <div>
          <p className="text-sm text-gray-700 mb-2">
            <span>
              <img
                id="lead-image"
                src={`/api/v1/lead/Photo/${lead._id}`}
                alt="Staff Photo"
                className="w-48"
              />
            </span>
          </p>
        </div>
      )}
      <div className="flex justify-center mt-5 print">
        <button
          style={{
            padding: "8px 24px",
            backgroundColor: "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            transition: "none",
            boxShadow: "none",
          }}
          className="hide-on-print "
          onClick={handleDownload}
          type="button"
        >
          Download Images
        </button>
      </div>
    </div>
  );
};

export default LeadModal;
