// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import toast, { Toaster } from "react-hot-toast";
// import { Link } from "react-router-dom";
// import Modal from "react-modal";
// import ViewReceiptModal from "./modals/ViewReceiptModal";
// import EditReceiptModal from "./modals/EditReceiptModal";

// const ManageReceipt = () => {
//   const [data, setData] = useState([]);
//   const [viewModal, setViewModal] = useState(false);
//   const [editModal, setEditModal] = useState(false);
//   const [modalData, setModalData] = useState(null);
//   const [search, setSearch] = useState({ startDate: "", endDate: "" });
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get("/api/v1/receipt/getreceipt/");

//         setData(response.data);
//         // toast.success("getting all receipt data....");
//       } catch (error) {
//         toast.error("error data not found...");
//       }
//     };

//     fetchData();
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       if (prompt("Are u sure to delete...! press yes") === "yes") {
//         await axios.delete(`/api/v1/receipt/deletereceipt/${id}`);
//         setData(data.filter((receiptData) => receiptData._id !== id));
//         toast.success("receipt Delete successfully");
//       } else {
//         toast.info("You did not press yes...!");
//       }
//     } catch (error) {
//       console.error("Error deleting receipt:", error);
//     }
//   };

//   const openViewModal = (receiptData) => {
//     setViewModal(true);
//     setModalData(receiptData);
//   };
//   const openEditModal = (receiptData) => {
//     setEditModal(true);
//     setModalData(receiptData);
//   };

//   // Function to close modal
//   const closeModal = () => {
//     setViewModal(false);
//     setEditModal(false);
//   };

//   const downloadPDF = async (id) => {
//     try {
//       const downloadUrl = `/api/v1/receipt/downloadPdf/${id}`;
//       window.open(downloadUrl);
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//   };

//   return (
//     <div className="addstaff-container text-black p-10 px-4 py-8">
//       <h2 className="text-3xl font-bold mb-8 text-center underline hide-on-print">
//         Manage Receipt
//       </h2>
//       <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
//         <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
//           <input
//             className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             type="date"
//             value={search.startDate}
//             onChange={(e) =>
//               setSearch({ ...search, startDate: e.target.value })
//             }
//             placeholder="enter start date"
//           />

//           <input
//             className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//             type="date"
//             value={search.endDate}
//             onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
//             placeholder="enter end date"
//           />
//         </div>

//         <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
//           <thead class="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
//             <tr>
//               <th scope="col" class="px-6 py-3">
//                 S.no
//               </th>
//               <th scope="col" class="px-6 py-3">
//                 Date
//               </th>
//               <th scope="col" class="px-6 py-3">
//                 Receipt No
//               </th>
//               <th scope="col" class="px-6 py-3">
//                 Received From
//               </th>
//               <th scope="col" class="px-6 py-3">
//                 Amount
//               </th>
//               <th scope="col" class="px-6 py-3">
//                 Action
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {data &&
//               data
//                 .filter((deduction) => {
//                   if (!search.startDate || !search.endDate) {
//                     return true;
//                   } else {
//                     const startDate = new Date(search.startDate);
//                     const endDate = new Date(search.endDate);
//                     const recordDate = new Date(deduction.date);
//                     return recordDate >= startDate && recordDate <= endDate;
//                   }
//                 })
//                 .map((item, index) => (
//                   <tr
//                     key={index}
//                     className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
//                   >
//                     <th scope="row" className="px-10 py-4">
//                       {index + 1}
//                     </th>
//                     <td className="px-6 py-4">{item.date}</td>
//                     <td className="px-6 py-4">{item.receiptNo}</td>
//                     <td className="px-6 py-4">{item.received}</td>
//                     <td className="px-6 py-4">{item.amount}</td>

//                     <td className="px-6 py-4">
//                       <Link
//                         className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0"
//                         onClick={() => openViewModal(item)}
//                       >
//                         view
//                       </Link>{" "}
//                       <Link
//                         className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0 p-1"
//                         onClick={() => openEditModal(item)}
//                       >
//                         edit
//                       </Link>{" "}
//                       <Link
//                         className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0"
//                         onClick={() => handleDelete(item._id)}
//                       >
//                         Delete
//                       </Link>{" "}
//                     </td>
//                   </tr>
//                 ))}
//           </tbody>
//         </table>

//         <Modal
//           isOpen={viewModal}
//           onRequestClose={closeModal}
//           contentLabel="View Item Modal"
//           style={{
//             content: {
//               width: "60%",
//               height: "77%",
//               maxWidth: "800px",
//               margin: "auto",
//               padding: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               borderRadius: "5px",
//             },
//           }}
//         >
//           <ViewReceiptModal closeModal={closeModal} receiptData={modalData} />
//         </Modal>

//         <Modal
//           isOpen={editModal}
//           onRequestClose={closeModal}
//           contentLabel="View Item Modal"
//           style={{
//             content: {
//               width: "60%",
//               height: "77%",
//               maxWidth: "800px",
//               margin: "auto",
//               padding: "5px",
//               boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//               borderRadius: "5px",
//             },
//           }}
//         >
//           <EditReceiptModal closeModal={closeModal} receiptData={modalData} />
//         </Modal>
//       </div>
//       <Toaster />
//     </div>
//   );
// };

// export default ManageReceipt;

import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import ViewReceiptModal from "./modals/ViewReceiptModal";
import EditReceiptModal from "./modals/EditReceiptModal";

const ManageReceipt = () => {
  const [data, setData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/receipt/getreceipt/");

        setData(response.data);
        // toast.success("getting all receipt data....");
      } catch (error) {
        toast.error("error data not found...");
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      if (prompt("Are u sure to delete...! press yes") === "yes") {
        await axios.delete(`/api/v1/receipt/deletereceipt/${id}`);
        setData(data.filter((receiptData) => receiptData._id !== id));
        toast.success("receipt Delete successfully");
      } else {
        toast.info("You did not press yes...!");
      }
    } catch (error) {
      console.error("Error deleting receipt:", error);
    }
  };

  const openViewModal = (receiptData) => {
    setViewModal(true);
    setModalData(receiptData);
  };
  const openEditModal = (receiptData) => {
    setEditModal(true);
    setModalData(receiptData);
  };

  // Function to close modal
  const closeModal = () => {
    setViewModal(false);
    setEditModal(false);
  };

  const downloadPDF = async (id) => {
    try {
      const downloadUrl = `/api/v1/receipt/downloadPdf/${id}`;
      window.open(downloadUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center underline hide-on-print">
        Manage Receipt
      </h2>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <div className="flex flex-col md:flex-row items-center w-full hide-on-print md:space-y-0 md:space-x-3 md:w-auto mr-1 ">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.no
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Receipt No
              </th>
              <th scope="col" class="px-6 py-3">
                Received From
              </th>
              <th scope="col" class="px-6 py-3">
                Amount
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th scope="row" className="px-10 py-4">
                      {index + 1}
                    </th>
                    <td className="px-6 py-4">{item.date}</td>
                    <td className="px-6 py-4">{item.receiptNo}</td>
                    <td className="px-6 py-4">{item.received}</td>
                    <td className="px-6 py-4">{item.amount}</td>

                    <td className="px-6 py-4">
                      <Link
                        className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0"
                        onClick={() => openViewModal(item)}
                      >
                        view
                      </Link>{" "}
                      <Link
                        className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0 p-1"
                        onClick={() => openEditModal(item)}
                      >
                        edit
                      </Link>{" "}
                      <Link
                        className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0 hover:bg-white hover:border-0"
                        onClick={() => handleDelete(item._id)}
                      >
                        Delete
                      </Link>{" "}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>

        <Modal
          isOpen={viewModal}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "80%",
              height: "90%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          <ViewReceiptModal closeModal={closeModal} receiptData={modalData} />
        </Modal>

        <Modal
          isOpen={editModal}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "60%",
              height: "77%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          <EditReceiptModal closeModal={closeModal} receiptData={modalData} />
        </Modal>
      </div>
      <Toaster />
    </div>
  );
};

export default ManageReceipt;
