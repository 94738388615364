import React, { useState, useEffect } from "react";

const DigitalWatch = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="digital-watch flex justify-center items-center w-40 h-40 border-green-500 border-4 rounded-full p-2 ">
       
        <div className="border-red-500 border-2 rounded-full flex flex-col p-2">
          <div className="date text-xl text-red-500">{formatDate(currentTime)}</div>
          <div className="time text-2xl text-green-500">{formatTime(currentTime)}</div>
        </div>
 
    </div>
  );
};

export default DigitalWatch;
