import React, { useEffect, useState } from "react";
import { useSpeechSynthesis } from "react-speech-kit";

import axios from "axios";

import saleImg from "../../assets/images/saleImg.jpg";
import ForgetPassword from "./ForgetPassword";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import AOS from "aos";
import "aos/dist/aos.css";

import { useSessionTimeout } from "../context/SessionTimeoutContext";
function Login() {
  const { resetTimer } = useSessionTimeout();
  const navigate = useNavigate();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(true);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();

  const { speak, voices } = useSpeechSynthesis();
  const [hindiVoice, setHindiVoice] = useState(null);
  const [gn, setGn] = useState("");
  const [land, setLand] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    resetTimer();
  }, [resetTimer]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    const billa = sessionStorage.getItem("dauth");
    const parsedBilla = JSON.parse(billa);
    delete parsedBilla?.user.photo;
  });

  useEffect(() => {
    const body = document.querySelector("body");
    const modal = document.querySelector(".modal");
    const modalButton = document.querySelector(".modal-button");
    const closeButton = document.querySelector(".close-button");
    const scrollDown = document.querySelector(".scroll-down");
    let isOpened = false;

    const openModal = () => {
      modal.classList.add("is-open");
      body.style.overflow = "hidden";
    };

    const closeModal = () => {
      modal.classList.remove("is-open");
      body.style.overflow = "initial";
    };

    window.addEventListener("scroll", () => {
      if (window.scrollY > window.innerHeight / 3 && !isOpened) {
        isOpened = true;
        scrollDown.style.display = "none";
        setIsScrolled(true);
        openModal();
      }
    });

    modalButton.addEventListener("click", openModal);
    closeButton.addEventListener("click", closeModal);
    // start from here

    return () => {
      window.removeEventListener("scroll", () => {});
      modalButton.removeEventListener("click", openModal);
      closeButton.removeEventListener("click", closeModal);
      if (document) {
        document.onkeydown = null;
      }
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
    setIsLoginModalOpen(false);
  };
  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post("/api/v1/user/loginUser", {
        email: email,
        pwd: password,
      });

      if (response.data.success) {
        // Assuming response.data.user exists
        delete response.data.user.photo;

        setGn(response.data.user.name);

        toast.success(response.data.message);
        setAuth({
          ...auth,
          duser: response.data.user,
          dtoken: response.data.token,
        });
        sessionStorage.setItem("dauth", JSON.stringify(response.data));

        // Clearing input fields after successful login
        setEmail("");
        setPassword("");

        // Wait for 5 seconds before speaking and navigating
        setTimeout(() => {
          // Navigate
          if (response.data.user.role === 1) {
            navigate("/admin/dashboard");
          } else if (response.data.user.role === 0) {
            navigate("/staff/dashboard");
          }
        }, 1000);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("Error logging in:", error.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.tagName !== "BUTTON") {
      handleLogin();
    }
  };
  // speech
  useEffect(() => {
    const voice = voices.find((voice) => voice.lang.includes("hi"));
    setHindiVoice(voice);
  }, [voices]);

  useEffect(() => {
    handleSpeak();
  }, [gn]);

  const handleSpeak = () => {
    let currentTime = new Date().getHours();
    let greeting;

    if (currentTime >= 5 && currentTime < 12) {
      greeting = "Good Morning";
    } else if (currentTime >= 12 && currentTime < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }

    const fullMessage = `${greeting} Dear ${gn}. Welcome to HRM CRM`;

    speak({ text: fullMessage, voice: hindiVoice });
  };

  const lanidingOf = () => {
    setLand(false);
  };

  //speech end
  return (
    <div>
      <div className="scroll-down">
        SCROLL DOWN
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M16 3C8.832031 3 3 8.832031 3 16s5.832031 13 13 13 13-5.832031 13-13S23.167969 3 16 3zm0 2c6.085938 0 11 4.914063 11 11 0 6.085938-4.914062 11-11 11-6.085937 0-11-4.914062-11-11C5 9.914063 9.914063 5 16 5zm-1 4v10.28125l-4-4-1.40625 1.4375L16 23.125l6.40625-6.40625L21 15.28125l-4 4V9z" />
        </svg>
      </div>
      <div className="container" style={{ overflowX: "scroll" }}></div>
      <div
        className="modal"
        style={{
          height: "100%",
          background: "rgba(51, 51, 51, 0.85)",
          position: "fixed",
          left: 0,
          bottom: 0,
        }}
      >
        {" "}
        <div className="modal-container">
          <div
            className="modal-left"
            style={{
              padding: "60px 30px 20px",
              background: "#fff",
              flex: 1.5,
              transitionDuration: "0.5s",
              transform: "translateY(0)",
              opacity: 1,
            }}
          >
            <h1 className="modal-title">Welcome!</h1>

            <p className="modal-desc">
              Revolutionize your sales strategy with LeadApp. Streamline lead
              management, track conversions, and boost sales effortlessly. Say
              goodbye to manual processes and hello to increased efficiency and
              revenue growth.
            </p>
            <div className="input-block">
              <label htmlFor="email" className="input-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="input-block">
              <label htmlFor="pwd" className="input-label">
                Password
              </label>
              <input
                type="password"
                name="pwd"
                id="pwd"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="modal-buttons">
              <button
                className="input-button"
                onClick={handleLogin}
                onKeyPress={handleKeyPress}
              >
                Login
              </button>
            </div>
            <p className="sign-up">
              Lost your password? Not a problem! ?{" "}
              <span onClick={openSignUpModal}>Forgot your password?</span>
            </p>
          </div>
          <div
            className="modal-right"
            style={{ flex: 2, transition: "0.3s", overflow: "hidden" }}
          >
            {" "}
            <img src={saleImg} alt="" />
          </div>
          <button
            className="icon-button close-button"
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            {" "}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
              <path d="M 25 3 C 12.86158 3 3 12.86158 3 25 C 3 37.13842 12.86158 47 25 47 C 37.13842 47 47 37.13842 47 25 C 47 12.86158 37.13842 3 25 3 z M 25 5 C 36.05754 5 45 13.94246 45 25 C 45 36.05754 36.05754 45 25 45 C 13.94246 45 5 36.05754 5 25 C 5 13.94246 13.94246 5 25 5 z M 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.980469 15.990234 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 z"></path>
            </svg>
          </button>
        </div>
        {land && !isScrolled && (
          <>
            <div
              data-aos-duration="500"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              className=" drop-shadow-lg italic ring ring-pink-500 ring-offset-4 bg-white border-solid border-2 border-indigo-600 rounded-md p-4 m-4"
            >
              <h1
                data-aos-duration="1000"
                data-aos="fade-left"
                class="text-white-500 font-semibold sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl"
              >
                HUMAN
              </h1>

              <h1
                data-aos-duration="1500"
                data-aos="zoom-in"
                class="text-white-500 font-semibold sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl"
              >
                RESOURCE
              </h1>
              <h1
                data-aos-duration="2000"
                data-aos="fade-right"
                class="text-white-500 font-semibold sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl"
              >
                MANAGEMENT
              </h1>
            </div>
          </>
        )}
        <button onClick={lanidingOf} className="modal-button">
          Click here to login
        </button>
      </div>
      {isSignUpModalOpen && <ForgetPassword onClose={closeSignUpModal} />}
      <ToastContainer />
    </div>
  );
}

export default Login;
