import React from 'react'

const AllRecords = () => {
  return (
    <div>AllRecords</div>
  )
}

export default AllRecords


