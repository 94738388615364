import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../components/context/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const QuationInvoice = () => {
  const [userData, setUserData] = useState();
  const [auth] = useAuth();
  const [discount, setDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [netAmountWords, setNetAmountWords] = useState("");
  const [mcompanyData, setCompanyData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [selectedState, setSelectedState] = useState("Madhya Pradesh");

  const [rows, setrows] = useState([
    {
      id: 1,
      description: "",
      hsn: "",
      qty: "",
      uom: "",
      rate: "",
      amount: "",
      taxable: "No",
      gst: "",
      cgst: "",
      sgst: "",
      igst: "",
      total: "",
    },
  ]);
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("/api/v1/company/getCompanyDetails");
        setCompanyData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const getAllStaffData = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        setStaffData(response.data.data);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    getAllStaffData();
  }, []);

  useEffect(() => {
    calculateSubtotal();
  }, [rows]);
  useEffect(() => {
    const netAmount = subtotal - parseFloat(discount);
    setNetAmount(netAmount);
    setNetAmountWords(numberToWords(netAmount));
  }, [subtotal, discount]);
  // Function to convert number to words
  const numberToWords = (num) => {
    // Create an array of units and tens
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    // Function to convert numbers less than 1000 to words
    const convertLessThanOneThousand = (num) => {
      if (num === undefined || num === null || isNaN(num) || num < 0) return ""; // Handle case when num is not provided or invalid
      if (num === 0) return "Zero"; // Handle case when num is 0
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertLessThanOneThousand(num % 10) : "")
        );
      return (
        units[Math.floor(num / 100)] +
        " Hundred" +
        (num % 100 !== 0 ? " " + convertLessThanOneThousand(num % 100) : "")
      );
    };

    // Function to add thousand, million, billion etc.
    const convert = (num) => {
      if (num === 0) return "";
      let word = "";
      let index = 0;
      do {
        const threeDigits = num % 1000;
        if (threeDigits !== 0) {
          const digitWords = convertLessThanOneThousand(threeDigits);
          word =
            digitWords +
            ["", " Thousand", " Million", " Billion"][index] +
            " " +
            word;
        }
        index++;
        num = Math.floor(num / 1000);
      } while (num > 0);
      return word.trim();
    };

    return convert(num);
  };

  const calculateAmount = (index) => {
    const updatedRows = [...rows];
    const { qty, rate, gst } = updatedRows[index];
    let amount = 0;
    if (qty && rate) {
      amount = parseFloat(qty) * parseFloat(rate);
      if (updatedRows[index].taxable === "Yes") {
        const gstPercentage = parseFloat(gst) || 0;
        const gstAmount = (amount * gstPercentage) / 100; // Calculate GST amount
        const totalWithTax = amount + gstAmount; // Add GST amount to total
        updatedRows[index].total = totalWithTax.toFixed(2); // Update total with tax
      } else {
        updatedRows[index].total = amount.toFixed(2); // Update total without tax
      }
      updatedRows[index].amount = amount.toFixed(2);
      setrows(updatedRows);
    }
    // Recalculate subtotal
    let subTotal = 0;
    updatedRows.forEach((row) => {
      subTotal += parseFloat(row.amount) || 0;
    });
    setSubtotal(subTotal);
  };

  const calculateSubtotal = () => {
    let subTotal = 0;
    rows.forEach((row) => {
      subTotal += parseFloat(row.amount) || 0;

      // Check if GST is applicable for the current row
      if (row.taxable === "Yes") {
        const gstPercentage = parseFloat(row.gst) || 0;
        const gstAmount = (parseFloat(row.amount) * gstPercentage) / 100; // Calculate GST amount for the current row
        subTotal += gstAmount; // Add GST amount to subtotal
      }
    });
    setSubtotal(subTotal);

    // Calculate net amount, net amount words, etc.
    const netAmount = subTotal - parseFloat(discount);
    setNetAmount(netAmount);
    setNetAmountWords(numberToWords(netAmount));
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      id: rows.length + 1,
      description: "",
      hsn: "",
      qty: "",
      uom: "",
      rate: "",
      amount: "",
      taxable: "No",
      gst: "",
      cgst: "",
      sgst: "",
      igst: "",
      total: "",
    };
    setrows([...rows, newRow]);
  };
  const handleDeleteRow = (id, e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent event propagation
    if (rows.length === 1) {
      return;
    }
    // Filter out the row with the given id
    const updatedRows = rows.filter((row) => row.id !== id);
    // Update the state with the filtered rows
    setrows(updatedRows);
    // Recalculate subtotal
    calculateSubtotal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    // Add rows to the formObject
    formObject.rows = rows;

    // Retrieve values of salesPersonNameBy and addressBy from the form
    const salesPersonNameBy = e.target.salesPersonNameBy.value;
    const addressBy = e.target.addressBy.value;

    // Add salesPersonNameBy and addressBy to the formObject
    formObject.salesPersonNameBy = salesPersonNameBy;
    formObject.addressBy = addressBy;
    formObject.businessName = mcompanyData.businessName;
    formObject.gstinBy = mcompanyData.gstIn;
    try {
      const response = await axios.post(
        "/api/v1/invoice/createInvoice",
        formObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      if (!response.data.success) {
        throw new Error(response.data.message || "Failed to submit quotation");
      }

      // Reset form fields
      e.target.reset();
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const autoResizeTextarea = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  return (
    <div className="addstaff-container p-6 bg-white  shadow-sm my-6">
      <h1 className="text-center text-3xl underline bg-gray-400 text-black">
        Invoice{" "}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mt-8 px-4 lg:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="border border-gray-200 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Invoice By -</h2>
              <p className="text-fuchsia-400 mb-4">
                {mcompanyData.businessName}
              </p>

              <div className="space-y-4">
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Address By
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="addressBy"
                    value={mcompanyData.address} // Ensure that mcompanyData.address has the address value
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    State By
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="stateBy" // Add the name attribute for stateBy field
                    value={mcompanyData.state} // Ensure that mcompanyData.state has the state value
                    readOnly
                  />
                </div>

                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Contact
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="contactBy"
                    value={mcompanyData.contactNo}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    GSTIN
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="gstinBy"
                    value={mcompanyData.gstIn}
                  />
                </div>

                <div className="col-md-5">
                  <div className="flex flex-col md:flex-row">
                    <label htmlFor="salesPersonNameBy" className="md:w-1/3">
                      Sales Person
                    </label>
                    <select
                      className="border border-gray-300 flex-1 "
                      name="salesPersonNameBy"
                    >
                      <option value="">Select Employee</option>
                      {staffData.map((staff) => (
                        <option key={staff._id} value={staff.name}>
                          {staff.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Invoice To -</h2>
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Date
                  </span>
                  <input
                    type="date"
                    className="border border-gray-300 flex-1"
                    name="date"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Invoice No
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="invoiceTo"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Name
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="nameTo"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Address
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="address"
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    State
                  </span>
                  <select
                    className="border border-gray-300 flex-1"
                    name="stateTo"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                  >
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">
                      Dadra and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Lakshadweep">Lakshadweep</option>

                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>

                    {/* Add more options as needed */}
                  </select>
                </div>

                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Contact
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="contact"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    GSTIN
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="gstinTo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
          <table className="min-w-full border">
            <thead className="border-b border-gray-300 text-gray-900 border">
              <tr className="text-left border shadow-md bg-gr00">
                <th scope="col" className="ml-1 p-2 border">
                  S.No.
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Description
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  HSN
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Qty
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  UOM
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Rate
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Amount
                </th>
                <th className="whitespace-nowrap">GST Applicable</th>
                <th className="whitespace-nowrap"> GST % </th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={row.id} className="border  bg-gray-100">
                  <td className="border p-2 ">{index + 1}</td>
                  <td className="border p-2">
                  <textarea
                      className="w-full overflow-hidden resize-none"
                      value={row.description}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].description = e.target.value;
                        setrows(updatedRows);
                        autoResizeTextarea(e);
                      }}
                      rows={1} // Starts with 1 row
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-[100%]"
                      value={row.hsn}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].hsn = e.target.value;
                        setrows(updatedRows);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-[100%]"
                      value={row.qty}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].qty = e.target.value;
                        setrows(updatedRows);
                        calculateAmount(index);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-[100%]"
                      value={row.uom}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].uom = e.target.value;
                        setrows(updatedRows);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-[100%]"
                      value={row.rate}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].rate = e.target.value;
                        setrows(updatedRows);
                        calculateAmount(index);
                      }}
                    />
                  </td>

                  <td className="border p-2">
                    <input className="w-[100%]" value={row.amount} readOnly />
                  </td>

                  {/* Taxble Calculation */}

                  <td className="border p-2">
                    <select
                      className="w-[100%]"
                      value={row.taxable}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].taxable = e.target.value;
                        setrows(updatedRows);
                        calculateAmount(index);
                      }}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>

                  {row.taxable === "No" && (
                    <>
                      <td className="border p-2"></td>
                      <td className="border p-2"></td>
                      <td className="border p-2"></td>
                      <td className="border p-2"></td>
                      <td className="border p-2"></td>
                    </>
                  )}

                  {row.taxable === "Yes" && (
                    <>
                      <td className="border p-2">
                        <input
                          className="w-[100%]"
                          placeholder="Enter"
                          value={row.gst}
                          onChange={(e) => {
                            const gstValue = parseFloat(e.target.value) || 0;
                            const halfGst = gstValue / 2; // Calculate half of the GST value
                            const updatedRows = [...rows];
                            updatedRows[index].gst = Number.isInteger(gstValue)
                              ? gstValue.toString()
                              : gstValue.toFixed(2); // Update GST value
                            if (mcompanyData.state === selectedState) {
                              // If states are the same, distribute GST value equally between CGST and SGST
                              updatedRows[index].cgst = Number.isInteger(
                                halfGst
                              )
                                ? halfGst.toString()
                                : halfGst.toFixed(2); // Update CGST value
                              updatedRows[index].sgst = Number.isInteger(
                                halfGst
                              )
                                ? halfGst.toString()
                                : halfGst.toFixed(2); // Update SGST value
                              updatedRows[index].igst = ""; // Reset IGST value
                            } else {
                              // If states are different, show entire GST value in IGST
                              updatedRows[index].cgst = ""; // Reset CGST value
                              updatedRows[index].sgst = ""; // Reset SGST value
                              updatedRows[index].igst = Number.isInteger(
                                gstValue
                              )
                                ? gstValue.toString()
                                : gstValue.toFixed(2); // Update IGST value
                            }
                            setrows(updatedRows);
                            calculateAmount(index);
                          }}
                        />
                      </td>
                      <td className="border p-2">
                        <input
                          className="w-[100%]"
                          value={row.cgst}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].cgst = e.target.value;
                            setrows(updatedRows);
                            calculateAmount(index);
                          }}
                          readOnly
                        />
                      </td>
                      <td className="border p-2">
                        <input
                          className="w-[100%]"
                          value={row.sgst}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].sgst = e.target.value;
                            setrows(updatedRows);
                            calculateAmount(index);
                          }}
                          readOnly
                        />
                      </td>
                      <td className="border p-2">
                        <input
                          className="w-[100%]"
                          value={row.igst}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].igst = e.target.value;
                            setrows(updatedRows);
                            calculateAmount(index);
                          }}
                          readOnly
                        />
                      </td>

                      <td className="border p-2">
                        <input
                          className="w-[100%]"
                          value={row.total}
                          onChange={(e) => {
                            const updatedRows = [...rows];
                            updatedRows[index].total = e.target.value;
                            setrows(updatedRows);
                            calculateAmount(index);
                          }}
                          readOnly
                        />
                      </td>
                    </>
                  )}

                  {/* taxble calculation end */}
                  <td className="border  text-center flex flex-justify ">
                    <button
                      className="bg-green-500 pl-3 pr-3 pt-1 m-1 pb-1 rounded text-black mr-2"
                      onClick={handleAddRow}
                    >
                      +
                    </button>
                  
                    <button
                      className="bg-red-500 pl-3 pr-3 pt-1 pb-1 m-1 rounded text-black"
                      onClick={(e) => handleDeleteRow(row.id, e)} // Pass event to handleDeleteRow
                    >
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Subtotal & Net Amount */}
        <div className="flex justify-center mt-3">
          <button
            style={{ backgroundColor: "#34D399", borderRadius: "0.375rem" }}
            className="text-black mr-2"
          >
            Submit
          </button>
        </div>
        <div className="mt-1 flex justify-end">
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-1 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Subtotal
                  </th>
                  <td>
                    <input
                      type="text"
                      name="subtotal"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={`₹ ${subtotal.toFixed(2)}`}
                      readOnly
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Discount ( if any)
                  </th>
                  <td>
                    <input
                      type="number"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={discount}
                      name="discount"
                      onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Net Amount
                  </th>
                  <td>
                    <input
                      type="text"
                      name="netAmount"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={`₹ ${netAmount.toFixed(2)}`}
                      readOnly
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Amount in words
                  </th>
                  <td>
                    <input
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={netAmountWords}
                      readOnly
                    />
                    only
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
export default QuationInvoice;
