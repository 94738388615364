import React, { useState } from "react";
import axios from "axios";
import {toast, Toaster } from 'react-hot-toast';

const FollowUp = () => {
  const [date, setDate] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [leadType, setLeadType] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [dealType, setDealType] = useState("");
  const [nextMeetingDate, setNextMeetingDate] = useState("");
  const [message, setMessage] = useState("");
  const [offerPrice, setOfferPrice] = useState("");
  const [submitError, setSubmitError] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date) {
      return toast.error("Please select a date");
    }
    if (!businessName) {
      return toast.error("Please enter businessName");
    }
    if (!businessAddress) {
      return toast.error("Please enter businessAddress");
    }
    if (!businessWebsite) {
      return toast.error("Please enter businessWebsite");
    }
    if (!businessEmail) {
      return toast.error("Please enter businessEmail");
    }
    if (!contactPersonName) {
      return toast.error("Please enter contact person name");
    }
    if (!contactPersonNumber) {
      return toast.error("Please enter contact person number");
    }
    if (!leadType) {
      return toast.error("Please select a lead type");
    }
    if (!serviceName) {
      return toast.error("Please select a service name");
    }
    if (!dealType) {
      return toast.error("Please select a deal type");
    }
    if (!nextMeetingDate) {
      return toast.error("Please select next meeting date");
    }
    if (!message) {
      return toast.error("Please enter a message");
    }
    if (!offerPrice) {
      return toast.error("Please enter offer price");
    }

    try {
      const formData = {
        date,
        businessName,
        businessAddress,
        businessWebsite,
        businessEmail,
        contactPersonName,
        contactPersonNumber,
        leadType,
        serviceName,
        dealType,
        nextMeetingDate,
        message,
        offerPrice,
      };

      const response = await axios.post("/api/v1/followup/createfollowup", formData);
      toast.success(response.data.message);

      resetForm();

    } catch (error) {
      console.error("Error while submitting form:", error);
      setSubmitError("An error occurred while submitting the form.");    }
  };

  const resetForm = () => {
    setDate("");
    setBusinessName("");
    setBusinessAddress("");
    setBusinessWebsite("");
    setBusinessEmail("");
    setContactPersonName("");
    setContactPersonNumber("");
    setLeadType("");
    setServiceName("");
    setDealType("");
    setNextMeetingDate("");
    setMessage("");
    setOfferPrice("");
  };

  const handleCancel = () => {
    resetForm();
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form className="max-w-5xl mx-auto" onSubmit={handleSubmit}>
        <h1 className="text-3xl font-bold underline mb-8 text-center">
          Follow Up
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
          <div className="mb-3 mx-4">
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="businessName" className="block font-bold mb-1">
              Business name
            </label>
            <input
              type="text"
              id="businessName"
              name="businessName"
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="businessAddress" className="block font-bold mb-1">
              Business address
            </label>
            <input
              type="text"
              id="businessAddress"
              name="businessAddress"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="businessWebsite" className="block font-bold mb-1">
              Business website
            </label>
            <input
              type="text"
              id="businessWebsite"
              name="businessWebsite"
              value={businessWebsite}
              onChange={(e) => setBusinessWebsite(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="businessEmail" className="block font-bold mb-1">
              Business Email
            </label>
            <input
              type="email"
              id="businessEmail"
              name="businessEmail"
              value={businessEmail}
              onChange={(e) => setBusinessEmail(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="contactPersonName" className="block font-bold mb-1">
              Contact person name
            </label>
            <input
              type="text"
              id="contactPersonName"
              name="contactPersonName"
              value={contactPersonName}
              onChange={(e) => setContactPersonName(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label
              htmlFor="contactPersonNumber"
              className="block font-bold mb-1"
            >
              Contact person number
            </label>
            <input
              type="tel"
              id="contactPersonNumber"
              name="contactPersonNumber"
              value={contactPersonNumber}
              onChange={(e) => setContactPersonNumber(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="leadType" className="block font-bold mb-1">
              Lead Type
            </label>
            <select
              id="leadType"
              name="leadType"
              value={leadType}
              onChange={(e) => setLeadType(e.target.value)}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Lead Type</option>
              <option value="cold">cold</option>
              <option value="warm">warm</option>
              <option value="hot">hot</option>
            </select>
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="serviceName" className="block font-bold mb-1">
              Service name
            </label>
            <select
              id="serviceName"
              name="serviceName"
              value={serviceName}
              onChange={(e) => setServiceName(e.target.value)}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Service name</option>
              <option value="createService">create service</option>
            </select>
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="dealType" className="block font-bold mb-1">
              Deal type
            </label>
            <select
              id="dealType"
              name="dealType"
              value={dealType}
              onChange={(e) => setDealType(e.target.value)}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Deal Type</option>
              <option value="followUp">follow up</option>
              <option value="dealFinal">deal final</option>
              <option value="dealCancel">deal cancel</option>
              <option value="postponed">postponed</option>
            </select>
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="nextMeetingDate" className="block font-bold mb-1">
              Next meeting date
            </label>
            <input
              type="date"
              id="nextMeetingDate"
              name="nextMeetingDate"
              value={nextMeetingDate}
              onChange={(e) => setNextMeetingDate(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="message" className="block font-bold mb-1">
              Message
            </label>
            <textarea
              type="text"
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-3 mx-4">
            <label htmlFor="offerPrice" className="block font-bold mb-1">
              Offer price
            </label>
            <input
              type="number"
              id="offerPrice"
              name="offerPrice"
              value={offerPrice}
              onChange={(e) => setOfferPrice(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
        </div>

        <div className="flex justify-between ">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4 mx-4 mt-4"
          >
            Create
          </button>

          <button
            type="button"
            onClick={handleCancel} 
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4 mx-4 mt-4"
          >
            Cancel
          </button>
        </div>
        {submitError && <p className="text-red-500">{submitError}</p>}

      </form>
      <Toaster/>
    </div>
  );
};

export default FollowUp;
