import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AllAttendenceRecord = () => {
  const [attendenceData, setAttendenceData] = useState([]);
  const [departments, setDepartments] = useState({});
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/record/at-record");
      const flattenedData = response.data.data.flatMap(
        (entry) => entry.atRecord
      );
      setAttendenceData(flattenedData);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      toast.error("Failed to fetch attendance data");
    }
  };


  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`/api/v1/depart/getalldepart`);
      if (Array.isArray(response.data.data)) {
        const departmentsMap = {};
        response.data.data.forEach((department) => {
          departmentsMap[department._id] = department.name;
        });
        setDepartments(departmentsMap);
      } else {
        console.error("Departments data is not an array:", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDepartments();

  }, []);

  return (
    <div className="main-container">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        All Attendance Record
      </h2>
      <div className="bg-white rounded px-1 pt-2 pb-8  ">
        <p className="mx-2">Search by date Range</p>
        <input
          className=" mb-2 appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.startDate}
          onChange={(e) => setSearch({ ...search, startDate: e.target.value })}
          placeholder="enter start date"
        />

        <input
          className=" shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.endDate}
          onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
          placeholder="enter end date"
        />
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {attendenceData && attendenceData.length > 0 ? (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  S.no
                </th>
                <th scope="col" className="px-6 py-3">
                  Staff ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Staff Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Department
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Time In
                </th>
                <th scope="col" className="px-6 py-3">
                  Time Out
                </th>
                <th scope="col" className="px-6 py-3">
                  Attendance Status
                </th>
              </tr>
            </thead>
            <tbody>
              {attendenceData
                .filter((attendance) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(attendance.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((attendance, index) => (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <td className="px-6 py-4">{attendance.staffId}</td>
                    <td className="px-6 py-4">{attendance.staffName}</td>
                    <td className="px-6 py-4">
                      {departments[attendance.department]}
                    </td>
                    <td className="px-6 py-4">{attendance.date}</td>
                    <td className="px-6 py-4">{attendance.timeIn}</td>
                    <td className="px-6 py-4">{attendance.timeOut}</td>
                    <td className="px-6 py-4">{attendance.attendenceStatus}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default AllAttendenceRecord;
