import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

const Managedailytask = () => {
  const [viewModal, setViewModal] = useState(false);
  const [fdata, setfdata] = useState([]);
  const [date, setDate] = useState("");
  const [team, setTeam] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffId, setStaffId] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [task, setTask] = useState("");
  const [allTeam, setAllTeam] = useState([]);
  const [alldept, setAlldept] = useState([]);

  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const getTD = async () => {
    try {
      const res = await axios.get("/api/v1/depart/getalldepart");
      setAlldept(res.data.data);
      const dtm = await axios.get("/api/v1/team/getAll");
      setAllTeam(dtm.data.allTeam);
    } catch (error) {}
  };

  useEffect(() => {
    getTD();
  }, []);

  const tn = (tid) => {
    const t = allTeam.filter((item) => item._id === tid);
    return t[0]?.name;
  };

  const dn = (did) => {
    const d = alldept.filter((fello) => fello._id === did);
    return d[0]?.name;
  };

  const openModal = () => {
    setViewModal(true);
  };

  const closeModal = () => {
    setViewModal(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const getAlldailytask = async () => {
    try {
      const res = await axios.get("/api/v1/dailytask/allTask");
      setfdata(res.data);
      // if (res.data.success) {
      //   setfdata(res.data);
      //   toast.success(res.data.message);
      // }
    } catch (error) {
      toast.error("error data not found...");
    }
  };

  const singleItemdailytask = async (_id) => {
    try {
      const user = await axios.get("/api/v1/dailytask/allTask/" + _id);

      setDate(user.data.date);
      setTeam(user.data.team);
      setStaffName(user.data.staffName);
      setStaffId(user.data.staffId);
      setDepartment(user.data.department);
      setDesignation(user.data.designation);
      setTask(user.data.task);

      openModal();
    } catch (error) {
      console.error("Error fetching daily-task item:", error);
      toast.error("Error fetching daily-task item");
    }
  };

  useEffect(() => {
    getAlldailytask();
  }, []);

  const handleViewClick = (_id) => {
    singleItemdailytask(_id);
    setViewModal(true);
  };
  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Daily Task
      </h2>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                S.no
              </th>
              <th scope="col" class="px-6 py-3">
                Date
              </th>
              <th scope="col" class="px-6 py-3">
                Team
              </th>
              <th scope="col" class="px-6 py-3">
                Staff name
              </th>
              <th scope="col" class="px-6 py-3">
                Staff id
              </th>
              <th scope="col" class="px-6 py-3">
                Department
              </th>
              <th scope="col" class="px-6 py-3">
                Designation
              </th>
              <th scope="col" class="px-6 py-3">
                Task
              </th>
              {/* <th scope="col" class="px-6 py-3">
                  Action
                </th> */}
            </tr>
          </thead>
          <tbody>
            {fdata
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((item, index) => (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" class="px-6 py-4">
                    {index + 1}
                  </th>
                  {/* <td class="px-6 py-4">{item.date}</td> */}
                  <td class="px-6 py-4">
                    {new Date(item.date).toLocaleDateString("en-GB")}
                  </td>
                  <td class="px-6 py-4">{tn(item.team)}</td>
                  <td class="px-6 py-4">{item.name}</td>
                  <td class="px-6 py-4">{item.id}</td>
                  <td class="px-6 py-4">{dn(item.department)}</td>
                  <td class="px-6 py-4">{item.designation}</td>
                  <td class="px-6 py-4">{item.task}</td>
                  {/* <td class="px-6 py-4">
                    <Link
                      className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0	hover:bg-white hover:border-0"
                      onClick={() => handleViewClick(item._id)}
                    >
                      view
                    </Link>{" "}
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>

        <Modal
          isOpen={viewModal}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "60%",
              height: "77%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          <div className="addstaff-container text-black p-10 px-4 py-8">
            <form className="max-w-5xl mx-auto">
              <h1 className="text-3xl font-bold underline mb-8 text-center">
                Daily Task
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2">
                <div className="mb-4 mx-4">
                  <label htmlFor="date" className="block font-bold mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={date}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="team" className="block font-bold mb-1">
                    Team
                  </label>
                  <input
                    type="text"
                    id="team"
                    name="team"
                    value={team}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="staffName" className="block font-bold mb-1">
                    Staff name
                  </label>
                  <input
                    type="text"
                    id="staffName"
                    name="staffName"
                    value={staffName}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="staffId" className="block font-bold mb-1">
                    Staff Id
                  </label>
                  <input
                    type="text"
                    id="staffId"
                    name="staffId"
                    value={staffId}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="designation" className="block font-bold mb-1">
                    Designation
                  </label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    value={designation}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="department" className="block font-bold mb-1">
                    Department
                  </label>
                  <input
                    type="text"
                    id="department"
                    name="department"
                    value={department}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>

                <div className="mb-4 mx-4">
                  <label htmlFor="task" className="block font-bold mb-1">
                    Task
                  </label>
                  <input
                    type="text"
                    id="task"
                    name="task"
                    value={task}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
              </div>

              <div className="flex justify-between ">
                <button
                  type="button"
                  className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4 mx-4 mt-4"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      <Toaster />
    </div>
  );
};

export default Managedailytask;
