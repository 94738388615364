// ManagePerformance.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Modal from "react-modal";
import ViewTargets from "./ViewTargets";
/* import EditTargets from "./EditTargets"; */

Modal.setAppElement("#root");

const ManageTargets = () => {
  const [targetData, setTargetData] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [staffNames, setStaffNames] = useState([]);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  useEffect(() => {
    const names = targetData.map((target) => target.staffName);
    setStaffNames([...new Set(names)]);
  }, [targetData]);
  useEffect(
    () => {
      fetchData();
    },
    [
      /* staffData, staffNames */
    ]
  );
  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/target");
      setTargetData(response.data);
    } catch (error) {
      console.error("Error fetching Target data:", error);
    }
  };
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Target record?"
    );
    if (!confirmDelete) {
      return;
    }
    try {
      await axios.delete(`/api/v1/target/${id}`);
      toast.success("Target record deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting Target record:", error);
      toast.error("Error deleting Target record");
    }
  };
  const handleView = (Target) => {
    setSelectedTarget(Target);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setSelectedTarget({});
    setModalOpen(false);
  };
  /*   const handleEdit = (Target) => {
    setSelectedTarget(Target);
  setEditModalOpen(true);
  }; */
  const handlePrint = () => {};
  const handleCloseEditModal = () => {
    setSelectedTarget({});
    setEditModalOpen(false);
  };
  return (
    <div className="main-container">
      <h2 className="text-3xl text-black font-bold mb-8 text-center underline">
        Manage Targets
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Month
              </th>
              <th scope="col" className="px-6 py-3">
                Target From
              </th>
              <th scope="col" className="px-6 py-3">
                {" "}
                Target To
              </th>
              <th scope="col" className="px-6 py-3">
                Total Days
              </th>
              <th scope="col" className="px-6 py-3">
                Staff Id
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {targetData
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((target, index) => {
                return (
                  <tr
                    key={target._id}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <th scope="row" className="px-6 py-4">
                      {index + 1}
                    </th>
                    <td className="px-6 py-4">{target.designation}</td>
                    <td className="px-6 py-4">{target.month}</td>
                    <td className="px-6 py-4">{target.targetFrom}</td>
                    <td className="px-6 py-4">{target.targetTo}</td>
                    <td className="px-6 py-4">{target.totalDays} days</td>
                    <td className="px-6 py-4">{target.staffId}</td>
                    <td className="px-6 py-4 flex gap-2">
                      <Link
                        to="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => handleView(target)}
                      >
                        View
                      </Link>
                      {/*  <Link to="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => handleEdit(target)}>Edit</Link> */}
                      <Link
                        to="#"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        onClick={() => handleDelete(target._id)}
                      >
                        Delete
                      </Link>
                      {/* <Link to="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" onClick={() => handlePrint(target._id)}>Print</Link> */}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Toaster />
      <ViewTargets
        isOpen={modalOpen}
        selectedTarget={selectedTarget}
        handleCloseModal={handleCloseModal}
      />

    </div>
  );
};

export default ManageTargets;
