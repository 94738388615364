import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
const AdvanceSalary = () => {
  const [staffData, setStaffData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [teamName, setTeamName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState({
    employeeId: "",
    team: "",
    designation: "",
    department: "",
  });
  const [formData, setFormData] = useState({
    date: "",
    month: "",
    id:employeeDetails.employeeId,
    AdvanceSalary: "",
    amountofadvancesalary: "",
    amount: "",
  });
  const clearForm = () => {
    setFormData({
      Date: "",
      Month: "",
      AdvanceSalary: "",
      amountofadvancesalary: "",
      amount: "",
    });
    setSelectedEmployee("");
    setEmployeeDetails({
      employeeId: "",
      team: "",
      designation: "",
      department: "",
    });
  };

  useEffect(() => {
    const getAllStaffData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/staff/getAllStaff"
        );
       
        setStaffData(response.data.data);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    getAllStaffData();
  }, []);

  useEffect(() => {
    const fetchTeamName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/team/getById/${employeeDetails.team}`
        );
        setTeamName(response.data.name);
      } catch (error) {
        console.error(
          `Error fetching team name for team ${employeeDetails.team}:`,
          error
        );
        setTeamName("Unknown");
      }
    };

    if (employeeDetails.team) {
      fetchTeamName();
    }
  }, [employeeDetails.team]);

  useEffect(() => {
    const fetchDepartmentName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/depart/getSingledepart/${employeeDetails.department}`
        );
 
        setDepartmentName(response.data.data.name);
      } catch (error) {
        console.error(
          `Error fetching department name for department ${employeeDetails.department}:`,
          error
        );
        setDepartmentName("Unknown");
      }
    };

    if (employeeDetails.department) {
      fetchDepartmentName();
    }
  }, [employeeDetails.department]);


  const handleEmployeeSelect = (e) => {
    const selectedEmployeeId = e.target.value;
    setSelectedEmployee(selectedEmployeeId);
    const selectedStaff = staffData.find(
      (staff) => staff._id === selectedEmployeeId
    );
    if (selectedStaff) {
      setEmployeeDetails({
        employeeId: selectedStaff.id,
        team: selectedStaff.team,
        designation: selectedStaff.designation,
        department: selectedStaff.department,
      });
  
      // Update the employeeId in formData
      setFormData({
        ...formData,
        employeeId: selectedStaff.id
      });
    } else {
      setEmployeeDetails({
        employeeId: "",
        team: "",
        designation: "",
        department: "",
      });
  
      // If no employee is selected, clear the employeeId in formData
      setFormData({
        ...formData,
        employeeId: ""
      });
    }
  };
  

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const response = await axios.post(
        "/api/v1/advancesalary/createadvancesalarydata",
        {
          ...formData,
          staffid: selectedEmployee,
          id:employeeDetails.employeeId,
        }
      );
      if (response.data.success) {
       
        toast.success(response.data.message);
        clearForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setFormData({
      ...formData,
      amountofadvancesalary: amount,
      amount: convertAmountToWords(Number(amount)), // Convert amount to a number before passing to the function
    });
  };

  const convertAmountToWords = (amount) => {
    const singleDigits = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if (amount === 0) return "Zero";

    const inWords = (number) => {
      if (number < 10) return singleDigits[number];
      if (number < 20) return teens[number - 10];
      if (number < 100)
        return `${tens[Math.floor(number / 10)]} ${
          singleDigits[number % 10]
        }`.trim();
      if (number < 1000)
        return `${singleDigits[Math.floor(number / 100)]} Hundred ${inWords(
          number % 100
        )}`.trim();
      if (number < 1000000)
        return `${inWords(Math.floor(number / 1000))} Thousand ${inWords(
          number % 1000
        )}`.trim();
      if (number < 1000000000)
        return `${inWords(Math.floor(number / 1000000))} Million ${inWords(
          number % 1000000
        )}`.trim();
      return "Number too large";
    };

    return inWords(amount);
  };



  return (
    <div className="addstaff-container px-4 mx-auto lg:px-12 w-full  my-4">
      <div className="relative bg-gray-700 dark:bg-gray-800">
        <div className="flex flex-col items-center justify-center p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <div className="w-full md:w-1/2 text-center text-gray-100 font-bold text-lg ">
            Advance Salary To Employee
          </div>
         
     
        </div>
      </div>
      <div className="flex flex-col bg-gray-200">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8  ">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 justify-between p-8 space-y-3 ">
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-center">
                    <div className="col-md-4 ">
                      <div className="form-group">
                        <label
                          htmlFor="date"
                          className="block text-sm font-medium text-gray-700"
                        >
                          date
                        </label>
                        <input
                          type="Date"
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="date"
                          placeholder="Enter Name*"
                          name="date"
                          value={formData.date}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="month"
                          className="block text-sm font-medium text-gray-700"
                        >
                          month
                        </label>
                        <select
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300  bg-white focus:ring-indigo-500 focus:border-indigo-500"
                          id="month"
                          name="month"
                          value={formData.month}
                          onChange={handleInputChange}
                        >
                          <option value="" disabled selected>
                            Select Month
                          </option>
                          <option value="January">January</option>
                          <option value="February">February</option>
                          <option value="March">March</option>
                          <option value="April">April</option>
                          <option value="May">May</option>
                          <option value="June">June</option>
                          <option value="July">July</option>
                          <option value="August">August</option>
                          <option value="September">September</option>
                          <option value="October">October</option>
                          <option value="November">November</option>
                          <option value="December">December</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="staffid"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Employee Name
                        </label>
                        <select
                          id="staffid"
                          className="form-select rounded p-1 mt-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          onChange={handleEmployeeSelect}
                          name="staffid"
                          value={selectedEmployee}
                        >
                          <option value="">Select Employee</option>
                          {staffData.map((staff) => (
                            <option key={staff._id} value={staff._id}>
                              {staff.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="Id"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Employee Id
                        </label>
                        <input
                          type="text"
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder="Employee Id *"
                          value={employeeDetails.employeeId}
                       
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="Team"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Team
                        </label>
                        <input
                          type="text"
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder="Team*"
                          value={teamName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="Designation"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Designation
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 rounded p-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500"
                          placeholder="Designation *"
                          value={employeeDetails.designation}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="Department"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Department
                        </label>
                        <input
                          type="text"
                          className="form-input mt-1 block w-full border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 rounded p-1"
                          placeholder="Department *"
                          value={departmentName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="resonforadvancesalary"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Reason Of Advance Salary
                        </label>
                        <textarea
                          type="text"
                          className="form-input rounded mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="resonforadvancesalary"
                          placeholder="Reason Of Advance Salary *"
                          name="resonforadvancesalary"
                          value={formData.resonforadvancesalary}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="amountofadvancesalary"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Amount
                        </label>
                        <input
                          type="text"
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="amountofadvancesalary"
                          placeholder="Amount *"
                          name="amountofadvancesalary"
                          value={formData.amountofadvancesalary}
                          onChange={handleAmountChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="amount"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Amount In Words
                        </label>
                        <input
                          type="text"
                          className="form-input rounded p-1 mt-1 block w-full border border-gray-300  focus:ring-indigo-500 focus:border-indigo-500"
                          id="amount"
                          placeholder="In Words *"
                          name="amount"
                          value={formData.amount} // Display the converted amount here
                          readOnly // Make it read-only to prevent user input
                        />
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer text-center mt-3">
                    <button
                      type="submit"
                      className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2"
                    >
                      Create
                    </button>
                    <button
                      type="submit"
                      className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-800 ml-2"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AdvanceSalary;
