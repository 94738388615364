import React, { useState, useEffect } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const AllDeductionRecord = () => {
  const [staffData, setStaffData] = useState([]);
  const [dop, setdop] = useState([]);
  const [viewingUser, setViewingUser] = useState(false);
  const [deduction, setDeduction] = useState([]);
  const [staff, setStaff] = useState([]);
  const [dept, setDept] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState("");
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [teams, setTeams] = useState([]);
  const[search,setSearch] = useState({startDate:"",endDate:""});

  const [teamNames, setTeamNames] = useState({});
  const [deptNames, setDeptNames] = useState({});

  const [formData, setFormData] = useState({
    Date: "",
    EmployeeName: "",
    EmployeeId: "",
    Designation: "",
    department: "",
    team: "",
    Reason: "",
    Amountofdeduction: "",
  });

  const getAllStaff = async () => {
    try {
      const data = await axios.get("/api/v1/staff/getAllStaff");
      setStaff(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");
      setDept(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        setTeams(response.data.allTeam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getAllDepart();
    getAllStaff();
    fetchData();
  }, []);

  const handleTeamChange = (e) => {
    const selectedTeam = e.target.value;
    setFormData({
      ...formData,
      team: selectedTeam,
      staffName: "",
    });
    const teamStaff = staff.filter((staff) => staff.team === selectedTeam);
    setFilteredStaff(teamStaff);
  };

  // function creation for fetching deductionm amount
  const fetchD = async (did) => {
    try {
      const res = await axios.get(`/api/v1/salary/single-salary/${did}`);
      const { data } = res.data;

      if (res) {
        var sOp = [];

        // Store values in the array
        sOp.push(data[0]?.lateAmount ? data[0].lateAmount : "0");
        sOp.push(data[0]?.absentAmount ? data[0].absentAmount : "0");
        sOp.push(data[0]?.rules_regulation ? data[0].rules_regulation : "0");
        setdop(sOp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchD();
  }, []);

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepart(selectedDepartment);
    setFormData({
      ...formData,
      department: selectedDepartment,
      team: "",
      staffName: "",
      designation: "",
    });
    setFilteredStaff([]);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/record/ded-record "); //  change api
      const { data } = response.data;
      setDeduction(data.map((entry) => entry.dedRecord).flat());
      getAllStaff();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const fetchTeamAndDeptNames = async () => {
      try {
        const responseDept = await axios.get("/api/v1/depart/getalldepart");
        const deptNamesData = {};
        responseDept.data.data.forEach((dept) => {
          deptNamesData[dept._id] = dept.name;
        });
        setDeptNames(deptNamesData);

        const responseTeam = await axios.get("/api/v1/team/getAll/");
        const teamNamesData = {};
        responseTeam.data.allTeam.forEach((team) => {
          teamNamesData[team._id] = team.name;
        });
        setTeamNames(teamNamesData);
      } catch (error) {
        console.error("Error fetching team and department names:", error);
      }
    };
    fetchTeamAndDeptNames();
  }, []);

  return (
    <div className="addstaff-container max-w-screen-xl px-4 mx-auto lg:px-2 w-full  my-4">
        
      <h1 className="text-2xl font-bold mb-4 text-center underline">
        All Deduction Record
      </h1>
      <div className="bg-white rounded px-1 pt-2 pb-8  ">
        <p className="mx-2">Search by date Range</p>
        <input
          className=" mb-2 appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.startDate}
          onChange={(e) => setSearch({ ...search, startDate: e.target.value })}
          placeholder="enter start date"
        />

        <input
          className=" shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.endDate}
          onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
          placeholder="enter end date"
        />
      </div>


      <div className="flex flex-col">
        <div className=" overflow-x-auto ">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  S.No.
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Employee Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Employee Id
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Designation
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Team
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Reason of deduction
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-200 divide-y divide-gray-200">
              {deduction
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.Date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((deduction, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.Date}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.EmployeeName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.EmployeeId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.Designation}
                  </td>
                  <td className="px-2 py-4 text-center">
                    {deptNames[deduction.department]}
                  </td>
                  <td className="px-2 py-4 text-center">
                    {teamNames[deduction.team]}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.Reason}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {deduction.Amountofdeduction}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default AllDeductionRecord;
