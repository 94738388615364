import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./components/auth/Login";
import { useEffect } from "react";
import AdminProtectedRoute from "./components/Routes/AdminProtectedRoute";
import StaffProtectedRoute from "./components/Routes/StaffProtectedRoute";
import AdminRoutes from "./components/Routes/AdminRoutes";
import StaffRoutes from "./components/Routes/StaffRoutes";
import Test from "./components/Test";
import Speech from "./components/Speech";
import { SessionTimeoutProvider,useSessionTimeout } from "./components/context/SessionTimeoutContext";
function App() {
  return (
    <BrowserRouter>
        <SessionTimeoutProvider>
         <AppContent />
        </SessionTimeoutProvider>
     </BrowserRouter>
  );
}

function AppContent() {
  const { resetTimer } = useSessionTimeout();
  useEffect(() => {
    resetTimer();
  }, [resetTimer]);

  return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/staff" element={<StaffProtectedRoute />} >
          <Route path="/staff/dashboard/*" element={<StaffRoutes />} />
        </Route>
        <Route path="/admin" element={<AdminProtectedRoute />} >
          <Route path="/admin/dashboard/*" element={<AdminRoutes />} />
        </Route>
        <Route path="/*" element={<Test />} />
      </Routes>
   
  );
}

export default App;