import {React,useState} from "react";
import { BsSearch, BsJustify } from "react-icons/bs";
import "./Admin.css";
import NoticeModal from "./modals/NoticeModal";
import CelebrationModel from "./modals/CelebrationModel";
import ReminderModel from "./modals/ReminderModel";
import { MdOutlineNotificationsActive, MdSpeakerNotes, MdCelebration } from "react-icons/md";
function Header({ OpenSidebar }) {
  
  const [noticeStatus, setNoticeStatus] = useState(false);
  const [CelebrationStatus, setCelebrationStatus] = useState(false);
  const [ReminderStatus, setReminderStatus] = useState(false);


  const toggleNoticeModal = () => {
    setNoticeStatus(!noticeStatus); // Toggle the state
  };

  const closeNoticeModal = () => {
    setNoticeStatus(false); 
  };
  const toggleCelebrationeModal = () => {
    setCelebrationStatus(!CelebrationStatus); // Toggle the state
  };

  const closeCelebrationeModal = () => {
    setCelebrationStatus(false); 
  };
  const toggleReminderModal = () => {
    setReminderStatus(!CelebrationStatus); // Toggle the state
  };

  const closeReminderModal = () => {
    setReminderStatus(false); 
  };
  
  return (
    <header className="header ">
      <div className="menu-icon">
        <BsJustify className="icon" onClick={OpenSidebar} />
      </div>

      <div className="header-left">
        
      </div>

      <div className="header-right ml-1">
      <button onClick={toggleCelebrationeModal} className="text-white bg-transparent rounded-full pr-2  ">
          <MdCelebration className="h-12 w-8" />
        </button>
        <button onClick={toggleNoticeModal} className="text-white bg-transparent rounded-full pr-2">
          <MdSpeakerNotes className="h-12 w-8" />
        </button>
        <button onClick={toggleReminderModal} className="text-white bg-transparent rounded-full pr-2">
          <MdOutlineNotificationsActive className="h-12 w-8"/>
        </button>
        <img
          className="w-10 h-10 rounded-full"
          alt="head"
          src="/api/v1/staff/getPhoto/6603d66fe12133df05660980"
        />
        {/* <button onClick={toggleButton}>{isOn ? "On" : "Off"}</button> */}
      </div>
      {noticeStatus && <NoticeModal isOpen={noticeStatus} onClose={closeNoticeModal} />}
      {CelebrationStatus && <CelebrationModel isOpen={CelebrationStatus} onClose={closeCelebrationeModal} />}
      {ReminderStatus && <ReminderModel isOpen={ReminderStatus} onClose={closeReminderModal} />}
    </header>
  );
}

export default Header;
