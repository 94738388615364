

import React, { useState } from "react";
import axios from "axios";
import signup from "../../assets/images/forget.jpg";
import ResetPassword from "./ResetPassword";
import toast, { Toaster } from 'react-hot-toast';
import Loader from "../loader/Loader";

function ForgetPassword({ onClose }) {
  const [email, setEmail] = useState("");
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 

  const handleSendMailClick = async () => {
    try {
      setLoading(true); 

      sessionStorage.setItem("read",email)
      const response = await axios.post("/api/v1/user/forget-password", {
        email
      });
      setLoading(false); 
      if (response.data.success) {
        toast.success(response.data.message);
      
       
        
        setResetPasswordModalOpen(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      setLoading(false); 
      console.error("Error sending reset password email:", error);
    }
  };

  return (
    <div className="modal is-open">
      <div className="modal-container">
        <div className="modal-left">
          <h1 className="modal-title">Forget Password!</h1>
          <p className="modal-desc">
            Oops! Can't recall your password? Don't sweat it! Enter your email, and we'll send you an otp to reset your password in no time.
          </p>
          <div className="input-block">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className="modal-buttons">
            <button className="input-button" onClick={handleSendMailClick}>
              {loading ? <Loader /> : "Send Mail"} {/* Render Loader or button text based on loading state */}
            </button>
          </div>
          <p className="sign-up">
            Already have an account? <span onClick={onClose}>Login now</span>
          </p>
        </div>
        <div className="modal-right">
          <img src={signup} alt="" />
        </div>
        {resetPasswordModalOpen && (
          <ResetPassword onClose={() => setResetPasswordModalOpen(false)} />
        )}
        <button className="icon-button close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M 25 3 C 12.86158 3 3 12.86158 3 25 C 3 37.13842 12.86158 47 25 47 C 37.13842 47 47 37.13842 47 25 C 47 12.86158 37.13842 3 25 3 z M 25 5 C 36.05754 5 45 13.94246 45 25 C 45 36.05754 36.05754 45 25 45 C 13.94246 45 5 36.05754 5 25 C 5 13.94246 13.94246 5 25 5 z M 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.980469 15.990234 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 z"></path>
          </svg>
        </button>
      </div>
      <Toaster />
    </div>
  );
}

export default ForgetPassword;
