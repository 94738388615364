import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const CreatePerformance = () => {
  const [teams, setTeams] = useState([]);
  const [department, setDepartment] = useState([]);
  const [deptData, setDeptData] = useState("");
  const [allStaff, setAllStaff] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [team, setTeam] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [staffId, setStaffId] = useState("");
  const [designation, setDesignation] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        // console.log(response.data.allTeam[2].name, " :  allteam");
        setTeams(response.data.allTeam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // getAlldepart();
    fetchData();
  }, []);
  const [performanceData, setPerformanceData] = useState({
    date: "",
    month: "",
    from: "",
    to: "",
    team: "",
    staffName: "",
    staffId: "",
    department: "",
    designation: "",
    targets: [{ serviceName: "", amount: "" }],
    achievement: [{ serviceName: "", amount: "", performance: "" }],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Performance Data:", performanceData);

    try {
      await axios.post("/api/v1/performance", performanceData);
      toast.success("Staff Performance Created Successfully");
      setPerformanceData({
        date: "",
        month: "",
        from: "",
        to: "",
        team: "",
        staffName: "",
        staffId: "",
        department: "",
        designation: "",
        targets: [{ serviceName: "", amount: "" }],
        achievement: [{ serviceName: "", amount: "", performance: "" }],
      });
    } catch (error) {
      toast.error("Error in creating Performance");
      console.error("Error submitting performance data:", error);
    }
  };

  ///////
  //get all department
  const getAlldepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");

      const departmentNames = data.data.data.map(
        (department) => department.name
      );
      setDepartment(data.data.data);
      setDeptData(departmentNames);
    } catch (error) {
      console.log(error);
    }
  };
  // getAll Team
  const getAllTeam = async () => {
    try {
      const response = await axios.get("/api/v1/team/getAll/");
      setTeams(response.data.allTeam);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // getAll staff
  const getAllStaff = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      setAllStaff(response.data.data);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  /// handle department changesw
  const handleDepartmentChange = (e) => {
    const selectedDeptId = e.target.value;
    setSelectedDepartment(selectedDeptId);
    setPerformanceData({ ...performanceData, department: selectedDeptId });
    setSelectedTeamId("");
    setSelectedStaff("");
    setStaffId("");
    setDesignation("");
  };

  // handle team changes

  const handleTeamChange = (e) => {
    const selectedTeamId = e.target.value;
    setSelectedTeamId(selectedTeamId);
    setPerformanceData({ ...performanceData, team: selectedTeamId });
    setSelectedStaff("");
    setStaffId("");
    setDesignation("");
  };

  // handlestaffchange
  const handleStaffChange = (e) => {
    const selectedStaffId = e.target.value;
    setSelectedStaff(selectedStaffId);
    const selectedStaffObj = allStaff.find(
      (staff) => staff._id === selectedStaffId
    );

    if (selectedStaffObj) {
      setStaffId(selectedStaffObj.id);
      setDesignation(selectedStaffObj.designation);
      setPerformanceData({
        ...performanceData,
        staffName: selectedStaffObj.name,
        staffId: selectedStaffObj.id,
        designation: selectedStaffObj.designation,
      });
    } else {
      setStaffId("");
      setDesignation("");
    }
  };

  useEffect(() => {
    getAlldepart();
    getAllTeam();
    getAllStaff();
  }, [selectedDepartment, selectedTeamId, selectedStaff]);

  const filteredTeams = teams.filter(
    (team) => team.departId === selectedDepartment
  );

  const filteredStaff = Array.isArray(allStaff)
    ? allStaff.filter((staff) => staff.team === selectedTeamId)
    : [];
  //////

  const handlePerformanceDataChange = (e, fieldName) => {
    const { name, value } = e.target;
    setPerformanceData({ ...performanceData, [name]: value });
  };

  const handleTargetChange = (index, e, field) => {
    const { value } = e.target;
    const updatedTargets = [...performanceData.targets];
    updatedTargets[index] = {
      ...updatedTargets[index],
      [field]: value,
    };
    setPerformanceData((prevData) => ({
      ...prevData,
      targets: updatedTargets.map((target, idx) => ({
        ...target,
        amount: idx === index ? parseFloat(value) || 0 : target.amount,
      })),
    }));
  };

  const handleAchievementChange = (index, e, field) => {
    const { value } = e.target;
    const updatedAchievements = [...performanceData.achievement];
    updatedAchievements[index] = {
      ...updatedAchievements[index],
      [field]: value,
    };
    setPerformanceData((prevData) => ({
      ...prevData,
      achievement: updatedAchievements.map((achievement, idx) => ({
        ...achievement,
        [field]: field === "performance" ? value : achievement[field],
        amount: idx === index ? parseFloat(achievement.amount) || 0 : achievement.amount,
      })),
    }));
  };

  const addTarget = (e) => {
    e.preventDefault();
    setPerformanceData({
      ...performanceData,
      targets: [...performanceData.targets, { serviceName: "", amount: "" }],
    });
  };
  const removeTarget = (index) => {
    if (index < 1) {
      return;
    }

    const updatedTargets = [...performanceData.targets];
    updatedTargets.splice(index, 1);
    setPerformanceData({ ...performanceData, targets: updatedTargets });
  };
  const addAchievement = (e) => {
    e.preventDefault();
    setPerformanceData({
      ...performanceData,
      achievement: [
        ...performanceData.achievement,
        { serviceName: "", amount: "", performance: "" },
      ],
    });
  };
  const removeAchievement = (index) => {
    if (index < 1) {
      return;
    }
    const updatedAchievements = [...performanceData.achievement];
    updatedAchievements.splice(index, 1);
    setPerformanceData({
      ...performanceData,
      achievement: updatedAchievements,
    });
  };
  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form className="max-w-6xl mx-auto" onSubmit={handleSubmit}>
        <h1 className="text-3xl font-bold underline mb-8 text-center">
          Create Performance
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
          <div className="mb-3 mx-4">
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={performanceData.date}
              required
              onChange={(e) => handlePerformanceDataChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="Month" className="block font-bold mb-1">
              Month
            </label>
            <select
              id="Month"
              name="month"
              value={performanceData.month}
              required
              onChange={(e) => handlePerformanceDataChange(e)}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="from" className="block font-bold mb-1">
              From
            </label>
            <input
              type="date"
              id="from"
              name="from"
              required
              value={performanceData.from}
              onChange={(e) => handlePerformanceDataChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="to" className="block font-bold mb-1">
              To
            </label>
            <input
              type="date"
              id="to"
              name="to"
              value={performanceData.to}
              onChange={(e) => handlePerformanceDataChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <select
              id="department"
              name="department"
              value={selectedDepartment}
              onChange={(e) => handleDepartmentChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
            >
              <option value="">Select Department</option>
              {Array.isArray(department) ? (
                department.map((dept, index) => (
                  <option key={index} value={dept._id}>
                    {dept.name}
                  </option>
                ))
              ) : (
                <option value="">Department data not available</option>
              )}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="team" className="block font-bold mb-1">
              Team
            </label>
            <select
              id="team"
              name="team"
              value={selectedTeamId}
              onChange={(e) => handleTeamChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
              required
            >
              <option value="">Select Team</option>
              {filteredTeams.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="staff" className="block font-bold mb-1">
              Staff Name
            </label>
            <select
              id="staff"
              name="staff"
              value={selectedStaff}
              onChange={(e) => handleStaffChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
              required
            >
              <option value="">Select Staff</option>
              {filteredStaff.map((staff) => (
                <option key={staff._id} value={staff._id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="staffId" className="block font-bold mb-1">
              StaffId
            </label>
            <input
              type="text"
              id="staffId"
              name="staffId"
              value={staffId}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="designation" className="block font-bold mb-1">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={designation}
              onChange={(e) => handlePerformanceDataChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
              readOnly
            />
          </div>
        </div>
        <div className="mb-3 mt-3 mx-4">
          <h2 className="text-2xl font-bold">Targets Given</h2>
        </div>
        {performanceData.targets.map((target, index) => (
          <div key={index} className="flex flex-wrap items-center mt-3 mx-3">
            <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
              <label
                htmlFor={`serviceName_${index}`}
                className="block font-bold mb-1"
              >
                Service Name
              </label>
              <input
                type="text"
                id={`serviceName_${index}`}
                name={`serviceName_${index}`}
                value={target.serviceName || ""}
                onChange={(e) => handleTargetChange(index, e, "serviceName")}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
              <label
                htmlFor={`amount_${index}`}
                className="block font-bold mb-1"
              >
                Amount
              </label>

              <input
                type="text"
                id={`amount_${index}`}
                name={`amount_${index}`}
                value={target.amount || ""}
                onChange={(e) => handleTargetChange(index, e, "amount")}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <button
              className="text-black py-2 px-8 rounded mx-4 mt-5 bg-gray-400"
              onClick={() => removeTarget(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          className="text-black py-2 px-8 rounded mb-4 mx-4 mt-4 bg-gray-400"
          onClick={addTarget}
        >
          Add Target
        </button>
        <div className="mb-3 mt-3 mx-4">
          <h2 className="text-2xl font-bold mt-4">Targets Achievement</h2>
        </div>
        {performanceData.achievement.map((achievement, index) => (
          <div key={index} className="flex flex-wrap items-center mt-3 mx-3">
            <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
              <label
                htmlFor={`serviceName_achievement_${index}`}
                className="block font-bold mb-1"
              >
                Service Name
              </label>
              <input
                type="text"
                id={`serviceName_${index}`}
                name={`serviceName_${index}`}
                value={achievement.serviceName || ""}
                onChange={(e) =>
                  handleAchievementChange(index, e, "serviceName")
                }
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
              <label
                htmlFor={`amount_${index}`}
                className="block font-bold mb-1"
              >
                Amount
              </label>
              <input
                type="text"
                id={`amount_${index}`}
                name={`amount_${index}`}
                value={achievement.amount || ""}
                onChange={(e) => handleAchievementChange(index, e, "amount")}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
              <label
                htmlFor={`performance_${index}`}
                className="block font-bold mb-1"
              >
                Performance
              </label>
              <select
                id={`performance_${index}`}
                name={`performance_${index}`}
                value={achievement.performance || ""}
                onChange={(e) =>
                  handleAchievementChange(index, e, "performance")
                }
                className="px-4 py-2 border text-black rounded-md w-full sm:max-w-md"
              >
                <option value="">Select Performance</option>
                <option value="Excellent">Excellent</option>
                <option value="Good">Good</option>
                <option value="Average">Average</option>
              </select>
            </div>
            <button
              className="text-black py-2 px-8 rounded mx-4 mt-5 bg-gray-400"
              onClick={() => removeAchievement(index)}
            >
              Remove
            </button>
          </div>
        ))}
        <button
          className="text-black py-2 px-8 rounded mb-4 mx-4 mt-4 bg-gray-400"
          onClick={addAchievement}
        >
          Add Achievement
        </button>
        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="text-black bg-green-400 hover:bg-green-500 py-2 px-8 rounded"
          >
            Submit
          </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default CreatePerformance;
