import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const ViewCms = ({ isOpen, cms, handleCloseModal }) => {
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("80%");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setModalWidth("90%");
        setModalHeight("90%");
      } else {
        setModalWidth("80%");
        setModalHeight("80%");
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          height: modalHeight,
          overflow: "auto",
        },
      }}
      overlayClassName="overlay"
    >
      <div className="flex justify-end">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <h2 className="text-3xl font-bold text-center underline">
        View Proposal
      </h2>
      <div className="  bg-white rounded  ">
        {cms && (
          <div className="bg-white rounded  ">
            <div className="gap-4 ">
              <div className="">
                <p
                className="sm:p-20 text-black sm:min-w-full h-custom"
                style={{ height: "420px" }}>{cms.message}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewCms;
