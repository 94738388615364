import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const Receipt = () => {
  const [transactionType, setTransactionType] = useState("");
  const [sumOfRupees, setsumOfRupees] = useState("");

  const [formData, setFormData] = useState({
    businessName: "",
    address: "",
    contactNo: "",
    email: "",
    website: "",
    gstIn: "",
    receiptNo: "",
    date: "",
    received: "",
    againstInvoice: "",
    fordata: "",
    sumOfRupees: "",
    by: "",
    transactionId: "",
    amount: "",
    logo: null,
  });
  const fetchCompanyData = async () => {
    try {
      const response = await axios.get("/api/v1/company/getCompanyDetails");
      setFormData(response.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    
    fetchCompanyData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("by", transactionType);
      formDataToSend.append("sumOfRupees", sumOfRupees);


      const response = await axios.post(
        "/api/v1/receipt/createreceipt/",
        formDataToSend
      );
      if (response.status === 200) {
        // Reset form data after successful submission
        setFormData({
          businessName: "",
          address: "",
          contactNo: "",
          email: "",
          website: "",
          gstIn: "",
          receiptNo: "",
          date: "",
          received: "",
          againstInvoice: "",
          fordata: "",
          sumOfRupees: "",
          by: "",
          transactionId: "",
          amount: "",
          logo: null,
        });
        toast.success(response.data.message);
        fetchCompanyData();
      } else {
        console.error(
          "Error creating receipt - Unexpected status code:",
          response.status
        );
        toast.error("Error creating receipt. Please try again.");
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
      toast.error("Error creating receipt. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Convert amount to words when amount field changes
    if (name === "amount") {
      const sumOfRupees = convertAmountToWords(value);
      setsumOfRupees(sumOfRupees);
    }
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  // Function to convert numeric amount to words
  const convertAmountToWords = (amount) => {
    // Function to convert a numeric amount to words
    const ones = [
        "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
        "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen",
        "seventeen", "eighteen", "nineteen"
    ];
    const tens = [
        "", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"
    ];

    const toWords = (num) => {
        if (num === 0) return "zero";
        if (num < 20) return ones[num];
        if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + ones[num % 10] : "");
        if (num < 1000) return ones[Math.floor(num / 100)] + " hundred" + (num % 100 !== 0 ? " and " + toWords(num % 100) : "");
        if (num < 1000000) return toWords(Math.floor(num / 1000)) + " thousand" + (num % 1000 !== 0 ? " " + toWords(num % 1000) : "");
        if (num < 1000000000) return toWords(Math.floor(num / 1000000)) + " million" + (num % 1000000 !== 0 ? " " + toWords(num % 1000000) : "");
        return "Number too large to convert";
    };

    // Convert the numeric amount to words
    return toWords(parseInt(amount, 10));
};



  return (
    <div className="addstaff-container text-black mt-4">
      <style>
        {`

  @media print {

    @page {
      size: A4;
      margin: 0;
      width:50%;
    }

    .border-b {
      border-bottom: none;
    }

    body * {
      visibility: hidden;

    }
    .addstaff-container, .addstaff-container * {
      visibility: visible;
    }

    .addstaff-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    .flex {
      display: block;
    }

    .hide-on-print {
      display: none !important;
    }
    .hide-on-print button{
      display: none !important;
    }
  }

  .print-container {
    display: block;
    page-break-before: always;
  }
        `}
      </style>
      <form
        className="max-w-7xl mx-auto rounded-lg shadow-md md:pl-4 md:pr-4 p-2"
        onSubmit={handleSubmit}
      >
            <div className="flex flex-col md:flex-row mt-2 text-center text-md sm:text-xl md:text-3xl ">
          
              <input
                type="text"
                id="businessName"
                name="businessName"
                className="flex-1 text-center"
                onChange={handleChange}
                value={formData.businessName}
                readOnly
              />
            </div>
            <div className="flex flex-col md:flex-row mt-2 text-center   text-sm sm:text-md md:text-xl">
           
              <input
                type="text"
                id="address"
                name="address"
                className="flex-1 text-center"
                onChange={handleChange}
                value={formData.address}
                readOnly
              />
            </div>
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
          <div className="mb-5">
            <input type="file" className="hidden text-black" />
            <div className="text-right">
              <div className="mt-2">
                <img
                  className="block w-38 h-24 rounded-1/2  "
                  src={`/api/v1/company/getPhoto/${formData._id}` }
                  name="logo"
                  id="logo"
                  alt="Company Logo"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
          
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="contact"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                Contact:
              </label>
              <input
                type="text"
                id="contactNo"
                name="contactNo"
                className="flex-1"
                onChange={handleChange}
                value={formData.contactNo}
                readOnly
              />
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="email"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                E-mail:
              </label>
              <input
                type="text"
                id="email"
                name="email"
                className="flex-1"
                onChange={handleChange}
                value={formData.email}
                readOnly
              />
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="website"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                Website:
              </label>
              <input
                type="text"
                id="website"
                name="website"
                className="flex-1"
                onChange={handleChange}
                value={formData.website}
                readOnly
              />
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <label
                htmlFor="gstin"
                className="font-semibold md:w-1/3 mb-2 md:mb-0"
              >
                GSTIN:
              </label>
              <input
                type="text"
                id="gstIn"
                name="gstIn"
                className="flex-1"
                onChange={handleChange}
                value={formData.gstIn}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h1 className="bold text-center mb-4 text-3xl mb-5 mt-2 underline">
            Receipt 
          </h1>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="receiptNo"
                className="font-semibold mb-2 md:mb-0 md:mr-2"
              >
                Receipt No
              </label>
              <input
                type="text"
                id="receiptNo"
                name="receiptNo"
                className="w-2/3 px-2 py-1 text-black border-b border-black mb-2 md:mb-0"
                onChange={handleChange}
                value={formData.receiptNo}
              />
            </div>
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="date"
                className="font-semibold mb-2 md:mb-0 md:mr-2"
              >
                Date
              </label>
              <input
                type="date"
                id="date"
                name="date"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                onChange={handleChange}
                value={formData.date}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="received"
                className="block font-bold mb-1 md:mb-0 md:mr-2"
              >
                Received with Thanks from
              </label>
              <input
                type="text"
                id="received"
                name="received"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                onChange={handleChange}
                value={formData.received}
              />
            </div>
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="againstInvoice"
                className="block font-bold mb-1 md:mb-0 md:mr-2"
              >
                Against invoice
              </label>
              <input
                type="text"
                id="againstInvoice"
                name="againstInvoice"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                onChange={handleChange}
                value={formData.againstInvoice}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="fordata"
                className="block font-bold mb-1 md:mb-0 md:mr-2"
              >
                For
              </label>
              <input
                type="text"
                id="fordata"
                name="fordata"
                className="w-2/3 px-4 py-1 text-black border-b border-black"
                onChange={handleChange}
                value={formData.fordata}
              />
            </div>
          

            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
            <label
              htmlFor="sumOfRupees"
              className="block font-bold mb-1 md:mb-0 md:mr-2"
            >
              the Sum of Rupees
            </label>
            <input
              type="text"
              id="sumOfRupees"
              name="sumOfRupees"
              className="w-2/3 px-4 py-2 text-black border-b border-black"
              value={sumOfRupees}
              readOnly
            />
          </div>
          </div>
          <div className="flex flex-col md:flex-row mt-2">
            <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
              <label
                htmlFor="by"
                className="block font-bold mb-1 md:mb-0 md:mr-2"
              >
                By
              </label>

              <select
                id="by"
                name="by"
                className="w-2/3 px-4 py-2 border-b border-black text-black"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
              >
                <option value="">Transaction Type</option>
                <option value="cash">Cash</option>
                <option value="online">Online</option>
                <option value="bankTransfer">Bank Transfer</option>
              </select>
            </div>
            {transactionType === "online" ||
            transactionType === "bankTransfer" ? (
              <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
                <label
                  htmlFor="transactionId"
                  className="block font-bold mb-1 md:mb-0 md:mr-2"
                >
                  Transaction Id
                </label>
                <input
                  type="text"
                  id="transactionId"
                  name="transactionId"
                  className="w-2/3 px-4 py-2 text-black border-b border-black"
                  onChange={handleChange}
                  value={formData.transactionId}
                />
              </div>
            ) : null}
          </div>
          <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
            <label
              htmlFor="amount"
              className="block font-bold mb-1 md:mb-0 md:mr-2"
            >
              Amount
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              className="w-2/3 px-4 py-2 text-black border-b border-black"
              onChange={handleChange}
              value={formData.amount}
            />
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="print-button bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded flex flex-col md:flex-row mt-2 m-4 hide-on-print"
          >
            Create Recipt
          </button>
          {/* <button
            type="reset"
            onClick={handlePrint}
            className="save-button bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded flex flex-col md:flex-row mt-2 m-4 hide-on-print"
          >
            Print
          </button> */}
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default Receipt;