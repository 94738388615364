import axios from "axios";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Toaster } from "react-hot-toast";
import { useAuth } from "../../components/context/auth";
const ManageIncentive = () => {
  const [incentiveData, setIncentiveData] = useState([]);
  const [selectedIncentive, setSelectedIncentive] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const [auth] = useAuth();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/incentive/getsingleIncentiveData/${auth.duser.id}`
        );

        setIncentiveData(response.data.incentiveData);

        const res = await axios.get("/api/v1/staff/getAllStaff");

        setStaffData(res.data.data);
      } catch (error) {
        console.error("Error fetching manageincentiveData data:", error);
      }
    };

    fetchData();
  }, []);

  const namer = (id) => {
    const dt = staffData.filter((item) => item._id === id);
    if (dt.length > 0) {
      return dt[0].name;
    } else {
      return null; // Return null or handle the case when no matching object is found
    }
  };

  const viewModal = (incentive) => {
    setSelectedIncentive(incentive);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedIncentive(null);
    setIsModalOpen(false);
  };

  const handlePrint = () => {
    window.print();
  };

  if (!Array.isArray(incentiveData)) {
    return <div>Loading.......</div>;
  }

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Incentive
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Staff Name
              </th>
              <th scope="col" className="px-6 py-3">
                Staff Id
              </th>
              <th scope="col" className="px-6 py-3">
                Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {incentiveData
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-10 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{item.date}</td>
                  <td className="px-6 py-4">{namer(item.employeeName)}</td>
                  <td className="px-6 py-4">{item.employeeId}</td>
                  <td className="px-6 py-4">{item.employeeDesignation}</td>
                  <td className="px-6 py-4">{item.employeeDepartment}</td>
                  <td className="px-6 py-4">
                    <button
                      className="font-3xl text-blue-600 dark:text-blue-500 hover:underline bg-white border-0	hover:bg-white hover:border-0"
                      onClick={() => viewModal(item)}
                    >
                      view
                    </button>{" "}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "100%",
              height: "90u%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          <div>
            {selectedIncentive && (
              <div className="addstaff-container p-6 bg-white shadow-sm my-6 print">
            <style>
                  {`
           @media print {
            .hide-on-print {
              display: block;
            }
            /* Hide unnecessary elements when printing */
            body * {
              visibility: hidden;
   
            }
            .print,
            .print * {
              visibility: visible;
            }
            .print {
              position: absolute;
              left: 0;
              top: 0;
            }
          
            /* Adjust page size and margins for A4 paper */
            @page {
              size: A4;
              margin: 0;
            }
          
            /* Ensure content fits within the A4 page size */
            html, body {
              width: 250mm;
            }
            .hide-on-print {
              display: none;
            }
          }
        `}
                </style>
                <h1 className="text-center text-3xl underline mb-4">
                  Incentives
                </h1>
                <h1 className="text-fuchsia-400 mb-4 text-center font-bold ">
                  Manasvi Technologies (OPC Private Limited)
                </h1>
                <form>
                  <div className="mt-8 px-4 lg:px-0">
                    <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-8">
                      <div className="border border-gray-200 p-2 lg:p-2 rounded-lg">
                        <div className="space-y-4">
                          <div className="flex flex-col md:flex-row mt-2">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Company Address
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="companyAddress"
                              value={selectedIncentive.companyAddress}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Company Contact
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="companyContact"
                              value={selectedIncentive.companyContact}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Company GSTIN
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="companyGstin"
                              value={selectedIncentive.companyGstin}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Company E-mail
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="companyEmail"
                              value={selectedIncentive.companyEmail}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Company Website
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="companyWebsite"
                              value={selectedIncentive.companyWebsite}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border border-gray-200 p-2 lg:p-2 rounded-lg">
                        <div className="space-y-4">
                          <div className="flex flex-col md:flex-row mt-3">
                            <span className="font-semibold sm:w-1/3 mb-2 md:mb-0  ">
                              Employee Name
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="employeeName"
                              value={namer(selectedIncentive.employeeName)}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Employee Id
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="employeeId"
                              value={selectedIncentive.employeeId}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Employee Designation
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="employeeDesignation"
                              value={selectedIncentive.employeeDesignation}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Employee Department
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="employeeDepartment"
                              value={selectedIncentive.employeeDepartment}
                            />
                          </div>
                          <div className="flex flex-col md:flex-row">
                            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                              Date
                            </span>
                            <input
                              className="border border-gray-300 flex-1"
                              name="date"
                              type="date"
                              value={selectedIncentive.date}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* // table */}
                  <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
                    <div className="mb-3 flex justify-center">
                      <span className="font-semibold md:mb-0">
                        Incentive For the Month of
                      </span>
                      <input
                        className=" border-none rounded-md"
                        style={{ textAlignLast: "center", width: "100px" }}
                        value={selectedIncentive.incentiveMonth}
                      />

                      <input
                        className=" border-none rounded-md"
                        style={{ width: "100px" }}
                        value={selectedIncentive.year}
                      />
                    </div>

                    <table className="w-full border">
                      <thead className="border-b border-gray-300 text-gray-900 border">
                        <tr className="text-left border shadow-md bg-gr00">
                          <th className="ml-1 p-2 border">S.No</th>
                          <th className="ml-1 p-2 border">Service name</th>
                          <th className="ml-1 p-2 border">Amount</th>
                          <th className="ml-1 p-2 border">Achieve</th>
                          <th className="ml-1 p-2 border">Over Achieve</th>
                          <th className="ml-1 p-2 border">Over Achieve %</th>
                          <th className="ml-1 p-2 border">Perform</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedIncentive.rows.map((row, index) => (
                          <tr key={row.id} className="border bg-gray-100">
                            <td className="border p-2">{index + 1}</td>
                            <td className="border p-2">
                              <input
                                className="w-full"
                                value={row.serviceName}
                              />
                            </td>
                            <td className="border p-2">
                              <input className="w-full" value={row.amount} />
                            </td>
                            <td className="border p-2">
                              <input className="w-full" value={row.achieve} />
                            </td>
                            <td className="border p-2">{row.overAchieve}</td>
                            <td className="border p-2">
                              {row.overAchievePercentage}%
                            </td>
                            <td className="border p-2">
                              <input
                                className="w-full"
                                name="perform"
                                id="perform"
                                type="text"
                                value={row.perform}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-5">
                      <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                        Total
                      </span>
                      <input
                        className="border border-gray-300 flex-1 mx-5"
                        name="total"
                        id="total"
                        value={selectedIncentive.total}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="mt-8 overflow-x-auto flex justify-center grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                    <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                      <table className="w-full border">
                        <tfoot>
                          <tr className="bg-gray-100">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              Transaction Id
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="text"
                                id="transationId"
                                name="transationId"
                                value={selectedIncentive.transactionId}
                                className="w-[80%] border border-gray-300"
                              />
                            </td>
                          </tr>
                          <tr className="bg-gray-200">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              Date
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="date"
                                id="dateTransaction"
                                name="dateTransaction"
                                value={selectedIncentive.dateTransaction}
                                className="w-[80%] border border-gray-300"
                              />
                            </td>
                          </tr>
                          <tr className="bg-gray-100">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              Signature of Employee
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="text"
                                id=""
                                name=""
                                className="w-[80%] border border-gray-300"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                      <table className="w-full border">
                        <tfoot>
                          <tr className="bg-gray-100">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              Name of Bank
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="text"
                                id="nameOfBank"
                                name="nameOfBank"
                                value={selectedIncentive.nameOfBank}
                                className="w-[80%] border border-gray-300"
                              />
                            </td>
                          </tr>
                          <tr className="bg-gray-200">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              IFSC Code
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="text"
                                id="irfcCode"
                                name="irfcCode"
                                value={selectedIncentive.irfcCode}
                                className="w-[80%] border border-gray-300"
                              />
                            </td>
                          </tr>
                          <tr className="bg-gray-100">
                            <th
                              scope="row"
                              className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                            >
                              Signature of Employer
                            </th>
                            <td className="px-4 py-2 font-bold text-sm text-gray-500">
                              <input
                                type="text"
                                id=""
                                name=""
                                className="w-[80%] border border-gray-200"
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                  <div className="flex justify-between m-2">
                    <button
                      type="button"
                      style={{
                        marginLeft: "10px",
                        padding: "8px 24px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        transition: "none",
                        boxShadow: "none",
                      }}
                      className="hide-on-print mt-4"
                      onClick={closeModal}
                    >
                      cancel
                    </button>
                    <button
                      type="button"
                      style={{
                        marginLeft: "10px",
                        padding: "8px 24px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        transition: "none",
                        boxShadow: "none",
                      }}
                      className="hide-on-print mt-4"
                      onClick={handlePrint}
                    >
                      print
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </Modal>
      </div>
      <Toaster />
    </div>
  );
};

export default ManageIncentive;
