import React, { useState, useEffect,useRef } from "react";
import axios from "axios";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const ManageInvoice = () => {
  const [invoiceDta, setInvoiceData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [printInvoice, setPrintInvoice] = useState(null);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });


  const textAreaRefs = useRef([]);
  useEffect(() => {
    if (selectedInvoice) {
      textAreaRefs.current.forEach((textarea) => {
        if (textarea) {
          textarea.style.height = "auto";
          textarea.style.height = `${textarea.scrollHeight}px`;
        }
      });
    }
  }, [selectedInvoice]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/invoice/getInvoice");
        setInvoiceData(response.data.manageInvoice);
      } catch (error) {
        console.error("Error fetching manageInvoiceData data:", error);
      }
    };

    fetchData();
  }, []);
  //   View invoice Model

  const openModal = (manageInvoiceData) => {
    setSelectedInvoice(manageInvoiceData);
  };

  //   print invoice Model
  const PrintModel = (manageInvoiceData) => {
    setPrintInvoice(manageInvoiceData);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedInvoice(null);
  };
  const closePrintModal = () => {
    setPrintInvoice(null);
  };

  if (!Array.isArray(invoiceDta)) {
    return <div>Loading...</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="main-container">
      <style>
        {`
@media print {
  @page {
    size: A4;
    scale: 60%  ;
    margin: 0;
  }

  body {
    margin: 0;
    font-size: 10pt;
    overflow: hidden;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .content {
    padding-top: 3cm; /* Adjust this value based on the height of your header */
    padding-left: 1cm;
    padding-right: 1cm;
    margin: 0;
  }

  .page-content {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  /* Ensure elements are not split between pages */
  .content,
  table,
  .table-container {
    page-break-inside: avoid;
  }
}

        `}
      </style>
      <h2 className="text-3xl text-black font-bold mb-8 text-center underline">
        Manage Invoice
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className=" text-center text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Sales Person
              </th>
              <th scope="col" className="px-6 py-3 whitespace-nowrap">
                Invoice to
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              <th scope="col" className="px-6 py-3">
                Contact
              </th>
              {/* Add more fields as needed */}
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceDta
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((manageInvoiceData, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(manageInvoiceData.date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {manageInvoiceData.salesPersonNameBy}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {manageInvoiceData.nameTo}
                  </td>

                  <td className="px-6 py-4">{manageInvoiceData.address}</td>
                  <td className="px-6 py-4">{manageInvoiceData.contact}</td>

                  {/* Add more fields as needed */}
                  <td className=" flex gap-2">
                    <button
                      onClick={() => openModal(manageInvoiceData)}
                      className=" font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                       Print Invoice
                    </button>

                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />

      {selectedInvoice && (
        <div className="fixed inset-0 z-10 addstaff-container  ">
          <div className=" mt-2 p-2 bg-white  shadow-sm ">
            <h1 className="text-black bg-gray-300 text-center">Invoice</h1>
            <form>
              <div className="mt-1 lg:px-0">
                <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-8 p-4">
                  <div className=" p-[2.6rem] rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Invoice By -</h2>
                    <p className="text-fuchsia-400 mb-4">
                      {selectedInvoice.businessName}
                    </p>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Address
                        </span>
                        <input
                          className=" flex-1"
                          id="addressBy"
                          name="addressBy"
                          value={selectedInvoice.addressBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Contact
                        </span>
                        <input
                          className=" flex-1"
                          name="contactBy"
                          id="contactBy"
                          value={selectedInvoice.contactBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          GSTIN
                        </span>
                        <input
                          className=" flex-1"
                          name="gstin"
                          value={selectedInvoice.gstinBy}
                          id="gstin"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Sales Person
                        </span>
                        <input
                          className=" flex-1"
                          name="salespersonBy"
                          value={selectedInvoice.salesPersonNameBy}
                          id="salespersonBy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" p-[2.6rem] rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Invoice To -</h2>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Date
                        </span>
                        <input
                          type="text"
                          className=" flex-1"
                          value={new Date(
                            selectedInvoice.date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Invoice No
                        </span>
                        <input
                          className=" flex-1"
                          name="invoiceTo"
                          value={selectedInvoice.invoiceTo}
                          id="invoiceTo"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Name
                        </span>
                        <input
                          className=" flex-1"
                          name="name"
                          value={selectedInvoice.nameTo}
                          id="name"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Address
                        </span>
                        <input
                          className=" flex-1"
                          name="address"
                          value={selectedInvoice.address}
                          id="address"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          State
                        </span>
                        <input
                          className=" flex-1"
                          name="state"
                          value={selectedInvoice.stateTo}
                          id="state"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Contact
                        </span>
                        <input
                          className=" flex-1"
                          name="contact"
                          value={selectedInvoice.contact}
                          id="contact"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          GSTIN
                        </span>
                        <input
                          className=" flex-1"
                          name="gstin"
                          value={selectedInvoice.gstinTo}
                          id="gstin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*  table data rendring  */}
              <div className="-mx-4 mt-10 flow-root sm:mx-0 overflow-x-auto">
                <table className="min-w-full border">
                  <thead className="border-b border-gray-300 text-gray-900 border">
                    <tr className="text-left border shadow-md bg-gr00">
                      <th scope="col" className="ml-1 p-2 border">
                        S.No.
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Description
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        HSN
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Qty
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        UOM
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Rate
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Amount
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        GST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        CGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        SGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        IGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedInvoice.rows.map((rowData, index) => (
                      <tr key={index} className="border bg-gray-100">
                        <td className="border p-2">{index + 1}</td>
                        <td className="border p-2">
                        <textarea
                            ref={(el) => (textAreaRefs.current[index + 5] = el)}
                            className="w-full resize-none overflow-hidden border-none"
                            value={rowData.description}
                            rows="1"
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.hsn}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.qty}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.uom}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.rate}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.amount}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.gst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.cgst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.sgst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.igst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.total}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Subtotal & Net Amount */}

              <div className="mt-1 flex justify-end">
                <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                  <table className="w-full border">
                    <tfoot>
                      <tr className="bg-gray-100">
                        <th
                          scope="row"
                          className="px-4 py-1 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Subtotal
                        </th>
                        <td>
                          <input
                            type="text"
                            name="subtotal"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={selectedInvoice.subtotal}
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr className="bg-gray-200">
                        <th
                          scope="row"
                          className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Discount ( if any)
                        </th>
                        <td>
                          <input
                            type="number"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={selectedInvoice.discount}
                            name="discount"
                            // onChange={(e) => setDiscount(parseFloat(e.target.value))}
                          />
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <th
                          scope="row"
                          className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Net Amount
                        </th>
                        <td>
                          <input
                            type="text"
                            name="netAmount"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={selectedInvoice.netAmount}
                            readOnly
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
             
            <button
                className="hide-on-print text-white bg-green-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                onClick={handlePrint}
                type="button"
              >
                Print
              </button>
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* print model start */}

      {/* {printInvoice && (
        <div className="fixed inset-0 z-10 addstaff-container  ">
          <div className=" mt-2 p-2 bg-white  shadow-sm  ">
            <h1 className="text-black bg-gray-300 text-center">Invoice</h1>
            <form>
              <div className="mt-1 lg:px-0">
                <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-8 p-4">
                  <div className="   rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Quotation By</h2>
                    <p className="text-fuchsia-400 mb-4">
                      Manasvi Technologies (OPC Private Limited)
                    </p>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Address
                        </span>
                        <input
                          className=" flex-1"
                          id="addressBy"
                          name="addressBy"
                          value={printInvoice.addressBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Contact
                        </span>
                        <input
                          className=" flex-1"
                          name="contactBy"
                          id="contactBy"
                          value={printInvoice.contactBy}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          GSTIN
                        </span>
                        <input
                          className=" flex-1"
                          name="gstin"
                          value={printInvoice.gstinBy}
                          id="gstin"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Sales Person
                        </span>
                        <input
                          className=" flex-1"
                          name="salespersonBy"
                          value={printInvoice.salesPersonNameBy}
                          id="salespersonBy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="   rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Quation To -</h2>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-1 md:mb-0">
                          Date
                        </span>
                        <input
                          type="text"
                          className=" flex-1"
                          value={new Date(printInvoice.date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-1 md:mb-0">
                          Invoice No
                        </span>
                        <input
                          className=" flex-1"
                          name="invoiceTo"
                          value={printInvoice.invoiceTo}
                          id="invoiceTo"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Name
                        </span>
                        <input
                          className=" flex-1"
                          name="name"
                          value={printInvoice.nameTo}
                          id="name"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Address
                        </span>
                        <input
                          className=" flex-1"
                          name="address"
                          value={printInvoice.address}
                          id="address"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          State
                        </span>
                        <input
                          className=" flex-1"
                          name="state"
                          value={printInvoice.stateTo}
                          id="state"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Contact
                        </span>
                        <input
                          className=" flex-1"
                          name="contact"
                          value={printInvoice.contactBy}
                          id="contact"
                        />
                      </div>
                      <div className="flex flex-col md:flex-row ">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          GSTIN
                        </span>
                        <input
                          className=" flex-1"
                          name="gstin"
                          value={printInvoice.gstinTo}
                          id="gstin"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

     
              <div className="-mx-4 mt-1 flow-root sm:mx-0 overflow-x-auto">
                <table className="min-w-full border">
                  <thead className="border-b border-gray-300 text-gray-900 border">
                    <tr className="text-left border shadow-md bg-gr00">
                      <th scope="col" className="ml-1 p-2 border">
                        S.No.
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Description
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        HSN
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Qty
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        UOM
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Rate
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        Amount
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        GST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        CGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        SGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        IGST
                      </th>
                      <th scope="col" className="ml-1 p-2 border">
                        TOTAL
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {printInvoice.rows.map((rowData, index) => (
                      <tr key={index} className="border bg-gray-100">
                        <td className="border p-2">{index + 1}</td>
                        <td className="border p-2">
                        <textarea
                            ref={(el) => (textAreaRefs.current[index + 5] = el)}
                            className="w-full resize-none overflow-hidden border-none"
                            value={rowData.description}
                            rows="1"
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.hsn}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.qty}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.uom}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.rate}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.amount}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.gst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.cgst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.sgst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.igst}
                            readOnly
                          />
                        </td>
                        <td className="border p-2">
                          <input
                            className="w-[100%]"
                            value={rowData.total}
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-1 flex justify-end">
                <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                  <table className="w-full border">
                    <tfoot>
                      <tr className="bg-gray-100">
                        <th
                          scope="row"
                          className="px-4 py-1 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Subtotal
                        </th>
                        <td>
                          <input
                            type="text"
                            name="subtotal"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={printInvoice.subtotal}
                            readOnly
                          />
                        </td>
                      </tr>
                      <tr className="bg-gray-200">
                        <th
                          scope="row"
                          className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Discount ( if any)
                        </th>
                        <td>
                          <input
                            type="number"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={printInvoice.discount}
                            name="discount"
                            
                          />
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <th
                          scope="row"
                          className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                        >
                          Net Amount
                        </th>
                        <td>
                          <input
                            type="text"
                            name="netAmount"
                            className="px-4 py-1 font-bold text-sm text-gray-500  rounded-md"
                            value={printInvoice.netAmount}
                            readOnly
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
              <button
                className="hide-on-print text-white bg-green-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                onClick={handlePrint}
                type="button"
              >
                Print
              </button>
              <button
                className="hide-on-print text-white bg-red-500 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={closePrintModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default ManageInvoice;
