import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../../App.css";
import StaffHome from "../../pages/staff/StaffHome.js";
import StaffHeader from "../../pages/staff/StaffHeader.js";
import StaffSidebar from "../../pages/staff/StaffSidebar.js";
import ApplyLeave from "../../pages/staff/ApplyLeave.js";
import DailyTask from "../../pages/staff/DailyTask.js";
import Test from "../Test.js";
import TaskStatus from "../../pages/staff/TaskStatus.js";
import Quation from "../../pages/staff/Quation.js";
import FollowUp from "../../pages/staff/FollowUp.js";
import ManageIncentive from "../../pages/staff/ManageIncentive.js";
import StaffAttendance from "../../pages/staff/StaffAttendence.js";
import CreateLead from "../../pages/staff/CreateLeads"
import ViewAttendance from "../../pages/staff/ViewAttendence.js";
import ManageAprovels from "../../pages/staff/ManageAprovels.js";
import Calender  from "../../pages/staff/Calender.js"
import ManageSalarySlip from "../../pages/staff/ManageSalarySlip.js";
import RulesRegulations from "../../pages/staff/RulesRegulations.js";
import ViewLead from "../../pages/staff/ViewLead.js";
import LogOut from "../auth/LogOut.js";
import ManageQuotationStaff from "../../pages/staff/ManageQuotationStaff.js";
import CreateStaffWorking from "../../pages/staff/CreateStaffWorking.js";
import ManageStaffWorking from "../../pages/staff/ManageStaffWorking.js";
import ViewFollowUp from "../../pages/staff/ViewFollowUp.js";
import ViewFinalDeal from "../../pages/staff/ViewFinalDeal.js";
import ViewPostPondDeal from "../../pages/staff/ViewPostPondDeal.js";
import ViewCancelDeal from './../../pages/staff/ViewCancelDeal';


function StaffRoutes() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="grid-container">
      <StaffHeader OpenSidebar={OpenSidebar} />
      <StaffSidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <Routes>
        <Route path="/" element={<StaffHome />} />
        <Route path="/applyleave" element={<ApplyLeave />} />
        <Route path="/manageArovels" element={<ManageAprovels />} />
        <Route path="/dailytask" element={<DailyTask />} />
        <Route path="/taskStatus" element={<TaskStatus />} />

        <Route path="/quation" element={<Quation />} />
        <Route path="/managequation" element={<ManageQuotationStaff />} />
        <Route path="/followup" element={<FollowUp />} />
        <Route path="/ManageIncentive" element={<ManageIncentive />} />
      
        <Route path="/attendence" element={<StaffAttendance />} />
        <Route path="/viewlead" element={<ViewLead/>}/>

         <Route path="/createleads" element={<CreateLead/>}/>
         <Route path="/viewleads" element={<ViewLead/>}/>
        <Route path="/viewstaffattendence" element={<ViewAttendance />} />
        <Route path="/calender" element={<Calender/>} />
        <Route path="/managesalaryslip"  element={<ManageSalarySlip/>}/>
        <Route path="/rulesregulation" element={<RulesRegulations/>}/>
        <Route path="/working"  element={<CreateStaffWorking/>}/>
        <Route path="/manageworking" element={<ManageStaffWorking/>}/>
        <Route path ="/log-out" element={<LogOut/>}/>
        <Route path="/viewfollowup" element={<ViewFollowUp/>}/>
        <Route path= "/viewFinalDeal" element={<ViewFinalDeal/>}/>
        <Route path = "/viewCancelDeal" element={<ViewCancelDeal/>}/>
        <Route path ="/viewPostPondDeal" element={<ViewPostPondDeal/>}/>
        <Route path="/*" element={<Test />} />
      </Routes>
    </div>
  );
}

export default StaffRoutes;
