import React, { useState, useEffect } from "react";
import axios from "axios";

const SalaryRecord = () => {
  const [salaraydata, setSalaryData] = useState([]);
  const [staffData, setStaffData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salaryResponse = await axios.get(
          "/api/v1/salary/getAllSalaryRecord"
        );
        setSalaryData(salaryResponse.data.allsalaryRecord);

        const staffResponse = await axios.get(
          "/api/v1/staff/getAllStaff"
        );
        setStaffData(staffResponse.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  if (!Array.isArray(salaraydata) || !Array.isArray(staffData)) {
    return <div>Loading...</div>;
  }
  const getStaffInfo = (staffId) => {
    const staff = staffData.find((staff) => staff._id === staffId);
    return staff ? { id: staff.id, name: staff.name } : { id: "", name: "" };
  };


  return (
    <div className="salaryRecord-container">
      <h2 className="text-3xl font-bold text-center text-gray-400 underline">
        Salary Record
      </h2>
     

      <div className="flex flex-col text-1xl p-5 font-bold  sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3  text-gray-600">
        {/* Your From and To date inputs */}
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No.
              </th>
              <th scope="col" className="px-6 py-3">
                Emp Name
              </th>
              <th scope="col" className="px-6 py-3">
                Emp id
              </th>
              <th scope="col" className="px-6 py-3">
                CTC
              </th>
              <th scope="col" className="px-6 py-3">
                Total Days
              </th>
              <th scope="col" className="px-6 py-3">
                Total Present
              </th>
              <th scope="col" className="px-6 py-3">
                Total Absent
              </th>
              <th scope="col" className="px-6 py-3">
                Total Deductions
              </th>
              <th scope="col" className="px-6 py-3">
                Advance Salary
              </th>
              <th scope="col" className="px-6 py-3">
                Net Pay
              </th>
            </tr>
          </thead>
          <tbody>
            {salaraydata.map((slarayRecord, index) => {
              const staffInfo = getStaffInfo(slarayRecord.staff);
              const { Employeename, Employeeid } = slarayRecord; // Destructuring here

              return (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4">{index + 1}</td>
                  <td className="px-6 py-4">{Employeename}</td>{" "}
                  {/* Using destructured Employeename */}
                  <td className="px-6 py-4">{Employeeid}</td>{" "}
                  {/* Using destructured Employeeid */}
                  <td className="px-6 py-4">{salaraydata[index].salaryAmount}</td>
                  <td className="px-6 py-4">30</td>
                  <td className="px-6 py-4">--------</td>
                  <td className="px-6 py-4">--------</td>
                  <td className="px-6 py-4">-------</td>
                  <td className="px-6 py-4">----</td>
                  <td className="px-6 py-4">----</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SalaryRecord;
