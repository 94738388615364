import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";


const ViewPerformance = ({ isOpen, performance, handleCloseModal }) => {
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("80%");
  const [departmentName, setDepartmentName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [staffName, setStaffName] = useState("");

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setModalWidth("90%");
        setModalHeight("90%");
      } else {
        setModalWidth("80%");
        setModalHeight("80%");
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


const fetchData = async () => {
    try {
      const [departRes, teamRes, staffRes] = await Promise.all([
        axios.get("/api/v1/depart/getalldepart"),
        axios.get("/api/v1/team/getAll/"),
        axios.get("/api/v1/staff/getAllStaff"),
      ]);

      const departments = departRes.data.data;
      const teams = teamRes.data.allTeam;
      const staff = staffRes.data.data;

      const selectedDepartment = departments.find(
        (dept) => dept._id === performance.department
      );
      const selectedTeam = teams.find((team) => team._id === performance.team);
      const selectedStaff = staff.find((staff) => staff._id === performance.staffName);


      setDepartmentName( selectedDepartment.name );
      setTeamName( selectedTeam.name );
      setStaffName( selectedStaff.name);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [performance]);



  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          height: modalHeight,
          overflow: "auto",
        },
      }}
      overlayClassName="overlay"
    >
      <div className="flex justify-end">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        View Staff Performance
      </h2>
      <div className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
        {performance && (
          <div className="bg-white rounded px-8 pt-6 pb-8 mb-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Date:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.date}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Month:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.month}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  From:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.from}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  To:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.to}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Department:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={departmentName}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Team:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={teamName}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Staff Name:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.staffName}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Staff Id:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.staffId}
                  readOnly
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Designation:
                </label>
                <input
                  className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  value={performance.designation}
                  readOnly
                />
              </div>
            </div>
            {/* Render targets */}
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Targets:</h2>
              {Array.isArray(performance.targets) &&
                performance.targets.map((target, index) => (
                  <div
                    key={index}
                    className="bg-white rounded px-8 pt-6 pb-8 mb-4"
                  >
                    <div className="grid grid-cols-2 gap-4">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Service Name:
                        </label>
                        <input
                          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={target.serviceName}
                          readOnly
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Amount:
                        </label>
                        <input
                          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={target.amount}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* Render achievement */}
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Achievement:</h2>
              {Array.isArray(performance.achievement) &&
                performance.achievement.map((achievement, index) => (
                  <div
                    key={index}
                    className="bg-white rounded px-8 pt-6 pb-8 mb-4"
                  >
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Service Name:
                        </label>
                        <input
                          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={achievement.serviceName}
                          readOnly
                        />
                      </div>

                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Amount:
                        </label>
                        <input
                          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={achievement.amount}
                          readOnly
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                          Performance:
                        </label>
                        <input
                          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          type="text"
                          value={achievement.performance}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ViewPerformance;
