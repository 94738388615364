import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useAuth } from "../../components/context/auth";

export default function TaskStatus() {
  const [tasks, setTasks] = React.useState([]);
  const [expandedTaskId, setExpandedTaskId] = React.useState(null);
  const [auth] = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [description, setDescription] = useState("");

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (!selectedStatus) {
        toast.error("please select an option ");
        return;
      }
      
    
      const res = await axios.put( `/api/v1/dailyTask/update/${selectedTaskId}`,
        {
          status: selectedStatus,
          description: description,
        }
      );
   handleCloseModal();
   const { _id } = auth.duser;
   getTasks(_id);
   

    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const toggleExpandTask = (taskId) => {
    setExpandedTaskId(expandedTaskId === taskId ? null : taskId);
  };


  const getTasks = async (_id) => {
    try {
      const response = await axios.get(`/api/v1/dailyTask/getSingle/${_id}`);

      if (response.data.success) {
        toast.success(response.data.message);
      }

      const tasksWithDescription = response.data.data.map((task) => ({
        ...task,
        description: task.description,
      }));

      setTasks(tasksWithDescription);
    } catch (error) {
      console.log(error);
    }
  };

 
  
 useEffect(()=>{
  
    const { _id } = auth.duser;
    getTasks(_id);
 },[auth])
   


  const columns = [
    { field: "id", headerName: "SN", width: 70 },
    {
      field: "date",
      headerName: "Date",
      width: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
    },
    { field: "status", headerName: "Status", width: 150 },
    { field: "Description", headerName: "Description", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <button
          type="button"
          className=" text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-2 py-2 text-center mb-1"
          onClick={() => {
            setIsModalOpen(true);
            setSelectedTaskId(params.row._id);
          }}
        >
          Update Status
        </button>
      ),
    },
    { field: "task", headerName: "Task", width: 500 },
  ];

  const rows = tasks.map((task, index) => ({
    id: index + 1,
    date: task.date,
    status: task.status,
    Description: task.description,
    action: (
      <button
        type="button"
        onClick={() => {
          setIsModalOpen(true);
          setSelectedTaskId(task._id);
        }}
      >
        Update Status
      </button>
    ),
    task: expandedTaskId === task.id ? task.task : task.task,
    _id: task._id,
  }));

  return (
    <div
      style={{ height: "100%", width: "100%" }}
      className="addstaff-container"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
      />
      <Toaster />

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div className="fixed inset-0 flex items-center justify-center">
              <div className="bg-white rounded-lg p-8 max-w-md w-full">
                <button className="close float-right p-1 bg-white border px-4 ">
                  <span
                    className="close float-right cursor-pointer"
                    onClick={handleCloseModal}
                  >
                    &times;
                  </span>
                </button>
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Status
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <select
                    id="status"
                    name="status"
                    className="h-full rounded-md border bg-transparent py-0 p-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    style={{ padding: "10px" }}
                  >
                    <option value="" disabled selected>
                      {" "}
                      Select{" "}
                    </option>
                    <option value="Completed">Completed</option>
                    <option value="Incompleted">Incompleted</option>
                  </select>
                </div>
                {selectedStatus === "Incompleted" && (
                  <div className="relative mt-2 rounded-md shadow-sm">
                    <input
                      type="text"
                      name="Complete"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                )}
                <button
                  type="button"
                  className="text-white bg-cyan-600 rounded-lg w-20 text-sm px-3 py-1.5 text-center m-2 mb-2 border-none"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
