import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const Remainder = () => {
  const [date, setDate] = useState("");
  const [team, setTeam] = useState("");
  const [staffName, setStaffName] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [message, setMessage] = useState("");
  const [submitError, setSubmitError] = useState(null);

  const [staffData, setStaffData] = useState([]);
  const [deptData, setDeptData] = useState([]);

  useEffect(() => {
    getAllStaff();
    getAllDepart();
  }, []);

  const getAllStaff = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      setStaffData(response.data.data);
    } catch (error) {
      console.log("Error fetching staff data:", error);
    }
  };

  const getAllDepart = async () => {
    try {
      const response = await axios.get("/api/v1/depart/getalldepart");
      setDeptData(response.data.data);
    } catch (error) {
      console.log("Error fetching department data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!date) {
      return toast.error("Please select a date");
    }
    if (!team) {
      return toast.error("Please select a team type");
    }
    if (!staffName) {
      return toast.error("Please select staff name");
    }
   
    try {
      const formData = {
        date,
        team,
        staffName,
        department,
        designation,
        message,
      };

      const response = await axios.post(
        "/api/v1/remainder/createRemainder",
        formData
      );
      toast.success(response.data.message);

      setDate("");
      setTeam("");
      setStaffName("");
      setDepartment("");
      setDesignation("");
      setMessage("");
      setSubmitError(null);
    } catch (error) {
      console.error("Error while submitting form:", error);
      setSubmitError("An error occurred while submitting the form.");
    }
  };

  const handleStaffChange = (e) => {
    const selectedStaff = staffData.find(
      (staff) => staff.name === e.target.value
    );
    if (selectedStaff) {
      setStaffName(selectedStaff.name);
      setDepartment(selectedStaff.department);
      setDesignation(selectedStaff.designation);
    }
  };

  const getDepartmentNameById = (id) => {
    const departmentObj = deptData.find((dept) => dept._id === id);
    return departmentObj ? departmentObj.name : "";
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form
        className="max-w-3xl mx-auto rounded-lg shadow-md md:pl-28 md:pr-28"
        onSubmit={handleSubmit}
      >
        <h1 className="text-3xl font-bold underline mb-4 text-center">
          Remainder
        </h1>

        <div className="grid grid-cols-1 sm:grid-cols-1 ">
          <div className="mb-3">
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="celebrationType" className="block font-bold mb-1">
              Team
            </label>
            <select
              id="celebrationType"
              name="celebrationType"
              className="w-full px-4 py-2 border text-black rounded-md"
              value={team}
              onChange={(e) => setTeam(e.target.value)}
            >
              <option value="">Select Team</option>
              <option value="sales">sales</option>
              <option value="development">development</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="staffName" className="block font-bold mb-1">
              Staff Name
            </label>
            <select
              id="staffName"
              name="staffName"
              className="w-full px-4 py-2 border text-black rounded-md"
              value={staffName}
              onChange={handleStaffChange}
            >
              <option value="">Select Staff Name</option>
              {staffData.map((staff) => (
                <option key={staff.id} value={staff.name}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <input
              type="text"
              id="department"
              name="department"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={getDepartmentNameById(department)}
              disabled
            />
          </div>

          <div className="mb-3">
            <label htmlFor="designation" className="block font-bold mb-1">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="block font-bold mb-1">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="flex justify-between ">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4"
          >
            send
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-black py-2 px-8 rounded mb-4"
            onClick={() => {
              setDate("");
              team("");
              setStaffName("");
              setDepartment("");
              setDesignation("");
              setMessage("");
              setSubmitError(null);
            }}
          >
            Cancel
          </button>
        </div>
        {submitError && <p className="text-red-500">{submitError}</p>}
      </form>
      <Toaster />
    </div>
  );
};

export default Remainder;
