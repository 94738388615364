import React, { useState, useEffect } from "react";
import axios from "axios";

const Department = () => {
  const [name, setName] = useState("");
  const [depart, setDepart] = useState([]);
  const [editName, setEditName] = useState("");
  const [editdepartId, setEditDepartId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const response = await axios.get("/api/v1/depart/getalldepart");
      setDepart(response.data.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!name) return;

      const response = await axios.post("/api/v1/depart/create", { name });
      fetchDepartments();
      setName("");
    } catch (error) {
      console.error("Error creating department:", error);
    }
  };

  const handleDelete = async (departid) => {
    try {
      await axios.delete(`/api/v1/depart/deletedepart/${departid._id}`);
      const updatedDepartments = depart.filter(
        (depart) => depart._id !== departid._id
      );
      setDepart(updatedDepartments);
    } catch (error) {
      console.error("Error deleting department:", error);
    }
  };

  const handleEditModalOpen = (id, previousName) => {
    setEditName(previousName);
    setEditDepartId(id);
    setIsModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUpdateSubmit = async () => {
    try {
      if (!editName) return;
      await axios.put(`/api/v1/depart/updatedepart/${editdepartId}`, {
        name: editName,
      });
      setIsModalOpen(false);
      setEditName("");
    } catch (error) {
      console.error("Error updating department:", error);
    }
  };

  return (
    <div className="addstaff-container mx-auto sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
      <h1 className="text-2xl font-bold mb-4 ml-12 mb-12">Department</h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <input
          required
          type="text"
          placeholder="Enter Department Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border rounded py-2 px-3 mr-2"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Create
        </button>
      </form>
      <div>
        {Array.isArray(depart) &&
          depart.map((depart) => (
            <div
              key={depart._id}
              className="flex items-center justify-between border-b py-2"
            >
              <span>{depart.name}</span>
              <div>
                <button
                  onClick={() => handleEditModalOpen(depart._id, depart.name)}
                  className="mr-2 bg-green-500 text-white py-1 px-2 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(depart)}
                  className="bg-red-500 text-white py-1 px-2 rounded"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-80">
            <button
              onClick={handleEditModalClose}
              className="absolute top-0 right-0 p-2 text-red-500"
            >
              &#x2715;
            </button>
            <form
              onSubmit={handleUpdateSubmit}
              className="flex flex-col items-center justify-center"
            >
              <input
                type="text"
                placeholder="Enter Updated Department Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                className="border rounded py-2 px-3 mb-4 w-full"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Department;
