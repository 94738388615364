
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../components/context/auth";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from 'antd';
import { ToastContainer, toast } from "react-toastify";
const ViewLead = () => {
  const [leadsdata, setLeadsData] = useState([]);
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [staff, setStaff] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null); 
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const [isModalVisible, setIsModalVisible] = useState(false); 


  const fetchData = async () => {
    try {
      const l = await axios.get(`/api/v1/lead/getSingleLead/${auth.duser.id}`);
      setLeadsData(l.data.data);

      const sUser = await axios.get(`/api/v1/staff/getSingleUserById/${auth.duser._id}`);
      setStaff(sUser.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
   

    fetchData();
  }, [auth.duser.id, auth.duser._id]);

  const viewHandle = (dt) => {
    switch (dt) {
      case "postPond":
        navigate('/staff/dashboard/viewPostPondDeal');
        break;
      case "followUp":
        navigate('/staff/dashboard/viewfollowup');
        break;
      case "dealFinal":
        navigate('/staff/dashboard/viewFinalDeal');
        break;
      default:
        navigate('/staff/dashboard/viewCancelDeal');
        break;
    }
  };

  const updateLead = (lead) => {
    setSelectedLead(lead); 
    setIsModalVisible(true); 
  };

  const handleOk = async () => {
    try {
      if (!selectedLead.dealType) {
        console.error("Deal type is not selected");
        return;
      }

      const response = await axios.put(`/api/v1/lead/update-lead/${selectedLead._id}`, {
        dealType: selectedLead.dealType
      });
     if(response.data.success){
      toast.success(response.data.message)
     }
   fetchData();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false); 
  };

  const handleChange = (e) => {
    setSelectedLead({ ...selectedLead, dealType: e.target.value });
  };

  return (
    <div className="salaryRecord-container">
      <h2 className="text-3xl font-bold text-center text-gray-400 underline mb-5">
        View Leads
      </h2>

      <div className="flex flex-col text-1xl p-5 font-bold sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3 text-gray-600">
        <div className="sm:flex sm:flex-col-2 items-stretch justify-end flex-shrink-0 w-full md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          <div className="flex flex-col md:flex-row items-center w-full md:space-y-0 md:space-x-3 md:w-auto mr-1">
            <input
              className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.startDate}
              onChange={(e) =>
                setSearch({ ...search, startDate: e.target.value })
              }
              placeholder="enter start date"
            />

            <input
              className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.endDate}
              onChange={(e) =>
                setSearch({ ...search, endDate: e.target.value })
              }
              placeholder="enter end date"
            />
          </div>
        </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-2 py-3">
                Staff Name
              </th>
              <th scope="col" className="px-2 py-3">
                Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Staff id
              </th>
              <th scope="col" className="px-6 py-3">
                Business Name
              </th>
              <th scope="col" className="px-6 py-3">
                Contact
              </th>
              <th scope="col" className="px-6 py-3">
                Service Name
              </th>
              <th scope="col" className="px-6 py-3">
                Deal Type
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
              <th scope="col" className="px-6 py-3">
                View Detail
              </th>
              <th scope="col" className="px-6 py-3">
                Offer Price
              </th>
            </tr>
          </thead>
          <tbody>
            {leadsdata &&
              leadsdata
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((item, index) => (
                  <tr
                    key={index}
                    className="bg-white mr-8 border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="pl-6 py-4">
                      {new Date(item.date)
                        .toLocaleDateString("en-GB")
                        .split("/")
                        .reverse()
                        .join("/")}
                    </td>
                    <td className="pl-6 py-4">{auth.duser.name}</td>
                    <td className="pl-6 py-4">{auth.duser.designation}</td>
                    <td className="pl-6 py-4">{item.id}</td>
                    <td className="pl-6 py-4">{item.businessName}</td>
                    <td className="pl-6 py-4">{item.contactPersonNumber}</td>
                    <td className="pl-6 py-4">{item.serviceName}</td>
                    <td className="pl-6 py-4">{item.dealType}</td>
                    <td className="pl-6 py-4  text-blue-500">
                      <button onClick={() => updateLead(item)}>Update</button>
                    </td>
                    <td className="pl-6 py-4 text-blue-500">
                      <button onClick={() => viewHandle(item.dealType)}>
                        View
                      </button>
                    </td>
                    <td className="pl-6 py-4">{item.offerPrice}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <Modal
        title="Update Lead"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {selectedLead && (
          <div>
            <label htmlFor="dealtype" className="block font-bold mb-1">
              Deal Type
            </label>
            <select
              id="dealType"
              name="dealType"
              value={selectedLead.dealType || ""}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md mb-2"
            >
              <option value="">Select Deal Type</option>
              <option value="followUp">Follow Up</option>
              <option value="dealFinal">Deal Final</option>
              <option value="postPond">PostPond</option> 
              <option value="Cancel">Cancel</option> 
            </select>
          </div>
        )}
      </Modal>
      <ToastContainer/>
    </div>
  );
};

export default ViewLead;
