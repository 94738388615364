import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";

const EditStaffModal = ({ staff, onClose }) => {
  const [editedStaff, setEditedStaff] = useState({
    ...staff,
    Anniversarydate: staff.Anniversarydate ? new Date(staff.Anniversarydate).toISOString().substr(0, 10) : "",
    dateOfJoining: staff.dateOfJoining ? new Date(staff.dateOfJoining).toISOString().substr(0, 10) : "",
  });
  const [newPhoto, setNewPhoto] = useState(null);
  const [displayedPhoto, setDisplayedPhoto] = useState(staff.photo);
  const [teams, setTeam] = useState([]);
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        setTeam(response.data.allTeam);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await axios.get("/api/v1/depart/getalldepart");
        setDepartment(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchTeams();
    fetchDepartments();
  }, []);

  useEffect(() => {
    setDisplayedPhoto(`/api/v1/staff/getPhoto/${editedStaff._id}`);
  }, [editedStaff.photo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedStaff({
      ...editedStaff,
      [name]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setNewPhoto(file);
    setDisplayedPhoto(URL.createObjectURL(file)); // Show the newly uploaded photo immediately
  };

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("name", editedStaff.name);
      formData.append("email", editedStaff.email);
      formData.append("team", editedStaff.team);
      formData.append("dob", editedStaff.dob);
      formData.append("contact", editedStaff.contact);
      formData.append("paddress", editedStaff.paddress);
      formData.append("caddress", editedStaff.caddress);
      formData.append("Anniversarydate", editedStaff.Anniversarydate);
      formData.append("dateOfJoining", editedStaff.dateOfJoining);
      formData.append("designation", editedStaff.designation);
      formData.append("department", editedStaff.department);
      formData.append("contactPerson", editedStaff.contactPerson);
      formData.append("relationship", editedStaff.relationship);
      formData.append("contactNumber", editedStaff.contactNumber);
      formData.append("documentsType", editedStaff.documentsType);
      formData.append("documentNumber", editedStaff.documentNumber);

      if (newPhoto) {
        formData.append("photo", newPhoto);
      }

      const res = await axios.put(`/api/v1/staff/updateStaff/${staff._id}`, formData);
      onClose();
    } catch (error) {
      console.error("Error updating staff:", error);
    }
  };

  return (
    <Transition.Root show={true} as="div">
      <Dialog as="div" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="transition-all transform duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-all transform duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex items-center justify-center pt-10 overflow-auto">
            <div className="bg-white rounded-lg p-4 max-w-md w-full">
              <div className="flex items-center justify-between">
                <Dialog.Title as="h3" className="text-lg font-semibold text-gray-900">
                  Edit Staff Details
                </Dialog.Title>
                <button className="text-gray-500 hover:text-gray-700 border" onClick={onClose}>
                  <span className="sr-only">Close</span>
                  <FaTimes className="w-50 h-50" aria-hidden="true" />
                </button>
              </div>
              <div className="pt-2">
                {/* Add input fields for other staff details */}
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Name:</p>
                  <input
                    type="text"
                    name="name"
                    value={editedStaff.name}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Name"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Email:</p>
                  <input
                    type="text"
                    name="email"
                    value={editedStaff.email}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Email"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Designation:</p>
                  <input
                    type="text"
                    name="designation"
                    value={editedStaff.designation}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Designation"
                  />
                </div>
                <div className="flex items-center mb-2">
                  <p className="font-semibold w-1/3">Department:</p>
                  <select
                    name="department"
                    value={editedStaff.department}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                  >
                    {department?.map((department) => (
                      <option key={department.id} value={department._id}>
                        {department.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Team:</p>
                  <select
                    name="team"
                    value={editedStaff.team}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                  >
                    {teams?.map((team) => (
                      <option key={team.id} value={team._id}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Contact:</p>
                  <input
                    type="text"
                    name="contact"
                    value={editedStaff.contact}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Contact"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Permanent Address:</p>
                  <input
                    type="text"
                    name="paddress"
                    value={editedStaff.paddress}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Permanent Address"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Current Address:</p>
                  <input
                    type="text"
                    name="caddress"
                    value={editedStaff.caddress}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Current Address"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Anniversary Date:</p>
                  <input
                    type="date"
                    name="Anniversarydate"
                    value={editedStaff.Anniversarydate}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Date of Joining:</p>
                  <input
                    type="date"
                    name="dateOfJoining"
                    value={editedStaff.dateOfJoining}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Contact Person:</p>
                  <input
                    type="text"
                    name="contactPerson"
                    value={editedStaff.contactPerson}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Contact Person"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Relationship:</p>
                  <input
                    type="text"
                    name="relationship"
                    value={editedStaff.relationship}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Relationship"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Contact Number:</p>
                  <input
                    type="text"
                    name="contactNumber"
                    value={editedStaff.contactNumber}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Contact Number"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Documents Type:</p>
                  <input
                    type="text"
                    name="documentsType"
                    value={editedStaff.documentsType}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Documents Type"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Document Number:</p>
                  <input
                    type="text"
                    name="documentNumber"
                    value={editedStaff.documentNumber}
                    onChange={handleInputChange}
                    className="input-field w-2/3"
                    placeholder="Document Number"
                  />
                </div>
                <div className="flex items-center">
                  <p className="font-semibold w-1/3">Photo:</p>
                  <img
                    src={displayedPhoto}
                    alt="Staff Photo"
                    className="h-48 w-full object-cover"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    className="w-2/3"
                  />
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <button
                  onClick={handleUpdate}
                  className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 mr-2"
                >
                  Update
                </button>
                <button
                  onClick={onClose}
                  className="bg-gray-300 text-gray-700 font-semibold py-2 px-4 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default EditStaffModal;
