import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from "react-modal";
import { toast, Toaster } from "react-hot-toast";


function EditCms({ isOpen, cms, handleCloseModal, fetchData }) {
    const [cmsData, setCmsData] = useState(cms); 
useEffect(()=>{
  setCmsData(cms)
},[cms])
    const [modalWidth, setModalWidth] = useState("80%");
    const [modalHeight, setModalHeight] = useState("80%");
  
    useEffect(() => {
      function handleResize() {
        if (window.innerWidth < 768) {
          setModalWidth("90%");
          setModalHeight("90%");
        } else {
          setModalWidth("80%");
          setModalHeight("80%");
        }
      }
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleMessageChange = (e) => {
        setCmsData({ ...cmsData, message: e.target.value });
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await axios.put(`/api/v1/cms/${cmsData._id}`, cmsData);
          toast.success("cmsData updated successfully");
          handleCloseModal();
          fetchData(); 
      } catch (error) {
          console.error("Error updating performance data:", error);
          toast.error("Failed to update performance data");
      }
  };



    return (
      <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          height: modalHeight,
          overflow: "auto",
        },
      }}
      overlayClassName="overlay"
    >
      <div className="flex justify-end">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <h2 className="text-3xl mt-4 mb-4 font-bold text-center underline">
        Edit Proposal
      </h2>
    
            <div className="relative bg-gray-700 dark:bg-gray-800">
                <div className="flex flex-col items-center justify-center p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                    <div className="w-full md:w-1/2 text-center text-gray-100 font-bold text-lg ">
                        Proposal
                    </div>
                </div>
            </div>
            <div className="flex flex-col bg-gray-200">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 justify-between p-8 space-y-3">
                            <form onSubmit={handleSubmit}>
                                <div className="min-w-full mx-auto grid grid-cols-1 h-full gap-4 justify-center">
                                    <textarea
                                        value={cmsData.message}
                                        onChange={handleMessageChange}
                                        className="sm:p-20 px-8 py-8 text-black sm:min-w-full h-custom"
                                        placeholder="Write your message..."
                                        style={{ height: "420px" }}
                                    />
                                </div>
                                <div className="modal-footer text-center mt-3">
                                    <button type="submit" className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2">Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        
    </Modal>
    );
}

export default EditCms;
