import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const CreateTeam = () => {
  const [name, setName] = useState("");
  const [teams, setTeams] = useState([]);
  const [editName, setEditName] = useState("");
  const [editTeamId, setEditTeamId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!name || !selectedDepartment) {
        return;
      }

      const response = await axios.post("/api/v1/team/create", {
        name,
        departId: selectedDepartment,
      });
      if (response.data.success) {
        toast.success(response.data.message);

        setTeams([...teams, response?.data?.data]);
        setName("");
      }
    } catch (error) {
      console.error("Error creating team:", error);
    }
  };

  const getAllDepart = async () => {
    try {
      const response = await axios.get("/api/v1/depart/getalldepart");
      setDepartments(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (teamId) => {
    try {
      await axios.delete(`/api/v1/team/delete/${teamId}`);

      const updatedTeams = teams.filter((team) => team._id !== teamId);
      setTeams(updatedTeams);
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  };

  const handleEditModalOpen = (teamId, previousName) => {
    setEditName(previousName);
    setEditTeamId(teamId);
    setIsModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUpdateSubmit = async () => {
    try {
      if (!editName) {
        return;
      }
      await axios.put(`/api/v1/team/update/${editTeamId}`, { name: editName });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  const fetchTeams = async () => {
    try {
      const response = await axios.get("/api/v1/team/getAll");
      setTeams(response.data.allTeam);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  useEffect(() => {
    fetchTeams();
    getAllDepart();
  }, []);

  return (
    <div className=" addstaff-container mx-auto sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ">
      <h1 className="text-2xl font-bold pl-8 flex justify-center items-center">
        Create Team
      </h1>
      <form onSubmit={handleSubmit} className="mb-4">
        <select
          required
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
          className="border rounded py-2 px-3 mr-2 mb-4"
        >
          <option value="">Select Department</option>
          {departments.map((department) => (
            <option key={department._id} value={department._id}>
              {department.name}
            </option>
          ))}
        </select>
        <br />
        <input
          required
          type="text"
          placeholder="Enter Team Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border rounded py-2 px-3 mr-2"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Create
        </button>
      </form>
      <div>
        {teams.map((team) => (
          <div
            key={team._id}
            className="flex items-center justify-between border-b py-2"
          >
            <span>{team.name}</span>
            <div>
              <button
                onClick={() => handleEditModalOpen(team._id, team.name)}
                className="mr-2 bg-green-500 text-white py-1 px-2 rounded"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(team._id)}
                className="bg-red-500 text-white py-1 px-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg shadow-lg relative w-80">
            <button
              onClick={handleEditModalClose}
              className="absolute top-0 right-0 p-2 text-red-500"
            >
              &#x2715;
            </button>
            <form
              onSubmit={handleUpdateSubmit}
              className="flex flex-col items-center justify-center"
            >
              <input
                type="text"
                placeholder="Enter Updated Team Name"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                className="border rounded py-2 px-3 mb-4 w-full"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default CreateTeam;
