import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from '../../components/context/auth';
import { useNavigate } from "react-router-dom";

const ViewFinalDeal = () => {
  const [leadsdata, setLeadsData] = useState([]);
  const [auth] = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1; // Set items per page to 2
  const navigate =useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const l = await axios.get(`/api/v1/lead/getSingleLead/${auth.duser.id}`);
        setLeadsData(l.data.data); // Adjusted to store the array directly
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [auth.duser.id]);

  const postPondDeals = leadsdata.filter((data) => data.dealType === "dealFinal");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = postPondDeals.slice(indexOfFirstItem, indexOfLastItem);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(postPondDeals.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const qpr=()=>{
    navigate("/staff/dashboard/quation")
  }
  return (
    <div className="addstaff-container max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-align:justify">Final Deal Information</h1>
      <button onClick={qpr} className="px-2 m-2 py-2  bg-green-500 text-white rounded disabled:opacity-50">
  Quotation
</button>


      <div className="flex justify-between mb-4">
        <div className="flex">
          {Array.from({ length: Math.ceil(postPondDeals.length / itemsPerPage) }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageClick(i + 1)}
              className={`px-3 py-1 mx-1 bg-blue-500 text-white rounded ${currentPage === i + 1 ? 'bg-blue-700' : ''}`}
            >
              {i + 1}
            </button>
          ))}
        </div>
        <div>
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={handleNextPage}
            disabled={currentPage === Math.ceil(postPondDeals.length / itemsPerPage)}
            className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50 ml-2"
          >
            Next
          </button>
        </div>
      </div>
      {currentItems.length > 0 ? (
        <>
          {currentItems.map((data, index) => (
            <div key={index} className="grid grid-cols-1 gap-4 mb-6">
              <div className="p-4 border rounded-lg bg-sky-50">
                <div className="grid grid-cols-2 gap-2">
                  <p className="text-left"><span className="font-semibold">ID:</span></p>
                  <p className="text-align:justify">{data.id}</p>
                  <p className="text-left"><span className="font-semibold">Business Name:</span></p>
                  <p className="text-align:justify">{data.businessName}</p>
                  <p className="text-left"><span className="font-semibold">Business Address:</span></p>
                  <p className="text-align:justify">{data.businessAddress}</p>
                  <p className="text-left"><span className="font-semibold">Business Website:</span></p>
                  <p className="text-align:justify">{data.businessWebsite}</p>
                  <p className="text-left"><span className="font-semibold">Business Email:</span></p>
                  <p className="text-align:justify">{data.businessEmail}</p>
                  <p className="text-left"><span className="font-semibold">Contact Person Name:</span></p>
                  <p className="text-align:justify">{data.contactPersonName}</p>
                  <p className="text-left"><span className="font-semibold">Contact Person Number:</span></p>
                  <p className="text-align:justify">{data.contactPersonNumber}</p>
                  <p className="text-left"><span className="font-semibold">Lead Type:</span></p>
                  <p className="text-align:justify">{data.leadType}</p>
                  <p className="text-left"><span className="font-semibold">Service Name:</span></p>
                  <p className="text-align:justify">{data.serviceName}</p>
                  <p className="text-left"><span className="font-semibold">Deal Type:</span></p>
                  <p className="text-align:justify">{data.dealType}</p>
                  <p className="text-left"><span className="font-semibold">Next Meeting Date:</span></p>
                  <p className="text-align:justify">{data.nextMeetingDate}</p>
                  <p className="text-left"><span className="font-semibold">Message:</span></p>
                  <p className="text-align:justify">{data.message}</p>
                  <p className="text-left"><span className="font-semibold">Offer Price:</span></p>
                  <p className="text-align:justify">{data.offerPrice}</p>
                  <p className="text-left"><span className="font-semibold">Date:</span></p>
                  <p className="text-align:justify">{new Date(data.date).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <p className="text-gray-700 text-align:justify">No PostPond deals found.</p>
      )}
     
    </div>
  );
};

export default ViewFinalDeal;
