import React, { useState, useEffect } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { set } from "mongoose";

const AlladvanceSalaryRecord = () => {
  const [staffData, setStaffData] = useState([]);
  const [dop, setdop] = useState([]);
  const [viewingUser, setViewingUser] = useState(false);
  const [deduction, setDeduction] = useState([]);
  const [staff, setStaff] = useState([]);
  const [dept, setDept] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState("");
  const [filteredStaff, setFilteredStaff] = useState([]);

  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  const [teams, setTeams] = useState([]);
  const [departments, setDNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    date: "",
    EmployeeName: "",
    EmployeeId: "",
    Designation: "",
    department: "",
    team: "",
    Reason: "",
    Amountofdeduction: "",
  });

  const getAllStaff = async (staffid) => {
    try {
      const data = await axios.get("/api/v1/staff/getAllStaff");

      setStaff(data.data.data);
    } catch (error) {
      return;
      console.log(error);
    }
  };
  const getAllTeams = async (staffid) => {
    try {
      const data = await axios.get("/api/v1/team/getAll/");

      setTeams(data.data.allTeam);
    } catch (error) {
      return;
      console.log(error);
    }
  };

  const getAllDepartment = async (staffid) => {
    try {
      const res = await axios.get("/api/v1/depart/getalldepart");

      setDNames(res.data.data);
    } catch (error) {
      return;
      console.log(error);
    }
  };
  useEffect(() => {
    getAllStaff();
    getAllTeams();
    getAllDepartment();
  }, []);

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepart(selectedDepartment);
    setFormData({
      ...formData,
      department: selectedDepartment,
      team: "",
      staffName: "",
      designation: "",
    });
    setFilteredStaff([]);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/record/advance-record"); //  change api

      const { data } = response.data;

      setDeduction(data.map((entry) => entry.adsal).flat());
      setLoading(false);
    } catch (error) {
      return;
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const sname = (staffid) => {
    const dn = staff.filter((item) => item._id === staffid);

    return dn ? dn[0]?.name : "";
  };

  const empid = (staffid) => {
    const dn = staff?.filter((item) => item._id === staffid);
    if (!dn[0]) {
      return null;
    }
    return dn[0] ? dn[0].id : "";
  };
  const designation = (staffid) => {
    const pn = staff.filter((item) => item._id === staffid);
    if (!pn[0]) {
      return null;
    }
    return pn[0] ? pn[0].designation : "";
  };

  const tname = (dd) => {
    const dn = staff.filter((item) => item._id === dd);

    const teamname = teams.filter((item) => item._id === dn[0]?.team);
    if (!teamname[0]) {
      return null;
    }
    return teamname[0] ? teamname[0].name : "unknown";
  };

  const dname = (f) => {
    const dno = staff.filter((item) => item._id === f);
    if (!dno) {
      return "null";
    }
    const deptName = departments.filter((i) => i._id === dno[0]?.department);
    if (!deptName[0]) {
      return null;
    }
    return deptName[0] ? deptName[0].name : "unknown";
  };

  const department = (staffid) => {
    const dn = departments.filter((item) => item._id === staffid);

    return dn ? dn[0].name : " ";
  };

  return (
    <div className="addstaff-container max-w-screen-xl px-4 mx-auto lg:px-2 w-full  my-4">
      <h1 className="text-2xl font-bold mb-4 text-center underline">
        All Advance Salary Record
      </h1>

      {loading ? ( // Render loading text if data is still loading
        <p>Loading...</p>
      ) : (
        <>
      <div className="bg-white rounded px-1 pt-2 pb-8  ">
        <p className="mx-2">Search by date Range</p>
        <input
          className=" mb-2 appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.startDate}
          onChange={(e) => setSearch({ ...search, startDate: e.target.value })}
          placeholder="enter start date"
        />

        <input
          className=" shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.endDate}
          onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
          placeholder="enter end date"
        />
      </div>

          <div className="flex flex-col">
            <div className=" overflow-x-auto ">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      S.No.
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Date
                    </th>
                    <th className=" whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Employee Name
                    </th>
                    <th className=" whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Employee Id
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Designation
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Department
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Team
                    </th>
                    <th className=" whitespace-nowrap px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Reason of Advance Salary
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-black-900 font-bold uppercase tracking-wider">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-gray-200 divide-y divide-gray-200">
                  {deduction &&
                    deduction
                      .filter((deduction) => {
                        if (!search.startDate || !search.endDate) {
                          return true;
                        } else {
                          const startDate = new Date(search.startDate);
                          const endDate = new Date(search.endDate);
                          const recordDate = new Date(deduction.date);
                          return (
                            recordDate >= startDate && recordDate <= endDate
                          );
                        }
                      })
                      .map((deduction, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <td>
                              {new Date(deduction.date).toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "long",

                                  year: "numeric",
                                }
                              )}
                            </td>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {sname(deduction?.staffid)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {empid(deduction?.staffid)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {designation(deduction?.staffid)}
                          </td>
                          <td className="px-2 py-4 text-center">
                            {dname(deduction?.staffid)}
                          </td>
                          <td className="px-2 py-4 text-center">
                            {tname(deduction?.staffid)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {deduction?.resonforadvancesalary}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {deduction?.amountofadvancesalary}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default AlladvanceSalaryRecord;
