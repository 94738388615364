

import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Button } from "antd";
import DigitalWatch from "./modals/DigitalWatch";
import { useAuth } from "../../components/context/auth";

const StaffAttendance = () => {
  const [auth] = useAuth();
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [showDescription, setShowDescription] = useState(false);

  const [calendarData, setCalendarData] = useState([]);
  const [holidayArr, setHolidayArr] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]); // Add state for attendance data

  const openModal = (data) => {
    const newHolidayArr = [...holidayArr];
    newHolidayArr.push(data.date);
    newHolidayArr.push(data.description);
    setHolidayArr(newHolidayArr);
  };

  useEffect(() => {
    const fetchCalendarData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/calenderholiday/findAllCalenderHoliday"
        );
        if (response.data.success) {
          const rowDataToSet = [];
          response.data.CalenderHolidaydata.forEach((calendarItem) => {
            if (calendarItem && Array.isArray(calendarItem.row)) {
              calendarItem.row.forEach((rowData) => {
                rowDataToSet.push(rowData);
              });
            }
          });
          setCalendarData(rowDataToSet);
        } else {
          toast.error("Failed to fetch holidays");
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
        toast.error("An error occurred while fetching holidays.");
      }
    };

    const fetchAttendanceData = async () => {
      try {
        const staffId = auth?.duser.id;
        const response = await axios.get(`/api/v1/attendence/getSingleAt/${staffId}`);
        if (response.data.success) {
          setAttendanceData(response.data.data);
        } else {
          toast.error("Failed to fetch attendance data");
        }
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        toast.error("An error occurred while fetching attendance data.");
      }
    };

    fetchCalendarData();
    fetchAttendanceData();
  }, []);

  const handleDateClick = (date, holidayId) => {
    const holiday = calendarData.find((item) => item.date.substring(0, 10) === date);
    const holidayDescription = holiday ? holiday.description : "";
    setHolidayArr([date, holidayDescription]);
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handlePreviousMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];
    const fontSize = window.innerWidth <= 425 ? "8px" : "16px";
    let week = [];

    for (let i = 0; i < firstDay; i++) {
      week.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const date = `${year}-${String(month + 1).padStart(2, "0")}-${String(i).padStart(2, "0")}`;
      const holiday = calendarData.find((item) => item.date.substring(0, 10) === date);
      const holidayDescription = holiday ? holiday.description : "";

      const attendanceRecord = attendanceData.find((item) => item.date === date); 
      const attendanceStatus = attendanceRecord ? attendanceRecord.attendanceStatus : ""; 

      const backgroundColor = attendanceStatus === "HalfDay" ? "blue" : attendanceStatus === "Present" ? "green" : "";

      week.push(
        <div
          key={date}
          className={`calendar-day ${holiday ? "holiday" : ""} ${selectedDate === date ? "selected" : ""}`}
          onClick={() => handleDateClick(date)}
          style={{
            border: "2px solid #ccc",
            margin: "4px",
            borderRadius: "10px",
            backgroundColor: holiday ? "rgb(244 63 94)" : backgroundColor,
          }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <span className="day" style={{ color: holiday ? "white" : backgroundColor ? "white" : "" }}>
              {i}
            </span>
            <br />
            {showDescription && holiday && (
              <span
                className="column-1"
                style={{
                  color: "white",
                  fontSize: fontSize,
                }}
                onClick={() => openModal(holiday)}
              >
                {holidayDescription}
              </span>
            )}
          </div>
        </div>
      );

      if (week.length === 7 || i === daysInMonth) {
        calendar.push(
          <div key={`week-${i}`} className="calendar-week grid grid-cols-7 gap-2">
            {week}
          </div>
        );
        week = [];
      }
    }

    return calendar;
  };

  const attendanceItmeIn = async () => {
    try {
      const staffId = auth?.duser.id;
      const staffName = auth?.duser.name;
      const department = auth?.duser.department;
      const team = auth?.duser.team;

      const data = await axios.post("/api/v1/attendence/timein", { staffId, staffName, department, team });

      if (data.data.success) {
        toast.success(data.data.message);
      }
      if (!data.data.success) {
        toast.error(data.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const attendanceTimeOut = async () => {
    try {
      const staffId = auth?.duser.id;
      const data = await axios.post("/api/v1/attendence/timeout", { staffId });
      if (data.data.success) {
        toast.success(data.data.message);
      }
      if (!data.data.success) {
        toast.error(data.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  return (
    <div className="addstaff-container calendar p-4">
      <h1 className="text-center text-2xl text-violet-950 underline">ATTENDANCE SYSTEM</h1>

      <div className="mt-4 mb-4 flex justify-between items-center">
        <button
          onClick={toggleDescription}
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {showDescription ? "Hide festivals" : "Show festivals"}
        </button>
        <DigitalWatch />
      </div>
      <div className="mt-2 mb-4 flex justify-center items-center">
        <button onClick={attendanceItmeIn} className="color-green-500 m-8 p-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Time In
        </button>
        <button onClick={attendanceTimeOut} className="p-8 m-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Time Out
        </button>
      </div>
      <div className="calendar-header mb-4 flex justify-between items-center">
        <button className="text-xl font-bold" onClick={handlePreviousMonth}>
          &lt;
        </button>
        <h2 className="text-xl font-bold">
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button className="text-xl font-bold" onClick={handleNextMonth}>
          &gt;
        </button>
      </div>
      <div className="calendar-grid">
        <div className="calendar-week grid grid-cols-7 gap-2 m-8 text-center">
          <div className="calendar-day-header">Sun</div>
          <div className="calendar-day-header">Mon</div>
          <div className="calendar-day-header">Tue</div>
          <div className="calendar-day-header">Wed</div>
          <div className="calendar-day-header">Thu</div>
          <div className="calendar-day-header">Fri</div>
          <div className="calendar-day-header">Sat</div>
        </div>
        {renderCalendar()}
      </div>
      <ToastContainer />
    </div>
  );
};

export default StaffAttendance;
