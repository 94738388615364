import React, { useEffect, useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";

const Salary = () => {

  const [teamName, setTeamName] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const [formData, setFormData] = useState({
    Employeename: "",
    Employeeid: "",
    designation: "",
    department: "",
    team: "",
    contact: "",
    email: "",
    salaryType: "",
    salaryAmount: "",
    effectiveFrom: "",
    holidayAllowance: "",
    rules_regulationchechekbox: "",
    rules_regulation: "",
    absentAmount: "",
    lateAmount: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
    officeTiming: "",
    lunchTime: "",
    teaTime: "",
    bankName: "",
    accountNumber: "",
    reEnterAccountNumber: "",
    ifscCode: "",
  });
  const [eye, openEye] = useState(false);
  const toggleShowAccountNumber = () => {
    openEye((prevShowAccountNumber) => !prevShowAccountNumber);
  };
  const [isChecked, setIsChecked] = useState({
    absentCheckbox: false,
    lateCheckbox: false,
  });
  const formatTimeToAMPM = (time) => {
    if (time === "12:NaN AM") {
      return time;
    }
    const date = new Date(time);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12; // Handle midnight (0 hours)
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = hours + ":" + minutes + " " + ampm;
    return formattedTime;
  };
  const initialFormData = {
    Employeename: "",
    Employeeid: "",
    designation: "",
    department: "",
    team: "",
    contact: "",
    email: "",
    salaryType: "",
    salaryAmount: "",
    effectiveFrom: "",
    holidayAllowance: "",
    rules_regulationchechekbox: "",
    rules_regulation: "",
    absentAmount: "",
    lateAmount: "",
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
    officeTiming: "",
    lunchTime: "",
    teaTime: "",
    bankName: "",
    accountNumber: "",
    reEnterAccountNumber: "",
    ifscCode: "",
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setIsChecked((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
      if (checked) {
        setFormData((prevState) => ({
          ...prevState,
          [name]: name,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: "",
        }));
      }
    } else if (name === "Employeename") {
      const selectedEmployee = staffData.find(
        (employee) => employee._id === value
      );
      if (selectedEmployee) {
        setFormData((prevState) => ({
          ...prevState,
          Employeename: selectedEmployee.name,
          Employeeid: selectedEmployee.id,
          designation: selectedEmployee.designation,
          department: selectedEmployee.department,
          team: selectedEmployee.team,
          contact: selectedEmployee.contact,
          email: selectedEmployee.email,
        }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchDepartmentName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/depart/getSingledepart/${formData.department}`
        );
        setDepartmentName(response.data.data.name);
      } catch (error) {
        console.error(
          `Error fetching department name for department ${formData.department}:`,
          error
        );
        setDepartmentName("Unknown");
      }
    };

    if (formData.department) {
      fetchDepartmentName();
    }
  }, [formData.department]);

  useEffect(() => {
    const fetchTeamName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/team/getById/${formData.team}`
        );
        setTeamName(response.data.name);
      } catch (error) {
        console.error(
          `Error fetching team name for team ${formData.team}:`,
          error
        );
        setTeamName("Unknown");
      }
    };

    if (formData.team) {
      fetchTeamName();
    }
  }, [formData.team]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.officeTiming) {
      const [hours, minutes] = formData.officeTiming.split(":");
      if (!formData.reEnterAccountNumber) {
        toast.error("Please Enter Re-Account Number ");
        return;
      }
      if (formData.accountNumber !== formData.reEnterAccountNumber) {
        toast("Account number does not match");
        return;
      }
      if (!isNaN(hours) && !isNaN(minutes)) {
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        if (!isNaN(date.getTime())) {
          const formattedOfficeTiming = formatTimeToAMPM(formData.officeTiming);
          setFormData((prevState) => ({
            ...prevState,
            officeTiming: formattedOfficeTiming,
          }));
        }
      }
    }
    try {
      const response = await axios.post(
        "/api/v1/salary/salarycreate",
        formData
      );

      if (response.data.success) {
        toast.success("Salary Created Successfully..!");
        clearForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error occurred during POST request:", error);
    }
  };
  const clearForm = () => {
    setFormData(initialFormData);
    setIsChecked({
      absentCheckbox: false,
      lateCheckbox: false,
    });
  };
  const [staffData, setStaffData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        setStaffData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="addstaff-container">
      <form
        className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6"
        onSubmit={handleSubmit}
      >
        <h2 className="text-3xl font-bold underline text-center mb-6">
          Salary
        </h2>

        {/* Employee Details */}
        <label htmlFor="salary" className="block font-bold text-2xl underline">
          Employee Details
        </label>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {/* Employeename data */}
          <div className="mb-4">
            <label htmlFor="team" className="block font-bold mb-1">
              Employee Name{" "}
            </label>
            <select
              id="Employeename"
              name="Employeename"
              value={formData.Employeename}
              onChange={handleChange}
              className="w-full px-4 py-2 text-gray-400 border rounded-md"
            >
              <option value="" className="text-gray-400">
                Select Staff Name
              </option>
              {staffData.map((Employeename) => (
                <option
                  className="text-gray-400"
                  key={Employeename._id}
                  value={Employeename._id}
                >
                  {Employeename.name}
                </option>
              ))}
            </select>
          </div>
          {/* Employee ID */}
          <div className="mb-4">
            <label htmlFor="Employeeid" className="block font-bold mb-1">
              Employee ID
            </label>
            <input
              type="text"
              id="Employeeid"
              name="Employeeid"
              value={formData.Employeeid}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
          {/* Designation */}
          <div className="mb-4">
            <label htmlFor="designation" className="block font-bold mb-1">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
          {/* Department */}

          <div className="mb-4">
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <input
              type="text"
              id="department"
              name="department"
              value={departmentName}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>

          {/* Team */}
          <div className="mb-4">
            <label htmlFor="team" className="block font-bold mb-1">
              Team
            </label>
            <input
              type="text"
              id="team"
              name="team"
              value={teamName}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
          {/* Contact */}
          <div className="mb-4">
            <label htmlFor="contact" className="block font-bold mb-1">
              Contact
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
          {/* Email */}
          <div className="mb-4">
            <label htmlFor="email" className="block font-bold mb-1">
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              readOnly
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
        </div>

        {/* Salary Section */}
        <label htmlFor="salary" className="block font-bold text-2xl underline">
          Salary Section
        </label>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {/* Salary Type */}
          <div>
            <label htmlFor="salaryType" className="block font-bold">
              Salary Type
            </label>
            <select
              id="salaryType"
              name="salaryType"
              className="w-full px-4 py-2 border rounded-md mb-2"
              onChange={handleChange}
              value={formData.salaryType}
            >
              <option value="">Select salary type</option>
              <option value="monthly">Monthly Basis</option>
              <option value="daily">Daily Basis</option>
            </select>
          </div>
          {/* Salary Amount */}
          <div>
            <label htmlFor="salaryAmount" className="block font-bold">
              Salary Amount
            </label>
            <input
              type="text"
              id="salaryAmount"
              name="salaryAmount"
              className="w-full py-2 border rounded-md mb-2"
              onChange={handleChange}
              value={formData.salaryAmount}
            />
          </div>

          {/* Effective From */}
          <div>
            <label htmlFor="effectiveFrom" className="block font-bold">
              Effective From
            </label>
            <input
              type="date"
              id="effectiveFrom"
              name="effectiveFrom"
              className="w-full py-2 border rounded-md mb-2"
              onChange={handleChange}
              value={formData.effectiveFrom}
            />
          </div>
          {/* Holiday Allowance */}
          <div className="flex items-baseline mt-6">
            <div className="w-32 font-bold">Holiday Allow</div>
            <div className="flex space-x-4 items-baseline group">
              <div className="rounded-full flex bg-gray-50 border border-gray-300 py-2 px-4 space-x-4 group-checked:border-gray-500">
                <div>
                  <input
                    type="radio"
                    name="holidayAllowance"
                    id="yes"
                    value="yes"
                    className="peer hidden"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="yes"
                    className="cursor-pointer peer-checked:text-blue-500 peer-checked:font-bold peer-checked:cursor-default"
                  >
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="holidayAllowance"
                    id="no"
                    value="no"
                    className="peer hidden"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="no"
                    className="cursor-pointer peer-checked:text-red-500 peer-checked:font-bold peer-checked:cursor-default"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <label
          htmlFor="deductions"
          className="block font-bold text-2xl underline mt-4"
        >
          Deductions
        </label>

        <div className="flex items-center justify-between mt-4">
          <input
            id="absentCheckbox"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-6 h-6"
            type="checkbox"
            name="absentCheckbox"
            onChange={handleChange}
            checked={isChecked.absentCheckbox}
          />
          <label htmlFor="absentCheckbox" className="block font-bold p-2">
            <span className="md:ml-[-100px]">When Absent (Per Day)</span>
          </label>
          <input
            type="text"
            id="absentAmount"
            name="absentAmount"
            placeholder="  Amount"
            className="w-1/2 py-2 border rounded-md mb-2"
            onChange={handleChange}
            value={formData.absentAmount}
            disabled={!isChecked.absentCheckbox}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <input
            id="lateCheckbox"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-6 h-6"
            type="checkbox"
            name="lateCheckbox"
            onChange={handleChange}
            checked={isChecked.lateCheckbox}
          />
          <label htmlFor="lateCheckbox" className="block font-bold  p-2">
            <span className="md:ml-[-50px]"> When Late Coming (Per Day)</span>
          </label>
          <input
            type="text"
            id="lateAmount"
            name="lateAmount"
            placeholder="   Amount"
            className="w-1/2 py-2 border rounded-md mb-2"
            onChange={handleChange}
            value={formData.lateAmount}
            disabled={!isChecked.lateCheckbox}
          />
        </div>
        <div className="flex items-center justify-between mt-4">
          <input
            id="rules_regulationchechekbox"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-6 h-6"
            type="checkbox"
            name="rules_regulationchechekbox"
            onChange={handleChange}
            checked={isChecked.rules_regulationchechekbox}
          />
          <label htmlFor="rules_regulation" className="block font-bold  p-2">
            <span className="md:ml-[-50px]">
              for Breach of Rules & Regulation
            </span>
          </label>
          <input
            type="text"
            id="rules_regulation"
            name="rules_regulation"
            placeholder="   Amount"
            className="w-1/2 py-2 border rounded-md mb-2"
            onChange={handleChange}
            value={formData.rules_regulation}
            disabled={!isChecked.rules_regulationchechekbox}
          />
        </div>
        {/* More Deductions... */}
        {/* Working Days */}
        <label
          htmlFor="workingDays"
          className="block font-bold m-2 text-2xl underline"
        >
          Working Days
        </label>
        <div className="flex items-center justify-between mt-4  grid grid-cols-4 sm:grid-cols-6 md:grid-cols-6 lg:grid-cols-8 gap-4">
          <label htmlFor="monday" className="block font-bold ">
            Monday
          </label>
          <input
            id="monday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="monday"
            onChange={handleChange}
            checked={formData.monday}
          />
          <label htmlFor="tuesday" className="block font-bold ">
            Tuesday
          </label>
          <input
            id="tuesday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="tuesday"
            onChange={handleChange}
            checked={formData.tuesday}
          />
          <label htmlFor="wednesday" className="block font-bold  ml">
            Wednesday
          </label>
          <input
            id="wednesday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="wednesday"
            onChange={handleChange}
            checked={formData.wednesday}
          />
          <label htmlFor="thursday" className="block font-bold ">
            Thursday
          </label>
          <input
            id="thursday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="thursday"
            onChange={handleChange}
            checked={formData.thursday}
          />
          <label htmlFor="friday" className="block font-bold ">
            Friday
          </label>
          <input
            id="friday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="friday"
            onChange={handleChange}
            checked={formData.friday}
          />
          <label htmlFor="saturday" className="block font-bold ">
            Saturday
          </label>
          <input
            id="saturday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="saturday"
            onChange={handleChange}
            checked={formData.saturday}
          />
          <label htmlFor="sunday" className="block font-bold ">
            Sunday
          </label>
          <input
            id="sunday"
            className="dark:border-white-400/20 dark:scale-100 transition-all duration-500 ease-in-out dark:hover:scale-110 dark:checked:scale-100 w-5 h-5"
            type="checkbox"
            name="sunday"
            onChange={handleChange}
            checked={formData.sunday}
          />
        </div>

        {/* Office and Lunch Time */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-4">
          <div>
            <label
              htmlFor="officeTiming"
              className="block font-bold mt-2 text-lg"
            >
              Office Timing
            </label>
            <input
              type="time"
              id="officeTiming"
              name="officeTiming"
              className="w-full py-2 border rounded-md mb-2 p-2"
              onChange={handleChange}
              value={formData.officeTiming}
            />
          </div>

          <div>
            <label htmlFor="lunchTime" className="block font-bold mb-2 text-lg">
              Lunch Time
            </label>
            <div className="relative">
              <input
                type="text"
                id="lunchTime"
                name="lunchTime"
                className="w-full px-4 py-2 border rounded-md pr-16"
                placeholder="Enter lunch time"
                onChange={handleChange}
                value={formData.lunchTime}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none text-gray-500">
                minutes
              </span>
            </div>
          </div>
          <div>
            <label htmlFor="teaTime" className="block font-bold mb-2 text-lg">
              Tea Timing
            </label>
            <div className="relative">
              <input
                type="text"
                id="teaTime"
                name="teaTime"
                className="w-full px-4 py-2 border rounded-md pr-16"
                placeholder="Enter Tea time"
                onChange={handleChange}
                value={formData.teaTime}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none text-gray-500">
                minutes
              </span>
            </div>
          </div>
        </div>
        {/* Bank Details */}
        <label
          htmlFor="bankDetails"
          className="block font-bold m-2 text-2xl underline mt-4"
        >
          Bank Details
        </label>
        <div>
          <label htmlFor="bankName" className="block font-bold mb-2 mt-2">
            Bank Name
          </label>
          <input
            type="text"
            id="bankName"
            name="bankName"
            className="w-full px-4 py-2 border rounded-md"
            onChange={handleChange}
            value={formData.bankName}
          />
        </div>
        <div>
          <label htmlFor="accountNumber" className="block font-bold mb-2 mt-2">
            Bank Account Number
          </label>
          <input
            type="text"
            id="accountNumber"
            name="accountNumber"
            className="w-full px-4 py-2 border rounded-md"
            onChange={handleChange}
            value={formData.accountNumber}
          />
        </div>
        <div style={{ position: "relative" }}>
          <label
            htmlFor="reEnterAccountNumber"
            className="block font-bold mb-2 mt-2"
          >
            Re-Enter Bank Account Number
          </label>
          <input
            type={eye ? "text" : "password"}
            id="reEnterAccountNumber"
            name="reEnterAccountNumber"
            className="w-full px-4 py-2 border rounded-md"
            onChange={handleChange}
            value={formData.reEnterAccountNumber}
            style={{ textAlign: "left" }}
            placeholder="Enter Bank Account Number"
          />
          <button
            type="button"
            style={{
              position: "absolute",
              right: "0px",
              top: "68%",
              padding: "10px",
              border: "none",
              transform: "translateY(-50%)",
            }}
            className="inline"
            onClick={toggleShowAccountNumber}
          >
            {eye ? <FaRegEyeSlash /> : <FaRegEye />}
          </button>
        </div>
        <div>
          <label htmlFor="ifscCode" className="block font-bold mb-2 mt-2">
            IFSC code
          </label>
          <input
            type="text"
            id="ifscCode"
            name="ifscCode"
            className="w-full px-4 py-2 border rounded-md"
            onChange={handleChange}
            value={formData.ifscCode}
          />
        </div>
        {/* Submit and Cancel Buttons */}
        <div className="flex justify-between mt-4">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-white py-2 px-10 rounded"
          >
            Save
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-10 rounded"
            onClick={clearForm}
          >
            Cancel
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Salary;
