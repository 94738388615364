import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
   
const SessionTimeoutContext = createContext();

const INACTIVITY_TIME_LIMIT = 15 * 60 * 1000;

const CHECK_INTERVAL = 15; 

export const SessionTimeoutProvider = ({ children }) => {
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const navigate = useNavigate();
  const sessionExpired = useRef(false);
  const timerRef = useRef(null);

  const handleSessionExpire = useCallback(() => {
    if (!sessionExpired.current) {
      toast.info("Session expired due to inactivity.");
      sessionStorage.removeItem("dauth");
      navigate("/");
      sessionExpired.current = true; // Set flag to true after session expiration
    }
  }, [navigate]);



  const checkInactivity = useCallback(() => {
    const currentTime = Date.now();
    if (currentTime - lastActivityTime >= INACTIVITY_TIME_LIMIT) {
      handleSessionExpire();
    }
  }, [lastActivityTime, handleSessionExpire]);

  const handleActivity = useCallback(() => {
    setLastActivityTime(Date.now());
    sessionExpired.current = false; // Reset session expiration flag
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Check inactivity every CHECK_INTERVAL seconds
    timerRef.current = setInterval(checkInactivity, CHECK_INTERVAL);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      if (timerRef.current) {
        clearInterval(timerRef.current); // Clean up the interval on unmount
      }
    };
  }, [handleActivity, checkInactivity]);

  return (
    <SessionTimeoutContext.Provider value={{ resetTimer: handleActivity }}>
      {children}
    </SessionTimeoutContext.Provider>
  );
};

export const useSessionTimeout = () => {
  return useContext(SessionTimeoutContext);
};



