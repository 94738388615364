import React from "react";
import { FaTimes } from "react-icons/fa";

const workingViewModel = ({ WorkingData, closeModal }) => {
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  return (
    <div className="max-w-3xl mx-auto md:pl-4 border-b-2 md:pr-4 p-2 responsive-container text-black">
      <button
        className="absolute top-2 right-2 p-2 text-gray-700 text-xl hover:text-gray-900 focus:outline-none md:text-2xl md:top-4 md:right-4 border"
        onClick={closeModal}
      >
        <FaTimes />
      </button>
      <h4 className="text-3xl font-semibold mb-4 text-center underline mb-6 text-violet-800 mt-8">
        Working
      </h4>
      <div>
      <div>
            <label className="block mb-2">
             Link
              <span className="flex-1 pl-4 border-b-2">{WorkingData.bidLink}</span>
            </label>
          </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-6">
          <div>
            <label className="block mb-2">
              Date
              <span className="flex-1 pl-4 border-b-2"> {formatDate(WorkingData.date)}</span>
            </label>
          </div>

         

          <div>
            <label className="block mb-2">
              Profile
              <span className="flex-1 pl-4 border-b-2">{WorkingData.profile}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Platform
              <span className="flex-1 pl-4 border-b-2">{WorkingData.platform}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Project Title
              <span className="flex-1 pl-4 border-b-2">{WorkingData.projectTitle}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Country
              <span className="flex-1 pl-4 border-b-2">{WorkingData.country}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Budget
              <span className="flex-1 pl-4 border-b-2">{WorkingData.budget}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Bid Placed By
              <span className="flex-1 pl-4 border-b-2">{WorkingData.bidPlacedBy}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Status
              <span className="flex-1 pl-4 border-b-2">{WorkingData.status}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Remark
              <span className="flex-1 pl-4 border-b-2">{WorkingData.remark}</span>
            </label>
          </div>

          <div>
            <label className="block mb-2">
              Technology
              <span className="flex-1 pl-4 border-b-2">{WorkingData.technology}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default workingViewModel;
