


import React, { useEffect, useState } from "react";
import axios from "axios";

const CelebrationModel = ({ isOpen, onClose }) => {
  const [celebration, setCelebration] = useState([]);

  useEffect(() => {
    const fetchcelebration = async () => {
      try {
        const response = await axios.get("/api/v1/celebration/getallcelebration");
        setCelebration(response.data); 

      } catch (error) {
        console.error("Error fetching celebration:", error);
      }
    };

    if (isOpen) {
      fetchcelebration();
    }
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-end items-start ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="absolute top-0 right-0 bg-gray-500 opacity-75 h-full w-full"></div>
      <div className="z-50 w-full md:w-1/4 bg-gray-500 text-white p-4 overflow-auto">
        <button className="text-white absolute top-1 right-2" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="h-screen overflow-y-auto"> {/* Set a fixed height and enable vertical scrolling */}
          {celebration?.map((celebration, index) => (
            <div key={index} className="text-black mt-3 text-justify p-3 bg-yellow-300 rounded mr-1">
             <span>{celebration.staffName}</span><br/>
               <span>{celebration.celebrationType}</span><br/>
              {celebration.message}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CelebrationModel;

