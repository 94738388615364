import React from "react";
import { PiArrowFatLinesRightFill } from "react-icons/pi";
import logo from "../../assets/images/logo.png";
const RulesRegulations = () => {
  return (
    <div className=" addstaff-container px-4 mx-auto lg:px-12 w-full  my-4">
      <div className="relative bg-neutral-900	 dark:bg-gray-600">
        <div className="flex flex-col items-center justify-center space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          {/* <img
            src={logo}

            alt="Company Logo"
            className="h-5 w-5 w-auto mx-auto mt-0"
          /> */}
          <div className="w-full text-center text-gray-100">
            <p className="font-bold text-3xl">
              Manasvi Technologies (OPC) Pvt. Ltd.
            </p>
            <p className="text-xl mt-2">CIN- U52342MP2023OPC064272</p>
            <p className="text-base">
              Corporate Office – 111B, in Front of Hotel Manpreet, Old Ashoka
              Garden, Bhopal 462023
            </p>
            <p className="text-base">
              Mob: 8319955741 | E-mail:{" "}
              <a
                href="mailto:manasvitech01@gmail.com"
                className="text-blue-200"
              >
                manasvitech01@gmail.com
              </a>{" "}
              | Web:{" "}
              <a href="http://www.manasvitech.in" className="text-blue-200">
                www.manasvitech.in
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-gray-200">
        <div class="max-w-screen-xl mx-auto bg-gray-100 rounded-lg shadow-md p-8">
          <h1 class="text-3xl font-bold mb-4 flex flex-col items-center justify-center">
            <i>Rules & Regulations</i>
          </h1>
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Office Working Hours:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              Manasvi Technologies OPC Pvt Ltd adheres to the following office
              working hours:
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Work Week:</strong> Monday to Saturday (6 days per week)
              </li>
              <li class="mb-2">
                <strong>Saturday:</strong> All Saturdays will be working days
                with operating hours from 10:00 AM to 4:00 PM.
              </li>
              <li class="mb-2">
                <strong>Note:</strong> To ensure a healthy work-life balance,
                the 4th Saturday of each month will be a holiday.
              </li>
              <li>
                <strong>Hours:</strong> 10:00 AM to 7:00 PM (Applicable
                Retrospectively)
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Lunch Break:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                A dedicated lunch break is provided from 1:00 PM to 1:40 PM
                during each workday.
              </li>
              <li class="mb-2">
                A dedicated Tea break is provided from 4:00 PM to 4:20 PM during
                each workday.
              </li>
              <li>
                Employees are encouraged to use this time to rest, recharge, and
                enjoy their meal break.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Please note:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                {" "}
                This schedule applies to all employees unless otherwise
                specified by their department or Director.
              </li>
              <li class="mb-2">
                Employees are encouraged to use this time to rest, recharge, and
                enjoy their meal break.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Time Management:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are expected to manage their time efficiently to
                complete their assigned tasks within the designated working
                hours, excluding the lunch break.
              </li>
              <li class="mb-2">
                Meetings and appointments should be scheduled during working
                hours whenever possible, with due consideration for lunch
                breaks.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Communication:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                If you anticipate working outside of standard office hours,
                please communicate this clearly to your supervisor in advance.
              </li>
              <li class="mb-2">
                The company will communicate any changes to the office working
                hours through official channels well in advance.
              </li>
            </ul>
          </div>
          {/* .................................second.................. */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Leave Policy: </span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              Manasvi Technologies OPC Pvt Ltd is committed to fostering a
              work-life balance for its employees. This policy outlines the
              procedures for requesting and taking leave.
            </h3>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Types of Leave:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Sick Leave:</strong>Used for personal illness or injury.
              </li>
              <li class="mb-2">
                <strong>Casual Leave: </strong>Used for unexpected personal
                matters.
              </li>
              <li class="mb-2">
                <strong>Vacation Leave: </strong>Used for planned time off.
              </li>
              <li class="mb-2">
                <strong>Other Leave: </strong>May be granted for specific
                reasons with prior approval (e.g., bereavement, jury duty).
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Advance Notice:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees must submit a leave request via email to their
                director at least 24 hours in advance for half-day leave and 48
                hours in advance for full-day leave, whenever possible.
              </li>
              <li class="mb-2">
                Urgent situations requiring immediate leave should be
                communicated by phone or in person to the Director and followed
                up with a written email as soon as possible.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Leave Request Information:</u>
            </h3>
            <h2>
              The leave request email should include the following information:{" "}
            </h2>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Employee Name:</strong>
              </li>
              <li class="mb-2">
                <strong>Department:</strong>
              </li>
              <li class="mb-2">
                <strong>Leave Type:</strong>(Sick Leave, Casual Leave, Vacation
                Leave, etc.)
              </li>
              <li class="mb-2">
                <strong>Dates of Leave:</strong>
              </li>
              <li class="mb-2">
                <strong>Reason for Leave:</strong>(in case of sick leave
                prescription of doctor is mandatory)
              </li>
              <li class="mb-2">
                <strong>Expected Return Date:</strong>
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Approval Process:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                All leave requests require approval from the employee's
                Director.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Unapproved Leave:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Absence from work without prior approval or notification will be
                considered unauthorized leave.
              </li>
              <li class="mb-2">
                Unauthorized leave may result in disciplinary action, up to and
                including a verbal or written warning, pay deduction, or
                termination of employment.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Additional Notes:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are encouraged to maintain a record of their approved
                leave.
              </li>
              <li class="mb-2">
                This policy may be subject to change at the company's discretion
                with reasonable notice.
              </li>
            </ul>
          </div>
          {/* ....................................second end.................. */}
          {/* ........................................third....................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Weekend Leave Policy: </span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              Manasvi Technologies OPC Pvt Ltd recognizes the importance of
              weekends for rest and rejuvenation. This policy clarifies how
              leave is calculated when taken on weekends.
            </h3>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Weekend Leave Rule (Sandwich Leave):</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                If an employee takes a planned leave on Saturday or Monday, and
                the following day is a Sunday (weekly off), then Sunday will
                also be counted as leave.
              </li>
              <li class="mb-2">
                This is referred to as the "Sandwich Leave" rule.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Applicability:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                This policy applies to all planned leaves, including sick leave,
                casual leave, and vacation leave.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                This policy ensures fairness and consistency in leave
                calculations.
              </li>
              <li class="mb-2">
                It discourages employees from taking short breaks that could
                disrupt workflows.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Additional Notes:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are encouraged to plan their leave requests in
                advance, considering this weekend leave rule.
              </li>
              <li class="mb-2">
                This policy is in addition to the existing leave policy
                regarding advance notice and approval procedures.
              </li>
            </ul>
          </div>
          {/* ...........................................third end.................... */}
          {/* ...............................................fourth........................ */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Streamlined Work Reporting and Task Management: </span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">Dear Employees, </h3>
            <p>
              We're implementing a new system to enhance communication, project
              management, and overall work efficiency. This system combines
              daily task reporting with a user-friendly web portal dashboard.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Task Reporting:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Upon arriving at the office:</strong> Access the company
                web portal and log in to your personalized dashboard.
              </li>
              <li class="mb-2">
                Within the dashboard, you'll find a dedicated section for "Daily
                Tasks."
              </li>
              <li class="mb-2">
                Here, you can list your <b>key tasks for the day</b> with brief
                descriptions and estimated completion times.
              </li>
              <li></li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Progress Updates:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Throughout the workday, update the progress of your tasks
                directly on the web portal dashboard.
              </li>
              <li class="mb-2">
                You can mark tasks as completed, update remaining time for
                ongoing tasks, and add any relevant notes or attachments.
              </li>
              <li></li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Before Leaving the Office:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Before leaving the office (or at the end of your shift), provide
                a brief final update on each task in your dashboard.
              </li>
              <li class="mb-2">
                Indicate completed tasks, update progress percentages for
                ongoing tasks, and highlight any outstanding issues or
                roadblocks you encountered.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Improved Communication and Transparency: </strong>The
                web portal provides a centralized platform for everyone to track
                progress and stay informed.
              </li>
              <li class="mb-2">
                <strong>Enhanced Project Management: </strong>Real-time task
                updates and clear visibility help managers ensure smooth project
                execution.
              </li>
              <li class="mb-2">
                <strong>Efficient Collaboration: </strong>Team members can
                readily see each other's progress and identify opportunities for
                collaboration.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Web Portal Dashboard Features:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Your web portal dashboard will offer intuitive features to
                manage your tasks effectively:
                <ul class="list-disc pl-4">
                  <li>
                    <strong>Drag-and-Drop Functionality:</strong>Easily manage
                    task priority by rearranging them within the dashboard.
                  </li>
                  <li>
                    <strong>Task Filtering: </strong>Filter tasks by project,
                    category, or completion status for easier navigation.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          {/* ...................................................fourth end........................ */}
          {/* .......................................................fifth................................ */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Importance of Confidentiality: </span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">Dear Employees,</h3>
            <p>
              Manasvi Technologies OPC Pvt Ltd is committed to protecting its
              confidential information and intellectual property. This
              information is vital to our success and maintaining a competitive
              edge.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <b>What is Confidential Information?</b>
              <p>Confidential information includes, but is not limited to:</p>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Trade Secrets: </strong>Formulas, inventions, processes,
                techniques, customer lists, and other information not generally
                known to the public and that provide the company with a
                competitive advantage.
              </li>
              <li class="mb-2">
                <strong>Financial Information: </strong>Financial data, budgets,
                forecasts, and other sensitive financial details.
              </li>
              <li class="mb-2">
                <strong>Business Plans and Strategies: </strong>Company
                strategies, marketing plans, product roadmaps, and other
                future-oriented information.
              </li>
              <li class="mb-2">
                <strong>Client Information: </strong>Information about our
                clients, including their data, contracts, and specific needs.
              </li>
              <li class="mb-2">
                <strong>Company Documents: </strong>Internal reports,
                presentations, emails, and other documents considered
                confidential by the company.
              </li>
              <li class="mb-2">
                <strong>Company Projects: </strong>completed Projects, Ongoing
                Projects, Projects functionality{" "}
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Your Responsibility to Maintain Confidentiality:</u>
            </h3>
            <p>
              As an employee of Manasvi Technologies OPC Pvt Ltd, you have a
              legal and ethical obligation to maintain the confidentiality of
              all company information. This includes:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Not disclosing confidential information to anyone outside the
                company without proper authorization.
              </li>
              <li class="mb-2">
                Exercising caution when discussing confidential information in
                public or on personal electronic devices.
              </li>
              <li class="mb-2">
                Securing confidential documents and electronic files to prevent
                unauthorized access.
              </li>
              <li class="mb-2">
                Not using confidential information for personal gain or to
                benefit a competitor.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Consequences of Breaching Confidentiality:</u>
            </h3>
            <p>
              A breach of confidentiality can have serious consequences for both
              the company and the employee involved. This may include:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Disciplinary Action: </strong>Up to and including
                termination of employment.
              </li>
              <li class="mb-2">
                <strong>Civil Lawsuits: </strong>The company may sue the
                employee for breach of contract, seeking financial compensation
                for damages caused by the breach.
              </li>
              <li class="mb-2">
                <strong>Criminal Charges: </strong>In extreme cases, depending
                on the nature of the confidential information disclosed, the
                company may pursue criminal charges under the Information
                Technology Act, 2000.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>The Information Technology Act, 2000 (IT Act):</u>
            </h3>
            <p>
              The IT Act defines and penalizes various cybercrimes, including:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Section 66: </strong>Punishes anyone who discloses,
                publishes or transmits any computer source code, computer
                program, computer system or computer network without lawful
                authority or with knowledge or reason to believe that such
                disclosure will cause any damage to the owner. (Penalty:
                Imprisonment up to 3 years or fine up to ₹1 lakh, or both)
              </li>
              <li class="mb-2">
                <strong>Section 66E: </strong>Penalizes the capture,
                publication, or transmission of a private image in electronic
                form without consent and in violation of privacy. (Penalty:
                Imprisonment up to 3 years or fine up to ₹2 lakh, or both)
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>The Indian Contract Act, 1872:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Section 23: </strong>Defines a contract and establishes
                the principle of "consideration" for a valid contract. A breach
                of a confidentiality clause in an employment contract could be
                considered a breach of contract.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Maintaining a Culture of Trust:</u>
            </h3>
            We believe that trust is essential for a successful and
            collaborative work environment. By protecting confidential
            information, you are contributing to the company's success and
            maintaining a competitive advantage.
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Please note:</u>
            </h3>
            <p>
              This information is for general awareness purposes only and does
              not constitute legal advice. For specific legal advice, please
              consult with a qualified lawyer.
            </p>
          </div>
          {/* ...........................................................fifth end.............................. */}
          {/* ...............................................................sixth.................................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Company Property Care and Accountability: </span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">Dear Employees,</h3>
            <p>
              Manasvi Technologies OPC Pvt Ltd is committed to providing a
              professional and well-maintained work environment for all
              employees. This includes ensuring the proper care and responsible
              use of all company property.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Company Property:</u>
            </h3>
            <p>
              Company property encompasses various assets entrusted to you for
              efficient work performance, including:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Computers and electronic devices (laptops, monitors, printers,
                etc.)
              </li>
              <li class="mb-2">
                Office furniture (desks, chairs, filing cabinets, etc.)
              </li>
              <li>Office equipment (copiers, fax machines, shredders, etc.)</li>
              <li>Supplies (stationary, printer cartridges, etc.)</li>
              <li>Company vehicles (if applicable)</li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Employee Responsibility:</u>
            </h3>
            <p>
              We expect all employees to treat company property with respect and
              care. This includes:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Using equipment and furniture for their designated purposes
                only.
              </li>
              <li class="mb-2">
                Reporting any malfunctions, defects, or damage to company
                property to your supervisor immediately.
              </li>
              <li>
                Exercising caution and following proper usage guidelines for all
                equipment.
              </li>
              <li>Maintaining a clean and organized workspace.</li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Accountability for Damage:</u>
            </h3>
            <p>
              Manasvi Technologies OPC Pvt Ltd holds employees accountable for
              any damage to company property resulting from negligence or
              misuse. This may include:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Intentional damage or destruction of property.
              </li>
              <li class="mb-2">
                Damage caused by carelessness, recklessness, or improper use.
              </li>
              <li class="mb-2">Loss of company property due to negligence.</li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Compensation for Damages:</u>
            </h3>
            <p>
              In cases of damage or loss of company property due to employee
              negligence or misuse, the company may seek compensation from the
              responsible employee. The amount of compensation will be
              determined based on the cost of repair or replacement of the
              damaged/lost property.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Disciplinary Action:</u>
            </h3>
            <p>
              In addition to compensation, incidents of property damage or loss
              due to negligence may also result in disciplinary action, up to
              and including termination of employment.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Preventing Damage:</u>
            </h3>
            <p>
              We encourage all employees to be mindful of company property and
              take necessary precautions to prevent damage. This includes:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Familiarizing yourself with proper equipment usage guidelines.
              </li>
              <li class="mb-2">
                Reporting any potential hazards or safety concerns related to
                company property.
              </li>
              <li class="mb-2">
                Practicing good housekeeping habits to maintain a clean and
                organized workspace.
              </li>
            </ul>
            <p>
              By working together, we can ensure a well-maintained work
              environment with minimal property damage.
            </p>
          </div>
          {/* ..................................................................sixth end.................................. */}
          {/* ....................................................................seventh...................................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Attendance Policy:</span>
            </u>
          </h2>
          <p>Dear Employees,</p>
          <div>
            <h3 class="font-semibold mb-2 text-xl">Working Hours:</h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Manasvi Technologies OPC Pvt Ltd maintains a six-day work week,
                with office hours from 10:00 AM to 7:00 PM (Monday to Saturday).
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Late Arrival:</u>
            </h3>
            <p>
              We understand that unforeseen circumstances may occasionally cause
              delays. However, consistent tardiness can disrupt workflow and
              team collaboration. Therefore, the following consequences will
              apply for late arrivals:
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Arrival between 10:00 AM and 10:10 AM: </strong>A late
                mark will be recorded. You are still expected to work the full
                workday (until 7:00 PM).
              </li>
              <li class="mb-2">
                <strong>Arrival after 10:15 AM: </strong>This will be considered
                a half-day absence. Half a day's leave will be deducted from
                your available leave balance.
              </li>
              <li></li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Absences:</u>
            </h3>
            <p>
              Please continue to follow established procedures for requesting
              leave in advance. Unapproved absences will be subject to
              disciplinary action.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Time Management:</u>
            </h3>
            <p>
              We encourage all employees to manage their time effectively to
              ensure timely arrival for their shifts.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits of Adhering to Attendance Policy:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Improved Work Efficiency: </strong>Timely arrival
                ensures smoother workflow and collaboration within teams.
              </li>
              <li class="mb-2">
                <strong>Increased Productivity: </strong>Focusing on work
                throughout designated hours maximizes daily output.
              </li>
              <li class="mb-2">
                <strong>Professionalism:</strong>Consistent punctuality reflects
                a professional work ethic.
              </li>
            </ul>
            <p>
              We appreciate your cooperation in adhering to this attendance
              policy.
            </p>
          </div>
          {/* .......................................................................seventh end........................... */}
          {/* ............................................................eighth.................................. */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Importance of Wearing ID Cards: </span>
            </u>
          </h2>
          <h3 class="font-semibold mb-2 text-xl">Dear Employees,</h3>
          <p>
            For security purposes and to maintain a professional work
            environment, Manasvi Technologies OPC Pvt Ltd requires all employees
            to wear their company ID cards visibly at all times while on company
            premises.
          </p>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Benefits of Wearing ID Cards:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>Enhanced Security:</strong>ID cards help identify
                authorized personnel and prevent unauthorized access to
                restricted areas.
              </li>
              <li class="mb-2">
                <strong>Improved Communication:</strong>Wearing ID cards
                facilitates smoother interaction with colleagues, visitors, and
                clients.
              </li>
              <li class="mb-2">
                <strong>Professional Appearance:</strong>ID cards project a
                professional image and create a sense of belonging within the
                company.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>ID Card Policy:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                All employees are required to wear their company ID cards around
                their necks using the provided lanyards.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Consequences of Non-Compliance:</u>
            </h3>
            <p>
              To ensure adherence to this policy, a penalty of ₹50 will be
              charged for each instance of not wearing an ID card while on
              company premises.
            </p>
          </div>
          {/* .............................................................eighth end.............................. */}
          {/* .................................................................ninth................................ */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Voluntary Leave Policy: </span>
            </u>
          </h2>
          <p>
            Manasvi Technologies OPC Pvt. Ltd. is committed to fostering a
            positive work-life balance for its employees. This policy outlines
            the guidelines for voluntary leave for employees who have completed
            a minimum tenure with the company.
          </p>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Eligibility</u>
            </h3>
            <p>
              This policy applies to all permanent employees who have been
              continuously employed with Manasvi Technologies OPC Pvt. Ltd. for
              10 months or more.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Voluntary Leave</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Eligible employees are entitled to take one (1) voluntary leave
                day per month.
              </li>
              <li class="mb-2">
                Voluntary leave can be used for personal reasons, emergencies,
                or additional time off.
              </li>
              <li class="mb-2">
                • Voluntary leave is separate from and does not affect
                entitlement to other forms of leave, such as sick leave, casual
                leave, or vacation leave.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Carry Forward</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Unused voluntary leave can be carried forward to the next month
                with a maximum accumulation of two (2) days.
              </li>
              <li class="mb-2">
                Any unused voluntary leave exceeding the maximum carry forward
                limit will not be encashed or compensated.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Leave Request Process</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees must submit a written leave request to their
                supervisor at least 2 business days in advance, whenever
                possible.
              </li>
              <li class="mb-2">
                Approval of voluntary leave requests is subject to Director
                discretion and workload requirements of the team.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Responsibilities</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are responsible for ensuring their workload is
                properly managed and delegated during their absence.
              </li>
              <li class="mb-2">
                Employees are expected to be reachable in case of urgent
                work-related matters during their voluntary leave, if possible.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Policy Review</u>
            </h3>
            <p>
              Manasvi Technologies OPC Pvt. Ltd. reserves the right to review
              and amend this policy at any time. Any changes will be
              communicated to all employees well in advance.
            </p>
          </div>
          {/* ....................................................................ninth end............................... */}
          {/* .......................................................................tenth....................................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Task Completion and Understanding Policy: </span>
            </u>
          </h2>
          <p>
            Manasvi Technologies OPC Pvt. Ltd. is committed to delivering
            high-quality work on time. This policy outlines the importance of
            completing tasks and projects within designated deadlines and
            ensuring a clear understanding of assigned work.
          </p>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Task Completion</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are responsible for completing assigned tasks and
                projects within the established timelines.
              </li>
              <li class="mb-2">
                Deadlines will be communicated clearly at the time of task
                assignment.
              </li>
              <li class="mb-2">
                Any delays or unforeseen circumstances must be communicated to
                the supervisor immediately, along with a revised timeline for
                completion.
              </li>
              <li></li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Task Understanding</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are expected to thoroughly understand the scope,
                objectives, and deliverables of assigned tasks before commencing
                work.
              </li>
              <li class="mb-2">
                If any aspect of the task is unclear, employees are encouraged
                to seek clarification from their supervisor or project lead.
              </li>
              <li class="mb-2">
                Supervisors are responsible for providing clear instructions and
                ensuring employees have the necessary resources to complete the
                task successfully.
              </li>
            </ul>

            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Consequences</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Failure to meet deadlines without prior communication may result
                in disciplinary action.
              </li>
              <li class="mb-2">
                Repeated instances of unclear task understanding may lead to
                additional training or re-assignment of tasks.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Completing tasks on time ensures project timelines are met and
                client expectations are exceeded.
              </li>
              <li class="mb-2">
                A clear understanding of tasks minimizes rework, improves
                efficiency, and fosters better communication within teams.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Conclusion</u>
            </h3>
            <p>
              Manasvi Technologies values a work environment that promotes
              timely completion of tasks with a clear understanding of
              expectations. By adhering to this policy, we can continue to
              deliver exceptional results for our clients.
            </p>
          </div>
          {/* ...........................................................................tenth end.................................. */}
          {/* ...........................................................................eleventh......................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Decision-Making and Communication Policy:</span>
            </u>
          </h2>
          <p>
            Manasvi Technologies OPC Pvt. Ltd. fosters a collaborative work
            environment where open communication is valued. This policy outlines
            the decision-making process and communication expectations within
            the company.
          </p>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Decision-Making Authority</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                The ultimate decision-making authority rests with the Director
                of the company.
              </li>
              <li class="mb-2">
                Team leaders and supervisors are entrusted with making decisions
                within their designated areas of responsibility.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Communication</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are encouraged to openly discuss ideas and concerns
                with their supervisors or team leaders.
              </li>
              <li class="mb-2">
                If an employee disagrees with a decision, they should
                communicate their concerns respectfully and provide constructive
                feedback to their supervisor or team leader in private.
              </li>
              <li>
                The Director's final decisions should be respected and
                implemented by all employees.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Respectful Communication</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                All communication within the company should be professional and
                respectful, regardless of the topic or hierarchy.
              </li>
              <li>
                Arguments or disrespectful behavior towards the Director, team
                leaders, or colleagues will not be tolerated.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits of Clear Communication</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Clear communication ensures everyone understands their roles and
                responsibilities.
              </li>
              <li class="mb-2">
                Open communication fosters a collaborative work environment.
              </li>
              <li>
                Addressing concerns respectfully promotes a positive work
                culture.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Conclusion</u>
            </h3>
            <p>
              Manasvi Technologies values open communication while respecting
              the final decision-making authority. This policy ensures efficient
              operations and a positive work environment.
            </p>
          </div>
          {/* .............................................................................eleventh end.................... */}
          {/* ................................................................12th.................................. */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Professional Conduct and Conflict Resolution Policy:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Professional Conduct</u>
            </h3>
            <p>
              Manasvi Technologies OPC Pvt. Ltd. is committed to fostering a
              professional and respectful work environment where employees
              collaborate effectively. This policy outlines the expected conduct
              regarding disagreements and conflict resolution.
            </p>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees are expected to treat each other with courtesy and
                respect at all times.
              </li>
              <li class="mb-2">
                Disagreements or conflicts concerning projects or tasks should
                be addressed constructively and professionally.
              </li>
              <li>
                Fighting, arguing, or using offensive language is strictly
                prohibited.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Conflict Resolution</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                If an employee encounters a disagreement with a colleague, they
                should attempt to resolve it directly and respectfully through
                open communication.
              </li>
              <li class="mb-2">
                If a direct resolution is not possible, the employee should
                involve their team leader or supervisor to mediate the
                situation.
              </li>
              <li>
                As a last resort, employees may approach the Director to discuss
                the issue in a private and professional manner.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Consequences</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Engaging in physical altercations, verbal abuse, or creating a
                hostile work environment will result in disciplinary action, up
                to and including immediate termination of employment without
                notice.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Benefits</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                A professional and respectful work environment fosters
                collaboration, improves communication, and promotes teamwork.
              </li>
              <li>
                Clear conflict resolution procedures ensure disagreements are
                addressed constructively and efficiently.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Conclusion</u>
            </h3>
            <p>
              Manasvi Technologies values a positive and productive work
              environment. By adhering to this policy, employees can maintain
              professional conduct and effectively resolve any conflicts that
              may arise.
            </p>
          </div>
          {/* ......................................................................12th end........................... */}
          {/* ..........................................................13th........................................ */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Notice Period and Salary Payment Policy:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Purpose:</u>
            </h3>
            <p>
              This policy outlines the notice period requirement for employee
              resignations and the implications of failing to meet the notice
              period.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">Notice Period:</h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                All employees are required to provide a written notice of
                resignation at least 30 calendar days in advance of their last
                desired day of employment. This notice period allows for a
                smooth transition and ensures proper handover of
                responsibilities.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Salary Payment:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Employees who provide the required 30-day notice will receive
                their full salary for the final month of employment, including
                any accrued but unused paid time off (PTO).
              </li>
              <li class="mb-2">
                Employees who fail to provide the required 30-day notice will
                forfeit their salary for the final month of employment.
                Exceptions may be considered on a case-by-case basis with
                written approval from the Managing Director.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Exceptions:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                In exceptional circumstances, such as a documented medical
                emergency or relocation outside the region for a
                non-work-related reason, the company may consider a shorter
                notice period or a pro-rated portion of the final month's salary
                upon written request and approval by the Managing Director.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Communication:</u>
            </h3>
            <p>
              Employees are encouraged to discuss their resignation plans with
              their Team Leader as soon as possible. This allows for a
              collaborative approach to ensure a smooth handover of
              responsibilities and minimizes disruption to ongoing projects.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Compliance:</u>
            </h3>
            <p>
              All employees are expected to comply with this policy. Failure to
              do so may result in disciplinary action, up to and including
              termination of employment without notice and forfeiture of any
              outstanding salary or benefits.
            </p>
          </div>
          {/* ..........................................................13th end................................... */}
          {/* ..................................................14th....................................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Sales Performance and Termination Policy:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Purpose:</u>
            </h3>
            <p>
              This policy outlines the expectations for sales performance and
              the potential consequences for not meeting sales targets.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Sales Targets:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Sales targets will be established for each sales employee at the
                beginning of each month / Product based on market conditions,
                product demand, historical data.
              </li>
              <li>
                These targets will be communicated to employees in writing.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Performance Evaluation:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Sales performance will be reviewed regularly weekly, monthly to
                track progress towards targets.
              </li>
              <li>
                Employees will receive feedback and coaching to support their
                achievement of sales goals.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Consequences of Not Meeting Targets:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                <strong>First Month:</strong> The company acknowledges that new
                sales representatives may require an adjustment period. For the
                first month of employment, underperformance will be addressed
                through coaching and additional support.
              </li>
              <li>
                <strong>Second Month Onwards:</strong> If a sales employee
                consistently fails to meet their targets for Second months, the
                company reserves the right to terminate their employment with 10
                days' written notice.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Termination Process:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                In the event of potential termination due to performance issues,
                the employee will receive a written warning outlining their
                shortcomings and outlining a performance improvement plan.
              </li>
              <li>
                The employee will be given a reasonable opportunity to improve
                their performance.
              </li>
              <li>
                If the employee fails to demonstrate significant improvement
                within the designated timeframe, their employment may be
                terminated.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Important Considerations:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                This policy does not constitute a contract of employment and
                does not guarantee continued employment.
              </li>
              <li class="mb-2">
                Termination decisions will be made on a case-by-case basis,
                considering all relevant factors, including effort, market
                conditions, and documented coaching efforts.
              </li>
              <li>
                The company is committed to supporting employees in achieving
                their sales goals. However, consistent underperformance can
                negatively impact the company's success.
              </li>
            </ul>
          </div>
          {/* ..............................................14th end................................................. */}
          {/* ...................................................15th....................................... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Employee Conduct and Discipline Policy:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Purpose:</u>
            </h3>
            <p>
              This policy outlines the standards of conduct expected from all
              employees of Manasvi Technologies OPC Private Limited and the
              potential consequences for violating these standards.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Expected Conduct:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                All employees are expected to maintain a professional and
                respectful work environment. This includes:
              </li>
              <li>
                Treating all colleagues, clients, and Director with courtesy and
                respect.
              </li>
              <li>
                Avoiding disruptive or offensive behavior, including shouting,
                arguing, or using profanity.
              </li>
              <li>Complying with all company policies and procedures.</li>
              <li>Representing the company in a positive light.</li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Prohibited Conduct:</u>
            </h3>
            <ul class="list-disc pl-6">
              <p>
                The following behaviors are strictly prohibited and may result
                in disciplinary action, up to and including immediate
                termination of employment:
              </p>
              <li>
                <strong>Insubordination:</strong> Disobeying a direct order from
                a supervisor or exhibiting a defiant or disrespectful attitude.
              </li>
              <li>
                <strong>Violence or Threats of Violence:</strong> Any act of
                violence or threat of violence towards colleagues, clients, or
                company property.
              </li>
              <li>
                <strong>Harassment:</strong> Engaging in any form of verbal,
                physical, or sexual harassment towards colleagues or clients.
              </li>
              <li>
                Theft or Fraud: Stealing company property or engaging in
                fraudulent activities.
              </li>
              <li>
                <strong>Misuse of Company Property:</strong> Using company
                property for personal gain or unauthorized purposes.
              </li>
              <li>
                <strong>Drug and Alcohol Abuse:</strong> Reporting to work under
                the influence of drugs or alcohol.
              </li>
              <li>
                <strong>Serious Misconduct:</strong> Any other behavior deemed
                seriously detrimental to the company's reputation or operations.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Disciplinary Action:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                The company reserves the right to take appropriate disciplinary
                action for violations of this policy. This may include:
              </li>
              <li>Verbal Warning</li>
              <li>Written Warning</li>
              <li>Suspension without Pay</li>
              <li>Termination of Employment</li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Immediate Termination:</u>
            </h3>
            <p>
              The company reserves the right to terminate an employee's
              employment immediately and without notice in cases of serious
              misconduct, such as violence, theft, or harassment.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Due Process:</u>
            </h3>
            <p>
              In most cases, employees will be subject to a progressive
              disciplinary process before termination. This may involve a verbal
              warning, followed by a written warning, and ultimately termination
              for repeated offenses. However, the company reserves the right to
              bypass this process in cases of serious misconduct.
            </p>
          </div>
          {/* ................................................16th 
                                end......................................... */}
          {/* ..............................17th...... */}
          <h2 class="text-2xl font-bold mt-8 mb-4 flex items-center">
            <PiArrowFatLinesRightFill />
            <u class="text-shadow">
              <span>Performance Improvement Policy:</span>
            </u>
          </h2>
          <div>
            <h3 class="font-semibold mb-2 text-xl">
              <u>Purpose:</u>
            </h3>
            <p>
              This policy outlines the process for addressing employee
              performance issues and providing opportunities for improvement.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Performance Expectations:</u>
            </h3>
            <p>
              All employees are expected to perform their duties competently,
              efficiently, and in accordance with deadlines. Specific
              performance expectations will be outlined in job descriptions or
              project plans.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Performance Evaluation:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Supervisors will regularly evaluate employee performance through
                performance reviews, project check-ins.
              </li>
              <li>
                Feedback will be provided to identify areas of strength and
                weakness.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Performance Improvement Process:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                If an employee is not meeting performance expectations, their
                director will initiate a performance improvement process.
              </li>
              <li>This process will involve the following steps:</li>
              <li>
                <strong>Verbal Warning:</strong> The supervisor will discuss the
                performance issue with the employee verbally and outline
                specific expectations for improvement.
              </li>
              <li>
                <strong>Written Warning:</strong> If the performance issue
                persists, a written warning will be issued, formally documenting
                the concerns and outlining a clear action plan for improvement
                with specific timelines.
              </li>
              <li>
                <strong>Performance Improvement Plan (PIP):</strong> In some
                cases, a formal PIP may be implemented, detailing specific
                goals, timelines, and resources to support the employee in
                achieving improvement.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Disciplinary Action:</u>
            </h3>
            <p>
              If an employee fails to demonstrate significant improvement after
              following the performance improvement process, disciplinary action
              may be taken, up to and including termination of employment.
            </p>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Termination:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                Termination will be considered a last resort after all
                reasonable efforts to improve performance have been exhausted.
              </li>
              <li>
                In cases of repeated or severe performance issues, the company
                may terminate employment with that Month written notice or upon
                project completion, whichever comes later.
              </li>
            </ul>
            <h3 class="font-semibold mb-2 text-xl mt-3">
              <u>Important Considerations:</u>
            </h3>
            <ul class="list-disc pl-6">
              <li class="mb-2">
                This policy is intended to be fair and provide employees with a
                clear opportunity to improve their performance.
              </li>
              <li>
                The specific timeframe for improvement will depend on the nature
                of the performance issue and the complexity of the task/project.
              </li>
              <li>
                Documentation will be maintained throughout the performance
                improvement process.
              </li>
              <li>
                Employees are encouraged to communicate any challenges or
                roadblocks hindering their performance to their supervisors.
              </li>
              <li>
                The company is committed to supporting employees in achieving
                their full potential. However, consistent underperformance can
                negatively impact the company's success.
              </li>
            </ul>
          </div>
          {/* ................................17th end....... */}
        </div>
      </div>
    </div>
  );
};

export default RulesRegulations;
