import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";

const ViewStaffModal = ({ staff, onClose }) => {
  const [team, setTeam] = useState([]);
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        setTeam(response.data.allTeam);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/depart/getalldepart");
        setDepartment(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const getTeamName = (teamId) => {
    const teamObj = team.find((t) => t._id === teamId);
    return teamObj ? teamObj.name : "Unknown";
  };

  const getDepartmentName = (departmentId) => {
    const departmentObj = department.find((d) => d._id === departmentId);
    return departmentObj ? departmentObj.name : "Unknown";
  };

  return (
    <Transition.Root show={true} as="div">
      <Dialog as="div" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="transition-all transform duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-all transform duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-white rounded-lg p-8 max-w-md w-full">
              <div className="flex items-center justify-between mb-4">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold text-gray-900"
                >
                  Staff Details
                </Dialog.Title>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <FaTimes className="w-50 h-50" aria-hidden="true" />
                </button>
              </div>
              <div>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-mono">Name:</span>{" "}
                  <span className="font-light"> {staff.name}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-mono">Email:</span>{" "}
                  <span className="font-light">{staff.email}</span>
                </p>

                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Team:</span>
                  <span className="font-light"> {getTeamName(staff.team)}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Photo:</span>{" "}
                  <span>
                    {" "}
                    <img
                      src={`/api/v1/staff/getPhoto/${staff._id}`}
                      alt="Staff Photo"
                      className="h-24"
                    />
                  </span>
                </p>

                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">DOB:</span>
                  <span className="font-light">
                    {new Date(staff.dob).toLocaleDateString("en-GB")}
                  </span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Contact:</span>{" "}
                  <span className="font-light">{staff.contact}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Permanent Address:</span>{" "}
                  <span className="font-light">{staff.paddress}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Current Address:</span>{" "}
                  <span className="font-light">{staff.caddress}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Anniversary Date:</span>{" "}
                  <span className="font-light">
                    {new Date(staff.Anniversarydate).toLocaleDateString(
                      "en-GB"
                    )}
                  </span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Date of Joining:</span>{" "}
                  <span className="font-light">
                    {new Date(staff.dateOfJoining).toLocaleDateString("en-GB")}
                  </span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Designation:</span>{" "}
                  <span className="font-light">{staff.designation}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Department:</span>
                  <span className="font-light">
                    {" "}
                    {getDepartmentName(staff.department)}
                  </span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Contact Person:</span>{" "}
                  <span className="font-light">{staff.contactPerson}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Relationship:</span>{" "}
                  <span className="font-light">{staff.relationship}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Contact Number:</span>{" "}
                  <span className="font-light">{staff.contactNumber}</span>
                </p>
                <p className="text-sm text-gray-700 mb-2">
                  <span className="font-bold">Documents Type:</span>{" "}
                  <span className="font-light">{staff.documentsType}</span>
                </p>
                <p className="text-sm text-gray-700">
                  <span className="font-bold">Document Number:</span>{" "}
                  <span className="font-light">{staff.documentNumber}</span>
                </p>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewStaffModal;
