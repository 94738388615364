import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import axios from "axios";

const Incentives = () => {
  const [companyData, setCompanyData] = useState({});
  const [staffData, setStaffData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [teamName, setTeamName] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [employeeDetails, setEmployeeDetails] = useState({
    employeeId: "",
    team: "",
    designation: "",
    department: "",
  });
  const [tableRows, setTableRows] = useState([
    {
      id: 1,
      serviceName: "",
      amount: "",
      achieve: "",
      overAchieve: "",
      overAchievePercentage: "",
      perform: "",
    },
  ]);

  const currentDate = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  useEffect(() => {
    const getAllStaffData = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        setStaffData(response.data.data);
      } catch (error) {
        toast.error("Error fetching staff data:", error);
      }
    };

    getAllStaffData();
  }, []);

  useEffect(() => {
    const fetchTeamName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/team/getById/${employeeDetails.team}`
        );
        setTeamName(response.data.name);
      } catch (error) {
        console.error(
          `Error fetching team name for team ${employeeDetails.team}:`,
          error
        );
        setTeamName("Unknown");
      }
    };

    if (employeeDetails.team) {
      fetchTeamName();
    }
  }, [employeeDetails.team]);

  useEffect(() => {
    const fetchDepartmentName = async () => {
      try {
        const response = await axios.get(
          `/api/v1/depart/getSingledepart/${employeeDetails.department}`
        );
        setDepartmentName(response.data.data.name);
      } catch (error) {
        console.error(
          `Error fetching department name for department ${employeeDetails.department}:`,
          error
        );
        setDepartmentName("Unknown");
      }
    };

    if (employeeDetails.department) {
      fetchDepartmentName();
    }
  }, [employeeDetails.department]);

  const handleEmployeeSelect = (e) => {
    const selectedEmployeeId = e.target.value;
    setSelectedEmployee(selectedEmployeeId);
    const selectedStaff = staffData.find(
      (staff) => staff._id === selectedEmployeeId
    );
    if (selectedStaff) {
      setEmployeeDetails({
        employeeId: selectedStaff.id,
        team: selectedStaff.team,
        designation: selectedStaff.designation,
        department: selectedStaff.department,
      });
    } else {
      setEmployeeDetails({
        employeeId: "",
        team: "",
        designation: "",
        department: "",
      });
    }
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("/api/v1/company/getCompanyDetails");
        setCompanyData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target); // Get form data

    // Construct form object
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    // Extract rows data from tableRows state
    const rows = tableRows.map((row) => ({
      serviceName: row.serviceName,
      amount: row.amount,
      achieve: row.achieve,
      overAchieve: row.overAchieve,
      overAchievePercentage: row.overAchievePercentage,
      perform: row.perform,
    }));

    // Add rows data to form object
    formObject.rows = rows;

    try {
      const response = await axios.post(
        "/api/v1/incentive/createincentive",
        formObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(response.data.message);
      e.target.reset();
      resetForm();
    } catch (error) {
      console.error("Error creating incentive:", error);
    }
  };

  const resetForm = () => {
    setSelectedEmployee("");
    setEmployeeDetails({
      employeeId: "",
      team: "",
      designation: "",
      department: "",
    });
    setTableRows([
      {
        id: 1,
        serviceName: "",
        amount: "",
        achieve: "",
        overAchieve: "",
        overAchievePercentage: "",
        perform: "",
      },
    ]);
    setSelectedMonth("");
    setSelectedYear(currentDate.getFullYear());
    setDepartmentName("");
    setTeamName("");
  };

  const handleAddRow = () => {
    const newRow = {
      id: tableRows.length + 1,
      serviceName: "",
      amount: "",
      achieve: "",
      overAchieve: "",
      overAchievePercentage: "",
      perform: "",
      total: "",
    };
    setTableRows([...tableRows, newRow]);
  };

  const handleDeleteRow = (id) => {
    if (tableRows.length === 1 && id === tableRows[0].id) {
      return;
    }
    setTableRows(tableRows.filter((row) => row.id !== id));
  };

  const calculateOverAchieve = (row) => {
    const achieve = parseFloat(row.achieve) || 0;
    const amount = parseFloat(row.amount) || 0;
    const overAchieve = achieve - amount;
    const overAchievePercentage = ((overAchieve / amount) * 100).toFixed(2);
    return {
      overAchieve: isNaN(overAchieve) ? "" : overAchieve.toFixed(2),
      overAchievePercentage: isNaN(overAchievePercentage)
        ? ""
        : overAchievePercentage,
    };
  };

  const handleAmountChange = (event, index) => {
    const { value } = event.target;
    const updatedRows = tableRows.map((row, i) =>
      i === index
        ? {
            ...row,
            amount: value,
            ...calculateOverAchieve({ ...row, amount: value }),
          }
        : row
    );
    setTableRows(updatedRows);
  };

  const handleAchieveChange = (event, index) => {
    const { value } = event.target;
    const updatedRows = tableRows.map((row, i) =>
      i === index
        ? {
            ...row,
            achieve: value,
            ...calculateOverAchieve({ ...row, achieve: value }),
          }
        : row
    );
    setTableRows(updatedRows);
  };

  const handlePerformChange = (event, index) => {
    const { value } = event.target;

    // Check if the entered value is a string
    if (typeof value === "string") {
      const updatedRows = tableRows.map((row, i) =>
        i === index
          ? {
              ...row,
              perform: value,
            }
          : row
      );
      setTableRows(updatedRows);
    }
  };

  const calculateTotalOverAchieve = () => {
    let totalOverAchieve = 0;
    tableRows.forEach((row) => {
      totalOverAchieve += parseFloat(row.overAchieve) || 0;
    });
    return totalOverAchieve.toFixed(2);
  };

  return (
    <div className="addstaff-container p-6 bg-white shadow-sm my-6">
      <h1 className="text-center text-3xl underline mb-4">Incentives</h1>
      <h1 className="text-fuchsia-400 mb-4 text-center font-bold ">
        {companyData.businessName}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mt-8 px-4 lg:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="border border-gray-200 p-2 lg:p-2 rounded-lg">
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row mt-2">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Company Address
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="companyAddress"
                    value={companyData.address}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Company Contact
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="companyContact"
                    value={companyData.contactNo}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Company GSTIN
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="companyGstin"
                    value={companyData.gstIn}
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Company E-mail
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="companyEmail"
                    value={companyData.email}
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Company Website
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="companyWebsite"
                    value={companyData.website}
                  />
                </div>
              </div>
            </div>
            <div className="border border-gray-200 p-2 lg:p-2 rounded-lg">
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row mt-3">
                  <label
                    htmlFor="staffid"
                    className="font-semibold sm:w-1/3 mb-2 md:mb-0 "
                  >
                    Employee Name
                  </label>
                  <select
                    id="employeeName"
                    className="border border-gray-300 flex-1"
                    onChange={handleEmployeeSelect}
                    name="employeeName"
                    value={selectedEmployee}
                  >
                    <option value="">Select Employee</option>
                    {staffData.map((staff) => (
                      <option key={staff._id} value={staff._id}>
                        {staff.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Employee Id
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="employeeId"
                    value={employeeDetails.employeeId}
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Employee Designation
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="employeeDesignation"
                    value={employeeDetails.designation}
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Employee Department:
                  </span>
                  <input
                    value={departmentName}
                    name="employeeDepartment"
                    readOnly
                    className="border border-gray-300 flex-1"
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Date
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="date"
                    type="date"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
          <div className="mb-3 flex justify-center">
            <span>Incentive for the Month</span>

            <select
              id="incentiveMonth"
              name="incentiveMonth"
              value={selectedMonth}
              onChange={handleMonthChange}
              className="border-none rounded-md"
              required
              style={{ textAlignLast: "center", width: "240px" }}
            >
              <option value="">Incentive For the Month of</option>
              {months.map((month) => (
                <option key={month} value={month.toLowerCase()}>
                  {month}
                </option>
              ))}
            </select>
            <input
              type="number"
              className="border border-gray-300"
              name="year"
              value={selectedYear}
              onChange={handleYearChange}
            />
          </div>
          <table className="w-full border">
            <thead className="border-b border-gray-300 text-gray-900 border">
              <tr className="text-left border shadow-md bg-gr00">
                <th className="ml-1 p-2 border">S.No</th>
                <th className="ml-1 p-2 border">Service name</th>
                <th className="ml-1 p-2 border">Amount</th>
                <th className="ml-1 p-2 border">Achieve</th>
                <th className="ml-1 p-2 border">Over Achieve</th>
                <th className="ml-1 p-2 border">Over Achieve %</th>
                <th className="ml-1 p-2 border">Perform</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, index) => (
                <tr key={row.id} className="border bg-gray-100">
                  <td className="border p-2">{index + 1}</td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.serviceName}
                      onChange={(e) => {
                        const { value } = e.target;
                        const updatedRows = [...tableRows];
                        updatedRows[index].serviceName = value;
                        setTableRows(updatedRows);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.amount}
                      onChange={(e) => handleAmountChange(e, index)}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.achieve}
                      onChange={(e) => handleAchieveChange(e, index)}
                    />
                  </td>
                  <td className="border p-2">{row.overAchieve}</td>
                  <td className="border p-2">{row.overAchievePercentage}%</td>
                  <td className="border p-2">
                    <select
                      className="w-full"
                      name="perform"
                      id="perform"
                      value={row.perform}
                      onChange={(e) => handlePerformChange(e, index)}
                    >
                      <option value="">Select </option>
                      <option value="Poor">Poor</option>
                      <option value="Good">Good</option>
                      <option value="VeryGood">Very Good</option>
                      <option value="Excellent">Excellent</option>
                    </select>
                  </td>

                  <td className="border p-2 text-center">
                    <Link
                      className="bg-green-500 pl-3 pr-3 pt-1 pb-1 rounded text-black mr-2"
                      onClick={handleAddRow}
                    >
                      +
                    </Link>
                    <Link
                      className="bg-red-500 pl-3 pr-3 pt-1 pb-1 rounded text-black"
                      onClick={() => handleDeleteRow(row.id)}
                    >
                      -
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-5">
            <span className="font-semibold md:w-1/3 mb-2 md:mb-0">Total</span>
            <input
              className="border border-gray-300 flex-1 mx-5"
              name="total"
              id="total"
              value={calculateTotalOverAchieve()}
              readOnly
            />
          </div>
        </div>
        <div className="mt-8 overflow-x-auto flex justify-center grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Transaction Id
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="transactionId"
                      name="transactionId"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Date
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="date"
                      id="dateTransaction"
                      name="dateTransaction"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Signature of Employee
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id=""
                      name=""
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Name of Bank
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="nameOfBank"
                      name="nameOfBank"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    IFSC Code
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="irfcCode"
                      name="irfcCode"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Signature of Employer
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id=""
                      name=""
                      className="w-[80%] border border-gray-200"
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="submit"
            style={{
              marginLeft: "10px",
              padding: "8px 24px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            className="hide-on-print mt-4"
          >
            Create incentive
          </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default Incentives;
