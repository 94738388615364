
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewAtModal from "./modals/ViewAtModal";

const ManageAllAttendence = () => {
  const [staffData, setStaffData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [departments, setDepartments] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
 

  useEffect(() => {
    fetchData();
    fetchDepartments();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      if (response.data && response.data.data) {
       // console.log("Fetched Staff Data:", response.data.data); // Debug fetched data
        setStaffData(response.data.data.filter(entry => entry && entry.id && entry.name)); // Ensure no empty entries
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`/api/v1/depart/getalldepart`);
      if (Array.isArray(response.data.data)) {
        const departmentsMap = {};
        response.data.data.forEach((department) => {
          departmentsMap[department._id] = department.name;
        });
        setDepartments(departmentsMap);
      } else {
        console.error("Departments data is not an array:", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleView = (attendance) => {
    setSelectedStaff(attendance);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedStaff(null);
    setModalOpen(false);
  };

  const saveAndReset = async () => {
    try {
   const dt =   await axios.post("/api/v1/record/attendance-create");
   console.log(dt,"response of savce and reset ")
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };




  const absentMarker = async(d)=>{
    try {
   
       const staffId=d.id;
       const department= d.department;
       const  staffName= d.name;
       const  team= d.team;
  
    

    
   const response = await axios.post('/api/v1/attendence/adminAbsent',{staffId,department,staffName,team})
   
   if(response.data.success)
    {
      toast.success(response.data.message)
    }
    if(!response.data.success)
      {
        toast.warn(response.data.message)
      }
    } catch (error) {
      console.log(error)
      
    }
  }



  return (
    <div className="main-container">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Attendance
      </h2>
      <div className="flex justify-end mb-2">
        <button onClick={saveAndReset} className="pr-4">save & reset</button>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {staffData && staffData.length > 0 ? (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">S.no</th>
                <th scope="col" className="px-6 py-3">Staff ID</th>
                <th scope="col" className="px-2 py-3">Staff Name</th>
                <th scope="col" className="px-2 py-3">Designation</th>
                <th scope="col" className="px-6 py-3">Contact No</th>
                <th scope="col" className="px-6 py-3">Department</th>
                <th scope="col" className="px-6 py-3">Mark Absent</th>
                <th scope="col" className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {staffData.map((attendance, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{attendance.id}</td>
                  <td className="px-2 py-4">{attendance.name}</td>
                  <td className="px-2 py-4">{attendance.designation}</td>
                  <td className="px-6 py-4">{attendance.contactNumber}</td>
                  <td className="px-6 py-4">
                    {departments[attendance.department]}
                  </td>
                  <td className="px-6 py-4  gap-2">
                    <Link 
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => absentMarker(attendance)}
                    >
                      Mark
                    </Link>
                  </td>
                  <td className="px-6 py-4 flex gap-2">
                    <Link 
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => handleView(attendance)}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <ViewAtModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        staff={selectedStaff}
      />
      <ToastContainer />
    </div>
  );
};

export default ManageAllAttendence;
