import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ManageNotice = () => {
  const [data, setData] = useState([]);
  const [deptNames, setDeptNames] = useState({});
  const [teamNames, setTeamNames] = useState({});
  const [staffNames, setStaffNames] = useState({});
  const [expandedMessages, setExpandedMessages] = useState({});

  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/notice/getallnotice");
        if (response.data.success) {
          setData(response.data.data);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching notice data:", error);
        toast.error("Error fetching notice data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTeamAndDeptNames = async () => {
      try {
        const responseDept = await axios.get("/api/v1/depart/getalldepart");
        if (responseDept.data.success) {
          const deptNamesData = {};
          responseDept.data.data.forEach((dept) => {
            deptNamesData[dept._id] = dept.name;
          });
          setDeptNames(deptNamesData);
        } else {
          toast.error(responseDept.data.message);
        }

        const responseTeam = await axios.get("/api/v1/team/getAll/");
        if (responseTeam.data.success) {
          const teamNamesData = {};
          responseTeam.data.allTeam.forEach((team) => {
            teamNamesData[team._id] = team.name;
          });
          setTeamNames(teamNamesData);
        } else {
          toast.error(responseTeam.data.message);
        }
      } catch (error) {
        console.error("Error fetching team and department names:", error);
        toast.error("Error fetching team and department names");
      }
    };
    fetchTeamAndDeptNames();
  }, []);

  useEffect(() => {
    const fetchStaffNames = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        if (response.data.success) {
          const staffNamesData = {};
          response.data.data.forEach((staff) => {
            staffNamesData[staff._id] = staff.name;
          });
          setStaffNames(staffNamesData);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching staff names:", error);
        toast.error("Error fetching staff names");
      }
    };
    fetchStaffNames();
  }, []);

  const toggleExpandMessage = (index) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderMessage = (message, index) => {
    if (message.length > 15 && !expandedMessages[index]) {
      return (
        <div>
          {message.substring(0, 15)}{" "}
          <button onClick={() => toggleExpandMessage(index)}>Read More</button>
        </div>
      );
    } else {
      return <div style={{ whiteSpace: "pre-line" }}>{message}</div>;
    }
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Notice & Important
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className=" mb-1 flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
               Date
              </th>
              <th scope="col" className="px-6 py-3">
                Staff Name
              </th>
              <th scope="col" className="px-6 py-3">
                Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              <th scope="col" className="px-6 py-3">
                Team
              </th>
              <th scope="col" className="px-6 py-3">
                Message
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((item, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-10 py-4">{index + 1}</td>
                  <td className="px-6 py-4">{item.date}</td>
                  <td className="px-6 py-4">{staffNames[item.name]}</td>
                  <td className="px-6 py-4">{item.designation}</td>
                  <td className="px-6 py-4">{deptNames[item.department]}</td>
                  <td className="px-6 py-4">{teamNames[item.team]}</td>
                  <td className="px-6 py-4">
                    {renderMessage(item.message, index)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Toaster />
    </div>
  );
};

export default ManageNotice;
