import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const SalarySlip = () => {
  const [dept, setDept] = useState([]);
  const [staff, setStaff] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [advanceSal, setAdvanceSal] = useState([]);
  const [incentiveAmount, setIncentiveAmount] = useState([]);

  const [companyData, setCompanyData] = useState({
    address: "",
    contactNo: "",
    email: "",
    website: "",
  });

  // table data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // for incentives
        const incentive = await axios.get("/api/v1/incentive/readincentive");
        setIncentiveAmount(incentive.data.manageIncentiveData);

        //for salaryRecord
        const salaryResponse = await axios.get(
          "/api/v1/salary/getAllSalaryRecord"
        );
        setSalaryData(salaryResponse.data.allsalaryRecord);

        // for advance salary
        const ad = await axios.get("/api/v1/advancesalary/getAllAdvanceSalary");
        setAdvanceSal(ad.data.advanceSalaryData);

        //for salary deduction
        const res = await axios.get("/api/v1/deduction/getdeduction");
        setDeduction(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // incentive data
  const getIncentiveAmount = (employeeId) => {
    const totalAmount = incentiveAmount.filter(
      (record) => record.employeeId === employeeId
    );
    const totalIncentive = totalAmount.reduce((total, record) => {
      return total + parseFloat(record.total);
    }, 0);

    return totalIncentive;
  };

  // salary data
  const getSalaryAmount = (employeeId) => {
    const employeeSalary = salaryData.find(
      (record) => record.Employeeid === employeeId
    );

    if (employeeSalary) {
      const salaryAmount = employeeSalary.salaryAmount;
      return salaryAmount;
    } else {
      return 0;
    }
  };

  const totalPresent = (eid) => {
    // console.log(attendance,"sdkjkj")
    if (typeof attendance === 'undefined' || !Array.isArray(attendance)) {
      return 0;
    }
  
    const up = attendance.filter((record) => record.staffId === eid);
  
    if (up.length === 0) {
      return 0;
    }
  
    const halfDaysCount = up.reduce((count, record) => {
      if (record.attendanceStatus === "HalfDay") {
        return count + 0.5; 
      }
      if (record.attendanceStatus === "Present") {
        return count + 1;
      } else {
        return count; 
      }
    }, 0);
    
    return halfDaysCount;
  };
  
  

  // get total absent
  const totalAbsent = (eid) => {
    if (typeof attendance === 'undefined' || !Array.isArray(attendance)) {
      return 0;
    }
    const up = attendance.filter((record) => record.staffId === eid);

    const absents = up.reduce((sum, record) => {
      if (record.attendanceStatus === "Absent") {
        return sum + 1;
      } else if (record.attendanceStatus === "HalfDay") {
        return sum + 0.5;
      } else if (record.attendanceStatus === "Present") {
        return sum + 0;
      } else {
        return sum;
      }
    }, 0);

    return absents;
  };

  // for getting deduction
  const getDeduction = (employeeId) => {
    const staffDeductor = deduction.filter(
      (item) => item.EmployeeId === employeeId
    );
    const stf = staffDeductor.reduce((Amountofdeduction, item) => {
      return Amountofdeduction + parseFloat(item.Amountofdeduction);
    }, 0);
    return stf;
  };

  const getAd = (employeeId) => {
    const keeper = advanceSal.filter((dk) => dk.id === employeeId);
    const adsalary = keeper.reduce((amountofadvancesalary, dk) => {
      return amountofadvancesalary + parseFloat(dk.amountofadvancesalary);
    }, 0);

    return adsalary;
  };

  // company detail
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("/api/v1/company/getCompanyDetails");
        setCompanyData(response.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyData();
  }, []);

  // all staff
  const getAllStaff = async () => {
    try {
      const data = await axios.get("/api/v1/staff/getAllStaff");
      setStaff(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // all department
  const getAllDepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");
      setDept(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDepart();
    getAllStaff();
  }, []);

  const fetchAttendance = async (staffId) => {
    try {
      const attendanceData = await axios.get(
        `/api/v1/attendence/getSingleAt/${staffId}`
      );
      setAttendance(attendanceData.data.data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const currentDate = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await axios.post(
        "/api/v1/salaryslip/createsalary",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error creating salary slip:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const [earnings, setEarnings] = useState({
    salaryAmount: 0,
    incentives: 0,
    id: "",
    designation: "",
    department: "",
  });

  const [deductions, setDeductions] = useState({
    advanceSalary: 0,
    totalAbsent: 0,
    deduction: 0,
  });

  const [totals, setTotals] = useState({
    totalEarnings: 0,
    totalDeductions: 0,
    netSalary: 0,
  });

  const [selectedEmployee, setSelectedEmployee] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "EmployeeName") {
      const selectedStaff = staff.find(
        (staffMember) => staffMember.name === value
      );

      if (selectedStaff) {
        setEarnings((prevState) => ({
          ...prevState,
          id: selectedStaff.id,
          designation: selectedStaff.designation,
          department: selectedStaff.department,
        }));
      
        fetchAttendance(selectedStaff.id);
      }
      setSelectedEmployee(value);

      const selectedDepartment = dept.find(
        (department) => department._id === selectedStaff.department
      );
      if (selectedDepartment) {
        setEarnings((prevState) => ({
          ...prevState,
          department: selectedDepartment.name,
        }));
      }
    } else {
      if (name in earnings) {
        setEarnings((prevState) => ({
          ...prevState,
          [name]: parseFloat(value),
        }));
      } else if (name in deductions) {
        setDeductions((prevState) => ({
          ...prevState,
          [name]: parseFloat(value),
        }));
      }
    }
  };

  useEffect(() => {
    const totalEarnings = earnings.salaryAmount + earnings.incentives;
    const totalDeductions =
      deductions.advanceSalary + deductions.totalAbsent + deductions.deduction;
    const netSalary = totalEarnings - totalDeductions;

    setTotals({
      totalEarnings: totalEarnings.toFixed(2),
      totalDeductions: totalDeductions.toFixed(2),
      netSalary: netSalary.toFixed(2),
    });
  }, [earnings, deductions]);

  return (
    <div className="addstaff-container p-6 bg-white shadow-sm my-6 ">
      <style>
        {`
             @media print {
              @page {
                size: A4;
                margin: 0;
                width:100%;
              }
                   
              @media print {
                body * {
                  visibility: hidden;
                }
                .addstaff-container, .addstaff-container * {
                  visibility: visible;
                }
                .addstaff-container {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                }
                .hide-on-print {
                  display: none !important;
                }
                .hide-on-print button{
                  display: none !important;
                }
              }
              .print-container {
                display: block;
                page-break-before: always;
              }

              html, body {
                width: 270mm;
              }
          
        `}
      </style>
      <h1 className="text-center text-3xl underline mb-4">
        Manasvi Technologies (OPC) Private Limited
      </h1>
      <h1 className="mb-4 text-center underline text-xl">
        CIN : U52342MP2023OPC064272
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 flex justify-center">
          <span>Salary slip for the Month</span>
          <select
            id="month"
            name="salarySlipMonth"
            className="border-none rounded-md"
            style={{ textAlignLast: "center", width: "150px" }}
            value={selectedMonth}
            required
            onChange={handleMonthChange}
          >
            <option value="Month">Select month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>
                {month}
              </option>
            ))}
          </select>
          <input
            type="number"
            className="border border-gray-300"
            name="year"
            value={selectedYear}
            required
            onChange={handleYearChange}
          />
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-8">
          <div className="border border-gray-200 p-2 lg:p-2 rounded-lg mt-4">
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Company Address
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="companyAddress"
                  value={companyData.address}
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Company Contact
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="companyContact"
                  value={companyData.contactNo}
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Company Email
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="companyEmail"
                  value={companyData.email}
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Company Website
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="companyWebsite"
                  value={companyData.website}
                />
              </div>
            </div>
          </div>

          <div className="border border-gray-200 p-2 lg:p-2 rounded-lg mt-4">
            <div className="space-y-4">
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Employee Name
                </span>
                <select
                  className="border border-gray-300 flex-1"
                  id="EmployeeName"
                  name="EmployeeName"
                  value={selectedEmployee}
                  onChange={handleInputChange}
                >
                  <option disabled value="">
                    Select Employee
                  </option>
                  {staff.map((staffMember) => (
                    <option key={staffMember.id} value={staffMember.name}>
                      {staffMember.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Employee ID
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="id"
                  value={earnings.id}
                  readOnly
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Designation
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="designation"
                  value={earnings.designation}
                  readOnly
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Department
                </span>
                <input
                  className="border border-gray-300 flex-1"
                  name="department"
                  value={earnings.department}
                  readOnly
                />
              </div>

              <div className="flex flex-col md:flex-row mt-2">
                <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Work Days
                </span>

                <input
                  className="border border-gray-300 flex-1"
                  name="workdays"
                  value={totalPresent(earnings.id)}
                  // value={30-totalAbsent(earnings.id)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
          <table className="w-full border">
            <thead className="border-b border-gray-300 text-gray-900 border">
              <tr className="text-left border shadow-md bg-gray-200">
                <th className="ml-1 p-2 border">Earning</th>
                <th className="ml-1 p-2 border">Amount</th>
                <th className="ml-1 p-2 border">Deductions</th>
                <th className="ml-1 p-2 border">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border">
                <td className="border p-2">Salary Amount</td>
                <td className="border p-2">
                  <input
                    className="w-full border"
                    name="salaryAmount"
                    value={getSalaryAmount(earnings.id)}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="border p-2">Advance Salary</td>
                <td className="border p-2">
                  <input
                    className="w-full border"
                    name="advanceSalary"
                    value={getAd(earnings.id)}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr className="border">
                <td className="border p-2">Incentives</td>
                <td className="border p-2">
                  <input
                    className="w-full border"
                    name="incentives"
                    value={getIncentiveAmount(earnings.id)}
                    onChange={handleInputChange}
                  />
                </td>
                <td className="border p-2"> Absent Amount</td>
                <td className="border p-2">
                  <input
                    className="w-full border"
                    name="totalAbsent"
                    value={(
                      (getSalaryAmount(earnings.id) / 30) *
                      totalAbsent(earnings.id)
                    ).toFixed(2)}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>

                <td className="border p-2"> deduction </td>
                <td className="border p-2">
                  <input
                    className="w-full border"
                    name="deduction"
                    value={getDeduction(earnings.id)}
                    onChange={handleInputChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-5 flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-col md:w-1/3 mb-2 md:mb-0">
              <span className="font-semibold">Total Earnings</span>
              <input
                className="border border-gray-300 mt-1 md:mt-0"
                name="totalEarning"
                value={
                  Number(getSalaryAmount(earnings.id)) +
                  Number(getIncentiveAmount(earnings.id))
                }
                readOnly
              />
            </div>
            <div className="flex flex-col md:w-1/3 mb-2 md:mb-0 md:mx-5">
              <span className="font-semibold">Total Deductions</span>
              <input
                className="border border-gray-300 mt-1 md:mt-0"
                name="totalDeductions"
                value={
                  Number(getDeduction(earnings.id)) +
                  Number(getAd(earnings.id)) +
                  Number(
                    (
                      (getSalaryAmount(earnings.id) / 30) *
                      totalAbsent(earnings.id)
                    ).toFixed(2)
                  )
                }
                readOnly
              />
            </div>
            <div className="flex flex-col md:w-1/3 mb-2 md:mb-0">
              <span className="font-semibold">Net Salary</span>
              <input
                className="border border-gray-300 mt-1 md:mt-0"
                name="netsalary"
                value={
                  Number(getSalaryAmount(earnings.id)) +
                  Number(getIncentiveAmount(earnings.id)) -
                  Number(
                    getDeduction(earnings.id) +
                      Number(getAd(earnings.id)) +
                      Number(
                        (getSalaryAmount(earnings.id) / 30) *
                          totalAbsent(earnings.id)
                      )
                  ).toFixed(2)
                }
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-x-auto flex justify-center grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Transaction Id
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="transationId"
                      name="transactionId"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Date
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="date"
                      id="date"
                      name="date"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Signature of Employee
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500 ">
                    <input className=" p-3 w-[80%]" />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Name of Bank
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="nameOfBank"
                      name="nameOfBank"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    IFSC Code
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500">
                    <input
                      type="text"
                      id="irfcCode"
                      name="irfcCode"
                      className="w-[80%] border border-gray-300"
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left "
                  >
                    Signature of Employer
                  </th>
                  <td className="px-4 py-2 font-bold text-sm text-gray-500 border-dashed">
                    <input className=" p-3 w-[80%]" />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <div className="flex justify-center mt-5 print">
          <button
            style={{
              padding: "8px 24px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            className="hide-on-print "
            onClick={handlePrint}
            type="button"
          >
            Print
          </button>

          <button
            type="submit"
            style={{
              marginLeft: "10px",
              padding: "8px 24px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            className="hide-on-print "
          >
            Create Salary Slip
          </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default SalarySlip;
