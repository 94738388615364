import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Modal from "react-modal";
import { toast, Toaster } from "react-hot-toast";

const EditPerformance = ({
  isOpen,
  performance,
  handleCloseModal,
  fetchData,
}) => {
  const [departmentName, setDepartmentName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [staffName, setStaffName] = useState("");
  const [performanceData, setPerformanceData] = useState(performance);
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("80%");
  useEffect(() => {
    setPerformanceData(performance);
  }, [performance]);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setModalWidth("90%");
        setModalHeight("90%");
      } else {
        setModalWidth("80%");
        setModalHeight("80%");
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);




  const handlePerformanceDataChange = (e, fieldName) => {
    const { name, value } = e.target;
    setPerformanceData({ ...performanceData, [name]: value });
  };
  const handleTargetChange = (index, e, field) => {
    const { value } = e.target;
    const updatedTargets = [...performanceData.targets];
    updatedTargets[index][field] = value;
    setPerformanceData({
      ...performanceData,
      targets: updatedTargets,
    });
  };
  const handleAchievementChange = (index, e, field) => {
    const { value } = e.target;
    const updatedAchievements = [...performanceData.achievement];
    updatedAchievements[index][field] = value;
    setPerformanceData({
      ...performanceData,
      achievement: updatedAchievements,
    });
  };
  const addTarget = (e) => {
    e.preventDefault();
    const updatedTargets = Array.isArray(performanceData.targets)
      ? [...performanceData.targets]
      : [];
    updatedTargets.push({ serviceName: "", amount: "" });
    setPerformanceData({
      ...performanceData,
      targets: updatedTargets,
    });
  };
  const removeTarget = (e, index) => {
    e.preventDefault();
    const updatedTargets = [...performanceData.targets];
    updatedTargets.splice(index, 1);
    setPerformanceData({ ...performanceData, targets: updatedTargets });
  };
  const addAchievement = (e) => {
    e.preventDefault();
    const updatedAchievements = Array.isArray(performanceData.achievement)
      ? [...performanceData.achievement]
      : [];
    updatedAchievements.push({ serviceName: "", amount: "", performance: "" });
    setPerformanceData({
      ...performanceData,
      achievement: updatedAchievements,
    });
  };
  const removeAchievement = (e, index) => {
    e.preventDefault();
    const updatedAchievements = [...performanceData.achievement];
    updatedAchievements.splice(index, 1);
    setPerformanceData({
      ...performanceData,
      achievement: updatedAchievements,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `/api/v1/performance/${performanceData._id}`,
        performanceData
      );
      toast.success("Performance data updated successfully");
      handleCloseModal();
      fetchData();
    } catch (error) {
      console.error("Error updating performance data:", error);
      toast.error("Failed to update performance data");
    }
  };


  const fetchData1 = async () => {
    try {
      const [departRes, teamRes, staffRes] = await Promise.all([
        axios.get("/api/v1/depart/getalldepart"),
        axios.get("/api/v1/team/getAll/"),
        axios.get("/api/v1/staff/getAllStaff"),
      ]);

      const departments = departRes.data.data;
      const teams = teamRes.data.allTeam;
      const staff = staffRes.data.data;

      const selectedDepartment = departments.find(
        (dept) => dept._id === performance.department
      );
      const selectedTeam = teams.find((team) => team._id === performance.team);
      const selectedStaff = staff.find((staff) => staff._id === performance.staffName);


      setDepartmentName( selectedDepartment.name );
      setTeamName( selectedTeam.name );
      setStaffName( selectedStaff.name);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData1();
  }, [performance]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          height: modalHeight,
          overflow: "auto",
        },
      }}
      overlayClassName="overlay"
    >
      <div className="flex justify-end mb-4">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="addstaff-container text-black p-10 px-4 py-8">
        <form className="max-w-6xl mx-auto" onSubmit={handleSubmit}>
          <h1 className="text-3xl font-bold underline mb-8 text-center">
            Update Performance
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
            <div className="mb-3 mx-4">
              <label htmlFor="date" className="block font-bold mb-1">
                Date
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={performanceData.date}
                required
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="Month" className="block font-bold mb-1">
                Month
              </label>
              <select
                id="Month"
                name="month"
                value={performanceData.month}
                required
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 border text-black rounded-md"
              >
                <option value="">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="from" className="block font-bold mb-1">
                From
              </label>
              <input
                type="date"
                id="from"
                name="from"
                required
                value={performanceData.from}
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="to" className="block font-bold mb-1">
                To
              </label>
              <input
                type="date"
                id="to"
                name="to"
                value={performanceData.to}
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="team" className="block font-bold mb-1">
                Team
              </label>
              <input
                id="team"
                name="team"
                value={teamName}
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>

            <div className="mb-3 mx-4">
              <label htmlFor="staffName" className="block font-bold mb-1">
                Staff Name
              </label>
              <input
                id="staffName"
                name="staffName"
                className="w-full px-4 py-2 text-black border rounded-md"
                value={performanceData.staffName}
                onChange={(e) => handlePerformanceDataChange(e)}
                required
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="staffId" className="block font-bold mb-1">
                Staff Id
              </label>
              <input
                type="text"
                id="staffId"
                name="staffId"
                required
                value={performanceData.staffId}
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="Department" className="block font-bold mb-1">
                Department
              </label>
              <input
                type="text"
                id="Department"
                name="department"
                className="w-full px-4 py-2 border text-black rounded-md"
                value={departmentName}
                readOnly
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="Designation" className="block font-bold mb-1">
                Designation
              </label>
              <input
                type="text"
                id="Designation"
                name="designation"
                value={performanceData.designation}
                onChange={(e) => handlePerformanceDataChange(e)}
                className="w-full px-4 py-2 border text-black rounded-md"
              />
            </div>
          </div>
          <div className="mb-3 mt-3 mx-4">
            <h2 className="text-2xl font-bold">Targets Given</h2>
          </div>
          {performanceData.targets &&
            performanceData.targets.map((target, index) => (
              <div
                key={index}
                className="flex flex-wrap items-center mt-3 mx-3"
              >
                <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
                  <label
                    htmlFor={`serviceName_${index}`}
                    className="block font-bold mb-1"
                  >
                    Service Name
                  </label>
                  <input
                    type="text"
                    id={`serviceName_${index}`}
                    name={`serviceName_${index}`}
                    value={target.serviceName || ""}
                    onChange={(e) =>
                      handleTargetChange(index, e, "serviceName")
                    }
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
                <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
                  <label
                    htmlFor={`amount_${index}`}
                    className="block font-bold mb-1"
                  >
                    Amount
                  </label>

                  <input
                    type="text"
                    id={`amount_${index}`}
                    name={`amount_${index}`}
                    value={target.amount || ""}
                    onChange={(e) => handleTargetChange(index, e, "amount")}
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
                <button
                  className="text-black py-2 px-8 rounded mx-4 mt-5 bg-gray-400"
                  onClick={(e) => removeTarget(e, index)}
                >
                  Remove
                </button>
              </div>
            ))}
          <button
            className="text-black py-2 px-8 rounded mb-4 mx-4 mt-4 bg-gray-400"
            onClick={addTarget}
          >
            Add Target
          </button>
          <div className="mb-3 mt-3 mx-4">
            <h2 className="text-2xl font-bold mt-4">Targets Achievement</h2>
          </div>
          {performanceData.achievement &&
            performanceData.achievement.map((achievement, index) => (
              <div
                key={index}
                className="flex flex-wrap items-center mt-3 mx-3"
              >
                <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
                  <label
                    htmlFor={`serviceName_achievement_${index}`}
                    className="block font-bold mb-1"
                  >
                    Service Name
                  </label>
                  <input
                    type="text"
                    id={`serviceName_${index}`}
                    name={`serviceName_${index}`}
                    value={achievement.serviceName || ""}
                    onChange={(e) =>
                      handleAchievementChange(index, e, "serviceName")
                    }
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
                <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
                  <label
                    htmlFor={`amount_${index}`}
                    className="block font-bold mb-1"
                  >
                    Amount
                  </label>
                  <input
                    type="text"
                    id={`amount_${index}`}
                    name={`amount_${index}`}
                    value={achievement.amount || ""}
                    onChange={(e) =>
                      handleAchievementChange(index, e, "amount")
                    }
                    className="w-full px-4 py-2 text-black border rounded-md"
                  />
                </div>
                <div className="flex-grow mb-3 mx-2 sm:mb-0 sm:mr-3">
                  <label
                    htmlFor={`performance_${index}`}
                    className="block font-bold mb-1"
                  >
                    Performance
                  </label>
                  <select
                    id={`performance_${index}`}
                    name={`performance_${index}`}
                    value={achievement.performance || ""}
                    onChange={(e) =>
                      handleAchievementChange(index, e, "performance")
                    }
                    className="px-4 py-2 border text-black rounded-md w-full sm:max-w-md"
                  >
                    <option value="">Select Performance</option>
                    <option value="Excellent">Excellent</option>
                    <option value="Good">Good</option>
                    <option value="Average">Average</option>
                  </select>
                </div>
                <button
                  className="text-black py-2 px-8 rounded mx-4 mt-5 bg-gray-400"
                  onClick={(e) => removeAchievement(e, index)}
                >
                  Remove
                </button>
              </div>
            ))}
          <button
            className="text-black py-2 px-8 rounded mb-4 mx-4 mt-4 bg-gray-400"
            onClick={addAchievement}
          >
            Add Achievement
          </button>
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="text-black bg-green-400 hover:bg-green-500 py-2 px-8 rounded "
            >
              Submit
            </button>
          </div>
        </form>
        <Toaster />
      </div>
    </Modal>
  );
};

export default EditPerformance;
