import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Modal from "react-modal";
import ViewCms from "./ViewCms";
import EditCms from "./EditCms";

Modal.setAppElement("#root");

const ManageProposal = () => {
  const [proposalData, setProposalData] = useState([]);
  const [selectedCms, setSelectedCms] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/cms");
      setProposalData(response.data);
    } catch (error) {
      console.error("Error fetching proposal data:", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this proposal record?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`/api/v1/cms/${id}`);
      toast.success("Proposal record deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting proposal record:", error);
      toast.error("Error deleting proposal record");
    }
  };

  const handleView = (cms) => {
    setSelectedCms(cms);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCms({});
    setModalOpen(false);
  };

  const handleEdit = (proposal) => {
    setSelectedCms(proposal);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedCms({});
    setEditModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: "long", day: "numeric", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="main-container">
      <h2 className="text-3xl text-black font-bold mb-8 text-center underline">
        Manage Proposal
      </h2>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-col md:flex-row items-center w-full md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="Enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="Enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Message
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {proposalData
              .filter((proposal) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(proposal.createdAt);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((proposal, index) => (
                <tr
                  key={proposal._id}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    {formatDate(proposal.createdAt)}
                  </td>
                  <td className="px-6 py-4">{proposal.message}</td>
                  <td className="px-6 py-4 flex gap-2">
                    <Link
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => handleView(proposal)}
                    >
                      View
                    </Link>
                    <Link
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => handleEdit(proposal)}
                    >
                      Edit
                    </Link>
                    <Link
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => handleDelete(proposal._id)}
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Toaster />
      <ViewCms
        isOpen={modalOpen}
        cms={selectedCms}
        handleCloseModal={handleCloseModal}
      />
      <EditCms
        isOpen={editModalOpen}
        cms={selectedCms}
        handleCloseModal={handleCloseEditModal}
        fetchData={fetchData}
      />
    </div>
  );
};

export default ManageProposal;
