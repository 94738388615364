// AdminRoutes.js

import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../../pages/admin/Header.js";
import Sidebar from "../../pages/admin/Sidebar.js";
import Home from "../../pages/admin/Home.js";
import ManageStaff from "../../pages/admin/Managestaff.js";
import Addstaff from "../../pages/admin/Addstaff.js";
import Approvals from "../../pages/admin/Approvals.js";
import Test from "../Test.js";
import CreateTeam from "../../pages/admin/CreateTeam.js";

import ManageQutation from "../../pages/admin/ManageQutation.js";
import QuationInvoice from "../../pages/admin/QuationInvoice.js";
import CreateCalenderHolidays from "../../pages/admin/CreateCalenderHolidays.js";
import Calender from "../../pages/admin/Calender.js";

import Celebration from "../../pages/admin/Celebration.js";
import Managefollowup from "../../pages/admin/Managefollowup.js";
import Managedailytask from "../../pages/admin/Managedailytask.js";
import CreateNotice from "../../pages/admin/CreateNotice.js";
import ManageNotice from "../../pages/admin/ManageNotice.js";
import Incentives from "../../pages/admin/Incentives.js";
import Remainder from "../../pages/admin/Remainder.js";

import Department from "../../pages/admin/Department.js";
import CreateTargets from "../../pages/admin/CreateTargets.js";
import CreatePerformance from "../../pages/admin/CreatePerformance.js";
import ManagePerformence from "../../pages/admin/ManagePerformance.js";
import ManageAllAttendence from "../../pages/admin/ManageAllAttendence.js";
import CompanySetUp from "../../pages/admin/CompanySetUp.js";
import SalarySlip from "../../pages/admin/SalarySlip.js";


import Receipt from "../../pages/admin/Receipt.js";
import ManageReceipt from "../../pages/admin/ManageReceipt.js";
import ManageDeduction from "../../pages/admin/ManageDeduction.js";
import SalaryRecord from "../../pages/admin/SalaryRecord.js";
import Salary from "../../pages/admin/Salary.js";
import AdvanceSalary from "../../pages/admin/AdvanceSalary.js";
import ManageAdvanceSalary from "../../pages/admin/ManageAdvanceSalary.js";
import ManageInvoice from "../../pages/admin/ManageInvoice.js";
import MessageBox from "../../pages/admin/MessageBox.js";
import ManageProposal from "../../pages/admin/ManageProposal.js";
import ManageTargets from "../../pages/admin/ManageTargets.js";
import RulesRegulations from "../../pages/admin/RulesRegulations.js";
import ManageLeads from "../../pages/admin/ManageLeads.js";
import LogOut from "../auth/LogOut.js"
import SalaryCalculation from "../../pages/admin/SalaryCalculation.js";
import AllRecords from "../../pages/admin/AllRecords.js";
import AllDeductionRecord from "../../pages/admin/AllDeductionRecord.js"
import AllAttendenceRecord from "../../pages/admin/AllAttendenceRecord.js";
import AlladvanceSalaryRecord from "../../pages/admin/AlladvanceSalaryRecord.js";
import ManageWorking from "../../pages/admin/ManageWorking.js";
import AllSalarySlipRecord from "../../pages/admin/AllSalarySlipRecord.js";
import AllIncentiveRecord from "../../pages/admin/AllIncentiveRecord.js"

function AdminRoutes() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <div className="grid-container">
      <Header OpenSidebar={OpenSidebar} />
      <Sidebar
        openSidebarToggle={openSidebarToggle}
        OpenSidebar={OpenSidebar}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/companySetUp" element={<CompanySetUp />} />
        <Route path="/addstaff" element={<Addstaff />} />
        <Route path="/managestaff" element={<ManageStaff />} />
        <Route path="/aproveles" element={<Approvals />} />
        <Route path="/createTeam" element={<CreateTeam />} />

        <Route path="/quotation" element={<ManageQutation />} />
        <Route path="/invoice" element={<QuationInvoice />}></Route>
        <Route
          path="/createcalenderholidays"
          element={<CreateCalenderHolidays />}
        ></Route>
        <Route path="/calender" element={<Calender />}></Route>

        <Route path="/celebration" element={<Celebration />}></Route>
        <Route path="/managefollowup" element={<Managefollowup />}></Route>
        <Route path="/Managedailytask" element={<Managedailytask />}></Route>
        <Route path="/notice" element={<CreateNotice />} />
        <Route path="/manageNotice" element={<ManageNotice />} />
        <Route path="/manageleads" element={<ManageLeads />} />
        <Route path="/incentives" element={<Incentives />} />
        <Route path="/Remainder" element={<Remainder />} />

        <Route path="/department" element={<Department />} />
        <Route path="/createtargets" element={<CreateTargets />} />
        <Route path="/createperformance" element={<CreatePerformance />} />
        <Route path="/manageperformance" element={<ManagePerformence />} />
        <Route path="/allattendence" element={<ManageAllAttendence />} />
        <Route path="/Receipt" element = {<Receipt/>}/>
        <Route path="/manageReceipt" element = {<ManageReceipt/>}/>
        <Route path="/salaryslip" element={<SalarySlip />} />
        <Route path="/managetargets" element={<ManageTargets />} />
        <Route path ="/DeductionRecord" element={<AllDeductionRecord/>}/>
        
        <Route path="/manageDeduction" element = {<ManageDeduction/>}/>
        <Route path = "/allrecords" element={<AllRecords/>}/>
        <Route path="/salary" element={<Salary />} />
        <Route path="/salrayrecord" element={<SalaryRecord />} />
        <Route path="/salarycalculation" element={<SalaryCalculation/>}/>
        <Route path="/advancesalary" element={<AdvanceSalary />} />
        <Route path="/manageadvancesalary" element={<ManageAdvanceSalary />} />
        <Route path="/manageinvoice" element={<ManageInvoice />} />
        <Route path="/messagebox" element={<MessageBox />} />
        <Route path="/manageproposal" element={<ManageProposal />} />
        <Route path="/rulesregulation" element={<RulesRegulations/>}/>
        <Route path ="/allatttendencerecord" element={<AllAttendenceRecord/>}/>
        <Route path="/alldvancesalary" element={<AlladvanceSalaryRecord />} />
        <Route path="/manageworking" element={<ManageWorking />} />

        <Route path="/allsalaryslip" element={<AllSalarySlipRecord />} />
        <Route path="/allincentive" element={<AllIncentiveRecord/>}/>
        <Route path ="/log-out" element={<LogOut/>}/>
        <Route path="/*" element={<Test />} />

      </Routes>
    </div>
  );
}

export default AdminRoutes;
