

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useAuth } from "../../components/context/auth";
const CreateStaffWorking = () => {
  const [auth] = useAuth();
  const [date, setDate] = useState("");
  const [bidLink, setBidLink] = useState("");
  const [profile, setProfile] = useState("");
  const [platform, setPlatform] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [country, setCountry] = useState("");
  const [budget, setBudget] = useState("");
  const [bidPlacedBy, setBidPlacedBy] = useState("");
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [technology, setTechnology] = useState("");
  const [userObjectId, setuserObjectId] = useState("");
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    if (auth && auth.duser) {
      const { _id } = auth.duser;
      setuserObjectId(_id);
    }
  }, [auth]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !userObjectId ||
      !date ||
      !bidLink ||
      !profile ||
      !platform ||
      !projectTitle ||
      !country ||
      !budget ||
      !bidPlacedBy ||
      !status ||
      !remark ||
      !technology
    ) {
      return toast.error("Please fill out all fields");
    }

    try {
      const formData = {
        userObjectId,
        date,
        bidLink,
        profile,
        platform,
        projectTitle,
        country,
        budget,
        bidPlacedBy,
        status,
        remark,
        technology,
      };

      const response = await axios.post(
        "/api/v1/working/createWorking",
        formData
      );
      toast.success(response.data.message);

      // Reset form fields
      setDate("");
      setBidLink("");
      setProfile("");
      setPlatform("");
      setProjectTitle("");
      setCountry("");
      setBudget("");
      setBidPlacedBy("");
      setStatus("");
      setRemark("");
      setTechnology("");
      setSubmitError(null);
    } catch (error) {
      console.error("Error while submitting form:", error);
      setSubmitError("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8 ">
      <form
        className="rounded-lg shadow-md md:pl-28 md:pr-28"
        onSubmit={handleSubmit}
      >
        <h1 className="text-3xl font-bold underline mb-4 text-center">
         My Work
        </h1>

        <div className="grid grid-cols-2 gap-5">
          <div className="mb-3">
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="bidLink" className="block font-bold mb-1">
            Link
            </label>
            <input
              type="text"
              id="bidLink"
              name="bidLink"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={bidLink}
              onChange={(e) => setBidLink(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="profile" className="block font-bold mb-1">
              Profile
            </label>
            <input
              type="text"
              id="profile"
              name="profile"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={profile}
              onChange={(e) => setProfile(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="platform" className="block font-bold mb-1">
              Platform
            </label>
            <input
              type="text"
              id="platform"
              name="platform"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="projectTitle" className="block font-bold mb-1">
              Project Title
            </label>
            <input
              type="text"
              id="projectTitle"
              name="projectTitle"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={projectTitle}
              onChange={(e) => setProjectTitle(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="country" className="block font-bold mb-1">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="budget" className="block font-bold mb-1">
              Budget
            </label>
            <input
              type="number"
              id="budget"
              name="budget"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="bidPlacedBy" className="block font-bold mb-1">
              Bid Placed By
            </label>
            <input
              type="text"
              id="bidPlacedBy"
              name="bidPlacedBy"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={bidPlacedBy}
              onChange={(e) => setBidPlacedBy(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="status" className="block font-bold mb-1">
              Status
            </label>
            <input
              type="text"
              id="status"
              name="status"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="remark" className="block font-bold mb-1">
              Remark
            </label>
            <textarea
              type="text"
              id="remark"
              name="remark"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="technology" className="block font-bold mb-1">
              Technology
            </label>
            <input
              type="text"
              id="technology"
              name="technology"
              className="w-full px-4 py-2 text-black border rounded-md"
              value={technology}
              onChange={(e) => setTechnology(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4"
          >
            Create
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-black py-2 px-8 rounded mb-4"
            onClick={() => {
              setDate("");
              setBidLink("");
              setProfile("");
              setPlatform("");
              setProjectTitle("");
              setCountry("");
              setBudget("");
              setBidPlacedBy("");
              setStatus("");
              setRemark("");
              setTechnology("");
              setSubmitError(null);
            }}
          >
            Cancel
          </button>
        </div>
        {submitError && <p className="text-red-500">{submitError}</p>}
      </form>
      <Toaster />
    </div>
  );
};

export default CreateStaffWorking;



