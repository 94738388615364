import React, { useState, useEffect } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const ManageDeduction = () => {
  const [staffData, setStaffData] = useState([]);
  const [dop, setdop] = useState([]);
  const [viewingUser, setViewingUser] = useState(false);
  const [deduction, setDeduction] = useState([]);
  const [staff, setStaff] = useState([]);
  const [dept, setDept] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState("");
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [teams, setTeams] = useState([]);

  const [teamNames, setTeamNames] = useState({});
  const [deptNames, setDeptNames] = useState({});
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  const [formData, setFormData] = useState({
    Date: "",
    EmployeeName: "",
    EmployeeId: "",
    Designation: "",
    department: "",
    team: "",
    Reason: "",
    Amountofdeduction: "",
  });

  const getAllStaff = async () => {
    try {
      const data = await axios.get("/api/v1/staff/getAllStaff");
      setStaff(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");
      setDept(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        setTeams(response.data.allTeam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getAllDepart();
    getAllStaff();
    fetchData();
  }, []);

  const handleTeamChange = (e) => {
    const selectedTeam = e.target.value;
    setFormData({
      ...formData,
      team: selectedTeam,
      staffName: "",
    });
    const teamStaff = staff.filter((staff) => staff.team === selectedTeam);
    setFilteredStaff(teamStaff);
  };

  // function creation for fetching deductionm amount
  const fetchD = async (did) => {
    try {
      const res = await axios.get(`/api/v1/salary/single-salary/${did}`);
      const { data } = res.data;

      if (res) {
        var sOp = [];

        // Store values in the array
        sOp.push(data[0]?.lateAmount ? data[0].lateAmount : "0");
        sOp.push(data[0]?.absentAmount ? data[0].absentAmount : "0");
        sOp.push(data[0]?.rules_regulation ? data[0].rules_regulation : "0");
        setdop(sOp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchD();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/v1/deduction/creatededuction",
        formData
      );
      toast.success(response.data.message);
      if (response) {
        fetchData();
        setViewingUser(false);
      }

      setFormData({
        Date: "",
        EmployeeName: "",
        EmployeeId: "",
        Designation: "",
        department: "",
        team: "",
        Reason: "",
        Amountofdeduction: "",
      });
    } catch (error) {
      console.error("Error creating model:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "Reason") {
      let amt, reson;
      if (value === "LateComing") {
        amt = dop[0];
        reson = "LateComing";
      } else if (value === "Absent") {
        reson = "Absent";
        amt = dop[1];
      } else if (value === "RuleBreak") {
        reson = "RuleBreak";
        amt = dop[2];
      } else if (value === "Other") {
        reson = "Other";
        amt = "";
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: reson,
        Amountofdeduction: amt,
      }));
    }

    if (name === "EmployeeName") {
      const selectedEmployee = staff.find(
        (employee) => employee.name === value
      );
      if (selectedEmployee) {
        setFormData((prevState) => ({
          ...prevState,
          EmployeeId: selectedEmployee.id,
          Designation: selectedEmployee.designation,
          department: selectedEmployee.department,
          team: selectedEmployee.team,
        }));
        fetchD(selectedEmployee.id);

        const selectedDepart = selectedEmployee.department;
        setSelectedDepart(selectedDepart);
        const teamStaff = staff.filter(
          (staff) => staff.team === selectedEmployee.team
        );

        setFilteredStaff(teamStaff);
      }
    }
  };

  const openModal = () => {
    setViewingUser(true);
  };

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepart(selectedDepartment);
    setFormData({
      ...formData,
      department: selectedDepartment,
      team: "",
      staffName: "",
      designation: "",
    });
    setFilteredStaff([]);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/deduction/getdeduction");
      setDeduction(response.data);
      getAllStaff();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const fetchTeamAndDeptNames = async () => {
      try {
        const responseDept = await axios.get("/api/v1/depart/getalldepart");
        const deptNamesData = {};
        responseDept.data.data.forEach((dept) => {
          deptNamesData[dept._id] = dept.name;
        });
        setDeptNames(deptNamesData);

        const responseTeam = await axios.get("/api/v1/team/getAll/");
        const teamNamesData = {};
        responseTeam.data.allTeam.forEach((team) => {
          teamNamesData[team._id] = team.name;
        });
        setTeamNames(teamNamesData);
      } catch (error) {
        console.error("Error fetching team and department names:", error);
      }
    };
    fetchTeamAndDeptNames();
  }, []);

  const saveAndReset = async () => {
    try {
      const ans = prompt("Are u sure to reset this data yes/No");
      if (ans === "yes") {
        const data = await axios.post("/api/v1/record/deduction-create");

        getAllStaff();
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="addstaff-container max-w-screen-xl px-4 mx-auto lg:px-2 w-full  my-4">
      <h1 className="text-3xl font-bold mb-2 text-center underline">
        Deduction
      </h1>
      <div className="flex justify-end mb-2">
        <button onClick={saveAndReset}>save & reset </button>
      </div>
      <div className="relative  mb-2">
        <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
          <div className="sm:flex sm:flex-col-2 items-stretch justify-end flex-shrink-0 w-full md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
              <input
                className=" mb-2 shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date"
                value={search.startDate}
                onChange={(e) =>
                  setSearch({ ...search, startDate: e.target.value })
                }
                placeholder="enter start date"
              />

              <input
                className="  shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="date"
                value={search.endDate}
                onChange={(e) =>
                  setSearch({ ...search, endDate: e.target.value })
                }
                placeholder="enter end date"
              />
            </div>
          </div>

          <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            <div className="flex items-center w-full space-x-3 md:w-auto">
              <button
                id="actionsDropdownButton"
                data-dropdown-toggle="actionsDropdown"
                className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg md:w-auto focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
                onClick={openModal}
              >
                <FaPlus />
                Add New
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className=" overflow-x-auto ">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  S.No.
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Employee Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Employee Id
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Designation
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Team
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Reason of deduction
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium  font-bold uppercase tracking-wider">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody className="bg-gray-200 divide-y divide-gray-200">
              {deduction
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.Date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((deduction, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.Date}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.EmployeeName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.EmployeeId}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.Designation}
                    </td>
                    <td className="px-2 py-4 text-center">
                      {deptNames[deduction.department]}
                    </td>
                    <td className="px-2 py-4 text-center">
                      {teamNames[deduction.team]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.Reason}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {deduction.Amountofdeduction}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {viewingUser && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          <div className="relative bg-white rounded-lg w-3/2 lg:w-1/2 md:1/2  px-4 py-6 ">
            <div className="max-w-screen-xl ">
              <div className="relative bg-gray-700 dark:bg-gray-800">
                <div className="flex flex-col items-center justify-center p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                  <div className="w-full md:w-1/2 text-center text-gray-100 font-bold text-lg ">
                    Deduction
                  </div>
                </div>
              </div>
              <div className="flex flex-col bg-gray-200">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8   ">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 justify-between p-6 space-y-3 ">
                      <div className="modal-body">
                        <form>
                          <div className="w-56 min-w-0 sm:min-w-full mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 justify-center">
                            <div className="col-md-4  ">
                              <div className="form-group ">
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                  Date
                                </label>
                                <input
                                  type="date"
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="Date"
                                  placeholder="Date *"
                                  name="Date"
                                  value={formData.Date}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employee Name
                                </label>
                                <select
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="EmployeeName"
                                  name="EmployeeName"
                                  value={formData.EmployeeName} // Bind value to state variable directly
                                  onChange={handleChange}
                                >
                                  <option value="">Select Employee</option>
                                  {staff.map((staffMember) => (
                                    <option
                                      key={staffMember.EmployeeId}
                                      value={staffMember.name}
                                    >
                                      {staffMember.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="block text-sm font-medium text-gray-700">
                                  Employee ID
                                </label>
                                <input
                                  type="text"
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="EmployeeId"
                                  name="EmployeeId"
                                  placeholder="Enter Employee ID"
                                  value={formData.EmployeeId}
                                  readOnly // Make it read-only so users cannot directly modify the employee ID
                                />
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="block text-sm font-medium text-gray-700">
                                  Designation
                                </label>
                                <input
                                  type="text"
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="Designation"
                                  name="Designation"
                                  placeholder="Enter Designation"
                                  value={formData.Designation}
                                  readOnly // Make it read-only so users cannot directly modify the designation
                                />
                              </div>
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="department"
                                className="block font-bold mb-1"
                              >
                                Department
                              </label>
                              <input
                                type="text"
                                className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                id="department"
                                name="department"
                                placeholder="Enter Department"
                                value={deptNames[formData.department] || ""}
                                readOnly // Make it read-only so users cannot directly modify the department name
                              />
                            </div>
                            <div className="mb-4">
                              <label
                                htmlFor="team"
                                className="block font-bold mb-1"
                              >
                                Team
                              </label>
                              <input
                                type="text"
                                className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                id="team"
                                name="team"
                                placeholder="Enter Team"
                                value={teamNames[formData.team] || ""}
                                readOnly // Make it read-only so users cannot directly modify the team name
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="block text-sm font-medium text-gray-700">
                                  Reason
                                </label>

                                <select
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="Reason"
                                  name="Reason"
                                  value={formData.Reason}
                                  onChange={handleChange}
                                >
                                  <option value="" disabled selected>
                                    Select
                                  </option>
                                  <option value="RuleBreak" name="RuleBreak">
                                    Rule Break
                                  </option>
                                  <option value="LateComing" name="LateComing">
                                    Late Coming
                                  </option>
                                  <option value="Absent" name="Absent">
                                    Absent
                                  </option>
                                  <option value="Other" name="Other">
                                    Other
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="block text-sm font-medium text-gray-700">
                                  Amount of deduction
                                </label>
                                <input
                                  type="text"
                                  className="form-input block w-full border border-gray-300 bg-white focus:ring-indigo-500 focus:border-indigo-500 text-gray-900"
                                  id="Amountofdeduction"
                                  placeholder="Amount *"
                                  name="Amountofdeduction"
                                  value={formData.Amountofdeduction}
                                  onChange={handleChange}
                                  readOnly={formData.Reason !== "Other"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer text-center mt-4">
                            <button
                              onClick={handleSubmit}
                              className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2"
                            >
                              Save
                            </button>
                            <button
                              type="submit"
                              className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-800 ml-2"
                              onClick={() => setViewingUser(null)}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      )}
    </div>
  );
};
export default ManageDeduction;
