import React, { useState, useEffect } from "react";
import axios from "axios";

const SalaryCalculation = () => {
  const [salaraydata, setSalaryData] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [advanceSal, setAdvanceSal] = useState([]);
  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        //for salaryRecord
        const salaryResponse = await axios.get(
          "/api/v1/salary/getAllSalaryRecord"
        );
        setSalaryData(salaryResponse.data.allsalaryRecord);
        //for attendance
        const attendanceData = await axios.get("/api/v1/attendence/allAttendance");
       
        setAttendance(attendanceData.data.data);
        //for salary deduct
        const res = await axios.get("/api/v1/deduction/getdeduction");
        setDeduction(res.data);

        // for advance salary
        const ad = await axios.get("/api/v1/advancesalary/getAllAdvanceSalary");

        setAdvanceSal(ad.data.advanceSalaryData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!Array.isArray(salaraydata)) {
    return <div>Loading...</div>;
  }
  
  const totalPresent = (eid) => {
    const up = attendance.filter((record) => record.staffId === eid);
    const halfDaysCount = up.reduce((count, record) => {
      if (record.attendanceStatus === "HalfDay") {
        return count + 0.5; 
      }
      if(record.attendanceStatus  === "Present"){
        return count + 1;
      } else {
        return count; 
      }
        
    }, 0);
    return halfDaysCount;
  };

  
  
  // get total abset
  const totalAbsent = (eid) => {
    const up = attendance.filter((record) => record.staffId === eid);
 
    const absents = up.reduce((sum, record) => {
      if (record.attendanceStatus === "Absent") {
        return sum + 1;
      } else if (record.attendanceStatus === "Halfday") {
        return sum + 0.5;
      } else if (record.attendanceStatus === "Present") {
        return sum + 0;
      } else {
        return sum;
      }
    }, 0);

    return absents;
  };

  /// for getting deduction
  const getDeduction = (employeeId) => {
    const staffDeductor = deduction.filter(
      (item) => item.EmployeeId === employeeId
    );
    const stf = staffDeductor.reduce((Amountofdeduction, item) => {
      return Amountofdeduction + parseFloat(item.Amountofdeduction);
    }, 0);
    return stf;
  };

  /// for getting advance salary
  const getAd = (emloyeeId) => {
    const keeper = advanceSal.filter((dk) => dk.id === emloyeeId);
    const adsalary = keeper.reduce((amountofadvancesalary, dk) => {
      return amountofadvancesalary + parseFloat(dk.amountofadvancesalary);
    }, 0);

    return adsalary;
  };

  const filteredSalaryData = salaraydata.filter((record) => {
    if (filterMonth && filterYear) {
      const recordDate = new Date(record.date);
      return (
        recordDate.getMonth() + 1 === filterMonth &&
        recordDate.getFullYear() === filterYear
      );
    } else if (filterMonth) {
      const recordDate = new Date(record.date);
      return recordDate.getMonth() + 1 === filterMonth;
    } else if (filterYear) {
      const recordDate = new Date(record.date);
      return recordDate.getFullYear() === filterYear;
    }
    return true;
  });

  return (
    <div className="salaryRecord-container">
      <h2 className="text-3xl font-bold text-center text-gray-400 underline">
        Salary Calculation
      </h2>

      {/* end here filter section  */}
      <div className="flex flex-col text-1xl p-5 font-bold  sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3  text-gray-600">
        {/* Your From and To date inputs */}
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.No.
              </th>
              <th scope="col" className="px-2 py-3">
                Emp Name
              </th>
              <th scope="col" className="px-6 py-3">
                Emp id
              </th>
              <th scope="col" className="px-6 py-3">
                CTC
              </th>
              <th scope="col" className="px-6 py-3">
                Total Days
              </th>
              <th scope="col" className="px-6 py-3">
                Salary
                <br />
                /Day
              </th>
              <th scope="col" className="px-6 py-3">
                Total Present
              </th>
              <th scope="col" className="px-6 py-3">
                Total Absent
              </th>
              <th scope="col" className="px-6 py-3">
                Gross Salary
              </th>
              <th scope="col" className="px-6 py-3">
                Total Deductions
              </th>
              <th scope="col" className="px-6 py-3">
                Advance Salary
              </th>
              <th scope="col" className="px-6 py-3">
                Net Pay
              </th>
            </tr>
          </thead>
          <tbody>
            {salaraydata
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })
              .map((slarayRecord, index) => {
                const { Employeename, Employeeid } = slarayRecord;

                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="pl-6 py-4">{index + 1}</td>
                    <td className="px-2 py-4">{Employeename}</td>
                    <td className="px-2 py-4">{Employeeid}</td> 
                    <td className="px-6 py-4">
                      {salaraydata[index].salaryAmount}
                    </td>
                    <td className="px-6 py-4">30</td>
                    <td className="px-6 py-4">
                      {(salaraydata[index].salaryAmount / 30).toFixed(2)}
                    </td>
                    <td className="px-6 py-4">{30-totalAbsent(Employeeid)}</td>
                    <td className="px-6 py-4">{totalAbsent(Employeeid)}</td>
                    <td className="px-6 py-4">
                      {(
                        (salaraydata[index].salaryAmount / 30).toFixed(2) *
                      (30-totalAbsent(Employeeid))
                      ).toFixed(2)}
                    </td>
                    <td className="px-6 py-4">{getDeduction(Employeeid)}</td>
                    <td className="px-6 py-4">{getAd(Employeeid)}</td>
                    <td className="px-6 py-4">
                      {(
                        (salaraydata[index].salaryAmount / 30).toFixed(2) *
                        (30-totalAbsent(Employeeid))
                      ).toFixed(2) -
                        (getDeduction(Employeeid) + getAd(Employeeid))}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default SalaryCalculation;
