import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
const Spinner = ({ path  }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevValue) => --prevValue);
    }, 1000);
    count === 0 &&
      navigate(`/${path}`, {    
        state: location.pathname,
      });
    return () => clearInterval(interval);
  }, [count, navigate, location, path]);
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
    <h1 className="text-center mb-4 mb-md-5">Redirecting to you in {count} second</h1>
    <div className="spinner-border mb-4 mb-md-5" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
  );
};

export default Spinner;


