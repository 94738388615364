import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";

const ViewTargets = ({ isOpen, selectedTarget, handleCloseModal }) => {
  const [modalWidth, setModalWidth] = useState("80%");
  const [modalHeight, setModalHeight] = useState("80%");
  const [department, SetDepartment] = useState("");
  const [deptData, setDeptData] = useState("");
  const [teamData, setTeamData] = useState("");
  const [teamNames, setTeamNames] = useState("");
  const [teams, setTeams] = useState("");
  const [allStaff, setAllStaff] = useState("");
  const [staffData, setStaffData] = useState("");
  const [deptNames, setDeptNames] = useState("");
  const [teamLeader, setTeamLeader] = useState("");
  const [coordinator, setCoordinator] = useState("");
  const [fileData, setFileData] = useState(null);

  
  const getFile = async () => {
    try {
      const response = await axios.get(`/api/v1/target/getFile/${selectedTarget._id}`, {
        responseType: 'blob', 
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setFileData(url);
    } catch (error) {
      console.error("Error fetching file data:", error);
    }
  };

  const isImage =
    selectedTarget.uploadFile &&
    selectedTarget.uploadFile.contentType.startsWith("image");
  //get all department
  useEffect(() => {
    getAlldepart();
    getAllTeam();
    getAllStaff();
    getFile();
  }, [selectedTarget, selectedTarget.uploadFile, department]);

  const getAlldepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");

      // Extracting department names
      const departmentNames = data.data.data.map(
        (department) => department.name
      );

      // Set all departments
      SetDepartment(data.data.data);
      // Filter department based on selected department ID
      const selectedDepartment = data.data.data.find(
        (department) => department._id === selectedTarget.department
      );
      const names = selectedDepartment.name;
      // Set department data to filtered department
      setDeptData(names);
      setDeptNames(departmentNames);
    } catch (error) {
      console.log(error);
    }
  };
  // getAll Team
  const getAllTeam = async () => {
    try {
      const response = await axios.get("/api/v1/team/getAll/");

      const teams = response.data.allTeam;
      const teamNames = teams.map((team) => team.name);
      // Set all teams
      setTeams(teams);

      // Filter team based on selected team ID
      const selectedTeam = teams.find(
        (team) => team._id === selectedTarget.team
      );
      const teamName = selectedTeam.name;
      // Set team data to filtered team
      if (teamName) {
        setTeamData(teamName);
      } else {
        console.error("Selected team not found.");
      }

      setTeamNames(teamNames);
    } catch (error) {
      console.error("Error fetching team data:", error);
    }
  };
  // getAll staff
  const getAllStaff = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      const allStaff = response.data.data;
      // Set all staffza
      setAllStaff(allStaff);
      // Filter staff based on selected staff ID
      const selectedStaff = allStaff.find(
        (staff) => staff._id === selectedTarget.staffName
      );
      const selectedTeamLeader = allStaff.find(
        (staff) => staff._id === selectedTarget.teamLeader
      );
      const selectedCoordinator = allStaff.find(
        (staff) => staff._id === selectedTarget.coordinator
      );

      setTeamLeader(selectedTeamLeader);
      setCoordinator(selectedCoordinator);

      if (selectedTeamLeader) {
        setTeamLeader(selectedTeamLeader.name);
      } else {
        console.error("Selected staff not found.");
      }

      if (selectedCoordinator) {
        setCoordinator(selectedCoordinator.name);
      } else {
        console.error("Selected staff not found.");
      }
      if (selectedStaff) {
        setStaffData(selectedStaff.name);
      } else {
        console.error("Selected staff not found.");
      }
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setModalWidth("90%");
        setModalHeight("90%");
      } else {
        setModalWidth("80%");
        setModalHeight("80%");
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: modalWidth,
          height: modalHeight,
          overflow: "auto",
        },
      }}
      overlayClassName="overlay"
    >
      <div className="flex justify-end">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleCloseModal}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div className="addstaff-container text-black p-10 px-4 py-8">
        <form className="max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold underline mb-8 text-center">
            Create Targets
          </h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
            <div className="mb-4">
              <label htmlFor="department" className="block font-bold mb-1">
                Department
              </label>
              <input
                id="department"
                name="department"
                value={deptData}
                className="w-full px-4 py-2 text-black border rounded-md"
              ></input>
            </div>

            <div className="mb-4 mx-2">
              <label
                htmlFor="team"
                className="block  text-black font-bold mb-1"
              >
                Team
              </label>
              <input
                id="team"
                name="team"
                value={teamData}
                className="w-full px-4 py-2 text-black-900 border rounded-md"
              ></input>
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="month" className="block font-bold mb-1">
                Month
              </label>
              <input
                id="month"
                name="month"
                value={selectedTarget.month}
                className="w-full px-4 py-2 border text-black rounded-md"
              ></input>
            </div>

            <div className="mb-4 mx-2">
              <label
                htmlFor="staff"
                className="block  text-black font-bold mb-1"
              >
                Staff
              </label>
              <input
                id="staff"
                name="staff"
                value={staffData}
                className="w-full px-4 py-2 text-black-900 border rounded-md"
              ></input>
            </div>

            <>
              {/* this are the default fields visible everytime */}
              <div className="mb-3 mx-4">
                <label htmlFor="staffId" className="block font-bold mb-1">
                  StaffId
                </label>
                <input
                  type="text"
                  id="staffId"
                  name="staffId"
                  value={selectedTarget.staffId}
                  className="w-full px-4 py-2 text-black border rounded-md"
                />
              </div>
              <div className="mb-3 mx-4">
                <label htmlFor="designation" className="block font-bold mb-1">
                  Designation
                </label>
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  value={selectedTarget.designation}
                  className="w-full px-4 py-2 text-black border rounded-md"
                />
              </div>
              <div className="mb-3 mx-4">
                <label htmlFor="targetFrom" className="block font-bold mb-1">
                  Target From
                </label>
                <input
                  type="date"
                  id="targetfrom"
                  name="targetFrom"
                  value={selectedTarget.targetFrom}
                  className="w-full px-4 py-2 text-black border rounded-md"
                />
              </div>
              <div className="mb-3 mx-4">
                <label htmlFor="targetTo" className="block font-bold mb-1">
                  Target To
                </label>
                <input
                  type="date"
                  id="targetto"
                  name="targetTo"
                  value={selectedTarget.targetTo}
                  className="w-full px-4 py-2 text-black border rounded-md"
                />
              </div>
              <div className="mb-3 mx-4">
                <label htmlFor="totaldays" className="block font-bold mb-1">
                  Total Days
                </label>
                <input
                  type="number"
                  id="totaldays"
                  name="totaldays"
                  value={selectedTarget.totalDays}
                  readOnly
                  className="w-full px-4 py-2 text-black border rounded-md"
                />
              </div>
            </>

            {/* {selectedTarget.department === "65f54fb95abca0b11174d21d" && (
            <>
              this will open after selecting department It and other */}

            <div className="mb-3 mx-4">
              <label
                htmlFor="teamleader"
                className="block  text-black font-bold mb-1"
              >
                Team Leader
              </label>
              <input
                id="teamleader"
                name="teamleader"
                value={teamLeader}
                className="w-full px-4 py-2 text-black-900 border rounded-md"
              ></input>
            </div>
            <div className="mb-3 mx-4">
              <label
                htmlFor="coordinator"
                className="block  text-black font-bold mb-1"
              >
                Coordinator
              </label>
              <input
                id="coordinator"
                name="coordinator"
                value={coordinator}
                className="w-full px-4 py-2 text-black-900 border rounded-md"
              ></input>
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="target" className="block font-bold mb-1">
                Target
              </label>
              <textarea
                type="text"
                id="target"
                name="target"
                value={selectedTarget.target}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mt-2">
              <div className="flex items-center ">
                <p className="font-semibold w-1/3">Upload File:</p>
                {fileData && (
                  <>
                    <img
                      src={fileData}
                      alt="Staff Photo"
                      className="h-24 mr-2"
                    />
                    <a
                      href={fileData}
                      download
                      className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-4 rounded"
                    >
                      Download
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ViewTargets;
