import React, { useState, useEffect, useRef, } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa";
import  {useAuth}  from "../../components/context/auth.js";

import { IoMdClose, IoMdPersonAdd } from "react-icons/io";
import {
  MdOutlineDashboard,
  MdCreateNewFolder,

  MdFollowTheSigns,

} from "react-icons/md";

import { MdUpcoming } from "react-icons/md";

import { FaRegistered } from "react-icons/fa6";

import { FiTarget } from "react-icons/fi";

import { MdOutlineImportantDevices } from "react-icons/md";

import { GrUserManager } from "react-icons/gr";
import { FaPersonCircleCheck, FaPerson } from "react-icons/fa6";
import ViewPostPondDeal from './ViewPostPondDeal';
 
function StaffSidebar({ openSidebarToggle, OpenSidebar }) {
  const [showHRMDropdown, setShowHRMDropdown] = useState(false);
  const [showCRMDropdown, setShowCRMDropdown] = useState(false);
 
  const [auth] = useAuth();

  const sidebarRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        openSidebarToggle
      ) {
        OpenSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openSidebarToggle, OpenSidebar]);

  const closeSidebar = () => {
    if (openSidebarToggle) {
      OpenSidebar();
    }
  };

  return (
    <aside
      ref={sidebarRef}
      id="sidebar"
      className={openSidebarToggle ? "sidebar-responsive" : ""}
    >
      <div className="sidebar-title">
        <div className="sidebar-brand">
          <img src="https://manasvitech.in/images/white-logo.png" />
        </div>
        <span
          style={{ borderColor: "white" }}
          className="icon close_icon border"
          onClick={OpenSidebar}
        >
          <IoMdClose />
        </span>
      </div>

      <ul className="sidebar-list">
        <li className="sidebar-list-item">
          <a href="../src/pages/Dharma.js" className="w-full">
            <Link to="/staff/dashboard" class="inline-container">
              <MdOutlineDashboard className="icon" />
              <span>{auth.duser.name}</span>
            </Link>
          </a>
        </li>
        <li className="sidebar-list-item">
          <div
            className="w-full"
            onClick={() => setShowHRMDropdown(!showHRMDropdown)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ cursor: "pointer", textAlign: "left" }}
                className="ml-3"
              >
                HRM
              </span>
              <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                <FaCaretDown />
              </div>
            </div>
          </div>
          {showHRMDropdown && (
            <ul className="sub-menu">
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/attendence" class="inline-container">
                  <FaPersonCircleCheck className="icon" />
                  Staff Attendance
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link
                  to="/staff/dashboard/viewstaffattendence"
                  class="inline-container"
                >
                  <FaPersonCircleCheck className="icon" />
                  View Attendance
                </Link>
              </li>
              {/* <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/viewlead" class="inline-container">
                  <FiTarget className="icon" />
                  View Lead
                </Link>
              </li> */}
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/applyleave" class="inline-container">
                  <GrUserManager className="icon" />
                  Apply Leave
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/manageArovels" class="inline-container">
                  <GrUserManager className="icon" />
                  Manage Leave Aprovels
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/dailytask" class="inline-container">
                  <GrUserManager className="icon" />
                  Daily Task
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/managesalaryslip" class="inline-container">
                  <GrUserManager className="icon" />
                  Manage Salary Slip
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/taskStatus" class="inline-container">
                  <GrUserManager className="icon" />
                  Task Status
                </Link>
              </li>
             
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/calender" class="inline-container">
                  <MdCreateNewFolder className="icon" />
                  Holiday calendar
                </Link>
              </li>
            
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/rulesregulation" class="inline-container">
                  <MdCreateNewFolder className="icon" />
                  Manage Rule & Regulation
                </Link>
              </li>
             
            </ul>
          )}
        </li>

        {/* CRM Dropdown */}
        <li className="sidebar-list-item">
          <div
            className="w-full"
            onClick={() => setShowCRMDropdown(!showCRMDropdown)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ cursor: "pointer", textAlign: "left" }}
                className="ml-3"
              >
                CRM
              </span>
              <div style={{ marginLeft: "auto", marginRight: "10px" }}>
                <FaCaretDown />
              </div>
            </div>
          </div>
          {showCRMDropdown && (
            <ul className="sub-menu">
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/createleads" class="inline-container">
                  <MdFollowTheSigns className="icon" />
                  <span>Create Lead</span>
                </Link>
              </li>
             
                 
                 <li className="sub-menu">
                  <li className="sidebar-list-item" onClick={closeSidebar}>
                    <Link to="/staff/dashboard/viewleads" className="inline-container">
                    <MdFollowTheSigns className="icon" />
                  <span>View Lead</span>
                    </Link>
                  </li>

                 </li>
                 <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/viewFinalDeal" class="inline-container">
                  <MdUpcoming className="icon" />
                  View Final Deal
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/viewCancelDeal" class="inline-container">
                  <MdUpcoming className="icon" />
                  View Cancel Deal 
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/viewfollowup" class="inline-container">
                  <MdUpcoming className="icon" />
                  View Follow Up
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/viewPostPondDeal" class="inline-container">
                  <MdUpcoming className="icon" />
                   View PostPond Deal
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/quation" class="inline-container">
                  <MdUpcoming className="icon" />
                  Quotation
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/dashboard/managequation" class="inline-container">
                  <MdUpcoming className="icon" />
                 Manage Quotation
                </Link>
              </li>
             
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/Dashboard/working" class="inline-container">
                  <MdOutlineImportantDevices className="icon" />
                   Working 
                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/staff/Dashboard/manageworking" class="inline-container">
                  <MdOutlineImportantDevices className="icon" />
                  Manage Working

                </Link>
              </li>
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link
                  to="/staff/Dashboard/ManageIncentive"
                  class="inline-container"
                >
                  <FaRegistered className="icon" />
                  Manage incentive
                </Link>
              </li>
              {/* <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link to="/admin/reminder" class="inline-container">
                  <CiSquareMore className="icon" />
                  Reminder
                </Link>
              </li> */}
            </ul>
          )}
        </li>

        {/* <li className="sidebar-list-item"onClick={closeSidebar}>
          <a href="" className="w-full">
            <Link class="inline-container">
              <IoMdSettings className="icon" />
              <span>Setting</span>
            </Link>
          </a>
        </li> */}
      </ul>
      <ul className="sub-menu">
              <li className="sidebar-list-item"onClick={closeSidebar}>
                <Link
                  to="/staff/dashboard/log-out"
                  class="inline-container"
                >
                  <MdFollowTheSigns className="icon" />
                  <span>Log Out</span>
                </Link>
              </li>
            
      </ul>
    </aside>
  );
}

export default StaffSidebar;
