import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../components/context/auth";
function CreateLeads() {
  const [auth]=useAuth();
  const[id,setId]=useState("")
  const initialFormData = {
    date: "",
    businessName: "",
    businessAddress: "",
    businessWebsite: "",
    businessEmail: "",
    contactPersonName: "",
    contactPersonNumber: "",
    leadType: "",
    serviceName: "",
    dealType:"",
    nextMeetingDate: "",
    message: "",
    documents: null,
    offerPrice: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      documents: e.target.files[0],
    }));
  };



  useEffect(() => {
    if (auth && auth.duser) {
      const {  id} = auth.duser;
      setId(id);
    }
  }, [auth]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
  
      // Append each field to the FormData object
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          formDataToSend.append(key, formData[key]);
        }
      }
      
      // Append the file separately if present
      if (formData.documents) {
        formDataToSend.append('documents', formData.documents);

      }
      formDataToSend.append('id',id)
  
      const response = await axios.post("/api/v1/lead/lead-create", formDataToSend);
      
      if (response.data.success) {
        toast.success("Lead created successfully!");
        setFormData(initialFormData);
      } else {
        toast.error(response.data.message);

      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An error occurred while creating the lead.");
    }
  };
 

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form
        onSubmit={handleSubmit}
        className="max-w-12xl mx-auto rounded-lg shadow-md md:pl-28 md:pr-28"
      >
        <div className="text-3xl font-bold underline text-center mb-6">
          <h1 className="text-3xl justify-between font-bold underline">
            Create Lead
          </h1>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="mb-4 ">
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="businessname" className="block font-bold mb-1">
              Business Name
            </label>
            <input
              type="text"
              id="businessName"
              name="businessName"
              value={formData.businessName}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="businessaddress" className="block font-bold mb-1">
              Business Address
            </label>
            <input
              type="text"
              id="businessAddress"
              name="businessAddress"
              value={formData.businessAddress}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="businesswebsite" className="block font-bold mb-1">
              Business Website
            </label>
            <input
              type="text"
              id="businessWebsite"
              name="businessWebsite"
              value={formData.businessWebsite}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="businessemail" className="block font-bold mb-1">
              Business E-mail
            </label>
            <input
              type="text"
              id="businessEmail"
              name="businessEmail"
              value={formData.businessEmail}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="contactpersonname" className="block font-bold mb-1">
              Contact Person Name
            </label>
            <input
              type="text"
              id="contactPersonName"
              name="contactPersonName"
              value={formData.contactPersonName}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="contactpersonnumber"
              className="block font-bold mb-1"
            >
              Contact Person Numbar
            </label>
            <input
              type="text"
              id="contactPersonNumber"
              name="contactPersonNumber"
              value={formData.contactPersonNumber}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div>
            <label htmlFor="leadtype" className="block font-bold mb-1">
              Lead Type
            </label>
            <select
              id="leadType"
              name="leadType"
              value={formData.leadType}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md mb-2"
            >
              <option value="">Select Lead Type</option>
              <option value="cold">Cold</option>
              <option value="warm">Warm</option>
              <option value="hot">Hot</option>
            </select>
          </div>

          <div>
            <label htmlFor="servicename" className="block font-bold mb-1">
              Service Name
            </label>
            <select
              id="serviceName"
              name="serviceName"
              value={formData.serviceName}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md mb-2"
            >
              <option value="">Select Service Name</option>
              <option value="web">Website Development</option>
              <option value="app">App Development</option>
              <option value="both">Both Web & App Development</option>
              <option value="digitalMarketing">Digital Marketing</option>
            </select>
          </div>
          <div>
            <label htmlFor="dealtype" className="block font-bold mb-1">
              Deal Type
            </label>
            <select
              id="dealType"
              name="dealType"
              value={formData.dealType}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md mb-2"
            >
              <option value="">Select Deal Type</option>
              <option value="followUp">Follow Up</option>
              <option value="dealFinal">Deal Final</option>
              <option value="postPond">PostPond</option> 
              <option value="Cancel">Cancel</option> 
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="nextmeet" className="block font-bold mb-1">
              Next Meeting Date
            </label>
            <input
              type="date"
              id="nextMeetingDate"
              name="nextMeetingDate"
              value={formData.nextMeetingDate}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block font-bold mb-1">
              Message
            </label>
            <textarea
              type="text"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="uploadfile"
              className="text-white block font-bold mb-1"
            >
              Upload File
            </label>
            <input
              type="file"
              id="documents"
              name="documents"
              onChange={handleFileChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="offerprice"
              className="text-black block font-bold mb-1"
            >
              Offer Price
            </label>
            <input
              type="text"
              id="offerPrice"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
        </div>

        <div className="flex justify-start mt-5">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black py-2 w-25 px-8 rounded"
          >
            Create
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default CreateLeads;
