import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import HolidayModal from "./modal/HolidayModal";

const CreateCalenderHolidays = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [holidayDescription, setHolidayDescription] = useState("");
  const [addedHolidays, setAddedHolidays] = useState({});
  const [showDescription, setShowDescription] = useState(false);
  const [selectedclickedHoliday, setSelectedclickedHoliday] = useState([]);
  const [openmodelupdate, setOpenmodelupdate] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [holidayArr, setHolidayArr] = useState([]);
  const [updatedHolidayDescription, setUpdatedHolidayDescription] = useState('');

  const openModal = (data) => {
    setOpenmodelupdate(true);
    const newHolidayArr = [...holidayArr];
    newHolidayArr.push(data.date);
    newHolidayArr.push(data.description);
    setHolidayArr(newHolidayArr);

    setSelectedclickedHoliday(newHolidayArr);
  };

  const holidaydate = holidayArr.length > 0 ? holidayArr[0] : "";
  const holidaydescription = holidayArr.length > 1 ? holidayArr[1] : "";

  const closeModal = () => {
    setOpenmodelupdate(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/calenderholiday/findAllCalenderHoliday"
        );
        if (response.data.success) {
          const rowDataToSet = [];
          response.data.CalenderHolidaydata.forEach((calendarItem, index) => {
            if (calendarItem && Array.isArray(calendarItem.row)) {
              calendarItem.row.forEach((rowData) => {
                rowDataToSet.push(rowData);
              });
            }
          });
          setCalendarData(rowDataToSet);
        } else {
          toast.error("Failed to fetch holidays");
        }
      } catch (error) {
        console.error("Error fetching holidays:", error);
        toast.error("An error occurred while fetching holidays.");
      }
    };
    fetchData();
  }, []);

  const handleDateClick = (date, holidayId) => {
    setSelectedclickedHoliday(holidayId);
    const holiday = calendarData.find(
      (item) => item.date.substring(0, 10) === date
    );
    const holidayDescription = holiday ? holiday.description : "";
    setHolidayArr([date, holidayDescription]);
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleAddHoliday = () => {
    if (selectedDate && holidayDescription.trim() !== "") {
      setAddedHolidays({
        ...addedHolidays,
        [selectedDate]: holidayDescription.trim(),
      });
      setSelectedDate(null);
      setHolidayDescription("");
    }
  };

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const renderCalendar = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const calendar = [];
    const fontSize = window.innerWidth <= 425 ? "8px" : "16px";
    let week = [];

    // Push empty placeholders for days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      week.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    // Loop through each day in the month
    for (let i = 1; i <= daysInMonth; i++) {
      const date = `${year}-${String(month + 1).padStart(2, "0")}-${String(i).padStart(2, "0")}`;
      const holiday = calendarData.find((item) => item.date.substring(0, 10) === date);
      const holidayDescription = holiday ? holiday.description : "";

      // Render each day
      week.push(
        <div
          key={date}
          className={`calendar-day ${holiday ? "holiday" : ""} ${selectedDate === date ? "selected" : ""}`}
          onClick={() => handleDateClick(date)}
          style={{
            border: "2px solid #ccc",
            padding: "2px",
            borderRadius: "10px",
            backgroundColor: holiday ? "rgb(244 63 94)" : "",
          }}
        >
          <div className="box" style={{ textAlign: "center" }}>
            <span className="day" style={{ color: holiday ? "white" : "" }}>
              {i}
            </span>
            <br />
            {showDescription && holiday && (
              <span
                className="column-1"
                style={{
                  color: "white",
                  fontSize: fontSize,
                }}
                onClick={() => openModal(holiday)}
              >
                {holidayDescription}
              </span>
            )}
          </div>
        </div>
      );

      // If a week is complete or it's the last day of the month, push the week into the calendar
      if (week.length === 7 || i === daysInMonth) {
        calendar.push(
          <div key={`week-${i}`} className="calendar-week grid grid-cols-7 gap-2">
            {week}
          </div>
        );
        week = []; // Reset the week array
      }
    }

    return calendar;
  };

  const handleCreate = async () => {
    const rows = Object.entries(addedHolidays).map(([date, description]) => ({
      date,
      description,
    }));

    if (rows.length === 0) {
      toast.error("No holidays to send");
      return;
    }

    try {
      const response = await axios.post(
        "/api/v1/calenderholiday/createCalenderHoliday",
        { rows }
      );

      if (response.status === 200) {
        toast.success("Holidays successfully added");
      } else {
        toast.error("Failed to add holidays");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while processing the request.");
      }
    }
  };

  const handleUpdateHoliday = async () => {
    try {
      const response = await axios.put(
        `/api/v1/calenderholiday/updateHoliday/${holidaydate}`,
        { description: updatedHolidayDescription }
      );
      if (response.status === 200) {
        toast.success("Holiday updated successfully");
        closeModal();
      } else {
        toast.error("Failed to update holiday");
      }
    } catch (error) {
      console.error("Error updating holiday:", error);
      toast.error("An error occurred while updating holiday.");
    }
  };

  const handleDeleteHoliday = async () => {
    try {
      const response = await axios.delete(
        `/api/v1/calenderholiday/deleteHoliday/${holidaydate}`
      );
      if (response.status === 200) {
        toast.success("Holiday deleted successfully");
        closeModal();
      } else {
        toast.error("Failed to delete holiday");
      }
    } catch (error) {
      console.error("Error deleting holiday:", error);
      toast.error("An error occurred while deleting holiday.");
    }
  };

  return (
    <div className="addstaff-container calendar p-4">
      <h1 className="text-center text-2xl underline">
        Create Calendar Holidays
      </h1>
      <div className="mt-4 mb-4">
        <input
          type="date"
          value={selectedDate || ""}
          onChange={(e) => setSelectedDate(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          value={holidayDescription}
          onChange={(e) => setHolidayDescription(e.target.value)}
          placeholder="Holiday description"
          className="border p-2"
        />
        <button
          onClick={handleAddHoliday}
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add
        </button>
        <button
          onClick={toggleDescription}
          className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {showDescription ? "Hide festivals" : "Show festivals"}
        </button>
        <button
          className="ml-3 bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleCreate}
        >
          Create holidays
        </button>
      </div>
      <div className="calendar-header mb-4 flex justify-between items-center">
        <button className="text-xl font-bold" onClick={handlePreviousMonth}>
          &lt;
        </button>
        <h2 className="text-xl font-bold">
          {currentDate.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </h2>
        <button className="text-xl font-bold" onClick={handleNextMonth}>
          &gt;
        </button>
      </div>
      <div className="calendar-grid">
        <div className="calendar-week grid grid-cols-7 gap-2">
          <div className="calendar-day-header">Sun</div>
          <div className="calendar-day-header">Mon</div>
          <div className="calendar-day-header">Tue</div>
          <div className="calendar-day-header">Wed</div>
          <div className="calendar-day-header">Thu</div>
          <div className="calendar-day-header">Fri</div>
          <div className="calendar-day-header">Sat</div>
        </div>
        {renderCalendar()}
      </div>
      <ToastContainer />
      {openmodelupdate && (
        <div className="fixed inset-0 z-10 flex justify-center items-center">
          <div className="md:w-1/2 lg:w-1/2 overflow-x-auto overflow-y-auto bg-white shadow-sm rounded-xl p-6">
            <form>
              <div className="mt-8 px-4 lg:px-0">
                <div className="grid grid-cols-1 gap-4 md:gap-8">
                  <div className="border border-gray-200 p-6 rounded-lg">
                    <h2 className="text-2xl text-center text-orange-500 font-semibold mb-4">
                      Update holiday
                    </h2>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Date
                        </span>
                        <input
                          className="border border-gray-300 flex-1"
                          id="addressBy"
                          name="addressBy"
                          value={holidaydate}
                          readOnly
                        />
                      </div>
                      <div className="flex flex-col md:flex-row">
                        <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                          Holiday
                        </span>
                        <input
                          className="border border-gray-300 flex-1"
                          name="contactBy"
                          placeholder="Enter Update Holiday"
                          value={updatedHolidayDescription}
                          onChange={(e) =>
                            setUpdatedHolidayDescription(e.target.value)
                          }
                          id="contactBy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
              <div className="flex flex-col sm:flex-row">
                <button
                  className="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 sm:mb-0 sm:mr-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleUpdateHoliday}
                >
                  Update Holiday
                </button>
                <button
                  className="text-orange-400 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 sm:mb-0 sm:mr-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleDeleteHoliday}
                >
                  Delete Holiday
                </button>
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 sm:mb-0 ease-linear transition-all duration-150"
                  type="button"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateCalenderHolidays;
