import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../components/context/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const Quation = () => {
  const [auth] = useAuth();

  const [discount, setDiscount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [id, setId] = useState("");
  const [netAmount, setNetAmount] = useState(0);
  const [netAmountWords, setNetAmountWords] = useState("");
  const [mcompanyData, setCompanyData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const indianStates = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const [rows, setrows] = useState([
    {
      id: 1,
      description: "",
      hsn: "",
      qty: "",
      uom: "",
      rate: "",
      amount: "",
    },
  ]);
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get("/api/v1/company/getCompanyDetails");
        setCompanyData(response.data[0]);
        //  console.log(response,"company data ")
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyData();
  }, []);

  useEffect(() => {
    const getAllStaffData = async () => {
      try {
        const response = await axios.get("/api/v1/staff/getAllStaff");
        setStaffData(response.data.data);
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    getAllStaffData();
  }, []);

  useEffect(() => {
    calculateSubtotal();
  }, [rows]);
  useEffect(() => {
    const netAmount = subtotal - parseFloat(discount);
    setNetAmount(netAmount);
    setNetAmountWords(numberToWords(netAmount));
  }, [subtotal, discount]);

  const numberToWords = (num) => {
    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convertLessThanOneThousand = (num) => {
      if (num === undefined || num === null || isNaN(num) || num < 0) return ""; // Handle case when num is not provided or invalid
      if (num === 0) return "Zero"; // Handle case when num is 0
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + convertLessThanOneThousand(num % 10) : "")
        );
      return (
        units[Math.floor(num / 100)] +
        " Hundred" +
        (num % 100 !== 0 ? " " + convertLessThanOneThousand(num % 100) : "")
      );
    };

    // Function to add thousand, million, billion etc.
    const convert = (num) => {
      if (num === 0) return "";
      let word = "";
      let index = 0;
      do {
        const threeDigits = num % 1000;
        if (threeDigits !== 0) {
          const digitWords = convertLessThanOneThousand(threeDigits);
          word =
            digitWords +
            ["", " Thousand", " Million", " Billion"][index] +
            " " +
            word;
        }
        index++;
        num = Math.floor(num / 1000);
      } while (num > 0);
      return word.trim();
    };

    return convert(num);
  };
  const calculateAmount = (index) => {
    const updatedRows = [...rows];
    const { qty, rate } = updatedRows[index];
    if (qty && rate) {
      updatedRows[index].amount = parseFloat(qty) * parseFloat(rate);
      setrows(updatedRows);
      calculateSubtotal(updatedRows);
    }
  };
  const calculateSubtotal = () => {
    let subTotal = 0;
    rows.forEach((row) => {
      subTotal += parseFloat(row.amount) || 0;
    });
    setSubtotal(subTotal);
    const netAmount = subTotal - parseFloat(discount);
    setNetAmount(netAmount);
    setNetAmountWords(numberToWords(netAmount));
  };
  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      id: rows.length + 1,
      description: "",
      hsn: "",
      qty: "",
      uom: "",
      rate: "",
      amount: "",
    };
    setrows([...rows, newRow]);
  };

  const handleDeleteRow = (id) => {
    if (rows.length === 1) {
      return;
    }

    setrows(rows.filter((row) => row.id !== id));
    calculateSubtotal(rows.filter((row) => row.id !== id));
  };
  useEffect(() => {
    if (auth && auth.duser) {
      const { id } = auth.duser;
      setId(id);
    }
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formObject = {};
    formData.forEach((value, key) => {
      formObject[key] = value;
    });

    // Add rows to the formObject
    formObject.items = rows;

    // Retrieve values of salespersonBy and addressBy from the form
    const salespersonBy = e.target.salespersonBy.value;
    const addressBy = e.target.addressBy.value;

    // Add salespersonBy and addressBy to the formObject
    formObject.salespersonBy = salespersonBy;
    formObject.addressBy = addressBy;
    formObject.id = id;

    formObject.netAmountWords = netAmountWords;

    try {
      const response = await axios.post(
        "/api/v1/quation/createQuation",
        formObject,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      //  console.log(response,"Quation data");
      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }

      if (!response.data.success) {
        throw new Error(response.data.message || "Failed to submit quotation");
      }

      // Reset form fields
      e.target.reset();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const autoResizeTextarea = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  return (
    <div className="addstaff-container p-6 bg-white  shadow-sm my-6">
      <h1 className="text-center text-3xl underline">Quotation</h1>
      <form onSubmit={handleSubmit}>
        <div className="mt-8 px-4 lg:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
            <div className="border border-gray-200 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Quotation By -</h2>
              <p className="text-fuchsia-400 mb-4">
                {mcompanyData.businessName}
              </p>
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Address
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    id="addressBy"
                    name="addressBy"
                    value={mcompanyData.address} // Ensure that mcompanyData.addressBy has the addressBy value
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    State
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    id="stateBy"
                    name="stateBy" // Add the name attribute for stateByBy field
                    value={mcompanyData.state} // Ensure that mcompanyData.stateBy has the stateBy value
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Website
                  </span>
                  <input
                    className="  flex-1"
                    name="gstin"
                    value={mcompanyData.website}
                    id="gstin"
                  />
                </div>

                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Contact
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="contactBy"
                    id="contactBy"
                    value={mcompanyData.contactNo}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    GSTIN
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="gstInBy"
                    id="gstInBy"
                    value={mcompanyData.gstIn}
                  />
                </div>

                <div className="col-md-5">
                  <div className="flex flex-col md:flex-row ">
                    <label htmlFor="salespersonBy" className="md:w-1/3">
                      Sales Person
                    </label>
                    <select
                      id="salespersonBy"
                      className="border border-gray-300 flex-1"
                      name="salespersonBy"
                      // value={selectedEmployee}
                    >
                      <option value="">Select Employee</option>
                      {staffData.map((staff) => (
                        <option key={staff._id} value={staff.name}>
                          {staff.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Quation To -</h2>
              <div className="space-y-4">
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Date
                  </span>
                  <input
                    type="date"
                    className="border border-gray-300 flex-1"
                    name="date"
                    id="date"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Quotation No
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="qutationNo"
                    id="qutationNo"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Name
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="name"
                    id="name"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Address
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="addressTo"
                    id="addressTo"
                  />
                </div>
                <div className="flex flex-col md:flex-row">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    State
                  </span>
                  <select
                    className="border border-gray-300 flex-1"
                    name="stateTo"
                    id="stateTo"
                  >
                    {indianStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    Contact
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="contactTo"
                    id="contactTo"
                  />
                </div>
                <div className="flex flex-col md:flex-row ">
                  <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                    GSTIN
                  </span>
                  <input
                    className="border border-gray-300 flex-1"
                    name="gstinTo"
                    id="gstinTo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
          <table className="min-w-full border">
            <thead className="border-b border-gray-300 text-gray-900">
              <tr className="text-left border shadow-md bg-gray-200">
                <th scope="col" className="ml-1 p-2 border">
                  S.No.
                </th>
                <th scope="col" className="ml-1 p-2 w-1/3 border">
                  Description
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  HSN
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Qty
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  UOM
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Rate
                </th>
                <th scope="col" className="ml-1 p-2 border">
                  Amount
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={row.id} className="border bg-gray-100">
                  <td className="border p-2">{index + 1}</td>
                  <td className="border p-2">
                    <textarea
                      className="w-full overflow-hidden resize-none"
                      value={row.description}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].description = e.target.value;
                        setrows(updatedRows);
                        autoResizeTextarea(e);
                      }}
                      rows={1} // Starts with 1 row
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.hsn}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].hsn = e.target.value;
                        setrows(updatedRows);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.qty}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].qty = e.target.value;
                        setrows(updatedRows);
                        calculateAmount(index);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.uom}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].uom = e.target.value;
                        setrows(updatedRows);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input
                      className="w-full"
                      value={row.rate}
                      onChange={(e) => {
                        const updatedRows = [...rows];
                        updatedRows[index].rate = e.target.value;
                        setrows(updatedRows);
                        calculateAmount(index);
                      }}
                    />
                  </td>
                  <td className="border p-2">
                    <input className="w-full" value={row.amount} readOnly />
                  </td>
                  <td className="border text-center flex justify-center">
                    <button
                      className="bg-green-500 pl-3 pr-3 pt-1 m-1 pb-1 rounded text-black mr-2"
                      onClick={handleAddRow}
                    >
                      +
                    </button>
                    <button
                      className="bg-red-500 pl-3 pr-3 pt-1 pb-1 m-1 rounded text-black"
                      onClick={() => handleDeleteRow(row.id)}
                    >
                      -
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Subtotal & Net Amount */}
        <div className="flex justify-center mt-3">
          <button
            style={{ backgroundColor: "#34D399", borderRadius: "0.375rem" }}
            className="text-black mr-2"
          >
            Submit
          </button>
        </div>
        <div className="mt-1 flex justify-end">
          <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
            <table className="w-full border">
              <tfoot>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-1 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Subtotal
                  </th>
                  <td>
                    <input
                      type="text"
                      name="subtotal"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={`₹ ${subtotal.toFixed(2)}`}
                      readOnly
                    />
                  </td>
                </tr>
                <tr className="bg-gray-200">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Discount ( if any)
                  </th>
                  <td>
                    <input
                      type="number"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={discount}
                      name="discount"
                      onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Net Amount
                  </th>
                  <td>
                    <input
                      type="text"
                      name="netAmount"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={`₹ ${netAmount.toFixed(2)}`}
                      readOnly
                    />
                  </td>
                </tr>
                <tr className="bg-gray-100">
                  <th
                    scope="row"
                    className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                  >
                    Amount in words
                  </th>
                  <td>
                    <textarea
                    rows={3}
                      type="text"
                      name="netAmountWords"
                      className="px-4 py-1 font-bold text-sm text-gray-500 border border-gray-300 rounded-md"
                      value={netAmountWords}
                      readOnly
                    />
                    only
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
export default Quation;