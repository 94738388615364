import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const CreateNotice = () => {
  const [submitError, setSubmitError] = useState(null);
  const [formData, setFormData] = useState({
    team: "",
    name: "",
    department: "",
    designation: "",
    messageType: "",
    date: "",
    message: "",
  });
  const [dept, setDept] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState("");
  const [teams, setTeams] = useState([]);
  const [staff, setStaff] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);

  const getAllStaff = async () => {
    try {
      const data = await axios.get("/api/v1/staff/getAllStaff");
      setStaff(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllDepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");
      setDept(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/v1/team/getAll/");
        setTeams(response.data.allTeam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getAllDepart();
    getAllStaff();
    fetchData();
  }, []);

  const handleDepartmentChange = (e) => {
    const selectedDepartment = e.target.value;
    setSelectedDepart(selectedDepartment);
    setFormData({
      ...formData,
      department: selectedDepartment,
      team: "",
      name: "",
      designation: "",
    });
    setFilteredStaff([]);
  };

  const handleTeamChange = (e) => {
    const selectedTeam = e.target.value;
    setFormData({
      ...formData,
      team: selectedTeam,
      name: "",
    });
    const teamStaff = staff.filter((staff) => staff.team === selectedTeam);
    setFilteredStaff(teamStaff);
  };

 

  const handleStaffChange = (e) => {
    const selectedStaffId = e.target.value;
    const selectedStaff = staff.find((staff) => staff._id === selectedStaffId);
    if (selectedStaff) {
      setSelectedDepart(selectedStaff.department);
      setFormData({
        ...formData,
        name: selectedStaffId,
        department: selectedStaff.department,
        designation: selectedStaff.designation,
      });
    }
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/v1/notice/createnotice",
        formData
      );
      if(!response.data.success){
        toast.error(response.data.message);
      }
      if(response.data.success){
        toast.success(response.data.message);
      }
    
      setFormData({
        ...formData,
        team: "",
        name: "",
        department: "",
        designation: "",
        messageType: "",
        date: "",
        message: "",
      });
      setSubmitError(null);
    } catch (error) {
      console.error("Error while submitting form:", error);
      setSubmitError("An error occurred while submitting the form.");
    }
  };

const autoResizeTextarea = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form
        className="max-w-5xl mx-auto rounded-lg shadow-md md:pl-16 md:pr-16"
        onSubmit={handleSubmit}
      >
        <h1 className="text-3xl bold underline text-center mb-4">
          Notice & Important
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <select
              id="department"
              name="department"
              value={selectedDepart}
              onChange={handleDepartmentChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            >
              <option value="">Select Department</option>
              {dept.map((department) => (
                <option key={department._id} value={department._id}>
                  {department.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="team" className="block font-bold mb-1">
              Team
            </label>
            <select
              id="team"
              name="team"
              value={formData.team}
              onChange={handleTeamChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            >
              <option value="">Select Team</option>
              {teams
                .filter((team) => team.departId === selectedDepart)
                .map((team) => (
                  <option key={team._id} value={team._id}>
                    {team.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="block font-bold mb-1">Staff Name</label>
            <select
              id="name"
              name="name"
              className="w-full px-4 py-2 border text-black rounded-md"
              onChange={handleStaffChange}
              value={formData.name}
            >
              <option value="">Select Staff Name</option>
              {filteredStaff.map((staff, index) => (
                <option key={index} value={staff._id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label className="block font-bold mb-1">Designation</label>
            <input
              type="text"
              id="designation"
              name="designation"
              className="w-full px-4 py-2 border text-black rounded-md"
              value={formData.designation}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label className="block font-bold mb-1">Message Type</label>
            <select
              id="messageType"
              name="messageType"
              className="w-full px-4 py-2 border text-black rounded-md"
              onChange={handleChange}
              value={formData.messageType}
            >
              <option value="">Select Message Type</option>
              <option value="notice">Notice</option>
              <option value="important">Important</option>
            </select>
          </div>
          <div className="mb-3">
            <label className="block font-bold mb-1">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              className="w-full px-4 py-2 text-black border rounded-md"
              onChange={handleChange}
              value={formData.date}
            />
          </div>
          <div className="mb-3 col-span-full">
            <label className="block font-bold mb-1">Message</label>
            <textarea
              id="message"
              name="message"
              className="w-full px-4 py-4 text-black border rounded-md"
              onChange={handleChange}
              value={formData.message}
            ></textarea>
          </div>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-8"
          >
            Create
          </button>
          <button
            type="button"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-8"
          >
            Cancel
          </button>
        </div>
        {submitError && <p className="text-red-500">{submitError}</p>}
      </form>
      <Toaster />
    </div>
  );
};

export default CreateNotice;
