import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import profile from "../../assets/images/Profile copy.jpg";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";

function Addstaff() {
  const initialFormData = {
    team: "",
    id: "",
    name: "",
    gender: "",
    dob: "",
    email: "",
    contact: "",
    paddress: "",
    caddress: "",
    Anniversarydate: "",
    dateOfJoining: "",
    designation: "",
    department: "",
    contactPerson: "",
    relationship: "",
    contactNumber: "",
    documentsType: "",
    documentNumber: "",
    photo: null,
  };

  const [dept,setDept]=useState([])
  const [selectedDepart,setSelectedDepart]=useState("");

  const [formData, setFormData] = useState(initialFormData);
  const photoInputRef = useRef(null);

  const handleChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      photo: file,
    }));
  };

  const handleSelectPhoto = (e) => {
    e.preventDefault();
    photoInputRef.current.click();
  };

  const [teams, setTeams] = useState([]);

const getAlldepart=async()=>{

  try {
    const data= await axios.get("/api/v1/depart/getalldepart");
    setDept(data.data.data)
    
    
  } catch (error) {
    console.log(error)
    
  }
}

// start filter
const filterdTeam=teams.filter(
(team)=>team.departId===selectedDepart
);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "/api/v1/team/getAll/"
        );

        setTeams(response.data.allTeam);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getAlldepart();
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (let key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("department", selectedDepart);
      // formDataToSend.append("photo", formData.photo);

      const response = await axios.post(
        "/api/v1/user/staffRegister",
        formDataToSend
      );
      
     
      if (response.data.success) {
        toast.success(response.data.message);
        handleReset();
      } else {
        toast.error(response.data.message);
      }

      //setFormData(initialFormData);
    } catch (error) {
      console.error("Error occurred:", error);
     
    }
  }; 
  
   
  const handleReset = () => {
    setFormData(initialFormData); 
  };
  return (
    <div className="addstaff-container text-black p-10 px-4 py-8">
      <form
       
        className="max-w-12xl mx-auto rounded-lg shadow-md md:pl-28 md:pr-28"
      >
        <div className="flex justify-between items-center mb-8">
          <div className="col-span-7 sm:col-span-2 md:col-span-2 lg:col-span-5 flex mb-8">
            <h1 className="text-3xl font-bold underline">Add Staff</h1>
          </div>
          <div className="col-span-7 sm:col-span-4 md:col-span-2 lg:col-span-2 ml-2 mb-5 sm:mr-3">
            <input
              type="file"
              className="hidden text-black"
              ref={photoInputRef}
              onChange={handlePhotoChange}
            />
            <div className="text-right">
              <div
                className="mt-2"
                style={{ display: formData.photo && "block" }}
              >
                <button
                  className="block w-24 h-24 rounded-full border-4"
                  onClick={handleSelectPhoto}
                  style={{
                    backgroundImage: `url('${
                      formData.photo
                        ? URL.createObjectURL(formData.photo)
                        : profile
                    }')`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                  }}
                ></button>
              </div>
            </div>
          </div>
        </div>

        <label
          htmlFor="companyName"
          className="block font-bold mb-3 text-xl underline"
        >
          Personal Details
        </label>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="mb-4 ">
            <label htmlFor="id" className="block font-bold mb-1">
               id
            </label>
            <input
              type="text"
              id="id"
              name="id"
              value={formData.id}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="name" className="block font-bold mb-1">
             name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.staffname}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="gender" className="block font-bold mb-1">
              Gender
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="dob" className="block font-bold mb-1">
              DOB
            </label>
            <input
              type="date"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="block font-bold mb-1">
              E-mail
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="contact" className="block font-bold mb-1">
              Contact
            </label>
            <input
              type="text"
              id="contact"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="paddress" className="block font-bold mb-1">
              Permanent address
            </label>
            <input
              type="text"
              id="paddress"
              name="paddress"
              value={formData.paddress}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="caddress" className="block font-bold mb-1">
              Correspondence address
            </label>
            <input
              type="text"
              id="caddress"
              name="caddress"
              value={formData.caddress}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="Anniversarydate" className="block font-bold mb-1">
              Anniversary Date
            </label>
            <input
              type="date"
              id="Anniversarydate"
              name="Anniversarydate"
              value={formData.Anniversarydate}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
        </div>
        <label
          htmlFor="dateOfJoining"
          className="block font-bold mb-3 underline text-xl"
        >
          Official information
        </label>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="mb-4">
            <label htmlFor="dateOfJoining" className="block font-bold mb-1">
              Date of Joining
            </label>
            <input
              type="date"
              id="dateOfJoining"
              name="dateOfJoining"
              value={formData.dateOfJoining}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="designation" className="block font-bold mb-1">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              value={formData.designation}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
         
          <div className="mb-4">
          <label htmlFor="department" className="block font-bold mb-1">
          Department
        </label>
              <select
          id="department"
          name="department"
          // value={formData.department}
          value={selectedDepart}
          onChange={(e) => setSelectedDepart(e.target.value)}
          className="w-full px-4 py-2 text-black border rounded-md"
        >
          <option value="">Select Department</option>
          {dept.map((department, index) => (
            <option key={index} value={department._id}  >
              {department.name}
            </option>
          ))}
        </select>
      </div>
          <div className="mb-4">
            <label htmlFor="team" className="block  text-black font-bold mb-1">
              Team
            </label>
            <select
              id="team"
              name="team"
              value={formData.team}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black-900 border rounded-md"
            >
              <option value="">Select Team</option>
              {filterdTeam.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <label
          htmlFor="emergencyDetails"
          className=" font-bold mb-3 underline text-xl"
        >
          Emergency Details
        </label>
        <div className="mb-4 grid lg:grid-cols-3 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="contactPerson" className="block font-bold mb-1">
              Contact Person
            </label>
            <input
              type="text"
              id="contactPerson"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md mb-2"
            />
          </div>
          <div>
            <label htmlFor="relationship" className="block font-bold mb-1">
              Relationship
            </label>
            <select
              id="relationship"
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
              className="w-full px-4 py-2 border text-black rounded-md mb-2"
            >
              <option value="">Select Relationship</option>
              <option value="spouse">Spouse</option>
              <option value="parent">Parent</option>
              <option value="child">Child</option>
              <option value="sibling">Sibling</option>
              <option value="friend">Friend</option>
            </select>
          </div>

          <div>
            {" "}
            <label htmlFor="contactNumber" className="block font-bold mb-1">
              Contact Number
            </label>
            <input
              type="text"
              id="contactNumber"
              name="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
        </div>
        <label
          htmlFor="identityDetails"
          className="block font-bold mb-3 underline text-xl"
        >
          Identity Details
        </label>
        <div className="mb-4 grid md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2 gap-4">
          <div className="mb-4">
            <label htmlFor="documentsType" className="block font-bold mb-1">
              Documents Type
            </label>
            <select
              id="documentsType"
              name="documentsType"
              value={formData.documentsType}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md mb-2"
            >
              <option value="">Select Document Type</option>
              <option value="passport">Passport</option>
              <option value="driverLicense">Driver's License</option>
              <option value="idCard">Voter ID Card</option>
              <option value="adhar">AdharCard</option>
            </select>
          </div>

          <div>
            <label htmlFor="documentNumber" className="block font-bold mb-1">
              Document Number
            </label>
            <input
              type="text"
              id="documentNumber"
              name="documentNumber"
              value={formData.documentNumber}
              onChange={handleChange}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
        </div>

        <div className="flex justify-between">
          <button
          
          onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-10"
          >
            Create
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-black py-2 px-8 rounded mb-10"
            onClick={handleReset}
          >
            Cancel
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
export default Addstaff;
