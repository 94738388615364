import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageAdvanceSalary = () => {
  const [manageAdvanceSalaryData, setManageAdvanceSalaryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teamName, setTeamName] = useState({});
  const [departmentName, setDepartmentName] = useState({});

  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "/api/v1/advancesalary/getAllAdvanceSalary"
      );
      const { data } = response;
      const updatedData = await Promise.all(
        data.advanceSalaryData.map(async (item) => {
          const staffData = await staffDetails(item.staffid);
          return { ...item, staffData };
        })
      );
      setManageAdvanceSalaryData(updatedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching advance salary data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const staffDetails = async (staffid) => {
    try {
      const response = await axios.get(
        `/api/v1/staff/getSingleUserById/${staffid}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching employee details:", error);
      return {}; // Return an empty object or handle the error appropriately
    }
  };

  useEffect(() => {
    const fetchTeamAndDeptNames = async () => {
      try {
        const responseDept = await axios.get("/api/v1/depart/getalldepart");
        const deptNamesData = {};
        responseDept.data.data.forEach((dept) => {
          deptNamesData[dept._id] = dept.name;
        });
        setDepartmentName(deptNamesData);
        const responseTeam = await axios.get("/api/v1/team/getAll/");
        const teamNamesData = {};
        responseTeam.data.allTeam.forEach((team) => {
          teamNamesData[team._id] = team.name;
        });
        setTeamName(teamNamesData);
      } catch (error) {
        console.error("Error fetching team and department names:", error);
      }
    };
    fetchTeamAndDeptNames();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  /// save and reset 0
  const saveAndReset = async () => {
    try {
      const res = await axios.post("/api/v1/record/advance-create");
      fetchData();
    } catch (error) {}
  };

  return (
    <div className="addstaff-container p-4 text-center">
      <h1 className="text-3xl font-bold mb-4 text-center underline">
        Advance Salary
      </h1>
      <div className="flex justify-end mb-2">
        <button onClick={saveAndReset}>save & reset </button>
      </div>
      <div className=" mb-1 flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
        <input
          className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.startDate}
          onChange={(e) => setSearch({ ...search, startDate: e.target.value })}
          placeholder="enter start date"
        />

        <input
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="date"
          value={search.endDate}
          onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
          placeholder="enter end date"
        />
      </div>
      {!loading && (
        <div className="overflow-x-auto">
          <table className="border-collapse border border-gray-500 w-full">
            <thead>
              <tr className="bg-gray-800 text-white">
                <th className="px-4 py-2 whitespace-nowrap">Sr. No.</th>
                <th className="px-4 py-2 whitespace-nowrap">Date</th>
                <th className="px-4 py-2 whitespace-nowrap">Month</th>
                <th className="px-4 py-2 whitespace-nowrap">Employee Name</th>
                <th className="px-4 py-2 whitespace-nowrap">Employee ID</th>
                <th className="px-4 py-2 whitespace-nowrap">Team</th>
                <th className="px-4 py-2 whitespace-nowrap">Department</th>
                <th className="px-4 py-2 whitespace-nowrap">Designation</th>
                <th className="px-4 py-2 whitespace-nowrap">Reason</th>
                <th className="px-4 py-2 whitespace-nowrap">Amount</th>
              </tr>
            </thead>
            <tbody>
              {manageAdvanceSalaryData
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((item, index) => (
                  <tr key={item._id} className="text-center">
                    <td className="border px-4 py-2">{index + 1}</td>
                    <td className="border px-4 py-2 whitespace-nowrap">
                      {formatDate(item.date)}
                    </td>
                    <td className="border px-4 py-2">{item.month}</td>
                    <td className="border px-4 py-2 whitespace-nowrap">
                      {item.staffData
                        ? item.staffData.name
                        : "Data Unavailable"}
                    </td>
                    <td className="border px-4 py-2">
                      {item.staffData ? item.staffData.id : "Data Unavailable"}
                    </td>
                    <td className="border px-4 py-2">
                      {teamName[item.staffData?.team] || "Data Unavailable"}
                    </td>
                    <td className="border px-4 py-2">
                      {departmentName[item.staffData?.department] ||
                        "Data Unavailable"}
                    </td>
                    <td className="border px-4 py-2">
                      {item.staffData
                        ? item.staffData.designation
                        : "Data Unavailable"}
                    </td>
                    <td className="border px-4 py-2">
                      {item.resonforadvancesalary}
                    </td>
                    <td className="border px-4 py-2">
                      {item.amountofadvancesalary}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ManageAdvanceSalary;
