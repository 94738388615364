

import React, { useState, useEffect } from "react";
import { Button, Card, Form, Input, Typography, Table, Col, Row } from "antd";
import axios from "axios";
import { useAuth } from "../../components/context/auth";
import toast, { Toaster } from "react-hot-toast";

const DailyTask = () => {
  const [auth] = useAuth();
  const [form] = Form.useForm();
  const [submittedData, setSubmittedData] = useState([]);
  const [depart, setDepartment] = useState([]);
  const [_id, set_id] = useState("");

  const [allTask, setAllTask] = useState([]);
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Task", dataIndex: "task", key: "task" },
  ];

  const getdName = (did) => {
    const data = depart.filter((item) => item._id === did);
    return data[0]?.name;
  };

  const getAllTask = async (_id) => {
    try {
      const res = await axios.get(`/api/v1/dailyTask/getSingle/${_id}`);
      if (res.data.success) {
        const formattedTasks = res.data.data.map((task) => ({
          ...task,
          date: new Date(task.date).toLocaleDateString("en-GB"),
        }));
        setAllTask(formattedTasks);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const detpartment = async () => {
    if (auth.duser.department) {
      try {
        const response = await axios.get("/api/v1/depart/getalldepart");
        setDepartment(response.data.data);
      } catch (error) {
        console.error("Error fetching department:", error);
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      values.staff_id = _id;
      // Set team and department values before submitting
      values.team = auth.duser.team;
      values.department = auth.duser.department;
      const response = await axios.post(
        "/api/v1/dailyTask/create",
        values
      );
      getAllTask(_id);
      if (!response.data.success) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        setSubmittedData([...submittedData, response.data.data]);
        form.setFieldsValue({ task: "" });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };



  useEffect(() => {
    if (auth && auth.duser) {
      const { name, id, designation, team, department, _id } = auth.duser;
      set_id(_id);

      form.setFieldsValue({
        name,
        id,
        staff_id: _id,
        team: team,
        designation,
        department: department,
      });
    }
  }, [auth, form]);

  useEffect(() => {
    if (_id) {
      getAllTask(_id);
    }
  }, [_id]);

  useEffect(() => {
    form.setFieldsValue({ date: getCurrentDate() });
  }, [form]);

  return (
    <div className="center addstaff-container mt-5">
      <Row justify="center">
        <Col xs={24} sm={22} md={20} lg={18} xl={16} xxl={14}>
          <Typography.Title level={2} style={{ textAlign: "center" }}>
            DailyTask
          </Typography.Title>
          <div
            style={{
              border: "1px solid blue",
              borderRadius: "20px",
              boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              margin: "10px",
            }}
          >
            <Form
              form={form}
              name="daily_task_form"
              onFinish={handleSubmit}
              initialValues={{
                date: "",
              }}
              layout="vertical"
            >
              <Form.Item label="Date" name="date">
                <Input type="date" readOnly />
              </Form.Item>

              {/* Hidden fields for team and department */}
              <Form.Item label="Team" name="team" hidden>
                <Input />
              </Form.Item>

              <Form.Item label="Department" name="department" hidden>
                <Input />
              </Form.Item>

              <Form.Item label="Staff Name" name="name">
                <Input style={{ marginBottom: "10px" }} readOnly />
              </Form.Item>

              <Form.Item label="Staff Id" name="id">
                <Input style={{ marginBottom: "10px" }} readOnly />
              </Form.Item>

              <Form.Item label="Designation" name="designation">
                <Input style={{ marginBottom: "10px" }} readOnly />
              </Form.Item>

              <Form.Item label="Task" name="task">
                <Input.TextArea rows={4} style={{ marginBottom: "10px" }} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "green",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      </Row>

      <Row
        justify="center"
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <Col xs={24} sm={22} md={20} lg={18} xl={16} xxl={14}>
          <div
            style={{
              border: "1px solid purple",
              borderRadius: "20px",
              overflowX: "auto",
              boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Card title="All Task" style={{ textAlign: "center" }}>
              <Table columns={columns} dataSource={allTask} />
            </Card>
          </div>
        </Col>
      </Row>
      <Toaster />
    </div>
  );
};

export default DailyTask;
