

import React, { useEffect, useState } from "react";
import axios from "axios";

const NoticeModal = ({ isOpen, onClose }) => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        const response = await axios.get("/api/v1/notice/getallnotice");
        setNotices(response.data.data); // Assuming the response contains an array of notices
      } catch (error) {
        console.error("Error fetching notices:", error);
      }
    };

    if (isOpen) {
      fetchNotices();
    }
  }, [isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-end items-start ${
        isOpen ? "" : "hidden"
      }`}
    >
      <div className="absolute top-0 right-0 bg-gray-500 opacity-75 h-full w-full"></div>
      <div className="z-50 w-full md:w-1/4 bg-gray-500 text-white p-4 overflow-auto">
        <button className="text-white absolute top-1 right-2" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="h-screen overflow-y-auto"> {/* Set a fixed height and enable vertical scrolling */}
          {notices.map((notice, index) => (
            <div key={index} className="text-black text-sm font-fantasy mt-3 text-justify p-3 bg-white rounded mr-1 shadow-2xl">
              {/* Render your notice content here */}
              {notice.message}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NoticeModal;

