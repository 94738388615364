import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";

const WorkingEditModal = ({ WorkingData, closeModal }) => {
  const [formData, setFormData] = useState(WorkingData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {


    try {
      const response = await axios.put(
        `/api/v1/working/updateworking/${formData._id}`,
        formData // Send only the updated fields for update
      );

      if (response.data.success) {
        toast.success("Working updated successfully...");
        closeModal();
      } else {
        console.error("Failed to update Working");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto md:pl-4 md:pr-4 p-2 responsive-container text-black">
      <button
        className="absolute top-2 right-2 p-2 text-gray-700 text-xl hover:text-gray-900 focus:outline-none md:text-2xl md:top-4 md:right-4 border"
        onClick={closeModal}
      >
        <FaTimes />
      </button>
      <h4 className="text-3xl font-semibold mb-4 text-center underline mb-6 text-violet-800 mt-8">
        Working
      </h4>

      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4 mt-6">
          <div>
            <label className="block mb-2">
              Date:
              <input
                type="text"
                name="date"
                value={formData.date}
                readOnly
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Bid Link:
              <input
                type="text"
                name="bidLink"
                value={formData.bidLink}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Profile:
              <input
                type="text"
                name="profile"
                value={formData.profile}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Platform:
              <input
                type="text"
                name="platform"
                value={formData.platform}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Project Title:
              <input
                type="text"
                name="projectTitle"
                value={formData.projectTitle}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Country:
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Budget:
              <input
                type="text"
                name="budget"
                value={formData.budget}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Bid Placed By:
              <input
                type="text"
                name="bidPlacedBy"
                value={formData.bidPlacedBy}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Status:
              <input
                type="text"
                name="status"
                value={formData.status}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Remark:
              <input
                type="text"
                name="remark"
                value={formData.remark}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
          <div>
            <label className="block mb-2">
              Technology:
              <input
                type="text"
                name="technology"
                value={formData.technology}
                onChange={handleChange}
                className="flex-1 pl-4 border-b-2"
              />
            </label>
          </div>
        </div>

        <div className="flex justify-between mt-4">
          <button
            onClick={handleUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-md focus:ring-2 focus:ring-violet-600"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkingEditModal;
