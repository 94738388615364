import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import LeadModal from "./modals/LeadModal";

const ManageLeads = () => {
  const [leadsdata, setLeadsData] = useState([]);

  const [filterMonth, setFilterMonth] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [staff, setStaff] = useState([]);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedlead, setSelectedLead] = useState([]);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        //for lead
        const allleads = await axios.get("/api/v1/lead/all-leads");
        setLeadsData(allleads.data.data);
        //for staff
        const allusers = await axios.get("/api/v1/staff/getAllStaff");

        setStaff(allusers.data.data);
        //for salary deduct
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //   /// for getting staff name
  const getSaffName = (id) => {
    const staffn = staff?.filter((item) => item.id === id);
    return staffn[0]?.name;
  };
  //
  const getDesignation = (id) => {
    const staffn = staff?.filter((item) => item.id === id);
    return staffn[0]?.designation;
  };

  const handleView = (item) => {
    setSelectedLead(item);
    setViewModalVisible(true);
  };

  const handleCloseModal = () => {
    setSelectedLead(null);
    setViewModalVisible(false);
  };

  return (
    <div className="salaryRecord-container">
      <h2 className="text-3xl font-bold text-center text-gray-400 underline">
        Manage Leads
      </h2>

      <div className="flex flex-col text-1xl p-5 font-bold  sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3  text-gray-600">
        <div className="sm:flex sm:flex-col-2 items-stretch justify-end flex-shrink-0 w-full md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
            <input
              className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.startDate}
              onChange={(e) =>
                setSearch({ ...search, startDate: e.target.value })
              }
              placeholder="enter start date"
            />

            <input
              className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.endDate}
              onChange={(e) =>
                setSearch({ ...search, endDate: e.target.value })
              }
              placeholder="enter end date"
            />
          </div>
        </div>{" "}
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg ">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-3">
                Date
              </th>
              <th scope="col" className="px-2 py-3">
                Staff Name
              </th>
              <th scope="col" className="px-2 py-3">
                Designation
              </th>
              <th scope="col" className="px-4 py-3">
                Staff id
              </th>

              <th scope="col" className="px-4 py-3">
                Business Name
              </th>
              <th scope="col" className="px-4 py-3">
                Address
              </th>
              <th scope="col" className="px-4 py-3">
                Contact
              </th>
              <th scope="col" className="px-4 py-3">
                Lead Type
              </th>
              <th scope="col" className="px-4 py-3">
                Service Name
              </th>
              <th scope="col" className="px-4 py-3">
                Deal Type
              </th>
              <th scope="col" className="px-4 py-3">
                Offer Price
              </th>
              <th scope="col" className="px-4 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {leadsdata &&
              leadsdata
                .filter((deduction) => {
                  if (!search.startDate || !search.endDate) {
                    return true;
                  } else {
                    const startDate = new Date(search.startDate);
                    const endDate = new Date(search.endDate);
                    const recordDate = new Date(deduction.date);
                    return recordDate >= startDate && recordDate <= endDate;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white mr-8 border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="pl-4 py-4">
                        {new Date(item.date)
                          .toLocaleDateString("en-GB")
                          .split("/")
                          .reverse()
                          .join("/")}
                      </td>
                      <td className="pl-4 py-4">{getSaffName(item.id)}</td>
                      <td className="pl-4 py-4">{getDesignation(item.id)}</td>
                      <td className="pl-4 py-4">{item.id}</td>

                      <td className="pl-4 py-4">{item.businessName}</td>
                      <td className="pl-4 py-4">{item.businessAddress}</td>
                      <td className="pl-4 py-4">{item.contactPersonNumber}</td>
                      <td className="pl-4 py-4">{item.leadType}</td>
                      <td className="pl-4 py-4">{item.serviceName}</td>
                      <td className="pl-4 py-4">{item.dealType}</td>
                      <td className="pl-4 py-4">{item.offerPrice}</td>
                      <td className="pl-2 py-4">
                        <Link
                          onClick={() => handleView(item)}
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        >
                          View/Download
                        </Link>{" "}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>

        <Modal
          isOpen={viewModalVisible}
          onRequestClose={handleCloseModal}
          contentLabel="View Item Modal"
          style={{
            content: {
              width: "60%",
              height: "77%",
              maxWidth: "800px",
              margin: "auto",
              padding: "5px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "5px",
            },
          }}
        >
          {/* Pass selectedlead object directly */}
          {selectedlead && (
            <LeadModal lead={selectedlead} onClose={handleCloseModal} />
          )}
        </Modal>
      </div>
    </div>
  );
};
export default ManageLeads;
