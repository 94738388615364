import React, { useState } from 'react';
import axios from 'axios';

function MessageBox() {
    const [messages, setMessages] = useState(['']);

    const handleMessageChange = (e, index) => {
        const updatedMessages = [...messages];
        updatedMessages[index] = e.target.value;
        setMessages(updatedMessages);
    };

    const handleSubmit = async (index) => {
        try {
            const response = await axios.post('/api/v1/cms', {
                message: messages[index]
            });

            // Optionally, provide feedback to the user
            alert('Message submitted successfully');

            // Clear the message after successful submission
            const updatedMessages = [...messages];
            updatedMessages[index] = '';
            setMessages(updatedMessages);
        } catch (error) {
            console.error('Error submitting message:', error);
            // Optionally, provide error feedback to the user
            alert('Error submitting message');
        }
    };

    const handleAddMoreClick = () => {
        setMessages([...messages, '']);
    };

    return (
        <div className="addstaff-container max-w-screen-xl px-4 mx-auto lg:px-12 w-full my-4">
            <div className="relative bg-gray-700 dark:bg-gray-800">
                <div className="flex flex-col items-center justify-center p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                    <div className="w-full md:w-1/2 text-center text-gray-100 font-bold text-lg ">
                        Proposal
                    </div>
                </div>
            </div>
            <div className="flex flex-col bg-gray-200">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 justify-between p-8 space-y-3">
                            {messages.map((msg, index) => (
                                <form key={index} onSubmit={(e) => { e.preventDefault(); handleSubmit(index); }}>
                                    <div className="min-w-full mx-auto grid grid-cols-1 h-full gap-4 justify-center">
                                        <textarea
                                            value={msg}
                                            onChange={(e) => handleMessageChange(e, index)}
                                            className="sm:p-20 sm:min-w-full h-custom"
                                            placeholder="Write your message..."
                                            style={{ height: "420px" }}
                                        />
                                    </div>
                                    <div className="modal-footer text-center mt-3">
                                        <button type="submit" className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2">Submit</button>
                                    </div>
                                </form>
                            ))}
                            <div className="modal-footer text-center mt-3">
                                <button type="button" className="bg-gray-800 text-white py-2 px-4 rounded-md hover:bg-gray-500 mr-2" onClick={handleAddMoreClick}> + Add Page</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageBox;
