import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../components/context/auth";
import { ToastContainer, toast } from "react-toastify";
const ManageSalarySlip = () => {
  const [salaryData, setSalaryData] = useState([]);
  const [auth] = useAuth();
  const [selectedQuotation, setSelectedQuotation] = useState(null);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`
          /api/v1/salaryslip/getsalarybyid/${auth.duser.id}`);
        setSalaryData(response.data.readSalaryData);
      } catch (error) {
        console.error("Error fetching salary data:", error);
      }
    };

    fetchData();
  }, []);

  const openModal = (quotation) => {
    setSelectedQuotation(quotation);
  };

  // Function to close modal
  const closeModal = () => {
    setSelectedQuotation(null);
  };

  if (!Array.isArray(salaryData)) {
    return <div>Loading...</div>;
  }

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="main-container ">
      <h2 className="text-3xl text-black font-bold mb-8 text-center underline">
        Manage Salary Slip
      </h2>

      <div className="flex flex-col text-1xl p-5 font-bold  sm:grid grid-cols-2 md:grid-cols-1 lg:grid-cols-3  text-gray-600 ">
        <div className="sm:flex sm:flex-col-2 items-stretch justify-end flex-shrink-0 w-full md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
          <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
            <input
              className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.startDate}
              onChange={(e) =>
                setSearch({ ...search, startDate: e.target.value })
              }
              placeholder="enter start date"
            />

            <input
              className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              value={search.endDate}
              onChange={(e) =>
                setSearch({ ...search, endDate: e.target.value })
              }
              placeholder="enter end date"
            />
          </div>
        </div>{" "}
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className=" text-center text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Employee name
              </th>
              <th scope="col" className="px-6 py-3">
                Employee id
              </th>
              <th scope="col" className="px-6 py-3">
                Designation
              </th>
              <th scope="col" className="px-6 py-3">
                Department
              </th>
              {/* Add more fields as needed */}
              <th scope="col" className="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {salaryData
              .filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              })

              .map((manageQuationData, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    {new Date(manageQuationData.date).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td className="px-6 py-4">
                    {manageQuationData.EmployeeName}
                  </td>
                  <td className="px-6 py-4">{manageQuationData.id}</td>

                  <td className="px-6 py-4">{manageQuationData.department}</td>
                  <td className="px-6 py-4">{manageQuationData.designation}</td>

                  <td className=" flex gap-2">
                    <button
                      onClick={() => openModal(manageQuationData)}
                      className=" font-medium text-blue-600 dark:text-blue-500 hover:underline p-4"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
      {selectedQuotation && (
        <div
          style={{ backgroundColor: "white" }}
          className="fixed inset-0 z-10 addstaff-container p-5 print "
        >
          <style>
            {`
           @media print {
            .hide-on-print {
              display: block;
            }
            /* Hide unnecessary elements when printing */
            body * {
              visibility: hidden;
   
            }
            .print,
            .print * {
              visibility: visible;
            }
            .print {
              position: absolute;
              left: 0;
              top: 0;
            }
          
            /* Adjust page size and margins for A4 paper */
            @page {
              size: A4;
              margin: 0;
            }
          
            /* Ensure content fits within the A4 page size */
            html, body {
              width: 250mm;
            }
            .hide-on-print {
              display: none;
            }
          }
        `}
          </style>
          <h1 className="text-center text-3xl underline mb-4">
            Manasvi Technologies (OPC) Private Limited
          </h1>
          <h1 className="mb-4 text-center underline text-xl">
            CIN : U52342MP2023OPC064272
          </h1>
          <form>
            <div className="mb-3 flex justify-center">
              <span>Salary slip for the Month</span>
              <input
                id="month"
                name="salarySlipMonth"
                className=" border-none rounded-md"
                style={{ textAlignLast: "center", width: "100px" }}
                value={selectedQuotation.salarySlipMonth}
              ></input>

              <input
                id="year"
                name="year"
                className=" border-none rounded-md"
                style={{ width: "100px" }}
                value={selectedQuotation.year}
              ></input>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-2 gap-4 md:gap-8">
              <div className="border border-gray-200 p-2 lg:p-2 rounded-lg mt-4">
                <div className="space-y-4">
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Company Address
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="companyAddress"
                      value={selectedQuotation.companyAddress}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Company Contact
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="companyContact"
                      value={selectedQuotation.companyContact}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Company Email
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="companyEmail"
                      value={selectedQuotation.companyEmail}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Company Website
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="companyWebsite"
                      value={selectedQuotation.companyWebsite}
                    />
                  </div>
                </div>
              </div>

              <div className="border border-gray-200 p-2 lg:p-2 rounded-lg mt-4">
                <div className="space-y-4">
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Employee Name
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      id="EmployeeName"
                      name="EmployeeName"
                      value={selectedQuotation.EmployeeName}
                    ></input>
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Employee ID
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="id"
                      value={selectedQuotation.id}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Designation
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="designation"
                      value={selectedQuotation.designation}
                      readOnly
                    />
                  </div>
                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Department
                    </span>
                    <input
                      className="border border-gray-300 flex-1"
                      name="department"
                      value={selectedQuotation.department}
                      readOnly
                    />
                  </div>

                  <div className="flex flex-col md:flex-row mt-2">
                    <span className="font-semibold md:w-1/3 mb-2 md:mb-0">
                      Work Days
                    </span>

                    <input
                      className="border border-gray-300 flex-1"
                      name="workdays"
                      value={selectedQuotation.workdays}
                      // value={30-totalAbsent(earnings.id)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="-mx-4 mt-8 flow-root sm:mx-0 overflow-x-auto">
              <table className="w-full border">
                <thead className="border-b border-gray-300 text-gray-900 border">
                  <tr className="text-left border shadow-md bg-gray-200">
                    <th className="ml-1 p-2 border">Earning</th>
                    <th className="ml-1 p-2 border">Amount</th>
                    <th className="ml-1 p-2 border">Deductions</th>
                    <th className="ml-1 p-2 border">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border">
                    <td className="border p-2">Salary Amount</td>
                    <td className="border p-2">
                      <input
                        className="w-full border"
                        name="salaryAmount"
                        value={selectedQuotation.salaryAmount}
                      />
                    </td>
                    <td className="border p-2">Advance Salary</td>
                    <td className="border p-2">
                      <input
                        className="w-full border"
                        name="advanceSalary"
                        value={selectedQuotation.advanceSalary}
                      />
                    </td>
                  </tr>
                  <tr className="border">
                    <td className="border p-2">Incentives</td>
                    <td className="border p-2">
                      <input
                        className="w-full border"
                        name="incentives"
                        value={selectedQuotation.incentives}
                      />
                    </td>
                    <td className="border p-2"> Absent Amount</td>
                    <td className="border p-2">
                      <input
                        className="w-full border"
                        name="totalAbsent"
                        value={selectedQuotation.totalAbsent}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>

                    <td className="border p-2"> deduction </td>
                    <td className="border p-2">
                      <input
                        className="w-full border"
                        name="deduction"
                        value={selectedQuotation.deduction}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-5 flex flex-col md:flex-row md:justify-between">
                <div className="flex flex-col md:w-1/3 mb-2 md:mb-0">
                  <span className="font-semibold">Total Earnings</span>
                  <input
                    className="border border-gray-300 mt-1 md:mt-0"
                    name="totalEarning"
                    value={selectedQuotation.totalEarning}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:w-1/3 mb-2 md:mb-0 md:mx-5">
                  <span className="font-semibold">Total Deductions</span>
                  <input
                    className="border border-gray-300 mt-1 md:mt-0"
                    name="totalDeductions"
                    value={selectedQuotation.totalDeductions}
                    readOnly
                  />
                </div>
                <div className="flex flex-col md:w-1/3 mb-2 md:mb-0">
                  <span className="font-semibold">Net Salary</span>
                  <input
                    className="border border-gray-300 mt-1 md:mt-0"
                    name="netsalary"
                    value={selectedQuotation.netsalary}
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 overflow-x-auto flex justify-center grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
              <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                <table className="w-full border">
                  <tfoot>
                    <tr className="bg-gray-100">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                      >
                        Transaction Id
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input
                          type="text"
                          id="transationId"
                          name="transactionId"
                          className="w-[80%] border border-gray-300"
                          value={selectedQuotation.transactionId}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                      >
                        Date
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input
                          type="date"
                          id="date"
                          name="date"
                          className="w-[80%] border border-gray-300"
                          value={selectedQuotation.date}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                      >
                        Signature of Employee
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input className=" p-3 w-[80%] " />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="shadow-md rounded-lg overflow-hidden w-3xl border">
                <table className="w-full border">
                  <tfoot>
                    <tr className="bg-gray-100">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                      >
                        Name of Bank
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input
                          type="text"
                          id="nameOfBank"
                          name="nameOfBank"
                          className="w-[80%] border border-gray-300"
                          value={selectedQuotation.nameOfBank}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-200">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left"
                      >
                        IFSC Code
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input
                          type="text"
                          id="irfcCode"
                          name="irfcCode"
                          className="w-[80%] border border-gray-300"
                          value={selectedQuotation.irfcCode}
                        />
                      </td>
                    </tr>
                    <tr className="bg-gray-100">
                      <th
                        scope="row"
                        className="px-4 py-2 font-bold text-sm text-gray-500 sm:pl-0 text-left "
                      >
                        Signature of Employer
                      </th>
                      <td className="px-4 py-2 font-bold text-sm text-gray-500">
                        <input className=" p-3 w-[80%] " />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="flex justify-center mt-5cd">
              <button
                style={{
                  padding: "8px 24px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  transition: "none",
                  boxShadow: "none",
                }}
                className="hide-on-print "
                onClick={handlePrint}
                type="button"
              >
                Print
              </button>

              <button
                type="submit"
                style={{
                  marginLeft: "10px",
                  padding: "8px 24px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  transition: "none",
                  boxShadow: "none",
                }}
                className="hide-on-print"
                onClick={closeModal}
              >
                cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ManageSalarySlip;