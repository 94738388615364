import React, { useState, useEffect } from "react";
import { useAuth } from "../../components/context/auth";
import axios from "axios"; // Import Axios for making HTTP requests
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ManageAprovels = () => {
  const [auth] = useAuth();
  const [leaveData, setManageAproveLeaveData] = useState([]);
  const [search, setSearch] = useState({ startDate: "", endDate: "" });
  const [formData, setFormData] = useState({
    date: "",
    staffId: "",
    leaveFrom: "",
    halfDayFrom: "",
    leaveTo: "",
    halfDayTo: "",
    numOfDays: "",
    message: "",
  });
  useEffect(() => {
    const { name, _id, designation, department, team } = auth.duser;
    setFormData((prevData) => ({
      ...prevData,
      staffName: name,
      staffId: _id,
      designation: designation,
      department: department,
      team: team, // Assuming team name should be included in the form
    }));
  }, [auth]);

  useEffect(() => {
    const fetchLeaveData = async () => {
      try {
        const response = await axios.get(
          `/api/v1/leave/getLeaveStaff/${auth.duser._id}`
        );
       
        setManageAproveLeaveData(response.data);
   
      } catch (error) {
        console.error("Error fetching leave data:", error);
      }
    };

    fetchLeaveData();
  }, []);

  function calculateLeaveDuration(leaveFrom, leaveTo) {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const fromDate = new Date(leaveFrom);
    const toDate = new Date(leaveTo);

    // Calculate the difference in milliseconds
    const diffDays = Math.round(Math.abs((fromDate - toDate) / oneDay));
    return diffDays;
  }

  return (
    <div className="addstaff-container w-full">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
        Manage Leave Approvals
      </h2>
      {/* Your table to display leave data */}
      <div className="relative overflow-x-auto max-auto overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex flex-col md:flex-row items-center w-full  md:space-y-0 md:space-x-3 md:w-auto mr-1">
          <input
            className="shadow appearance-none border mx-3 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.startDate}
            onChange={(e) =>
              setSearch({ ...search, startDate: e.target.value })
            }
            placeholder="enter start date"
          />

          <input
            className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            value={search.endDate}
            onChange={(e) => setSearch({ ...search, endDate: e.target.value })}
            placeholder="enter end date"
          />
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                S.no
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>

              <th scope="col" className="px-6 py-3">
                Leave from
              </th>
              <th scope="col" className="px-6 py-3">
                Half day From
              </th>
              <th scope="col" className="px-6 py-3">
                Leave To
              </th>
              <th scope="col" className="px-6 py-3">
                Half day To
              </th>
              <th scope="col" className="px-6 py-3">
                Days
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Mapping over the leave data and rendering each row */}
            {Array.isArray(leaveData) &&
              leaveData.filter((deduction) => {
                if (!search.startDate || !search.endDate) {
                  return true;
                } else {
                  const startDate = new Date(search.startDate);
                  const endDate = new Date(search.endDate);
                  const recordDate = new Date(deduction.date);
                  return recordDate >= startDate && recordDate <= endDate;
                }
              }).map((leave, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">
                    {" "}
                    {new Date(leave.date).toLocaleDateString("en-GB")}
                  </td>

                  <td className="px-6 py-4">
                    {new Date(leave.leaveFrom).toLocaleDateString("en-GB")}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {leaveData[index].halfDayFrom === "true" ? "Yes" : "No"}
                  </td>

                  <td className="px-6 py-4">
                    {new Date(leave.leaveTo).toLocaleDateString("en-GB")}
                  </td>
                  <td className="px-6 py-4 text-center">
                    {leaveData[index].halfDayTo === "true" ? "Yes" : "No"}
                  </td>
                  <td className="px-6 py-4 ">
                    {leaveData[index].numOfDays}{' '}
                      days
                  </td>

                  <td className="px-6 py-4">
                    {leaveData[index].status === "Pending" ? (
                      <span style={{ color: "orange" }}>Pending</span>
                    ) : leaveData[index].status === "Approved" ? (
                      <span style={{ color: "green" }}>Approved</span>
                    ) : (
                      <span style={{ color: "red" }}>Cancel</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ManageAprovels;
