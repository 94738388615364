import toast, { Toaster } from "react-hot-toast";
import React, { useState, useEffect } from "react";
import axios from "axios";
import resetPassword from "../../assets/images/resetpassword.jpg";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.css";

function ResetPassword({ onClose }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPass, setNewPass] = useState("");
  const [ConfPass, setConfPass] = useState("");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    try {
      const storedEmail = sessionStorage.getItem("read");
      if (storedEmail) {
        setEmail(storedEmail);
      }
    } catch (error) {
      console.error("Error retrieving email from sessionStorage:", error);
    }
  }, []);

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const resetHandle = async () => {
    try {
      const res = await axios.post("/api/v1/user/reset-password", {
        email,
        otp,
        newPass,
        ConfPass,
      });

      //console.log(res, "this is the data ");

      if (res.data.success) {
        toast.success(res.data.message);
        sessionStorage.removeItem("read");
        window.location.reload();
      }
      if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="modal max-w-xl mx-auto md:h-full lg:h-full sm:h-full">
      <div className="modal-container max-h-2xl">
        <div className="modal-left">
          <h1 className="modal-title pt-10 overflow-hidden">Reset Password!</h1>
          <p className="modal-desc">
            Reset your password now to secure your account access
          </p>
          <div className="input-block">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              disabled // Disable the email input field
            />
          </div>
          <div className="input-block">
            <label htmlFor="otp" className="input-label">
              OTP
            </label>
            <input
              type="text"
              name="otp"
              id="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="OTP"
            />
          </div>
          <div className="input-block">
            <label htmlFor="password" className="input-label">
              New Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              placeholder="New Password"
            />
          </div>
          <div className="input-block">
            <label htmlFor="confirmPassword" className="input-label">
              Confirm Password
            </label>
            <div className="password-input">
              <input
                type={confirmPasswordVisible ? "text" : "password"}
                name="confirmPassword"
                id="confirmPassword"
                value={ConfPass}
                onChange={(e) => setConfPass(e.target.value)}
                placeholder="Confirm Password"
              />
              <button
                className="toggle-password"
                onClick={toggleConfirmPasswordVisibility}
              >
                {confirmPasswordVisible ? "Hide" : "Show"}
              </button>
            </div>
          </div>
          <div className="modal-buttons">
            <button className="input-button" onClick={resetHandle}>
              Reset Password
            </button>
          </div>
        </div>
        <div className="modal-right">
          <img src={resetPassword} alt="" />
        </div>

        <button className="icon-button close-button" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
            <path d="M 25 3 C 12.86158 3 3 12.86158 3 25 C 3 37.13842 12.86158 47 25 47 C 37.13842 47 47 37.13842 47 25 C 47 12.86158 37.13842 3 25 3 z M 25 5 C 36.05754 5 45 13.94246 45 25 C 45 36.05754 36.05754 45 25 45 C 13.94246 45 5 36.05754 5 25 C 5 13.94246 13.94246 5 25 5 z M 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.980469 15.990234 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 z"></path>
          </svg>
        </button>
        <Toaster />
      </div>
    </div>
  );
}

export default ResetPassword;
