import React, { useState, useRef,useEffect } from "react";
import axios from "axios";

const EditReceiptModal = ({ closeModal, receiptData }) => {
  const [editedReceipt, setEditedReceipt] = useState(receiptData);
  const [newPhoto, setNewPhoto] = useState(null);
  const [displayedPhoto, setDisplayedPhoto] = useState(receiptData.photo);

  useEffect(() => {
    setDisplayedPhoto(`/api/v1/receipt/getPhoto/${editedReceipt._id}`);
  }, [editedReceipt.photo]);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setNewPhoto(file);
    setDisplayedPhoto(URL.createObjectURL(file));
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReceipt({
      ...editedReceipt,
      [name]: value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {

      const formData = new FormData();
      formData.append("companyName", editedReceipt.companyName);
      formData.append("address", editedReceipt.address);
      formData.append("contact", editedReceipt.contact);
      formData.append("gmail", editedReceipt.gmail);
      formData.append("website", editedReceipt.website);
      formData.append("gstin", editedReceipt.gstin);
      formData.append("receiptNo", editedReceipt.receiptNo);
      formData.append("date", editedReceipt.date);
      formData.append("received", editedReceipt.received);
      formData.append("againstInvoice", editedReceipt.againstInvoice);
      formData.append("forData", editedReceipt.forData);
      formData.append("sumOfRupees", editedReceipt.sumOfRupees);
      formData.append("by", editedReceipt.by);
      formData.append("transactionId", editedReceipt.transactionId);
      formData.append("amount", editedReceipt.amount);
      
      if (newPhoto) {
        formData.append("photo", newPhoto);
      }

      
      await axios.put(
        `/api/v1/receipt/updatereceipt/${receiptData._id}`,
        formData
      );
      window.location.reload();
        
    } catch (error) {
      console.error("Error updating staff:", error);
    }
  };

  return (
    <div>
      {" "}
      <div className="addstaff-container text-black p-8 px-4 py-8">
        <div className="flex justify-end">
          <button
            className="text-gray-500 hover:text-gray-700"
            style={{
              marginLeft: "10px",
              padding: "8px 24px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "none",
              boxShadow: "none",
            }}
            onClick={closeModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="max-w-7xl mx-auto rounded-lg shadow-md md:pl-4 md:pr-4 p-2">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-5">
              <img src={displayedPhoto} alt="receipt Photo"  className="h-24" />
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                className="w-2/3 "
              />

            </div>
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Company Name:
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={editedReceipt.companyName}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Address:
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={editedReceipt.address}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Contact:
                </label>
                <input
                  type="text"
                  id="contact"
                  name="contact"
                  value={editedReceipt.contact}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  E-mail:
                </label>
                <input
                  type="text"
                  id="gmail"
                  name="gmail"
                  value={editedReceipt.gmail}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  Website:
                </label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  value={editedReceipt.website}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
              <div className="flex flex-col md:flex-row mt-2">
                <label className="font-semibold md:w-1/3 mb-2 md:mb-0">
                  GSTIN:
                </label>
                <input
                  type="text"
                  id="gstin"
                  name="gstin"
                  value={editedReceipt.gstin}
                  onChange={handleInputChange}
                  className=" flex-1"
                />
              </div>
            </div>
          </div>

          <div className="mt-5">
            <h1 className="bold text- mb-4 text-3xl mb-5 mt-2 underline">
              Receipt Voucher
            </h1>
            <div className="flex flex-col md:flex-row mt-2">
              <div className="md:w-1/2 flex flex-col md:flex-row md:items-center mb-4">
                <label className="font-semibold mb-2 md:mb-0 md:mr-2">
                  Receipt No
                </label>
                <input
                  type="text"
                  id="receiptNo"
                  name="receiptNo"
                  value={editedReceipt.receiptNo}
                  onChange={handleInputChange}
                  className="w-2/3 px-2 py-1 text-black border-b border-black mb-2 md:mb-0 "
                />
              </div>
              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label className="font-semibold mb-2 md:mb-0 md:mr-2">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={editedReceipt.date}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-1 text-black border-b border-black"
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row mt-2">
              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label for="received" className="block font-bold mb-1">
                  Received with Thanks from
                </label>
                <input
                  type="text"
                  id="received"
                  name="received"
                  value={editedReceipt.received}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-1 text-black border-b border-black"
                />
              </div>

              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label className="block font-bold mb-1">Against invoice</label>
                <input
                  type="text"
                  id="againstInvoice"
                  name="againstInvoice"
                  value={editedReceipt.againstInvoice}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-1 text-black border-b border-black"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label className="block font-bold mb-1">For</label>
                <input
                  type="text"
                  id="fordata"
                  name="fordata"
                  value={editedReceipt.fordata}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-2 text-black border-b border-black"
                />
              </div>
              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label className="block font-bold mb-1">
                  the Sum of Rupees
                </label>
                <input
                  type="text"
                  id="sumOfRupees"
                  name="sumOfRupees"
                  value={editedReceipt.sumOfRupees}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-2 text-black border-b border-black"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row mt-2">
              <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                <label className="block font-bold mb-1">By</label>
                <input
                  type="text"
                  id="by"
                  name="by"
                  value={editedReceipt.by}
                  onChange={handleInputChange}
                  className="w-2/3 px-4 py-2 text-black border-b border-black"
                />
              </div>

                <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4">
                  <label for="transactionId" className="block font-bold mb-1">
                    Transaction Id
                  </label>
                  <input
                    type="text"
                    id="transactionId"
                    name="transactionId"
                    value={editedReceipt.transactionId}
                    onChange={handleInputChange}
                    className="w-2/3 px-4 py-2 text-black border-b border-black"
                  />
                </div>
            </div>

            <div className="md:w-1/2 flex flex-col md:flex-row md:items- mb-4 mt-4">
              <label className="block font-bold mb-1">Amount</label>
              <input
                type="number"
                id="amount"
                name="amount"
                value={editedReceipt.amount}
                onChange={handleInputChange}
                className="w-2/3 px-4 py-2 text-black border-b border-black"
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleUpdate}
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 mr-2"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditReceiptModal;
