import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";

const CreateTargets = () => {
  const [rows, setRows] = useState([{ id: 1, serviceName: "", amount: "" }]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedTl, setSelectedTl] = useState([]);
  const [selectedCoorinator, setSelectedCoordinator] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [team, setTeam] = useState("");
  const [designation, setDesignation] = useState("");
  const [targetFrom, setTargetFrom] = useState("");
  const [targetTo, setTargetTo] = useState("");
  const [totalDays, setTotalDays] = useState("");
  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [month, setMonth] = useState("");
  const [target, setTarget] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [deptData, setDeptData] = useState("");
  const [teams, setTeams] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const photoInputRef = useRef(null);

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setUploadFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();

      // Append all form fields

      formData.append("rows", JSON.stringify(rows));

      // Append all other input fields
      formData.append("department", selectedDepartment);
      formData.append("team", selectedTeamId);
      formData.append("month", month);
      formData.append("month", month);
      formData.append("staffName", selectedStaff);
      formData.append("staffId", staffId);
      formData.append("designation", designation);
      formData.append("targetFrom", targetFrom);
      formData.append("targetTo", targetTo);
      formData.append("totalDays", totalDays);
      formData.append("targetTo", targetTo);
      formData.append("totalAmount", totalAmount);
      formData.append("teamLeader", selectedTl);
      formData.append("coordinator", selectedCoorinator);
      formData.append("uploadFile", uploadFile);
      formData.append("target", target);

      const res = await axios.post("/api/v1/target/create", formData);
      toast.success("Targets Created Successfully");
      setSelectedDepartment("");
      setSelectedTeamId("");
      setMonth("");
      setSelectedStaff("");
      setStaffId("");
      setDesignation("");
      setDepartment("");
      setTargetFrom("");
      setTargetTo("");
      setTotalAmount("");
      setSelectedTl("");
      setSelectedCoordinator("");
      setUploadFile("");
      setTarget("");
      setTotalDays("");
    } catch (error) {
      // Handle error
      console.error("Error:", error);
    }
  };

  //get all department
  const getAlldepart = async () => {
    try {
      const data = await axios.get("/api/v1/depart/getalldepart");

      const departmentNames = data.data.data.map(
        (department) => department.name
      );
      setDepartment(data.data.data);
      setDeptData(departmentNames);
    } catch (error) {
      console.log(error);
    }
  };
  // getAll Team
  const getAllTeam = async () => {
    try {
      const response = await axios.get("/api/v1/team/getAll/");
      setTeams(response.data.allTeam);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // getAll staff
  const getAllStaff = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      setAllStaff(response.data.data);
    } catch (error) {
      console.error("Error fetching staff data:", error);
    }
  };

  /// handle department changesw
  const handleDepartmentChange = (e) => {
    const selectedDeptId = e.target.value;
    setSelectedDepartment(selectedDeptId);
    setTeam("");
  };

  // handle team changes

  const handleTeamChange = (e) => {
    const selectedTeamId = e.target.value;
    setSelectedTeamId(selectedTeamId);
    setAllStaff("");
  };

  // handlestaffchange
  const handleStaffChange = (e) => {
    const selectedStaffId = e.target.value;
    setSelectedStaff(selectedStaffId);
    // Find the selected staff object
    const selectedStaffObj = allStaff.find(
      (staff) => staff._id === selectedStaffId
    );

    if (selectedStaffObj) {
      setStaffId(selectedStaffObj.id);
      setDesignation(selectedStaffObj.designation);
    } else {
      setStaffId("");
      setDesignation("");
    }
  };

  //  filter team
  const filterdTeam = teams.filter(
    (team) => team.departId === selectedDepartment
  );

  //filter staff
  const filterdStaff = Array.isArray(allStaff)
    ? allStaff.filter((staff) => staff.team === selectedTeamId)
    : [];

  useEffect(() => {
    getAlldepart();
    getAllTeam();
    getAllStaff();
  }, [selectedDepartment, selectedTeamId, selectedStaff]);

  const handleDateChange = (e) => {
    if (e.target.id === "targetfrom") {
      setTargetFrom(e.target.value);
    } else if (e.target.id === "targetto") {
      setTargetTo(e.target.value);
    }
  };

  const calculateTotalDays = () => {
    if (targetFrom && targetTo) {
      const fromDate = new Date(targetFrom);
      const toDate = new Date(targetTo);
      const timeDiff = Math.abs(toDate - fromDate);
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      setTotalDays(days);
    }
  };

  const handleInputChange = (index, event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const updatedRows = [...rows];
    updatedRows[index][name] = value;
    setRows(updatedRows);
    calculateTotalAmount(updatedRows);
  };

  const calculateTotalAmount = (updatedRows) => {
    const total = updatedRows.reduce(
      (acc, row) => acc + parseFloat(row.amount || 0),
      0
    );
    setTotalAmount(total);
  };
  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = { id: rows.length + 1, serviceName: "", amount: "" };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };

  const handleTlChange = (e) => {
    const selectedTlOption = e.target.value;
    setSelectedTl(selectedTlOption);
  };

  const handleCoordinatorChange = (e) => {
    const selectedCoordinatorOption = e.target.value;
    setSelectedCoordinator(selectedCoordinatorOption);
  };

  return (
    <div
      className="addstaff-container text-black p-10 px-4 py-8"
      onSubmit={handleSubmit}
    >
      <form className="max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold underline mb-8 text-center">
          Create Targets
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 ">
          <div className="mb-4">
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <select
              id="department"
              name="department"
              value={selectedDepartment}
              onChange={(e) => handleDepartmentChange(e)}
              className="w-full px-4 py-2 text-black border rounded-md"
            >
              <option value="">Select Department</option>
              {Array.isArray(department) ? (
                department.map((dept, index) => (
                  <option key={index} value={dept._id}>
                    {dept.name}
                  </option>
                ))
              ) : (
                <option value="">Department data not available</option>
              )}
            </select>
          </div>

          <div className="mb-4 mx-2">
            <label htmlFor="team" className="block  text-black font-bold mb-1">
              Team
            </label>
            <select
              id="team"
              name="team"
              value={selectedTeamId}
              onChange={(e) => handleTeamChange(e)}
              className="w-full px-4 py-2 text-black-900 border rounded-md"
            >
              <option value="">Select Team</option>
              {filterdTeam.map((team) => (
                <option key={team._id} value={team._id}>
                  {team.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="month" className="block font-bold mb-1">
              Month
            </label>
            <select
              id="month"
              name="month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              className="w-full px-4 py-2 border text-black rounded-md"
            >
              <option value="">Select Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="February">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>

          <div className="mb-4 mx-2">
            <label htmlFor="staff" className="block  text-black font-bold mb-1">
              Staff
            </label>
            <select
              id="staff"
              name="staff"
              value={selectedStaff}
              onChange={(e) => handleStaffChange(e)}
              className="w-full px-4 py-2 text-black-900 border rounded-md"
            >
              <option value="">Select Staff</option>
              {filterdStaff.map((staff) => (
                <option key={staff._id} value={staff._id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>

          <>
            {/* this are the default fields visible everytime */}
            <div className="mb-3 mx-4">
              <label htmlFor="staffId" className="block font-bold mb-1">
                StaffId
              </label>
              <input
                type="text"
                id="staffId"
                name="staffId"
                value={staffId}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="designation" className="block font-bold mb-1">
                Designation
              </label>
              <input
                type="text"
                id="designation"
                name="designation"
                value={designation}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="targetFrom" className="block font-bold mb-1">
                Target From
              </label>
              <input
                type="date"
                id="targetfrom"
                name="targetFrom"
                value={targetFrom}
                onChange={handleDateChange}
                onBlur={calculateTotalDays}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="targetTo" className="block font-bold mb-1">
                Target To
              </label>
              <input
                type="date"
                id="targetto"
                name="targetTo"
                value={targetTo}
                onChange={handleDateChange}
                onBlur={calculateTotalDays}
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
            <div className="mb-3 mx-4">
              <label htmlFor="totaldays" className="block font-bold mb-1">
                Total Days
              </label>
              <input
                type="number"
                id="totaldays"
                name="totaldays"
                value={totalDays}
                readOnly
                className="w-full px-4 py-2 text-black border rounded-md"
              />
            </div>
          </>

          {/* {selectedDepartment === "65f54fb95abca0b11174d21d" && (
            <>
              this will open after selecting department It and other */}

          <div className="mb-3 mx-4">
            <label
              htmlFor="teamleader"
              className="block  text-black font-bold mb-1"
            >
              Team Leader
            </label>
            <select
              id="teamleader"
              name="teamleader"
              value={selectedTl}
              onChange={(e) => handleTlChange(e)}
              className="w-full px-4 py-2 text-black-900 border rounded-md"
            >
              <option value="">Select TeamLead</option>
              {filterdStaff.map((staff) => (
                <option key={staff._id} value={staff._id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3 mx-4">
            <label
              htmlFor="coordinator"
              className="block  text-black font-bold mb-1"
            >
              Coordinator
            </label>
            <select
              id="coordinator"
              name="coordinator"
              value={selectedCoorinator}
              onChange={(e) => handleCoordinatorChange(e)}
              className="w-full px-4 py-2 text-black-900 border rounded-md"
            >
              <option value="">Select coordinator</option>
              {filterdStaff.map((staff) => (
                <option key={staff._id} value={staff._id}>
                  {staff.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3 mx-4">
            <label htmlFor="target" className="block font-bold mb-1">
              Target
            </label>
            <textarea
              type="text"
              id="target"
              name="target"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
              className="w-full px-4 py-2 text-black border rounded-md"
            />
          </div>
          <div className="mt-2" style={{ display: uploadFile && "block" }}>
            <label htmlFor="add file" className="block font-bold mb-1">
              Add File
            </label>
            <input
              type="file"
              ref={photoInputRef} // Assign ref here
              onChange={handlePhotoChange}
            />
          </div>
          {/* </>
          )} */}
        </div>
        {selectedDepartment === "66333bf5878b056930e0407d" && (
          <>
            {/* this will open after selecting department sales */}

            <div className="mb-3 mt-3 mx-4">
              <h2 className="text-xl font-bold">Targets</h2>
            </div>

            <div>
              {rows.map((row, index) => (
                <div key={index} className="row">
                  <input
                    type="text"
                    placeholder="Service Name"
                    name="serviceName"
                    value={row.serviceName}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <input
                    type="number"
                    placeholder="Amount"
                    name="amount"
                    value={row.amount}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <button
                    onClick={() => handleRemoveRow(row.id)}
                    className="bg-red-500 hover:bg-red-600 text-black mt-6 py-2 px-8 rounded-md"
                  >
                    Remove Row
                  </button>
                </div>
              ))}
              <button
                onClick={handleAddRow}
                className="bg-green-500 hover:bg-green-600 text-black mt-6 py-2 px-8 rounded-md"
              >
                Add Row
              </button>
            </div>

            <div className="mb-3 mt-3 mx-4">
              <label htmlFor="totalamount" className="block font-bold mb-1">
                Total Target Amount
              </label>
              <input
                type="text"
                id="totalamount"
                name="totalamount"
                value={totalAmount}
                readOnly
                className="px-4 py-2 text-black border rounded-md"
                style={{ width: "38%", maxWidth: "100%" }}
              />
            </div>
          </>
        )}

        <div className="flex justify-between ">
          <button
            type="submit"
            i
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4 mx-4 mt-4"
          >
            Create
          </button>

          <button
            type="button"
            className="bg-green-500 hover:bg-green-600 text-black py-2 px-8 rounded mb-4 mx-4 mt-4"
          >
            Cancel
          </button>
        </div>
      </form>
      <Toaster />
    </div>
  );
};

export default CreateTargets;
