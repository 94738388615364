
import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from "axios";

const ViewAtModal = ({ isOpen, staff, onClose }) => {
  const [attendance, setAttendance] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [mergedData, setMergedData] = useState([]);

  const fetchAttendance = async () => {
    try {
      const res = await axios.get(`/api/v1/attendence/getSingleAt/${staff?.id}`);
      const at = res.data;
      setAttendance(at.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllHoliday = async () => {
    try {
      const holy = await axios.get('/api/v1/calenderholiday/findAllCalenderHoliday');
      const { data } = holy;
      setHolidays(data.CalenderHolidaydata);
    } catch (error) {
      console.log(error);
    }
  };

  const mergeData = (attendanceData, holidaysData) => {
    const holidayMap = new Map();
    holidaysData.forEach(holiday => {
      holiday.row.forEach(row => {
        holidayMap.set(row.date, row.description);
      });
    });

    const merged = attendanceData.map(record => ({
      ...record,
      holidayDescription: holidayMap.get(record.date) || ""
    }));

    const holidayOnlyData = holidaysData.flatMap(holiday =>
      holiday.row.map(row => {
        const date = new Date(row.date).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).replace(/\//g, '-'); // Convert date to MM-DD-YYYY format
    
        return {
          date: date,
          timeIn: "NA",
          timeOut: "NA",
          attendanceStatus: "Holiday",
          holidayDescription: row.description
        };
      })
    ).filter(holiday =>
      !attendanceData.some(record => record.date === holiday.date)
    );

    return [...merged, ...holidayOnlyData].sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  useEffect(() => {
    if (isOpen) {
      fetchAttendance();
      fetchAllHoliday();
    }
  }, [isOpen]);

  useEffect(() => {
    if (attendance?.length > 0) {
      setMergedData(mergeData(attendance, holidays));
    }
  }, [attendance, holidays]);

  const totalPresent = (attendanceData) => {
    if (!Array.isArray(attendanceData)) return 0;
    return attendanceData.reduce((count, record) => {
      return record.attendanceStatus === "Present" ? count + 1 : count;
    }, 0);
  };

  const totalHalfDay = (attendanceData) => {
    if (!Array.isArray(attendanceData)) return 0;
    return attendanceData.reduce((count, record) => {
      return record.attendanceStatus === "HalfDay" ? count + 1 : count;
    }, 0);
  };

  const totalAbsent = (attendanceData) => {
    if (!Array.isArray(attendanceData)) return 0;
    return attendanceData.reduce((count, record) => {
      return record.attendanceStatus === "Absent" ? count + 1 : count;
    }, 0);
  };

  const totalAttendance = (attendanceData) => {
    if (!Array.isArray(attendanceData)) return 0;
    return attendanceData.reduce((count, record) => {
      if (record.attendanceStatus === "Present") {
        return count + 1;
      } else if (record.attendanceStatus === "HalfDay") {
        return count + 0.5;
      } else {
        return count;
      }
    }, 0);
  };

  function formatDate(date) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      const parts = date.split('-');
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
      const [month, day, year] = date.split('/');
      return `${day}-${month}-${year}`;
    }

    return date;
  }

  return (
    <Transition.Root show={isOpen} as="div">
      <Dialog as="div" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="transition-all transform duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition-all transform duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="fixed inset-0 flex items-center justify-center xl:pl-48 lg:pl-72 md:pl-8">
            <div className="bg-white rounded-lg p-8 w-full max-w-5xl mx-auto">
              <div className="flex items-center justify-between mb-4">
                <Dialog.Title
                  as="h2"
                  className="text-lg text-center font-semibold text-blue-900 flex-grow"
                >
                  {staff?.name?.toUpperCase()}'S ATTENDANCE
                </Dialog.Title>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <FaTimes className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>

              <div style={{ maxHeight: '530px', overflowY: 'auto' }}>
                <TableContainer component={Paper} style={{ maxHeight: '530px', overflow: 'auto' }}>
                  <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead className="bg-stone-200" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Time In</TableCell>
                        <TableCell>Time Out</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Holiday Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(mergedData) && mergedData.map((i, index) => (
                        <TableRow key={index}>
                          <TableCell>{formatDate(i.date)}</TableCell>
                          <TableCell>{i.timeIn}</TableCell>
                          <TableCell>{i.timeOut}</TableCell>
                          <TableCell>{i.attendanceStatus}</TableCell>
                          <TableCell>{i.holidayDescription}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                  <TableHead className="bg-stone-200">
                    <TableRow>
                      <TableCell>Total Half Day</TableCell>
                      <TableCell>Total Present</TableCell>
                      <TableCell>Total Absent</TableCell>
                      <TableCell>Total Attendance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{totalHalfDay(attendance)}</TableCell>
                      <TableCell>{totalPresent(attendance)}</TableCell>
                      <TableCell>{totalAbsent(attendance)}</TableCell>
                      <TableCell>{totalAttendance(attendance)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewAtModal;
