
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewAtModal from "../admin/modals/ViewAtModal";
import { useAuth } from "../../components/context/auth";

const ViewAttendence = () => {
  const [staffData, setStaffData] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [departments, setDepartments] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [auth] = useAuth();
  const staffId = auth?.duser.id;

  useEffect(() => {
    fetchData();
    fetchDepartments();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/v1/staff/getAllStaff");
      if (response.data && response.data.data) {
        const allStaff = response.data.data.filter(
          (entry) => entry && entry.id && entry.name
        );
        setStaffData(allStaff);
        setFilteredStaff(allStaff.filter((staff) => staff.id === staffId));
      }
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get(`/api/v1/depart/getalldepart`);
      if (Array.isArray(response.data.data)) {
        const departmentsMap = {};
        response.data.data.forEach((department) => {
          departmentsMap[department._id] = department.name;
        });
        setDepartments(departmentsMap);
      } else {
        console.error("Departments data is not an array:", response.data.data);
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleView = (attendance) => {
    setSelectedStaff(attendance);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedStaff(null);
    setModalOpen(false);
  };



 

  return (
    <div className="main-container">
      <h2 className="text-3xl font-bold mb-8 text-center underline">
         Attendance 
      </h2>
      
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        {filteredStaff && filteredStaff.length > 0 ? (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-900 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">S.no</th>
                <th scope="col" className="px-6 py-3">Staff ID</th>
                <th scope="col" className="px-2 py-3">Staff Name</th>
                <th scope="col" className="px-2 py-3">Designation</th>
                <th scope="col" className="px-6 py-3">Contact No</th>
                <th scope="col" className="px-6 py-3">Department</th>
                
                <th scope="col" className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredStaff.map((attendance, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="row" className="px-6 py-4">
                    {index + 1}
                  </th>
                  <td className="px-6 py-4">{attendance.id}</td>
                  <td className="px-2 py-4">{attendance.name}</td>
                  <td className="px-2 py-4">{attendance.designation}</td>
                  <td className="px-6 py-4">{attendance.contactNumber}</td>
                  <td className="px-6 py-4">
                    {departments[attendance.department]}
                  </td>
                  
                
                  <td className="px-6 py-4 flex gap-2">
                    <Link
                      to="#"
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      onClick={() => handleView(attendance)}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <ViewAtModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        staff={selectedStaff}
      />
      <ToastContainer />
    </div>
  );
};

export default ViewAttendence;
