import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../components/context/auth";
import { ToastContainer, toast } from "react-toastify";
const ApplyLeave = () => {
  const [auth] = useAuth();
  const [teamNames, setTeamNames] = useState("");
  const [departName, setDepartmentName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startHalfDay, setStartHalfDay] = useState(false);
  const [endHalfDay, setEndHalfDay] = useState(false);
  const [numOfDays, setNumOfDays] = useState(0);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if(start>end){
        toast.error("you can not select previous date")
      
        return 
      }
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24) + 1);

      let totalDays = diffDays;
      if (startHalfDay) totalDays -= 0.5;
      if (endHalfDay) totalDays -= 0.5;

      setNumOfDays(totalDays);
    }
  }, [startDate, endDate, startHalfDay, endHalfDay]);

  useEffect(() => {
    const { name, _id, designation, department, team } = auth.duser;
    setFormData((prevData) => ({
      ...prevData,
      staffName: name,
      staffId: _id,
      designation: designation,
      department: department,
      team: team, // Assuming team name should be included in the form
    }));
  }, [auth]);

  const [formData, setFormData] = useState({
    date: "",
    staffId: "",
    leaveFrom: startDate,
    halfDayFrom: startHalfDay,
    leaveTo: endDate,
    halfDayTo: endHalfDay,
    numOfDays: "",
    message: "",
  });

  const getTeam = async () => {
    try {
      const res = await axios.get(`/api/v1/team/getById/${auth.duser.team}`);
      const { data } = res;
      setTeamNames(data.name);
    } catch (error) {
      console.log(error);
    }
  };
  const getDepartment = async () => {
    try {
     
      const res = await axios.get(
        `/api/v1/depart/getSingledepart/${auth.duser.department}`
      );
      const { data } = res;
      
      setDepartmentName(data.data.name);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTeam();
    getDepartment();
  }, []);

 
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare the leave application data
      const leaveData = {
        ...formData,
        leaveFrom:startDate,
        leaveTo:endDate,
        halfDayFrom:startHalfDay,
        halfDayTo:endHalfDay,
        numOfDays:numOfDays,
        staffId: auth.duser._id,
      };
  
   

      const response = await axios.post("/api/v1/leave/applyleave", leaveData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      // Handle the response
      if (response.data.message === "Leave Applied Successfully") {
        toast.success("Leave Applied Successfully");
        // Clear form fields after successful submission
        setFormData({
          ...formData,
          leaveFrom: "",
          halfDayFrom: false,
          leaveTo: "",
          halfDayTo: false,
          numOfDays: "",
          message: "",
        });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
     
      toast.error("An error occurred while applying for leave. Please try again later.");
    }
  };
  
  
 
  

  const handleClear = () => {
    // Clear form fields on Clear button click
    setFormData({
      date: "",
      team: "",
      staffName: "",
      staffId: "",
      designation: "",
      department: "",
      leaveFrom: "",
      halfDayFrom: false,
      leaveTo: "",
      halfDayTo: false,
      numOfDays: "",
      message: "",
    });
  };

  return (
    <div className="addstaff-container text-black p-10 px-4 py-8 center  bg-red-500 rounded-lg shadow-md flex  justify-center item-center">
      <form className="  p-6 md:w-1/2" onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold underline text-center mb-6">
          Apply For Leave
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1  sm:w-full lg:grid-cols-1 gap-4 ">
          <div>
            <label htmlFor="date" className="block font-bold mb-1">
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="team" className="block font-bold mb-1">
              Team
            </label>
            <input
              type="text"
              id="team"
              name="team"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={teamNames}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="staffName" className="block font-bold mb-1">
              Staff Name
            </label>
            <input
              type="text"
              id="staffName"
              name="staffName"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={auth.duser.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="staffId" className="block font-bold mb-1">
              Staff ID
            </label>
            <input
              type="text"
              id="staffId"
              name="staffId"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={auth.duser.id}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="designation" className="block font-bold mb-1">
              Designation
            </label>
            <input
              type="text"
              id="designation"
              name="designation"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={auth.duser.designation}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="department" className="block font-bold mb-1">
              Department
            </label>
            <input
              type="text"
              id="department"
              name="department"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={departName}
              onChange={handleChange}
            />
          </div>

          <div className="flex items-center justify-between mt-4 sm:gap-2">
            <label htmlFor="leaveFrom" className="block font-bold mb-1">
              Leave from
            </label>
            <input
              type="date"
              id="leaveFrom"
              name="leaveFrom"
              className="w-1/2 py-1 border text-black rounded-md mb-2"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <div className="flex items-center justify-between  sm:gap-2">
              <label
                htmlFor="halfDayFrom"
                className="block font-bold text-green mb-1"
              >
                Half day
              </label>
              <input
                name="halfDayFrom"
                id="halfDayFrom"
                type="checkbox"
                checked={startHalfDay}
                onChange={(e) => setStartHalfDay(e.target.checked)}
                className="form-checkbox h-5 w-5 text-green-600"
              />
            </div>
          </div>

          <div className="flex items-center justify-between mt-4 mb-4 sm:gap-2">
            <label htmlFor="leaveTo" className="block font-bold mb-1 m-2">
              Leave To
            </label>
            <input
              type="date"
              id="leaveTo"
              name="leaveTo"
              className="w-1/2 py-1 border text-black rounded-md mb-2"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <div className="flex items-center justify-between  mb-4 sm:gap-2">
              <label
                htmlFor="halfDayTo"
                className="block font-bold text-green mb-1"
              >
                Half day
              </label>
              <input
                name="halfDayTo"
                id="halfDayTo"
                type="checkbox"
                checked={endHalfDay}
                onChange={(e) => setEndHalfDay(e.target.checked)}
                className="form-checkbox h-5 w-5 text-green-600"
              />
            </div>
          </div>

          <div>
            <label htmlFor="numOfDays" className="block font-bold mb-1 mt-2">
              Number of days
            </label>
            <input
              type="text" // Change input type to text
              id="numOfDays"
              name="numOfDays"
              className="w-full py-2 border text-black rounded-md mb-2 p-2"
              value={numOfDays} // Use numOfDays from state
              readOnly // Make the input readOnly
              // onChange={handleChange} // handleChange not needed here
            />
          </div>
          <div>
            <label htmlFor="message" className="block font-bold mb-1">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              className="w-full py-2 border text-black rounded-md mb-2"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex justify-between mt-2 mb-5">
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-black mb-5 py-2 px-10 rounded"
          >
            Apply
          </button>
          <button
            type="button"
            className="bg-gray-500 hover:bg-gray-600 text-black py-2 mb-5 px-10 rounded"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default ApplyLeave;
